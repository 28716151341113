<template>
    <div class="row" style="margin-top: -30px;">
        <div class="col-12">
            <h4>Gravames</h4>
            <!-- filter start -->
            <div class="card col-12">
                <div class="card-header">
                    <div class="row col-12 justify-content-start">

                        <!-- Botão Filtrar -->
                        <div class="col-md-6 col-lg-6 col-sm-12 mt-2 p-2 pr-4 margin-bt">
                            <div class="row">

                                <div class="col-md-12 pad0 pl-3 pr-3">
                                    <div class="row">
                                        <div>
                                            <button class="btn btn-danger btn-filter dropdown-toggle" type="button"
                                                id="dropdownContract" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                Adicionar Registro de Gravame
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right"
                                                style="border: 1px solid rgba(0,0,0,.15) !important;"
                                                aria-labelledby="dropdownContract">
                                                <a class="dropdown-item" @click="addGravame">Adicionar
                                                    Novo Gravame</a>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn btn-sm btn-info ml-3" @click="openModalExport()">Exportar
                                                Relatório de Gravames Cadastrados</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- filter end -->


            <!-- Tabela de Gravames -->
            <vue-good-table mode="remote" :columns="columns" :rows="gravame" :pagination-options="{
                enabled: true,
                jumpFirstOrLast: true,
                firstLabel: 'Primeira Página',
                lastLabel: 'Última Página',
                nextLabel: 'Próximo',
                prevLabel: 'Anterior'

            }" :totalRows="totalRecords" :isLoading.sync="loading" @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange" @on-sort-change="onSortChange" :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    placeholder: 'Digite para buscar',
                }" @on-search="onSearch" styleClass="vgt-table">


                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'" class="d-flex justify-content-center">
                        <button class="btn btn-sm btn-warning mr-2" @click="editarGravame(props.row.id)">Editar</button>
                        <button class="btn btn-sm btn-info mr-2" @click="showGravame(props.row.id)">Visualizar</button>
                    </span>
                </template>
            </vue-good-table>


        </div>

        <div class="modal fade" id="modalExportItems" tabindex="-1" role="dialog"
            aria-labelledby="modalExportItemsTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalExportItemsTitle">Exportar Relatório de Gravames Cadastrados
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h6 style="text-align: center;" class="mt-2">Selecione o Período Desejado:</h6>
                        <div class="row">
                            <div class="col-md-6 p-4">
                                <div class="form-group date-custom">
                                    <datepicker :language="calendarLanguage" v-model="dataInicial"
                                        placeholder="Data Inicial" class="btn-filter" />
                                </div>
                            </div>
                            <div class="col-md-6 p-4">
                                <div class="form-group date-custom">
                                    <datepicker :language="calendarLanguage" v-model="dataFinal"
                                        placeholder="Data Final" class="btn-filter" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="exportGravame()">
                            <i class="far fa-file-excel"></i> Exportar para Excel</button>
                    </div>
                </div>
            </div>
        </div>

        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div>
</template>
<script>


import { VueGoodTable } from "vue-good-table";
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import 'vue-good-table/dist/vue-good-table.css'
import VuePagination from "../../Paginator.vue";
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            calendarLanguage: ptBR,
            dataInicial: "",
            dataFinal: "",
            seachTimeOut: null,
            page: 1,
            isLoading: false,
            loading: false,
            sortField: null,
            totalRecords: 0,
            currentPage: 1,
            perPage: 10,
            gravame: [],
            columns: [
                {
                    label: 'Nº Gravame',
                    field: 'numero_gravame',
                    headerClass: 'text-center'

                },
                {
                    label: 'CNPJ/CPF Parte',
                    field: 'parte.cpf_cnpj'
                },
                {
                    label: 'CNPJ/CPF Contraparte',
                    field: 'contraparte.cpf_cnpj'
                },
                {
                    label: 'Data Assinatura',
                    field: 'data_assinatura',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: 'dd/MM/yyyy',
                },
                {
                    label: 'Data de Vencimento',
                    field: 'data_vencimento',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: 'dd/MM/yyyy',
                },
                {
                    label: 'Ações',
                    field: 'actions',
                    align: 'center'
                },
            ],
        };
    },
    mounted() {
        this.loadGravame(this.page, this.perPage);

    },
    methods: {
        exportGravame() {
            const dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
            const dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";
            const urlApi = `/api/export/gravame?start_date=${dtInicial}&end_date=${dtFinal}`;

            this.isLoading = true;
            this.loading = true;

            axios.get(urlApi, { responseType: 'blob' }).then((res) => {
                // Crie um objeto Blob com o tipo de resposta
                const fileURL = window.URL.createObjectURL(new Blob([res.data]));

                // Crie um link temporário para download
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Relatorio_' + dtInicial + '_' + dtFinal + '.xlsx'); // Nome do arquivo               

                // Simule o clique no link para iniciar o download
                document.body.appendChild(fileLink);
                fileLink.click();

                // Remova o link depois de usá-lo
                document.body.removeChild(fileLink);
                window.URL.revokeObjectURL(fileURL);

                // Sucesso - Mostre o alerta
                this.$swal({
                    title: "Sucesso",
                    text: "Relatório Exportado com Sucesso",
                    icon: "success"
                }).then(() => {
                    $("#modalExportItems").modal('hide');
                    this.isLoading = false;
                    this.loading = false;
                    this.dataInicial = "",
                        this.dataFinal = ""
                });
            }).catch((err) => {
                this.$swal({
                    title: "Erro",
                    text: "Ocorreu um erro ao exportar o relatório.",
                    icon: "error"
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        openModalExport() {
            $("#modalExportItems").modal('show');

        },
        editarGravame(id) {
            let url = `/portal/gravame/edit/${id}`;

            location.href = url;
        },
        showGravame(id) {
            let url = `/portal/gravame/show/${id}`;

            location.href = url;
        },
        onSearch(params) {
            const _this = this;
            clearTimeout(_this.seachTimeOut);
            _this.seachTimeOut = setTimeout(function () {
                _this.loadGravame(_this.currentPage, _this.perPage, params.searchTerm);
            }, 1000);

        },

        onPageChange(params) {
            const newPage = params.currentPage || 1;

            // Evitar loop: só fazer a chamada se a página mudou
            if (this.currentPage !== newPage) {
                this.currentPage = newPage;
                this.loadGravame(this.currentPage, this.perPage, this.searchTerm);
            }
        },

        onPerPageChange(params) {
            const newPerPage = params.currentPerPage || 10;

            // Evitar loop: só fazer a chamada se o número de registros por página mudou
            if (this.perPage !== newPerPage) {
                this.perPage = newPerPage;
                this.loadGravame(this.currentPage, this.perPage, this.searchTerm);
            }
        },

        loadGravame(page, perPage, searchTerm = '', sortField = '') {
            let _this = this;

            // Prevenir múltiplas chamadas enquanto já está carregando
            if (_this.isLoading) return;

            _this.isLoading = true;
            _this.loading = true;

            axios
                .get(`/api/gravame`, {
                    params: {
                        page: page,
                        per_page: perPage,
                        searchTerm: searchTerm,
                        sortField: sortField !== '' ? sortField[0] : ''
                    }
                })
                .then((res) => {
                    _this.gravame = res.data.data;
                    _this.totalRecords = res.data.meta.total;
                    _this.isLoading = false;
                    _this.loading = false;
                })
                .catch((err) => {
                    _this.isLoading = false;
                    _this.loading = false;
                });
        },
        addGravame() {

            let url = `/portal/gravame/add`;

            location.href = url;
        },

        openModalGravame(item) {
            $("#modalAddGravame").modal('show');
        },
        filtrarGravames() {
            // Função para filtrar gravames com base nos filtros aplicados
        },
        onSortChange(params) {
            this.sortField = params
            this.loadGravame(this.currentPage, this.perPage, this.searchTerm, this.sortField);
        },
    },
    components: {
        VuePagination,
        VueGoodTable,
        Datepicker,
    },
};


</script>
<style>
.vgt-left-align,
.vgt-right-align {
    text-align: center;
}

.modal-content {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

#filtro.vs--searchable .vs__dropdown-toggle {
    height: 30px !important;
    background-color: #fdfdff !important;
    text-align: center;
    padding-left: 25px;
    font-size: 12px;
    border-color: #e4e6fc !important;
    border-radius: 30px;
}

#filtro.vs__selected {
    margin: 10px 2px 0 !important;
}

.contracts-filter input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
    text-align: center;
}

.margin-bt {
    margin-left: -30px;
}
</style>
