<template>
    <div class="card p-4">
        <div class="row">
            <div class="col">
                <!-- table -->
                <div v-if="!loading && contratoStatus.length > 0">
                    <vue-good-table
                        :columns="columns"
                        :rows="contratoStatus"
                        :pagination-options="{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,
						}"
                        :search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}"
                        styleClass="vgt-table"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field ==='actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)">
                                        <i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger"
                                            @click="deleteData(props.row.id, props.row.index)"><i
                                        class="far fa-trash-alt"></i></button>
								</div>
							</span>
                            <span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
                        </template>
                    </vue-good-table>
                </div>
                <div v-if="!contratoStatus.length && !loading" class="text-center p-3 text-muted">
                    <h5>Nenhum Status encontrado!</h5>
                </div>
                <!-- end table -->
                <div class="text-center p-4 text-muted" v-if="loading">
                    <h5>Carregando...</h5>
                    <p>Aguarde até que os dados sejam carregados...</p>
                </div>
            </div><!-- col -->

            <!--   modal cadastro de      -->
            <div class="modal fade" id="modalContratoStatus" tabindex="-1" aria-labelledby="modalContratoStatusLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalContratoStatusLabel">Contrato Status</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    @click="resetForm">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form method="POST" v-on:submit.prevent="save">
                                <div class="row">

                                    <!--nome-->
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="form-control-label" for="input-nome">Nome</label>
                                            <input type="text" id="input-nome" required class="form-control"
                                                   v-model="contrato.nome">
                                        </div><!-- form group -->
                                    </div><!-- col-md--->
                                    <!--nome-->
                                    <!--retorno_fila -->
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="tipo">Retorno Fila</label>
                                            <select class="form-control" pattern="0|1" name="retorno_fila" id="retorno_fila" required v-model="contrato.retorno_fila" >
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!--retorno_fila-->

                                    <!-- retorno_tempo -->
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="form-control-label" for="input-retorno_tempo">Retorno
                                                Tempo</label>
                                            <input type="number" id="input-retorno_tempo" class="form-control" required
                                                   v-model="contrato.retorno_tempo">
                                        </div><!-- form group -->
                                    </div><!-- col-md--->
                                </div>  <!-- retorno_tempo -->
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary">Salvar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div><!--    modal cadastro de marcas   -->
        </div><!-- row -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- card -->
</template>

<script>

//import "vue-good-table/dist/vue-good-table.css";
//import {VueGoodTable} from "vue-good-table";

export default {
    data() {
        return {
            contratoStatus: [],
            nome:'',
            retorno_fila:'',
            retorno_tempo:'',
            isEditContratoStatus: false,
            contratoStatusReturnID: '',
            contrato: {
                id: '',
                nome: '',
                retorno_fila: '',
                retorno_tempo: '',
            },
            fullPage: true,
            columns: [
                {
                    label: "Nome",
                    field: "nome",
                },
                {
                    label: "Retorna para fila?",
                    field: "retorno_fila",
                },
                {
                    label: "Tempo de retorno",
                    field: "retorno_tempo",
                },
                {
                    label: "Criado Em",
                    field: "created_at",
                    formatFn: this.formatDate,
                },
                {
                    label: "Ações",
                    field: "actions",
                    html: true,
                }
            ],
            errors: [],
            loading: false,
            message: ''
        }
    },
    mounted() {
        this.loadContratoStatus();
    },

    methods: {
        getData(key) {
            //return JSON.parse(this.detrans)[key];
        },

        loadContratoStatus() {
            let _this = this;
            _this.contratoStatus = [];
            _this.loading = true;
            axios.get("/admin/contratostatus").then((res) => {
                _this.contratoStatus = _this.contratoStatus.concat(res.data);
                _this.loading = false;
						}).catch(() => {
							_this.loading = false;
						});
				},

        save() {
            //validate if is edit
            if (this.isEditContratoStatus == true) {

                //edit
                let _this = this;
                $("#modalContratoStatus").modal("hide");
                _this.errors = [];
                _this.message = "";
                _this.loading = true;

                const dataInfo = {
                    "nome": _this.contrato.nome,
                    "retorno_fila": _this.contrato.retorno_fila,
                    "retorno_tempo": _this.contrato.retorno_tempo,
                };

                axios.patch(`/admin/contratostatus/${this.contratoStatusReturnID}`, dataInfo).then(() => {
                    _this.contratoStatus = ""
                    _this.isEditContratoStatus = false;
                    _this.contratoStatusReturnID = "";
                    _this.resetForm()
                    _this.loadContratoStatus()
                    _this.loading = false;

                    //show confirm alert
                    _this.$swal({
                        title: "Sucesso",
                        text: "Marca atualizada com sucesso!",
                        icon: "success"
                    })

                }).catch(() => {
                    _this.loading = false;
                    //show error alert
                    _this.$swal({
                        title: "Erro",
                        text: "Falha ao atualizar Marca. Tente novamente!",
                        icon: "error"
                    });
                });
            } //edit
            else {
                //new
                let _this = this;
                $("#modalContratoStatus").modal("hide");
                _this.errors = [];
                _this.message = "";
                _this.loading = true;

                const dataInfo = {
                    "nome": _this.contrato.nome,
                    "retorno_fila": _this.contrato.retorno_fila,
                    "retorno_tempo": _this.contrato.retorno_tempo,
                };

                axios.post("/admin/contratostatus", dataInfo).then(() => {

                    _this.loading = false;
                    _this.resetForm()
                    _this.loadContratoStatus();

                    //show confirm alert
                    _this.$swal({
                        title: "Sucesso",
                        text: "Status cadastrada com sucesso!",
                        icon: "success"
                    });
                }).catch((err) => {

                    _this.loading = false;

                    //show error alert
                    _this.$swal({
                        title: "Erro",
                        text: "Falha ao cadastrar Status. Tente novamente!",
                        icon: "error"
                    });
                });

            } //else save
        },

        editData(item) {
            this.contrato = item;
            this.isEditContratoStatus = true;
            this.contratoStatusReturnID = item.id;
            $("#modalContratoStatus").modal('show');
        },

        deleteData(ID, index) {
            let _this = this;
            //show alert confirmation
            _this.$swal({
                title: "Excluir Status de Contrato",
                text: "Tem certeza que deseja excluir esse status de contrato?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Excluir",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.delete("/admin/contratostatus/" + ID).then(() => {

                        _this.contratoStatus.splice(index, 1)
                        _this.loadContratoStatus()

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Status de contrato excluído com sucesso!",
                            icon: "success"
                        });

                    }).catch(() => {

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao excluir o status de contrato. Tente novamente!",
                            icon: "error"
                        });
                    });
                }//only close
                //end validate isConfirmed
            });
            //end alert confirmation
        },
        resetForm() {
            this.contrato = {
                id: '',
                nome: '',
                retorno_fila: '',
                retorno_tempo: '',
            };
        },

        formatCurrency: function (value) {
            return Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(value);
        },
        formatDate: function (value) {
            return moment(String(value)).format("DD/MM/YYYY H:m:s");
        }
    }
}
</script>
