<template>
    <div class="row">
        <div class="col">

            <!-- table -->
            <div class="card p-4">
                <vue-good-table :columns="columns" :rows="users" :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    position: 'bottom',
                    dropdownAllowAll: true,
                    perPageDropdown: [10, 30, 50],
                    nextLabel: 'Próximo',
                    prevLabel: 'Anterior',
                    rowsPerPageLabel: '',
                    ofLabel: 'de',
                    allLabel: 'Todos',
                    pageLabel: '',
                    perPageDropdownEnabled: true,
                    compactMode: true,

                }" :search-options="{
                    enabled: true,
                    placeholder: 'Search this table',
                }" styleClass="vgt-table">
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == '_status'">
                            <span class="alert-table alert alert-success" v-if="props.row.status == 1">Ativo</span>
                            <span class="alert-table alert alert-danger" v-if="props.row.status == 0">Inativo</span>
                        </span>
                        <span v-if="props.column.field == 'actions'">
                            <div class="text-center">
                                <button class="btn btn-sm btn-outline-danger" @click="resetPassword(props.row.id)"><i
                                        class="fa fa-key"></i></button>
                                <button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i
                                        class="far fa-edit"></i></button>
                                <button v-show="false" class="btn btn-sm btn-outline-danger"
                                    @click="deleteData(props.row.id, props.row.index)"><i
                                        class="far fa-trash-alt"></i></button>
                                <button class="btn btn-sm btn-outline-danger"
                                    @click="disableData(props.row.id, props.row.index)" v-if="props.row.status == 1"><i
                                        class="fas fa-ban"></i></button>
                                <button class="btn btn-sm btn-outline-success"
                                    @click="enableData(props.row.id, props.row.index)" v-if="props.row.status == 0"><i
                                        class="fas fa-check"></i></button>
                            </div>
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
                <div v-if="!users.length && !loading" class="text-center p-3 text-muted">
                    <h5>Nenhum usuário(a) encontrado(a)!</h5>
                </div>
            </div>
            <!-- end table -->
            <div class="text-center p-4 text-muted" v-if="loading">
                <h5>Carregando...</h5>
                <p>Aguarde até que os dados sejam carregados...</p>
            </div>
        </div><!-- col -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- row -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
    data() {
        return {
            users: [],
            loading: false,
            columns: [
                {
                    label: "Instituição",
                    field: "banco",
                },
                {
                    label: "Nome",
                    field: "nome",
                }, {
                    label: "E-mail",
                    field: "email",
                },
                {
                    label: "Grupo de Acesso",
                    field: "permission"
                },
                {
                    label: 'Grupo Econ?',
                    field: "user_gp_econ"
                },
                {
                    label: "Status",
                    field: "_status"
                }, {
                    label: "Ações",
                    field: "actions",
                    html: true,
                },
            ],
        }
    },
    mounted() {
        this.loadUsers();
    },
    methods: {
        loadUsers() {
            let _this = this;
            _this.loading = true;
            axios.get('/admin/users').then((res) => {
                _this.users = _this.users.concat(res.data);
                _this.loading = false;
            }).catch((err) => {
                _this.loading = false;
            });
        },
        deleteData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Excluir usuário(a)",
                text: "Tem certeza que deseja excluir esse(a) usuário(a)?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Excluir',
                cancelButtonText: 'Cancelar'
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.delete('/admin/users/' + ID).then((res) => {

                        _this.users.splice(index, 1);
                        _this.total = _this.total - 1;
                        _this.loadUsers();

                        //show confirm alert
                        _this.$swal({
                            title: 'Sucesso',
                            text: 'Usuário(a) excluído com sucesso!',
                            icon: 'success'
                        });

                    }).catch(error => {

                        //show error alert
                        _this.$swal({
                            title: 'Erro',
                            text: 'Falha ao excluir o(a) usuário(a). Tente novamente!',
                            icon: 'error'
                        });

                    });

                } else {

                    //only close

                }//end validate isConfirmed

            });
            //end alert confirmation

        },
        disableData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Desativar Usuário(a)",
                text: "Tem certeza que deseja desativar esse Usuário(a)?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Desativar",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.get("/admin/users/" + ID + "/disable").then((res) => {

                        //_this.users.splice(index, 1);
                        _this.users.map((row) => {
                            if (row.id == ID) {
                                row.status = 0;
                            }
                        });

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Usuário(a) desativado com sucesso!",
                            icon: "success"
                        });

                    }).catch(error => {

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao desativar usuário(a). Tente novamente!",
                            icon: "error"
                        });

                    });

                }//end validate isConfirmed

            });
            //end alert confirmation
        },
        editData(ID) {
            location.href = "/admin/users/" + ID + "/edit";
        },
        enableData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Ativar Usuário(a)",
                text: "Tem certeza que deseja ativar esse Usuário(a)?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ativar",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.get("/admin/users/" + ID + "/enable").then((res) => {

                        //_this.users.splice(index, 1);
                        _this.users.map((row) => {
                            if (row.id == ID) {
                                row.status = 1;
                            }
                        });

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Usuário(a) ativado com sucesso!",
                            icon: "success"
                        });

                    }).catch(error => {

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao ativar usuário(a). Tente novamente!",
                            icon: "error"
                        });

                    });

                }//end validate isConfirmed

            });
            //end alert confirmation
        },
        resetPassword(ID) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Resetar Senha do Usuário(a)",
                text: "Tem certeza que deseja resetar a senha do Usuário(a)?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Resetar Senha",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {
                    axios.get(`/admin/users/${ID}/resetPassword`)
                        .then(res => {
                            _this.$swal({
                                title: "Sucesso",
                                text: "Reset de senha realizado com sucesso com sucesso, em breve o usuário receberá um email com instuções!",
                                icon: "success"
                            });
                        }).catch(err => {

                            _this.$swal({
                                title: "Erro",
                                text: "Falha ao realizar o reset de senha. Tente novamente!",
                                icon: "error"
                            });
                        });
                }
            });
            //end alert confirmation
        }
    },
    components: {
        VueGoodTable,
    }
}
</script>
