<template>
    <div class="card p-4">
        <div class="col">
            <button type="button" class="btn btn-success btn-right btn-sincroniza-marca"
                    @click="syncMarcas"><i :class="iconSyncMarca"></i> {{ labelSync }}
            </button>
        </div>
        <div class="row">
            <div class="col">
                <!-- table -->
                <div v-if="!loading && marcas.length > 0">
                    <vue-good-table
                        :columns="columns"
                        :rows="marcas"
                        :pagination-options="{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,

						}"
                        :search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}"
                        styleClass="vgt-table"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field ==='actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)">
                                        <i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger"
                                            @click="deleteData(props.row.id, props.row.index)"><i
                                        class="far fa-trash-alt"></i></button>
								</div>
							</span>
                            <span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
                        </template>
                    </vue-good-table>
                </div>
                <div v-if="!marcas.length && !loading" class="text-center p-3 text-muted">
                    <h5>Nenhuma marca encontrada!</h5>
                </div>
                <!-- end table -->
                <div class="text-center p-4 text-muted" v-if="loading">
                    <h5>Carregando...</h5>
                    <p>Aguarde até que os dados sejam carregados...</p>
                </div>
            </div><!-- col -->

            <!--   modal cadastro de marcas     -->
            <div class="modal fade" id="modalNovaMarca" tabindex="-1" aria-labelledby="modalApiReturnsLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalApiReturnsLabel">Marcas</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    @click="resetForm">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form method="POST" v-on:submit.prevent="save">

                                <div class="row">
                                    <!--Tipos -->
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="tipo">Tipos</label>
                                            <select class="form-control" v-model="marca.tipo_id" name="tipo" id="tipo" required>
                                                <option value=""></option>
                                                <option v-for="t in tipos" :value="t.id" v-bind:key="t.id">
                                                    {{t.nome}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <!--Tipos-->
                                    <!-- marca -->
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label class="form-control-label" for="input-status_api">Marca</label>
                                            <input type="text" id="input-marca"
                                                   class="form-control" required v-model="marca.nome">
                                            <div class="invalid-feedback">
                                                <p></p>
                                            </div>
                                        </div><!-- form group -->
                                    </div><!-- col-md--->
                                </div>  <!-- marca -->
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary">{{ labelSaveEdit }}</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div><!--    modal cadastro de marcas   -->
        </div><!-- row -->

        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- card -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";

export default {
    data() {
        return {
            marcas: [],
            tipos:[],
            labelTitle: "Nova marca",
            isEditMarca: false,
            loading: false,
            labelSaveEdit: "Salvar",
            iconSyncMarca: "fas fa-sync",
            labelSync: "Sincronizar",
            marca: {
                nome: "",
                tipo_id: ""
            },
            tipo: {
                nome: ""
            },
            marcaReturnID: '',
            marcaItens: '',

            columns: [
                {
                    label: "Marca",
                    field: "nome",
                },
                {
                    label: "Tipo",
                    field: "tipos.nome",
                },

                {
                    label: "Criado Em",
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
                },
                {
                    label: "Ações",
                    field: "actions",
                    width: '100px',
                    html: true,
                },
            ],
        }
    },
    mounted() {
        this.loadItems();
        this.loadTipos();
    },
    methods: {

        showLoadingSyncInit: function () {
            this.iconSyncMarca = "fas fa-sync fa-spin";
            this.labelSync = " Sincronizando...";
        },
        showLoadingSyncEnd: function () {
            this.iconSyncMarca = "fas fa-sync";
            this.labelSync = " Sincronizar";
        },

        syncMarcas() {

            this.loading = true;
            this.showLoadingSyncInit();

            axios.get("sincroniza-marcas").then((res) => {
                    // handle success
                    this.showLoadingSyncEnd();
                    this.loading = false;
                    this.loadItems();
                    //show confirm alert
                    this.$swal({
                        title: "Sucesso",
                        text: "Sincronia realizada com sucesso!",
                        icon: "success"
                    }).then(function () {

                    });
                })
                .catch((err) => {
                    // handle error
                    this.showLoadingSyncEnd();
                    this.loading = false;
                    //show error alert
                    this.$swal({
                        title: "Erro",
                        text: "Falha ao sincronizar. Tente novamente mais tarde!",
                        icon: "error"
                    });

                });
        },

        editData(item) {

            this.marca = item;
            this.isEditMarca = true;
            this.marcaReturnID = item.id;
            this.labelTitle = "Editar marca";
            //this.labelSaveEdit = "Salvar edição"

            $("#modalNovaMarca").modal('show');
        },

        resetForm() {
            this.marca = {
                nome: '',
            };
            this.isEditMarca = false
            this.marcaReturnID = ""
        },

        save() {
            //validate if is edit
            if (this.isEditMarca == true) {

                //edit
                let _this = this;
                $("#modalNovaMarca").modal("hide");
                _this.errors = [];
                _this.message = "";
                _this.loading = true;

                const dataInfo = {
                    "marca": _this.marca.nome,
                    "tipo_id": _this.marca.tipo_id,
                };

                axios.patch(`/admin/marcas/${this.marcaReturnID}`, dataInfo).then((res) => {


                    _this.marca = ""
                    _this.isEditMarca = false;
                    _this.marcaReturnID = "";
                    _this.resetForm()
                    _this.loadItems()
                    _this.loading = false;

                    //show confirm alert
                    _this.$swal({
                        title: "Sucesso",
                        text: "Marca atualizada com sucesso!",
                        icon: "success"
                    }).then(function () {

                    });

                }).catch((err) => {

                    _this.loading = false;

                    //show error alert
                    _this.$swal({
                        title: "Erro",
                        text: "Falha ao atualizar Marca. Tente novamente!",
                        icon: "error"
                    });
                });
            } //edit
            else {
                //new
                let _this = this;
                $("#modalNovaMarca").modal("hide");
                _this.errors = [];
                _this.message = "";
                _this.loading = true;

                const dataInfo = {
                    "marca": _this.marca.nome,
                    "tipo_id": _this.marca.tipo_id,
                };

                axios.post("/admin/marcas", dataInfo).then((res) => {

                    _this.loading = false;
                    _this.resetForm()
                    _this.loadItems();

                    //show confirm alert
                    _this.$swal({
                        title: "Sucesso",
                        text: "Marca cadastrada com sucesso!",
                        icon: "success"
                    });
                }).catch((err) => {

                    _this.loading = false;

                    //show error alert
                    _this.$swal({
                        title: "Erro",
                        text: "Falha ao cadastrar marca. Tente novamente!",
                        icon: "error"
                    });
                });

            } //else save
        },

        loadItems() {
            let _this = this;
            _this.loading = true;
            _this.marcas = [];
            axios.get("/admin/marcas").then((res) => {
                _this.marcas = _this.marcas.concat(res.data);
                _this.loading = false;
            }).catch((err) => {
                _this.loading = false;
            });
        },

        loadTipos() {
            let _this = this;
            _this.loading = true;
            _this.tipos = [];
            axios.get("/admin/tipos").then((res) => {
                _this.tipos = _this.tipos.concat(res.data);
                _this.loading = false;
            }).catch((err) => {
                _this.loading = false;
            });
        },
        deleteData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Excluir marca",
                text: "Tem certeza que deseja excluir essa marca?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Excluir",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.delete(`/admin/marcas/${ID}`).then((res) => {

                        _this.marcas.splice(index, 1);
                        // _this.total = _this.total - 1;
                        _this.loadItems();

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Marca excluída com sucesso!",
                            icon: "success"
                        });

                    }).catch(error => {

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao excluir marca Tente novamente!",
                            icon: "error"
                        });
                    });
                }
            });
            //end alert confirmation*/
        },

        formatDate: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        formatCurrency: function (value) {
            return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
        },
    },
    components: {
        VueGoodTable,
    }
}
</script>
