<template>
  <div class="documentacao">
    <div>
      <h2>Portarias</h2>
      <div v-for="(item, index) in itemsDocumentacao" :key="index">
        <button class="accordion" @click="toggleAccordion(index)" :class="{ active: item.active }">{{
          item.titulo_principal }}</button>
        <div class="panel" v-if="item.active">
          <p>
            {{ item.titulo_secundario }} - Disponível em:
            <a :href="item.link" target="_blank">{{ item.link }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      name: 'documentacao',
      itemsDocumentacao: [],
      loading: false,
    }
  },

  mounted() {
    this.getListaDocumentacao();
  },

  methods: {

    getListaDocumentacao() {
      this.loading = true;
      axios
        .get("/portal/documentacoes")
        .then(response => {
          this.itemsDocumentacao = response.data.map(item => ({ ...item, active: true }));
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },

    toggleAccordion(index) {
      this.itemsDocumentacao[index].active = !this.itemsDocumentacao[index].active;
    }

  },


}
</script>
  
<style scoped>
.accordion {
  background-color: #eee;
  cursor: pointer;
  color: #6c757d;
  margin-bottom: 5px;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  transition: 0.4s;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 18px 18px 0 18px;
  background-color: white;
  overflow: hidden;
  margin-bottom: 16px;
}
</style>
  