<template>
  <div class="row">
    <div class="col-12">
      <!-- dashboard start -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h4>Próximos Vencimentos - DETRAN</h4>
            </div>
            <div class="card-body text-center" v-if="loading">
              <div class="spinner-border spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-else-if="!fechamentosDetran.length && !loading"
              class="text-center p-3 text-muted"
            >
              <h5>Nenhum Fechamento Encontrado</h5>
            </div>
            <div class="card-body" v-else>
              <div class="summary">
                <div class="summary-info">
                  <h4>{{ formatCurrency(valor_total_fechamento_detran) }}</h4>
                  <div class="text-muted">{{ qtd_contratos }} Contratos</div>
                </div>
                <div class="summary-item">
                  <h6>
                    Lista
                    <span class="text-muted"
                      >({{ fechamentosDetran.length }} Fechamento{{
                        fechamentos.length > 1 ? "s" : ""
                      }})</span
                    >
                  </h6>
                  <ul class="list-unstyled list-unstyled-border">
                    <li
                      class="media"
                      v-for="fechamentoDetran in fechamentosDetran"
                      v-bind:key="fechamentoDetran.id"
                    >
                      <div class="media-body">
                        <div class="media-right">
                          {{
                            formatCurrency(fechamentoDetran.valor_fechamento)
                          }}
                        </div>
                        <div class="media-title">
                          DETRAN {{ fechamentoDetran.detran }}
                        </div>
                        <div class="text-muted text-small">
                          {{
                            fechamentoDetran.data_limite_pagamento.date
                              | moment("DD/MM/YYYY")
                          }}
                          <div class="bullet"></div>
                          {{ fechamentoDetran.dia_da_semana_pagamento }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h4>Próximos Vencimentos - RESULT1</h4>
            </div>
            <div class="card-body text-center" v-if="loading">
              <div class="spinner-border spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              v-else-if="!fechamentosResult1.length && !loading"
              class="text-center p-3 text-muted"
            >
              <h5>Nenhum Fechamento Encontrado</h5>
            </div>
            <div class="card-body" v-else>
              <div class="summary">
                <div class="summary-info">
                  <h4>{{ formatCurrency(valor_total_fechamento_result1) }}</h4>
                  <div class="text-muted">{{ qtd_contratos }} Contratos</div>
                </div>
                <div class="summary-item">
                  <h6>
                    Lista
                    <span class="text-muted"
                      >({{ fechamentosResult1.length }} Fechamento{{
                        fechamentos.length > 1 ? "s" : ""
                      }})</span
                    >
                  </h6>
                  <ul class="list-unstyled list-unstyled-border">
                    <li
                      class="media"
                      v-for="fechamentoResult1 in fechamentosResult1"
                      v-bind:key="fechamentoResult1.id"
                    >
                      <div class="media-body">
                        <div class="media-right">
                          {{
                            formatCurrency(
                              fechamentoResult1.valor_fechamento_result1
                            )
                          }}
                        </div>
                        <div class="text-muted text-small">
                          {{
                            fechamentoResult1.data_limite_pagamento.date
                              | moment("DD/MM/YYYY")
                          }}
                          <div class="bullet"></div>
                          {{ fechamentoResult1.dia_da_semana_pagamento }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- dashboard end -->

      <!-- filter start -->
      <div class="card col-12">
        <div class="card-header">
          <div
            class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
          >
            <a
              href="#"
              data-toggle="dropdown"
              class="btn btn-light dropdown-toggle"
              >Detrans</a
            >
            <ul
              class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter"
            >
              <li class="dropdown-title">Selecione os Detrans</li>
              <li v-if="!detrans.length">
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in detrans" :key="item.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item.id"
                    v-model="checkedDetrans"
                    :id="item.id"
                  />
                  <label class="form-check-label" :for="item.id">
                    {{
                      item.estado ? item.estado : item.nome ? item.nome : "Erro"
                    }}
                  </label>
                </div>
              </li>
              <div class="text-center">
                <a
                  href="#"
                  class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filtrar"
                  id="inicial"
                  @click="updateItems"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>

          <div
            class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
          >
            <a
              href="#"
              data-toggle="dropdown"
              class="btn btn-light dropdown-toggle"
              >Instituiçoes</a
            >
            <ul
              class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter"
            >
              <li class="dropdown-title">Selecione as Instituições</li>
              <li v-if="!instituicoes.length">
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in instituicoes" :key="item.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item.id"
                    v-model="checkedInstituicoes"
                    :id="item.id"
                  />
                  <label class="form-check-label" :for="item.id">
                    {{ item.profile.name ? item.profile.name : "Indisponível" }}
                  </label>
                </div>
              </li>
              <div class="text-center">
                <a
                  href="#"
                  class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filtrar"
                  id="inicial"
                  @click="updateItems"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>

          <div
            class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
          >
            <a
              href="#"
              data-toggle="dropdown"
              class="btn btn-light dropdown-toggle"
              >Status</a
            >
            <ul
              class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter"
            >
            <li class="dropdown-title">Selecione os Status</li>
              <li class="dropdown-title">DETRAN</li>
              <li v-if="!statusPagamento.length">
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in statusPagamento" :key="item + 'Detran'">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    v-model="checkedStatusPagamentosDetran"
                    :id="item + 'Detran'"
                  />
                  <label class="form-check-label" :for="item">
                    {{ item ? item : "Indisponível" }}
                  </label>
                </div>
              </li>
              <li><hr class="ml-4 mr-4"></li>
              <li class="dropdown-title">Resul1</li>
              <li v-for="item in statusPagamento" :key="item +'Result1'">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    v-model="checkedStatusPagamentosResul1"
                    :id="item + 'Resul1'"
                  />
                  <label class="form-check-label" :for="item">
                    {{ item ? item : "Indisponível" }}
                  </label>
                </div>
              </li>
              <div class="text-center">
                <a
                  href="#"
                  class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filtrar"
                  id="inicial"
                  @click="updateItems"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>

          <!-- filtro período -->
          <div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
              <datepicker
                :language="calendarLanguage"
                v-model="dataInicial"
                placeholder="Data Inicial"
                class="btn-filter"
              />
            </div>
          </div>
          <div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
              <datepicker
                :language="calendarLanguage"
                v-model="dataFinal"
                placeholder="Data Final"
                class="btn-filter"
              />
            </div>
          </div>
          <!-- end filtro período -->

          <!-- filter button -->
          <div class="col-md-2 col-lg-2 col-sm-12 text-center">
            <a
              href="#"
              class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter"
              data-toggle="tooltip"
              data-placement="top"
              title="Filtrar"
              id="inicial"
              @click="updateItems"
            >
              <i class="fas fa-filter"></i>
            </a>
            <a
              href="#"
              class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter"
              data-toggle="tooltip"
              data-placement="top"
              title="Limpar Filtro"
              @click="limparFiltros"
            >
              <i class="fas fa-times"></i>
            </a>
          </div>
          <!-- end filter button -->
        </div>
      </div>
      <!-- filter end -->

      <div class="card">
        <!-- card body -->
        <div class="card-body">
          <!-- table -->
          <div class="pb-4" v-if="!loading && fechamentos.length > 0">
            <div class="col-md-3 col-lg-3 col-sm-12">
                <a
                href="#"
                class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter export-button"
                @click="exportDataGrid('pdf', 'Contasapagar')"
                >
                    <i class="far fa-file-pdf"></i> PDF
                </a>
                <a
                href="#"
                class="btn btn-success col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter export-button"
                @click="exportDataGrid('xlsx', 'Contasapagar')"
                >
                    <i class="far fa-file-excel"></i> EXCEL
                </a>
             </div>
            <vue-good-table
              :columns="columns"
              :rows="fechamentos"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                position: 'bottom',
                dropdownAllowAll: true,
                perPageDropdown: [10, 30, 50],
                nextLabel: 'Próximo',
                prevLabel: 'Anterior',
                rowsPerPageLabel: '',
                ofLabel: 'de',
                allLabel: 'Todos',
                pageLabel: '',
                perPageDropdownEnabled: true,
                compactMode: true,

              }"
              :search-options="{
                  enabled: true,
                  placeholder: 'Search this table',
                }"
                styleClass="vgt-table"

            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <div class="text-center">
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      @click="
                        getContratos(props.row.contratos, props.row.detran)
                      "
                    >
                      <i class="far fa-eye"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      v-if="props.row.status_pagamento != 1"
                      @click="
                        alterarPagamento(
                          props.row.faturamento_id,
                          props.row.originalIndex
                        )
                      "
                    >
                      <i
                        :class="
                          props.row.status_pagamento == 0
                            ? 'fas fa-chevron-circle-down'
                            : 'fas fa-undo'
                        "
                      ></i>
                    </button>
                  </div>
                </span>
                <div v-else-if="props.column.field == 'valor_fechamento'">
                  {{
                    props.row.tipo == "Detran"
                      ? formatCurrency(props.row.valor_fechamento)
                      : formatCurrency(props.row.valor_fechamento_result1)
                  }}
                </div>
                <div
                  v-else-if="
                    props.column.field == 'periodo_fechamento.data_inicial.date'
                  "
                >
                  {{
                    props.row.periodo_fechamento.data_inicial.date
                      | moment("DD/MM/YYYY")
                  }}
                  |
                  {{
                    props.row.periodo_fechamento.data_final.date
                      | moment("DD/MM/YYYY")
                  }}
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <sweet-modal
              ref="modalContratos"
              :hide-close-button="true"
              :blocking="true"
              width="60%"
            >
              <div class="row">
                <div class="col-12">
                  <div class="mb-5">
                    <h4 class="text-danger">Contratos do Fechamento</h4>
                  </div>
                  <div class="pb-4" v-if="!loading && contratos.length > 0">
                    <vue-good-table
                      :columns="contratoColumns"
                      :rows="contratos"
                      :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        position: 'bottom',
                        dropdownAllowAll: true,
                        perPageDropdown: [10, 30, 50],
                        nextLabel: 'Próximo',
                        prevLabel: 'Anterior',
                        rowsPerPageLabel: '',
                        ofLabel: 'de',
                        allLabel: 'Todos',
                        pageLabel: '',
                        perPageDropdownEnabled: true,
                        compactMode: true,

                      }"
                    >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'custo_contrato'">
                        {{ formatCurrency(props.row.bancos.detrans[0].custo_contrato) }}
                      </span>
                      <span v-else-if="props.column.field == 'valor_contrato'">
                        {{ formatCurrency(props.row.bancos.detrans[0].valor_contrato) }}
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                    </vue-good-table>
                  </div>
                  <div
                    v-else-if="!contratosLoading && !contratos.length"
                    class="text-center p-3 text-muted"
                  >
                    <h5>Nenhuma informação de retorno</h5>
                  </div>
                  <div class="d-flex justify-content-center" v-else>
                    <div class="spinner-border text-danger" role="status"></div>
                  </div>
                </div>
              </div>
              <sweet-button
                slot="button"
                class="btn btn-danger"
                @click="fecharModal"
                >Fechar</sweet-button
              >
            </sweet-modal>
            <sweet-modal icon="success" ref="alterarPagamentoSucesso">
              Pagamento alterado com sucesso!
            </sweet-modal>
            <sweet-modal icon="warning" ref="alterarPagamentoErro">
              Erro ao alterar o pagamento
            </sweet-modal>
            <div
              v-if="!fechamentos.length && !loading"
              class="text-center p-3 text-muted"
            >
              <h5>Nenhum contrato encontrado!</h5>
            </div>
            <!-- end table -->
            <div class="text-center p-4 text-muted" v-if="loading">
              <h5>Carregando...</h5>
              <p>Aguarde até que os dados sejam carregados...</p>
            </div>
          </div>
          <!-- end table -->
        </div>
        <!-- end card body -->
      </div>
      <vue-loading
        color="#ec2423"
        :active.sync="loading"
        :can-cancel="false"
      ></vue-loading>
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

export default {
  data() {
    return {
      contratos: [],
      contratosLoading: false,
      fechamentos: [],
      fechamentosDetran: [],
      fechamentosResult1: [],
      valor_total_fechamento_detran: 0,
      valor_total_fechamento_result1: 0,
      qtd_contratos: 0,
      statusPagamento: ["Pago", "Pendente", "Previsão"],
      checkedStatusPagamentosDetran: [],
      checkedStatusPagamentosResul1: [],
      instituicoes: [],
      checkedInstituicoes: [],
      detrans: [],
      checkedDetrans: [],
      dataInicial: "",
      dataFinal: "",
      loading: false,
      calendarLanguage: ptBR,
      columns: [
        {
          label: "Tipo",
          field: "tipo",
          sortable: false
        },
        {
          label: "DETRAN",
          field: "detran",
          sortable: false
        },
        {
          label: "Instituição",
          field: "instituicao",
          sortable: false
        },
        {
          label: "QTD Contratos",
          field: "qtd_contratos",
          sortable: false
        },
        {
          label: "Tipo Fechamento",
          field: "tipo_fechamento",
          sortable: false
        },
        {
          label: "Período Fechamento",
          field: "periodo_fechamento.data_inicial.date",
          formatFn: this.formatDate,
          sortable: false
        },
        {
          label: "Valor Total",
          field: "valor_fechamento",
          formatFn: this.formatCurrency,
          sortable: false
        },
        {
          label: "Vencimento",
          field: "data_limite_pagamento.date",
          formatFn: this.formatDate,
          sortable: false
        },
        {
          label: "Status Pagamento",
          field: "status_pagamento",
          sortable: false
        },
        {
          label: "Ações",
          field: "action",
          sortable: false,
          html: true,
        },
      ],
      contratoColumns: [
        {
          label: "DETRAN",
          field: "detrans.estado",
        },
        {
          label: "Instituição",
          field: "bancos.user.profile.name",
        },
        {
          label: "Número Gravame",
          field: "num_gravame",
        },
        {
          label: "Custo Registro",
          field: "custo_contrato",
          formatFn: this.formatCurrency,
        },
        {
          label: "Valor Registro",
          field: "valor_contrato",
          formatFn: this.formatCurrency,
        },
        {
          label: "Status",
          field: "contrato_status.nome",
        },
        {
          label: "Data Criação",
          field: "created_at",
          sortable: true,
          formatFn: this.formatDate,
        },
      ],
    };
  },
  mounted() {
    this.loadItems();
    this.loadInstituicoes();
    this.loadDetrans();
  },
  methods: {
    loadItems() {
      let _this = this;
      _this.loading = true;
      axios
        .get("/api/portal/contasapagar")
        .then((res) => {
          _this.fechamentos = res.data;
          _this.loading = false;
          res.data.forEach((item) => {
            if (item.tipo == "Detran") {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento > today) {
                _this.fechamentosDetran.push(item);
              }
              _this.valor_total_fechamento_detran += item.valor_fechamento;
            } else {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento > today) {
                _this.fechamentosResult1.push(item);
              }
              _this.valor_total_fechamento_result1 +=
                item.valor_fechamento_result1;
            }
            if (item.status_pagamento == 1) {
              _this.qtd_contratos += item.qtd_contratos / 2;
            } else {
              _this.qtd_contratos += item.qtd_contratos ;
            }
          });
        })
        .catch((err) => console.log(err));
    },
    updateItems() {
      let _this = this;
      _this.loading = true;
      _this.valor_total_fechamento_detran = 0;
      _this.valor_total_fechamento_result1 = 0;
      _this.qtd_contratos = 0;
      _this.fechamentosDetran = [];
      _this.fechamentosResult1 = [];
      axios
        .post("/api/portal/contasapagar", {
          search: [
            {
              field: "status_pagamento_detran",
              value: _this.checkedStatusPagamentosDetran,
            },
            {
              field: "status_pagamento_result1",
              value: _this.checkedStatusPagamentosResul1,
            },
            {
              field: "detrans",
              value: _this.checkedDetrans,
            },
            {
              field: "instituicoes",
              value: _this.checkedInstituicoes,
            },
            {
              field: "data_inicial",
              value:
                _this.dataInicial != ""
                  ? _this.formatFilterDate(_this.dataInicial)
                  : null,
            },
            {
              field: "data_final",
              value:
                _this.dataFinal != ""
                  ? _this.formatFilterDate(_this.dataFinal)
                  : null,
            },
          ],
        })
        .then((res) => {
          _this.fechamentos = res.data;
          _this.loading = false;
          res.data.forEach((item) => {
            if (item.tipo == "Detran") {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento >= today) {
                _this.fechamentosDetran.push(item);
              }
              _this.valor_total_fechamento_detran += item.valor_fechamento;
            } else {
              let today = new Date().toISOString().slice(0, 10);
              let vencimento = new Date(item.data_limite_pagamento.date)
                .toISOString()
                .slice(0, 10);
              if (_this.fechamentosDetran.length <= 5 && vencimento >= today) {
                _this.fechamentosResult1.push(item);
              }
              _this.valor_total_fechamento_result1 +=
                item.valor_fechamento_result1;
            }
            if (item.status_pagamento == 1) {
              _this.qtd_contratos += item.qtd_contratos / 2;
            } else {
              _this.qtd_contratos += item.qtd_contratos;
            }
          });
        })
        .catch((err) => console.log(err));
    },
    loadInstituicoes() {
      let _this = this;
      axios
        .get("/api/admin/instituicoes")
        .then((res) => {
          _this.instituicoes = res.data;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    loadDetrans() {
      let _this = this;
      axios
        .get("/admin/detrans")
        .then((res) => {
          _this.detrans = res.data;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getContratos(ids, tipo) {
      let _this = this;
      _this.contratosLoading = true;
      axios
        .post("/api/admin/contratos", {
          search: [
            { field: "ids_contratos", value: ids },
            { field: "tipo_contrato", value: tipo },
          ],
        })
        .then((res) => {
          _this.contratos = res.data;
          _this.contratosLoading = false;
        });
      _this.$refs.modalContratos.open();
    },
    fecharModal() {
      let _this = this;
      _this.contratos = [];
      _this.$refs.modalContratos.close();
    },
    limparFiltros() {
      let _this = this;
      _this.valor_total_fechamento_detran = 0;
      _this.valor_total_fechamento_result1 = 0;
      _this.qtd_contratos = 0;
      _this.checkedStatusPagamentosDetran = [];
      _this.checkedStatusPagamentosResul1 = [];
      _this.dataInicial = "";
      _this.dataFinal = "";
      _this.checkedDetrans = [];
      _this.checkedInstituicoes = [];
      _this.updateItems();
    },
    alterarPagamento(faturamentoId, index) {
      let _this = this;
      axios
        .post(
          "/api/portal/contasapagar/aterarpagamento?faturamentoId=" +
            faturamentoId,
          {}
        )
        .then((res) => {
          if (!res.data) {
            _this.$refs.alterarPagamentoErro.open();
          }
          _this.fechamentos[index].status_pagamento = res.data;
          _this.$refs.alterarPagamentoSucesso.open();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    formatCurrency: function (value) {
      return Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    formatDate: function (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    formatFilterDate(date) {
      let d = new Date(date);
      let dateString = moment(String(d)).format("YYYY-MM-DD");
      return dateString;
    },
        exportDataGrid(type, tableName){
        let params = type == 'pdf' ?
            {
                type: 'pdf',
                ignoreColumn: [9],
                fileName: tableName,
                htmlContent: false,
                onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
                jspdf: {
                    orientation: 'l',
                    margins: {right: 10, left: 10, top: 40, bottom: 40},
                    autotable: {
                        styles: {
                            fillColor: 'inherit',
                            textColor: 'inherit',
                            halign: 'center',
                            fillStyle: 'DF',
                            lineColor: 200,
                            lineWidth: 0.1},
                            headerStyles: {halign: 'left'},
                            tableWidth: 'auto'
                    }
                        }
            } :
            {
                type: 'excel',
                ignoreColumn: [9],
                htmlContent: false,
                fileName: tableName,
                mso:  {fileFormat: 'xlsx' }
            };

        let options = { tableName: tableName };

        jQuery.extend(true, options, params);

        $('#vgt-table').tableExport(options);
    },
  },
  components: {
    VueGoodTable,
    Datepicker,
    SweetModal,
    SweetModalTab,
  },
};
</script>
