<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- estado -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-estado">Estado</label>
									<select type="text" id="input-estado" v-bind:class="{'is-invalid': errors.estado}" class="form-control" placeholder="estado" v-model="estado" required="true">
										<option v-for="item, index in states" :key="index">{{item}}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.estado">
										<p>{{ errors.estado[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end estado -->

							<!-- model -->
							<div class="col-md-10">
								<div class="form-group">
									<label class="form-control-label" for="input-model">Model</label>
									<input type="text" id="input-model" v-bind:class="{'is-invalid': errors.model}" class="form-control" placeholder="Model" v-model="model" required="true">
									<div class="invalid-feedback" v-if="errors.model">
										<p>{{ errors.model[0] }}</p>
									</div>
								</div>
							</div>
							<!-- end model -->

							<!-- api envolope homolog -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-api_url_envelop_homolog">API Envelope Homologação</label>
									<input type="text" id="input-api_url_envelop_homolog" v-bind:class="{'is-invalid': errors.api_url_envelop_homolog}" class="form-control" placeholder="API Envelope Homologação" v-model="api_url_envelop_homolog" required="true">
									<div class="invalid-feedback" v-if="errors.api_url_envelop_homolog">
										<p>{{ errors.api_url_envelop_homolog[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end api envelope homolog -->

							<!-- api url homolog -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-api_url_homolog">API Homologação</label>
									<input type="text" id="input-api_url_homolog" v-bind:class="{'is-invalid': errors.api_url_homolog}" class="form-control" placeholder="API Homologação" v-model="api_url_homolog" required="true">
									<div class="invalid-feedback" v-if="errors.api_url_homolog">
										<p>{{ errors.api_url_homolog[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end api url homolog -->

							<!-- api envolope prod -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-api_url_envelop_prod">API Envelope Produção</label>
									<input type="text" id="input-api_url_envelop_prod" v-bind:class="{'is-invalid': errors.api_url_envelop_prod}" class="form-control" placeholder="API Envelope Produção" v-model="api_url_envelop_prod" required="true">
									<div class="invalid-feedback" v-if="errors.api_url_envelop_prod">
										<p>{{ errors.api_url_envelop_prod[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end api envelope prod -->

							<!-- api url prod -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-api_url_prod">API Produção</label>
									<input type="text" id="input-api_url_prod" v-bind:class="{'is-invalid': errors.api_url_prod}" class="form-control" placeholder="API Produção" v-model="api_url_prod" required="true">
									<div class="invalid-feedback" v-if="errors.api_url_prod">
										<p>{{ errors.api_url_prod[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end api url prod -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	export default {
		data() {
			return {
				estado: "",
				model: "",
				api_url_prod: "",
				api_url_envelop_prod: "",
				api_url_homolog: "",
				api_url_envelop_homolog: "",
				errors: [],
				message: "",
				loading: false,
				fullPage: true,
				states: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RO','RS','RR','SC','SE','SP','TO']
			}
		},
		mounted() {
		},
		methods: {
			handleFileUpload(field){
				this.field = this.$refs.field.files[0];
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"estado" : _this.estado,
					"model" : _this.model,
					"api_url_prod" : _this.api_url_prod,
					"api_url_envelop_prod" : _this.api_url_envelop_prod,
					"api_url_homolog" : _this.api_url_homolog,
					"api_url_envelop_homolog" : _this.api_url_envelop_homolog,
					"last": ""
				};
				axios.post("/admin/detrans", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Detrans cadastrado com sucesso!",
						icon: "success"
					});
				}).then(() => location.href="/admin/detrans")
				.catch((err) => {

					_this.loading = false;

					if (err.response.status == 422) {
							this.errors = err.response.data.errors;
					}

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar Detrans. Tente novamente!",
						icon: "error"
					});
				});
			},
			resetForm() {
				this.estado = '';
				this.api_url = '';
			}
		}
	}
</script>
