var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!_vm.loading && _vm.grupoEcon.length > 0)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.grupoEcon,"pagination-options":{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,

					},"search-options":{
	enabled: true,
	placeholder: 'Search this table',
},"styleClass":"vgt-table"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == '_status')?_c('span',[(props.row.status == 1)?_c('span',{staticClass:"alert-table alert alert-success"},[_vm._v("Ativo")]):_vm._e(),_vm._v(" "),(props.row.status == 0)?_c('span',{staticClass:"alert-table alert alert-danger"},[_vm._v("Inativo")]):_vm._e()]):_vm._e(),_vm._v(" "),(props.column.field == 'actions')?_c('span',[_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",on:{"click":function($event){return _vm.editData(props.row.id)}}},[_c('i',{staticClass:"far fa-edit"})]),_vm._v(" "),_c('button',{staticClass:"btn btn-sm btn-outline-danger",on:{"click":function($event){return _vm.deleteData(props.row.id, props.row.index)}}},[_c('i',{staticClass:"far fa-trash-alt"})])])]):_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(props.formattedRow[props.column.field])+"\n\t\t\t\t\t\t\t")])]}}],null,false,3631474148)})],1):_vm._e(),_vm._v(" "),(!_vm.grupoEcon.length && !_vm.loading)?_c('div',{staticClass:"text-center p-3 text-muted"},[_c('h5',[_vm._v("Nenhum Grupo Econômico encontrado!")])]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"text-center p-4 text-muted"},[_c('h5',[_vm._v("Carregando...")]),_vm._v(" "),_c('p',[_vm._v("Aguarde até que os dados sejam carregados...")])]):_vm._e()])]),_vm._v(" "),_c('vue-loading',{attrs:{"color":"#ec2423","active":_vm.loading,"can-cancel":false},on:{"update:active":function($event){_vm.loading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }