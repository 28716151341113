<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados Básicos</h6>

					<div class="row">
						<!-- grupoEcon -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Grupo Econômico?</label>
								<select v-bind:class="{ 'is-valid': errors.grupoEcon }" class="form-control"
									v-model="grupoEcon">
									<option v-for="(  item, key  ) in   isGroupEcon  " :key="key" :value="item.id">{{
										item.name }}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.isgroupecon">
									<p>{{ errors.isgroupecon[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end grupoEcon -->

						<!-- Lista grupoEcon -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Lista Grupo Econômico</label>
								<select v-bind:class="{ 'is-valid': errors.nomeGrupoEcon }" class="form-control"
									v-model="nomeGrupoEcon" :disabled="grupoEcon === 0">
									<option v-for="(  item, key  ) in   listaGrupoEcons  " :key="key" :value="item.id">{{
										item.nome_fantasia
									}}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.grupoEconId">
									<p>{{ errors.grupoEconId[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-5 -->
						<!-- end Lista grupoEcon -->
					</div>

					<div class="row">
						<!-- name -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Nome</label>
								<input type="text" id="name" v-bind:class="{ 'is-invalid': errors.name }"
									class="form-control" placeholder="Nome" v-model="name">
								<div class="invalid-feedback" v-if="errors.name">
									<p>{{ errors.name[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-6 -->
						<!-- end name -->

						<!-- cpf -->
						<div class="col-3">
							<div class="form-group">
								<label class="form-control-label">CPF</label>
								<input type="text" id="cpf" v-bind:class="{ 'is-invalid': errors.cpf }" class="form-control"
									placeholder="CPF" v-model="cpf" maxlength="11" v-mask="'###.###.###-##'">
								<div class="invalid-feedback" v-if="errors.cpf">
									<p>{{ errors.cpf[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end cpf -->

						<!-- email -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">E-mail</label>
								<input type="email" id="email" v-bind:class="{ 'is-invalid': errors.email }"
									class="form-control" placeholder="E-mail" v-model="email" disabled>
								<div class="invalid-feedback" v-if="errors.email">
									<p>{{ errors.email[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end email -->

					</div>
					<div class="row">


						<!-- ddd -->
						<div class="col-1">
							<div class="form-group">
								<label class="form-control-label">DDD</label>
								<input type="text" id="ddd" v-bind:class="{ 'is-invalid': errors.ddd_profile }"
									class="form-control" placeholder="ddd" v-model="ddd_profile" maxlength="3">
								<div class="invalid-feedback" v-if="errors.ddd_profile">
									<p>{{ errors.ddd_profile[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end ddd -->

						<!-- telefone -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Telefone</label>
								<input type="text" id="telefone" v-bind:class="{ 'is-invalid': errors.telefone }"
									class="form-control" placeholder="Telefone" v-model="telefone" maxlength="9"
									v-mask="'#####-####'">
								<div class="invalid-feedback" v-if="errors.telefone">
									<p>{{ errors.telefone[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end telefone -->

						<!-- CEP -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">CEP</label>
								<input type="text" id="input-cep" v-bind:class="{ 'is-invalid': errors.cep }"
									class="form-control" placeholder="CEP" v-model="cep" v-mask="'#####-###'"
									required="true" maxlength="9" @blur="verificarCEP">
								<div class="invalid-feedback" v-if="errors.cep">
									<p>{{ errors.cep[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-3 -->
						<!-- end CEP -->

						<!-- ENDEREÇO -->
						<div class="col-7">
							<div class="form-group">
								<label class="form-control-label">Endereço</label>
								<input type="text" id="endereco" v-bind:class="{ 'is-invalid': errors.endereco }"
									class="form-control" placeholder="Endereço" v-model="endereco">
								<div class="invalid-feedback" v-if="errors.endereco">
									<p>{{ errors.endereco[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end ENDEREÇO -->

					</div>
					<div class="row">

						<!-- BAIRRO -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">Bairro</label>
								<input type="text" id="bairro" v-bind:class="{ 'is-invalid': errors.bairro }"
									class="form-control" placeholder="Bairro" v-model="bairro">
								<div class="invalid-feedback" v-if="errors.bairro">
									<p>{{ errors.bairro[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end BAIRRO -->

						<!-- CIDADE -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">Cidade</label>
								<input type="text" id="cidade" v-bind:class="{ 'is-invalid': errors.cidade }"
									class="form-control" placeholder="Cidade" v-model="cidade">
								<div class="invalid-feedback" v-if="errors.cidade">
									<p>{{ errors.cidade[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end CIDADE -->

						<!-- UF -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">UF</label>
								<input type="text" id="uf" v-bind:class="{ 'is-invalid': errors.uf }" class="form-control"
									placeholder="UF" v-model="uf" maxlength="2">
								<div class="invalid-feedback" v-if="errors.uf">
									<p>{{ errors.uf[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-3 -->
						<!-- end UF -->

						<!-- NUMERO -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Número</label>
								<input type="text" id="numero" v-bind:class="{ 'is-invalid': errors.numero }"
									class="form-control" placeholder="Número" v-model="numero">
								<div class="invalid-feedback" v-if="errors.numero">
									<p>{{ errors.numero[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-3 -->
						<!-- end NUMERO -->

					</div>
					<div class="row">

						<!-- permission -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Grupo de Acesso</label>
								<select v-bind:class="{ 'is-valid': errors.permission }" class="form-control"
									placeholder="Grupo de Acesso" v-model="permission" :disabled="grupoEcon === 1">
									<option v-for="(  item, key  ) in   permissions  " :key="key" :value="item.id">{{
										item.name }}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.permission">
									<p>{{ errors.permission[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end permission -->

						<!-- banco -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Banco</label>
								<select id="banco" v-bind:class="{ 'is-invalid': errors.name }" class="form-control"
									placeholder="Banco" v-model="banco" :disabled="grupoEcon === 1">
									<option v-for="(  item, key  ) in   bancos  " :key="key" :value="item.id">{{ item.nome
									}}</option>
								</select>
								<div class="invalid-feedback" v-if="errors.banco">
									<p>{{ errors.banco[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-3 -->
						<!-- end banco -->

						<!-- função -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">Função</label>
								<input type="text" id="funcao" v-bind:class="{ 'is-invalid': errors.funcao }"
									class="form-control" placeholder="Função" v-model="funcao" maxlength="50">
								<div class="invalid-feedback" v-if="errors.funcao">
									<p>{{ errors.funcao[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end função -->

					</div>
					<div class="row">

						<!-- MATRICULA -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Matrícula</label>
								<input type="text" id="matricula" v-bind:class="{ 'is-invalid': errors.matricula }"
									class="form-control" placeholder="Matrícula" v-model="matricula">
								<div class="invalid-feedback" v-if="errors.matricula">
									<p>{{ errors.matricula[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-3 -->
						<!-- end MATRICULA -->
					</div>
					<div class="row">
						<div class="col-md-12 text-md-right">
							<a @click="updateData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
						</div>
					</div>
				</div>
				<!-- end form -->

			</div><!-- card body -->
		</div><!-- card -->

		<vue-loading color="red" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>

import { fieldNames } from '../fieldNames/fieldNames';

export default {
	props: ['user'],
	data() {
		const user = JSON.parse(this.user)
		return {
			userData: user,
			name: user.profile.name,
			email: user.email,
			banco: null,
			cpf: user.profile.cpf,
			funcao: user.profile.funcao,
			ddd_profile: user.profile.ddd_profile,
			telefone: user.profile.telefone,
			cep: user.profile.cep,
			endereco: user.profile.endereco,
			bairro: user.profile.bairro,
			cidade: user.profile.cidade,
			uf: user.profile.uf,
			numero: user.profile.numero,
			matricula: user.profile.matricula,
			permission: null,
			bancos: [],
			password: '',
			permissions: [],
			errors: [],
			message: '',
			loading: false,
			fullPage: true,
			user_id: user.id,
			inputIcon: 'fas fa-eye',
			inputType: 'password',
			grupoEcon: user.is_group_economic != 1 ? 0 : user.is_group_economic,
			listaGrupoEcons: [],
			nomeGrupoEcon: user.grupo_economico_id,
			isGroupEcon: [{ id: 0, name: 'Não' }, { id: 1, name: 'Sim' }]
		}
	},
	mounted() {
		this.loadBancos();
		this.loadPermissions();
		this.loadGrupoEcon();

	},
	watch: {
		grupoEcon(newVal) {
			if (newVal === 0) {
				this.banco = this.userData.banco_id
				this.permission = this.userData.roles[0].id
				this.nomeGrupoEcon = null
			} else {
				this.loadGrupoEcon();
				this.permission = null
				this.banco = null
				this.nomeGrupoEcon = this.userData.grupo_economico_id
			}
		},
	},
	computed: {
		selectedPermission() {
			return this.permissions.find(item => item.id === this.permission);
		}
	},
	methods: {
		loadGrupoEcon() {
			let _this = this;
			axios.get("/admin/grupoecon").then((res) => {
				this.listaGrupoEcons = res.data;
			}).catch((err) => {
				console.error(err)
			});
		},
		loadBancos() {
			let _this = this;

			axios.get("/admin/bancos").then((res) => {
				this.bancos = res.data;
			}).catch((err) => {
				console.error(err)
			});
		},
		loadPermissions() {
			let _this = this;

			axios.get('/admin/permissions').then((res) => {
				_this.permissions = res.data;
			}).catch((err) => {
				console.error(err)
			})
		},
		updateData() {

			let _this = this;
			_this.errors = [];
			_this.message = '';
			_this.loading = true;
			const user = JSON.parse(this.user);

			let formData = new FormData();
			formData.append('name', _this.name);
			formData.append('email', _this.email)
			formData.append('password', _this.password)
			formData.append('banco', _this.banco)
			formData.append('permission', _this.permission)
			formData.append('cpf', _this.cpf)
			formData.append('funcao', _this.funcao)
			formData.append('ddd_profile', _this.ddd_profile)
			formData.append('telefone', _this.telefone)
			formData.append('cep', _this.cep)
			formData.append('endereco', _this.endereco)
			formData.append('bairro', _this.bairro)
			formData.append('cidade', _this.cidade)
			formData.append('uf', _this.uf)
			formData.append('numero', _this.numero)
			formData.append('matricula', _this.matricula)

			formData.append('isgroupecon', _this.grupoEcon)

			let grupoEconId = 0;
			if (_this.nomeGrupoEcon) {
				grupoEconId = _this.nomeGrupoEcon
			}

			formData.append('grupoEconId', grupoEconId)

			formData.append('_method', 'PATCH')

			axios.post('/admin/users/' + user.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				_this.$swal({
					title: 'Sucesso',
					text: "Usuário atualizado com sucesso.",
					icon: 'success'
				}).then(() => {
					location.href = "/admin/users"
				});

			}).catch((err) => {

				_this.loading = false;

				if (err.response.status == 422) {

					this.errors = err.response.data.errors;

					var errorFields = Object.keys(err.response.data.errors).map((key) => {
						// Verifica se o mapeamento existe para o nome da variável
						if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
							return fieldNames.campoObrigatorio[key];
						} else {
							// Caso o mapeamento não exista, retorna o nome da variável original
							return key;
						}
					}).join(', <br> - ');

					_this.$swal({
						title: "Erro",
						html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
						icon: "error"
					});
				}
			});
		},
		validateRole() {
			const user = JSON.parse(this.user);
			if (user.roles.length === 0
				|| user.roles === null
				|| user.roles === undefined) {
				return '';
			} else {
				return user.roles[0].name;
			}
		},
		verificarCEP() {
			let cep = this.cep.replace('-', '');

			axios.get(`/admin/consultacep/${cep}`).then((res) => {

				this.endereco = res.data.logradouro
				this.bairro = res.data.bairro
				this.cidade = res.data.localidade
				this.uf = res.data.uf
			}).catch((err) => {
			})
		},
	}
}
</script>
