<template>
    <div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
        <div class="card mt-4">
            <div class="row">
                <div class="col-12">
                    <div class="card">

                        <!-- card header -->
                        <div class="card-header">
                            <h4>Preencha os dados abaixo</h4>

                            <a @click="updateData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
                        </div>
                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">

                            <!-- form -->
                            <h6 class="heading-small text-muted mb-4">Dados</h6>
                            <div class="row">

                                <!-- valor_duda -->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-vlDuda">R$ Duda</label>
                                        <!-- <input id="input-vlDuda" class="form-control" v-model="valor_duda" required> -->
                                        <money v-bind:class="{ 'is-invalid': errors.valor_duda }" v-model="valor_duda"
                                            class="form-control" v-bind="valueMaskD3" maxlength="11"></money>
                                        <div class="invalid-feedback" v-if="errors.valor_duda">
                                            <p>{{ errors.valor_duda }}</p>
                                        </div>
                                    </div><!-- form group -->
                                </div><!-- col-md-2 -->
                                <!-- end valor_duda -->

                                <!-- num_duda -->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-numDuda">Nº Duda</label>
                                        <input id="input-numDuda" class="form-control" v-model="num_duda" required>
                                        <div class="invalid-feedback" v-if="errors.num_duda">
                                            <p>{{ errors.num_duda }}</p>
                                        </div>
                                    </div><!-- form group -->
                                </div><!-- col-md-2 -->
                                <!-- end num_duda -->

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-dataEmissao">Data
                                            Contrato</label>
                                        <input type="date" id="input-dataEmissao"
                                            v-bind:class="{ 'is-invalid': errors.dataEmissao }" class="form-control"
                                            placeholder="Data Contrato" v-model="dataEmissao" required="true">
                                        <div class="invalid-feedback" v-if="errors.dataEmissao">
                                            <p>{{ errors.dataEmissao }}</p>
                                        </div>
                                    </div><!-- form group -->
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-dataVencimento">Data
                                            Contrato</label>
                                        <input type="date" id="input-dataVencimento"
                                            v-bind:class="{ 'is-invalid': errors.dataVencimento }" class="form-control"
                                            placeholder="Data Contrato" v-model="dataVencimento" required="true">
                                        <div class="invalid-feedback" v-if="errors.dataVencimento">
                                            <p>{{ errors.dataVencimento }}</p>
                                        </div>
                                    </div><!-- form group -->
                                </div>


                            </div><!-- row -->
                            <!-- end form -->

                        </div><!-- card body -->
                    </div><!-- card -->
                </div><!-- col-12 -->

                <vue-loading color="#212121" v-model="loading" :can-cancel="false"
                    :is-full-page="fullPage"></vue-loading>
            </div><!-- row -->
        </div><!-- card -->
    </div><!-- tab-pane -->
</template>

<script>
import axios from 'axios';

export default {
    props: ["dudas"],
    data() {
        const dudas = JSON.parse(this.dudas)
        return {
            dudaId: dudas.id,
            num_duda: dudas.num_duda,
            valor_duda: dudas.valor_duda,
            dataEmissao: dudas.data_emissao,
            dataVencimento: dudas.data_vencimento,
            errors: [],
            loading: false,
            fullPage: true,
            valueMaskD3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                precision: 2,
                masked: false
            },
        }
    },
    methods: {
        updateData() {
            this.errors = [];
            this.loading = true;
            const dataInfo = {
                num_duda: this.num_duda,
                valor_duda: this.valor_duda,
                data_emissao: this.dataEmissao,
                data_vencimento: this.dataVencimento,
                _method: "PATCH"
            };
            axios.post(`/admin/dudaRj/${this.dudaId}`, dataInfo)
                .then(() => {
                    this.loading = false;
                    this.$swal({
                        title: "Sucesso",
                        text: "Duda atualizada com sucesso!",
                        icon: "success"
                    }).then(() => {
                        location.href = `/admin/duda/edit/${this.dudaId}`;
                    });
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response.status == 422) {
                        this.errors = err.response.data.errors;
                    }
                    this.$swal({
                        title: "Erro",
                        text: "Falha ao cadastrar dudas. Tente novamente!",
                        icon: "error"
                    });
                });
        }
    }
}
</script>
