<template>
  <div class="row">
    <div class="col">

        <div class="col-md-4">
            <h3>
<!--                <a href="/marcas/index" class="btn btn-sm btn-primary">Marca</a>-->
            </h3>
        </div>

        <!-- table -->
        <div class="card p-4">
            <vue-good-table v-if="permissions.length && !loading"
                :columns="tableColumns"
                :rows="permissions"
                :pagination-options="tablePagination"
                :search-options="{
                    enabled: true,
                    placeholder: 'Buscar..',
                }"
                styleClass="vgt-table"
			>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                    <div>
                        <button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i class="far fa-edit"></i></button>
                        <button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)"><i class="far fa-trash-alt"></i></button>
                    </div>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
			</vue-good-table>
            <div v-else class="text-center p-3 text-muted">
                <h5>Nenhum Grupo de Acesso cadastrado!</h5>
            </div>
        </div>
        <!-- end table -->

        <div class="text-center p-4 text-muted" v-if="loading">
            <h5>Carregando...</h5>
            <p>Aguarde até que os dados sejam carregados...</p>
        </div>
    </div>

    <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

  </div><!-- row -->
</template>

<script>

    import "vue-good-table/dist/vue-good-table.css";
    import { VueGoodTable } from "vue-good-table";

    export default {
        components: {
            VueGoodTable,
        },
        data() {
            return {
                loading: false,
                permissions: [],
                tablePagination: {
                    enabled: true,
                    nextLabel: 'próxima',
                    prevLabel: 'anterior',
                    rowsPerPageLabel: 'Registros por página',
                    ofLabel: 'de',
                    pageLabel: 'página', // for 'pages' mode
                    allLabel: 'Todos',
                },
                tableColumns: [
                    {
                        label: "Nome",
                        field: "name",
                    },{
                    label: "Ações",
                        field: "actions",
                        sortable: false,
                    },
                ],
            }
        },
        mounted() {
            //this.loadPermissions();
        },
        methods: {
            loadPermissions() {
                let _this = this;
                _this.loading = true;
                axios.get('/admin/permissions').then((res) => {
                    _this.permissions = _this.permissions.concat(res.data);
                    _this.loading = false;
                }).catch((err) => {
                    _this.loading = false;
                });
            },
            editData(ID){
                location.href="/admin/permissions/"+ID+"/edit";
            }
        }
    }
</script>
