<template>
    <div class="row">
        <div class="col">
            <!-- tabela -->
            <div class="card p-4">

                <vue-good-table :columns="colunas" :rows="arquivos" :pagination-options="{enabled: false}"
                    :search-options="opcoesBusca" @on-search="handleSearch" styleClass="vgt-table">
                    <template slot="table-row" slot-scope="props">

                        <span v-if="props.column.field == 'actions'">
                            <div class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    @click="detalheArquivo(props.row.nome_arquivo)" title="Visualizar">
                                    <i class="fa fa-eye"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-secondary"
                                    @click="disparaEmail(props.row.nome_arquivo)" title="Enviar por email">
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </div>
                        </span>

                    </template>
                </vue-good-table>

                <div class="modal fade" id="modalNovaInstituicao" tabindex="-1"
                    aria-labelledby="modalNovaInstituicaoLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalNovaInstituicaoLabel">Detalhes do arquivo</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                    <div class="col-12 pt-4">
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label for="dthr_geracao_arquivo">Data/Hora Geração Arquivo</label>
                                                <input type="text" class="form-control" id="dthr_geracao_arquivo"
                                                    v-model="detalhe.dthr_geracao_arquivo" readonly>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="nosso_num">Nosso Número</label>
                                                <input type="text" class="form-control" id="nosso_num"
                                                    v-model="detalhe.nosso_num" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-4">
                                                <label for="periodo_inicial_cobr">Período Inicial Cobrança</label>
                                                <input type="text" class="form-control" id="periodo_inicial_cobr"
                                                    v-model="detalhe.periodo_inicial_cobr" readonly>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label for="periodo_final_cobr">Período Final Cobrança</label>
                                                <input type="text" class="form-control" id="periodo_final_cobr"
                                                    v-model="detalhe.periodo_final_cobr" readonly>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label for="vencimento">Vencimento</label>
                                                <input type="text" class="form-control" id="vencimento"
                                                    v-model="detalhe.vencimento" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-4">
                                                <label>CNPJ Cliente</label>
                                                <input type="text" class="form-control" id="cnpj_cliente"
                                                    v-model="detalhe.cnpj_cliente" readonly>
                                            </div>
                                            <div class="form-group col-md-8">
                                                <label for="nome_cliente">Nome Cliente</label>
                                                <input type="text" class="form-control" id="nome_cliente"
                                                    v-model="detalhe.nome_cliente" readonly>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-4">
                                                <label for="valor_boleto">Valor Boleto</label>
                                                <input type="text" class="form-control" id="valor_boleto"
                                                    v-model="detalhe.valor_boleto" readonly>
                                            </div>
                                            <div class="form-group col-md-8">
                                                <label for="linha_digitavel">Linha Digitável</label>
                                                <input type="text" class="form-control" id="linha_digitavel"
                                                    v-model="detalhe.linha_digitavel" readonly>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-if="!arquivos.length && !loading" class="text-center p-3 text-muted">
                <h5>Nenhuma duda encontrada!</h5>
            </div>
            <!-- fim da tabela -->
            <div class="text-center p-4 text-muted" v-if="loading">
                <h5>Carregando...</h5>
                <p>Aguarde até que os dados sejam carregados...</p>
            </div>
        </div><!-- col -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- row -->
</template>
<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";

export default {
    data() {
        return {
            arquivos: [],
            loading: false,
            selectedPeriodo: null,
            colunas: [
                {
                    label: "NomeArquivo",
                    field: "nome_arquivo",
                },
                {
                    label: "Dt Arquivo",
                    field: "data_arquivo",
                },
                {
                    label: "Ações",
                    field: "actions",
                    html: true,
                },
            ],
            opcoesBusca: {
                enabled: true,
                placeholder: "Pesquisar",
            },
            pesquisa: '',
            detalhe: {
                cnpj_cliente: '',
                dthr_geracao_arquivo: '',
                linha_digitavel: '',
                nome_cliente: '',
                nosso_num: '',
                periodo_final_cobr: '',
                periodo_inicial_cobr: '',
                valor_boleto: '',
                vencimento: ''
            }
        };
    },
    mounted() {
        this.getAllFtpSc();
    },
    methods: {
        handleSearch(searchTerm) {

            this.pesquisa = searchTerm['searchTerm']
            if (this.pesquisa != '') {
                this.getAllFtpSc();
            }
        },
        getAllFtpSc() {
            axios.get("/admin/getAllFtpSc")
                .then((res) => {
                    this.arquivos = res.data;
                })
                .catch((err) => {
                    this.arquivos = [];
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        detalheArquivo(nomeArquivo) {
            this.loading = true

            axios.get("/admin/detalheArquivo/SC/" + nomeArquivo)
                .then((res) => {
                    const response = res.data;
                    this.detalhe = {
                        cnpj_cliente: response.cnpj_cliente,
                        dthr_geracao_arquivo: response.dthr_geracao_arquivo,
                        linha_digitavel: response.linha_digitavel,
                        nome_cliente: response.nome_cliente,
                        nosso_num: response.nosso_num,
                        periodo_final_cobr: response.periodo_final_cobr,
                        periodo_inicial_cobr: response.periodo_inicial_cobr,
                        valor_boleto: response.valor_boleto,
                        vencimento: response.vencimento
                    };

                    $("#modalNovaInstituicao").modal('show');
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        disparaEmail(nomeArquivo) {

            this.$swal({
				title: "Disparo de email ao Financeiro",
				text: "Será disparado ao email do suporte e financeiro o referente boleto do detran SC.",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Enviar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				if (alertConfirm.isConfirmed) {
                    this.loading = true
					axios.get(`/admin/detalheArquivo/SC/${nomeArquivo}/${true}`)
                    .then((res) => {
                        this.loading = false;
						this.$swal({
							title: "Sucesso",
							text: "Email enviado com sucesso!",
							icon: "success"
						});
					}).catch(error => {
                        this.loading = false;
						this.$swal({
							title: "Erro",
							text: "Falha ao enviar o email. Tente novamente!",
							icon: "error"
						});

					});
                }
			});
        },
    },
    components: {
        VueGoodTable,
        Datepicker,
    },
};
</script>
