<template>
	<div class="row">
		<div class="col-12">


			<!-- LIST grupo -->
			<div class="card">


				<!-- table -->
				<div class="pb-4">

					<div class="card-body">

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados Básicos</h6>

						<div class="pl-lg-4">
							<div class="row">
								<div class="col-lg-3 col-md-4">
									<div class="form-group">
										<label class="form-control-label">Razão Social</label>
										<input type="text" id="name" v-bind:class="{ 'is-invalid': errors.name }" class="form-control"
											placeholder="Razão Social" v-model="razao">
										<div class="invalid-feedback" v-if="errors.name">
											<p>{{ errors.name[0] }}</p>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-4">
									<div class="form-group">
										<label class="form-control-label">Nome Fantasia</label>
										<input type="text" id="name" v-bind:class="{ 'is-invalid': errors.name }" class="form-control"
											placeholder="Nome Fantasia" v-model="nome_fantasia">
										<div class="invalid-feedback" v-if="errors.name">
											<p>{{ errors.name[0] }}</p>
										</div>
									</div>
								</div>

								<div class="col-lg-2 col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-tipo-instituicao">Tipo de Grupo</label>
										<select id="input-tipo-instituicao" v-bind:class="{ 'is-invalid': errors.tipo }" v-model="tipo"
											class="form-control" placeholder="Tipo de Instituição">
											<option value="horizontal">Horizontal</option>
											<option value="vertical">Vertical</option>
										</select>
										<div class="invalid-feedback" v-if="errors.tipo">
											<p>{{ errors.tipo[0] }}</p>
										</div>
									</div>
								</div>

								<div class="col-lg-2 col-md-4 pt-2">
									<div class="form-group">
										<a @click="updateItems()" class="btn btn-danger mt-4 btn-filter export-button"
											style="width: 120px;">ATUALIZAR</a>
									</div>
								</div>
							</div>
						</div>

						<!-- divider -->
						<hr class="my-4" />


						<h6 class="heading-small text-muted mb-4">Adicionar Instituição</h6>

						<div class="pl-lg-4">
							<div class="row align-items-center">
								<div class="col-lg-6 pt-4">
									<label for="instituicao" class="form-control-label">Selecione a Instituição</label>
									<v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições" label="label"
										@input="setSelectInstituicao" :options="bancoOptions" v-model="instituicao">
										<template #tags="{ values, search, isOpen, removeTag }">
											<div class="vue-select__tags">
												<span v-for="option in values" :key="option.id" class="vue-select__tags-item"
													@click.stop="removeTag(option)">
													{{ option.label }}
												</span>
											</div>
										</template>
									</v-select>
								</div>
								<div class="col-lg-4 pt-4">
									<a @click="updateInstituicao();"
										class="btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button">ADICIONAR</a>
								</div>
							</div>
						</div>

						<!-- divider -->
						<hr class="my-4" />

						<!-- Access -->
						<h6 class="heading-small text-muted mb-4">Instituições</h6>
						<!-- end form -->

						<vue-good-table :columns="columnsBancos" :rows="banco" :pagination-options="tablePagination" :search-options="{
							enabled: true,
							placeholder: 'Pesquise',
						}" styleClass="vgt-table">

							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field == 'action'">
									<div class="text-center">
										<button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row)"><i
												class="far fa-trash-alt"></i></button>
									</div>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>

					</div>

				</div>
				<!-- end table -->

				<div v-if="!grupo.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum Grupo Econômico encontrado!</h5>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
		</div>

		<div class="modal fade" id="modalGroup" tabindex="-1" aria-labelledby="modalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 1400px;">
				<div class="modal-content">
					<div class="modal-header">
						Instituições
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<vue-good-table :columns="columnsBancos" :rows="banco" :pagination-options="tablePagination" :search-options="{
							enabled: true,
							placeholder: 'Pesquise',
						}" styleClass="vgt-table">

							<template slot="table-row" slot-scope="props">
								<span>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>

					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modalEditGroup" tabindex="-1" aria-labelledby="modalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 1400px;">
				<div class="modal-content">
					<div class="modal-header">

						<h4>Preencha os dados abaixo</h4>

						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="">
							<div class="row">

								<!-- razao -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-razao-social">Razão Social</label>
										<input type="text" id="input-razao-social" autofocus v-bind:class="{ 'is-invalid': errors.razao }"
											class="form-control" placeholder="Razão Social" v-model="razao" required="true" maxlength="100">
										<div class="invalid-feedback" v-if="errors.razao">
											<p>{{ errors.razao[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end razao -->

								<!-- nome_fantasia -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-nome_fantasia">Nome
											fantasia</label>
										<input type="text" id="input-nome_fantasia" autofocus
											v-bind:class="{ 'is-invalid': errors.nome_fantasia }" class="form-control"
											placeholder="Nome fantasia" v-model="nome_fantasia" required="true" maxlength="25">
										<div class="invalid-feedback" v-if="errors.nome_fantasia">
											<p>{{ errors.nome_fantasia[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end nome_fantasia -->

								<!-- tipo -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-tipo-instituicao">Tipo de
											Grupo</label>
										<select id="input-tipo-instituicao" v-bind:class="{ 'is-invalid': errors.tipo }" v-model="tipo"
											class="form-control" placeholder="Tipo de Instituição">
											<option value="horizontal">Horizontal</option>
											<option value="vertical">Vertical</option>
										</select>
										<div class="invalid-feedback" v-if="errors.tipo">
											<p>{{ errors.tipo[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end tipo -->

								<div class="col-6 pt-4">
									<label for="instituicao" class="form-control-label">Selecione a Instituição</label>
									<v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições" label="label"
										@input="setSelectInstituicao" :options="bancoOptions" v-model="instituicao">
										<template #tags="{ values, search, isOpen, removeTag }">
											<div class="vue-select__tags">
												<span v-for="option in values" :key="option.id" class="vue-select__tags-item"
													@click.stop="removeTag(option)">
													{{ option.label }}
												</span>
											</div>
										</template>
									</v-select>
								</div>

								<div class="col-6 pt-4">
									<a @click="updateItems" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>

	</div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
	props: ['grupoecon'],
	data() {
		let data = JSON.parse(this.grupoecon);

		return {
			dataEcon: data,
			response: false,
			grupo: [],
			banco: [],
			bancos: [],
			loading: false,
			calendarLanguage: ptBR,
			razao: data.razao_social,
			nome_fantasia: data.nome_fantasia,
			errors: [],
			message: "",
			tipo: data.tipo,
			bancoOptions: [],
			instituicao: [],
			columns: [
				{
					label: "Razao Social",
					field: "razao_grupoecon",
				},
				{
					label: "Fantasia",
					field: "fantasia_grupoecon",
				},
				{
					label: "Qtd. Instituições",
					field: "qtd_bancos",
				},
				{
					label: "Ações",
					field: "action",
					html: true,
				},
			],
			columnsBancos: [
				{
					label: 'Razão Socail',
					field: "razao_banco"
				},
				{
					label: 'Fantasia',
					field: 'fantasia_banco'
				},
				{
					label: 'CNPJ',
					field: 'cnpj_banco',
				},
				{
					label: 'Cod. Agente',
					field: 'codAgente_banco'
				},
				{
					label: 'Endereço',
					field: 'endereco_banco'
				},
				{
					label: 'Bairro',
					field: 'bairro_banco'
				},
				{
					label: "Número",
					field: "numero_banco",
				},
				{
					label: "Complemento",
					field: "complemento_banco",
				},
				{
					label: "UF",
					field: "estado_banco",
				},
				{
					label: "DDD",
					field: "ddd_banco",
				},
				{
					label: "Telefone",
					field: "telefone_banco",
				},
				{
					label: "Ações",
					field: "action",
					html: true,
				},
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true,
			},
		};
	},
	mounted() {
		this.loadItems();
		this.getAllBancos();
	},
	methods: {

		getData(key) {
			return JSON.parse(this.grupoecon)[key];
		},
		setSelectInstituicao(item) {
			this.instituicao = item.map(banco => banco);
		},
		getAllBancos() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.bancoOptions = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
					selected: false
				}));
				this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		loadItems() {
			let _this = this;
			_this.grupo = []
			_this.banco = []
			_this.loading = true

			axios
				.get(`/admin/grupoecon/${_this.dataEcon.id}`)
				.then((res) => {
					for (let key in res.data) {
						if (typeof res.data[key] === 'object') {
							const group = res.data[key];
							group.vgt_id = key;
							_this.grupo.push(group);
						}
					}

					for (let key in res.data) {
						if (res.data.hasOwnProperty(key)) {
							const item = res.data[key];
							if (Array.isArray(item.infoBancos)) {
								item.infoBancos.forEach((info) => {
									if (typeof info === 'object') {
										info.vgt_id = key;
										_this.banco.push(info);
									}
								});
							}
						}
					}
					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
					console.error(err);
				});
		},
		updateItems() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();

			formData.append("razao_social", _this.razao);
			formData.append("nome_fantasia", _this.nome_fantasia);
			formData.append("tipo", _this.tipo);
			formData.append("_method", "PATCH")

			axios
				.post(`/admin/grupoecon/${_this.dataEcon.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {

					_this.loading = false;

					_this.$swal({
						title: "Sucesso",
						text: "Dados Atualizados com sucesso!",
						icon: "success"
					})
					this.loadItems();

				}).catch((err) => {
					_this.loading = false;
				});
		},

		updateInstituicao() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();

			for (let i = 0; i < this.instituicao.length; i++) {
				formData.append(`id_banco[${i}]`, this.instituicao[i].id);
			}
			formData.append("_method", "PATCH")

			axios
				.post(`/admin/grupoecon/${_this.dataEcon.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {

					_this.loading = false;

					_this.$swal({
						title: "Sucesso",
						text: "Instituições Adicionada(s) com Sucesso!",
						icon: "success"
					})
					this.loadItems();
					_this.instituicao = [];

				}).catch((err) => {
					_this.loading = false;
				});
		},
		deleteData(props) {
			let _this = this;

			_this.$swal({
				title: "Remover Instituição",
				text: "Tem certeza que deseja remover a Instituição?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Remover",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete(`/admin/grupoecon/removeInstituicao/${props.id_gpEconBanco}`).then((res) => {

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Instituição removida com sucesso!",
							icon: "success"
						});

						_this.loadItems();

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao remover a Instituição. Tente novamente!",
							icon: "error"
						});

					});
				}
			});
		},
		viewBancos(item) {
			this.banco = [];
			this.banco = item.infoBancos;
			$("#modalGroup").modal('show');
		},
		viewEditGroup() {
			$("#modalEditGroup").modal('show');
		},
		viewCancelamento(item) {
			this.contracts = [];
			this.contracts = item;
			$("#modalCancelamento").modal('show');
		},
		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},
		resized() {
			var tb = document.getElementById('vgt-table')
			tb.style.height = '200px';
		},
		resizedM() {
			var tb = document.getElementById('vgt-table')
			if (tb.style.height == '380px') {
				tb.style.height = 'auto';

			}
		},
	},
	components: {
		VueGoodTable,
		Datepicker
	},
};
</script>
