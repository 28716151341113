var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card p-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"text_checkbox",attrs:{"for":"input-select_Month"}},[_vm._v("Selecione o Mês")]),_vm._v(" "),_c('v-select',{attrs:{"label":"mesAno","options":_vm.months},on:{"input":_vm.setData},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6 pt-4"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"instituicao"}},[_vm._v("Selecione a Instituição")]),_vm._v(" "),_c('v-select',{attrs:{"multiple":"","id":"instituicao","placeholder":"Selecione uma ou mais Instituições","label":"label","options":_vm.bancoOptions},on:{"input":_vm.setSelectInstituicao},scopedSlots:_vm._u([{key:"tags",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
var removeTag = ref.removeTag;
return [_c('div',{staticClass:"vue-select__tags"},_vm._l((values),function(option){return _c('span',{key:option.id,staticClass:"vue-select__tags-item",on:{"click":function($event){$event.stopPropagation();return removeTag(option)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(option.label)+"\n\t\t\t\t\t\t\t\t\t")])}),0)]}}]),model:{value:(_vm.instituicao),callback:function ($$v) {_vm.instituicao=$$v},expression:"instituicao"}})],1),_vm._v(" "),_c('div',{staticClass:"col-6 pt-4"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"estado"}},[_vm._v("Selecione o Detran")]),_vm._v(" "),_c('v-select',{attrs:{"multiple":"","id":"estado","placeholder":"Selecione um ou mais estados.","label":"label","options":_vm.estadoOptions},on:{"input":_vm.setSelectedState},scopedSlots:_vm._u([{key:"tags",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
var removeTag = ref.removeTag;
return [_c('div',{staticClass:"vue-select__tags"},_vm._l((values),function(option){return _c('span',{key:option.id,staticClass:"vue-select__tags-item",on:{"click":function($event){$event.stopPropagation();return removeTag(option)}}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(option.label)+"\n\t\t\t\t\t\t\t\t\t")])}),0)]}}]),model:{value:(_vm.detransSelecionado),callback:function ($$v) {_vm.detransSelecionado=$$v},expression:"detransSelecionado"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"form_bt_exe"},[_c('a',{staticClass:"btn_exec_faturamento",attrs:{"id":"btn-executar"},on:{"click":_vm.execute}},[_vm._v("EXECUTAR")])])])]),_vm._v(" "),_c('vue-loading',{attrs:{"color":"#ec2434","active":_vm.loading,"can-cancel":false},on:{"update:active":function($event){_vm.loading=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }