import { cpf, cnpj } from 'cpf-cnpj-validator';

export function isValidCPF(cpfValue) {
  return cpf.isValid(cpfValue);
}

export function isValidCNPJ(cnpjValue) {
  return cnpj.isValid(cnpjValue);
}

export function validateCpfCnpj(documentoValue) {
  const cpfCnpjValue = documentoValue.trim().replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpfCnpjValue.length === 0) {
    return 'Campo obrigatório';
  } else if (cpfCnpjValue.length === 11) {
    if (!isValidCPF(cpfCnpjValue)) {
      return 'CPF inválido';
    }
  } else if (cpfCnpjValue.length > 11) {
    if (!isValidCNPJ(cpfCnpjValue)) {
      return 'CNPJ inválido';
    }
  } else {
    return 'Informação inválida';
  }

  return '';
}


export function validateCampo(type, value) {
  // Remove todos os caracteres que não são letras, números, barra (/) e hífen (-)
  if (type === 'type_numContrato') return value.replace(/[^a-zA-ZÀ-ÿ\d\/\-]/g, '');
  if (type === 'type_number_Lyrics') return value.replace(/[^a-zA-Z\d]/g, '');
  if (type === 'type_numbers') return value.replace(/[^\d]/g, '');
  if (type === 'type_mask') return value.replace(/[^\d\-]/g, '');
  if (type === 'type_lyrics') return value.replace(/[^a-zA-Z\s]/g, '');
  if (type === 'type_gravame') return value.replace(/[^\d\-\/]/g, '');
  if (type === 'type_indice') return value.replace(/[^a-zA-ZÀ-ÿ]/g, '');

}







