<template>
    <section class="section dashboard">

        <div class="row">

            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-secondary">
                        <i class="fas fa-file-alt"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4 style="margin-top:-10px">Contratos Cadastrados</h4>
                        </div>
                        <div class="card-body">
                            <div v-if="totalcontratos === -1"
                                class="spinner-border spinner-border spinner-border-sm text-secondary" role="status">
                            </div>
                            <div v-else>{{ totalcontratos }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-success">
                        <i class="fas fa-clipboard-check"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4 style="margin-top:-10px">Contratos Registrados</h4>
                        </div>
                        <div class="card-body">
                            <div v-if="totalContratosRegistrados === -1"
                                class="spinner-border spinner-border spinner-border-sm text-success" role="status">
                            </div>
                            <div v-else>{{ totalContratosRegistrados }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-warning">
                        <i class="fas fa-hourglass-half"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4 style="margin-top:-10px">Contratos em Processamento</h4>
                        </div>
                        <div class="card-body">
                            <div v-if="totalContratosProcessando === -1"
                                class="spinner-border spinner-border spinner-border-sm text-warning" role="status">
                            </div>
                            <div v-else>{{ totalContratosProcessando }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-primary">
                        <i class="fas fa-history"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4 style="margin-top:-10px">Contratos Pendentes</h4>
                        </div>
                        <div class="card-body">
                            <div v-if="totalContratosPendentes === -1"
                                class="spinner-border spinner-border spinner-border-sm text-danger" role="status"></div>
                            <div v-else>{{ totalContratosPendentes }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Filter -->
        <div class="modal fade" id="modalFiltrar" tabindex="-1" aria-labelledby="modalFiltrarLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalFiltrarLabel">Filtrar</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="userGpEcon.is_group_economic == 'S'" class="form-group">
                            <label for="estado" class="form-control-label">Selecione a Instituição</label>
                            <v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições"
                                label="label" @input="setSelectInstituicao" :options="bancoOptions"
                                :value="instituicaoSelecionada">
                            </v-select>
                        </div>
                        <div class="form-group">
                            <label for="estado" class="form-control-label">Selecione o Detran</label>
                            <v-select multiple id="estado" placeholder="Selecione um ou mais estados." label="states"
                                @input="setSelectedState" :options="states" class=""
                                :value="detransSelecionado"></v-select>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="date_initial" class="form-control-label">Data Inicial</label>
                                <input type="date" name="date_initial" class="form-control" v-model="date_initial"
                                    @change="handleChange" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="date_end" class="form-control-label">Data Final</label>
                                <input type="date" name="date_end" class="form-control" v-model="date_end"
                                    @change="handleChange" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                        <button type="button" class="btn btn-primary"
                            style="padding-right: 20px !important; padding-left: 20px !important;" @click="loadFilters">
                            <i class="fas fa-filter"></i> Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Model Filter -->

        <!-- chart -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-end">
                        <button type="button" class="btn btn-primary"
                            style="padding-left: 20px !important; padding-right: 20px !important;"
                            @click="openFilterModal">
                            <i class="fa fa-filter"></i> Filtrar
                        </button>
                        <button class="btn btn-limpar btn-filter float-right mr-2" data-toggle="tooltip"
                            data-placement="top" title="Limpar Filtro" @click="limparFiltros" style="margin-left:10px">
                            <i class="fas fa-trash fa-lg"></i> Limpar Filtros
                        </button>
                    </div>
                    <div class="card-body" id="chartDashboard" v-if="dataTotal.length > 0">
                        <line-chart :chartData="chartData" :options="chartOptions" :height="150" />
                    </div>
                    <div class="card-body p-4 text-center" v-else>
                        <div class="spinner-border spinner-border text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
        <!-- end chart -->
    </section>
</template>

<script>
import Line from "./line-chart";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
    components: {
        LineChart: Line,
        Datepicker
    },
    props: ['user'],
    data() {
        return {
            load: false,
            loading: false,
            banco: [],
            bancos: [],
            bancoOptions: [],
            instituicaoSelecionada: [],
            userGpEcon: JSON.parse(this.user),
            detrans: [],
            estadoOptions: [],
            detransSelecionado: [],
            date_initial: '',
            date_end: '',
            detranFilter: [],
            bancoFilter: [],
            listYears: [],
            contratos: {
                total: -1,
                concluido: -1,
                processando: -1,
                pendente: -1,
                contratos_status: "",
                contratos_processando: "",
                contratos_pendendte: "",
                contratos_concluido: ""
            },
            states: [],
            detranids: [],
            chartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
            dataProcessando: [],
            dataCocluido: [],
            dataPendente: [],
            dataTotal: [],
            totalcontratos: -1,
            totalContratosRegistrados: -1,
            totalContratosProcessando: -1,
            totalContratosPendentes: -1,
            periodo: {
                anual: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                ],
            },
            formChange: {
                values: {
                    date_initial: "",
                    date_end: "",
                    periodo: "mensal"
                },
                errors: {
                    date_initial: {
                        message: "Data inicial maior que a final",
                        error: false
                    }
                },
                touched: {}
            },
            changedPeriodo: {
                value: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                ]
            },
            calendarLanguage: ptBR
        };
    },
    mounted() {
        this.loadChartData();
        this.loadDates();
        this.calcPeriodo();
        if (this.userGpEcon.is_group_economic != 'S') {
            this.loadStates();
        }

        if (this.userGpEcon.is_group_economic == 'S') {
            this.getAllBancos()
        }

    },
    methods: {
        setSelectInstituicao(item) {
            this.instituicaoSelecionada = item
            this.loadStates();
        },
        setSelectedState(item) {
            this.detransSelecionado = item;
        },
        getAllBancos() {
            axios.get("/api/portal/getAllBancos").then(res => {
                this.bancos = res.data.bancos;
                this.bancoOptions = this.bancos.map(element => ({
                    label: element.nome,
                    id: element.id,
                    selected: false
                }));
                this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
            }).catch((err) => {
                console.error(err);
                this.loading = false;
            });
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        handleChange(event) {
            event.preventDefault();
            const name = event.target.name;
            const value = event.target.value;
            this.formChange.values = {
                ...this.formChange.values,
                [name]: value
            };
            this.formChange.errors = {
                ...this.formChange.errors,
                [name]: {
                    message: "",
                    error: false
                }
            };
        },
        validPeriodo() {
            try {
                if (new Date(this.formChange.values.date_initial) > new Date(this.formChange.values.date_end)) {
                    this.formChange.errors.date_initial = {
                        message: "Data inicial maior que a final",
                        error: true
                    };
                }
            } catch (err) { }
        },
        loadFilters() {
            this.load = true;
            this.validPeriodo();
            if (!this.formChange.errors.date_initial.error) {
                this.detranFilter = [];
                this.estadoOptions.forEach((element) => {
                    if (element.selected) {
                        this.detranFilter.push(this.detrans.filter(item => item.id == element.id));
                    }
                });
                this.bancoFilter = [];
                this.bancoOptions.forEach((element) => {
                    if (element.selected) {
                        this.bancoFilter.push(this.bancos.filter(item => item.id == element.id));
                    }
                });
                this.loadChartData();
                this.load = false;
                $('#modalFiltrar').modal('hide');
            }
        },
        loadChartData() {
            this.loading = false;
            this.selectedDetran();

            let detrans = [];
            if (this.detransSelecionado.length > 0) {
                this.detransSelecionado.forEach(detran => {
                    const option = this.estadoOptions.find(element => element.name === detran);

                    if (option) {
                        detrans.push(option.id);
                    }
                });
            }
            let bancos = [];
            if (this.instituicaoSelecionada.length > 0) {
                this.instituicaoSelecionada.forEach(banco => {
                    const option = this.bancoOptions.find(element => element.label === banco.label);
                    if (option) {
                        bancos.push(option.id);
                    }
                });
            }

            if (this.formChange.values.date_initial === "" && this.formChange.values.date_end === "") {
                const date_atual = new Date();
                const ano_atual = Intl.DateTimeFormat("pt-BR", { dateStyle: "short" }).format(date_atual);
                const [dia, mes, ano] = ano_atual.split("/");
                this.formChange.values.date_initial = `${parseInt(ano)}-01-01`;
                this.formChange.values.date_end = `${parseInt(ano)}-12-31`;
            }

            this.calcPeriodo();

            const formValues = [
                this.formChange.values.periodo,
                this.formChange.values.date_initial,
                this.formChange.values.date_end
            ];

            const getData = (endpoint, dataKey, targetData) => {
                if (this.load === true) {
                    this.loading = true;
                }
                let url = ''
                if (this.userGpEcon.is_group_economic == 'S') {
                    if (bancos.length > 0) {
                        url = detrans.length > 0
                            ? `/api/admin/dashboard/grafico/${detrans}/${formValues}/${endpoint}/${this.userGpEcon.gp_econ_id}/${bancos}`
                            : `/api/admin/dashboard/grafico/todos/${formValues}/${endpoint}/${this.userGpEcon.gp_econ_id}/${bancos}`
                    }
                    if (bancos.length == 0) {
                        url = detrans.length > 0
                            ? `/api/admin/dashboard/grafico/${detrans}/${formValues}/${endpoint}/${this.userGpEcon.gp_econ_id}`
                            : `/api/admin/dashboard/grafico/todos/${formValues}/${endpoint}/${this.userGpEcon.gp_econ_id}`
                    }
                } else {
                    url = detrans.length > 0
                        ? `/api/admin/dashboard/grafico/${detrans}/${formValues}/${endpoint}`
                        : `/api/admin/dashboard/grafico/todos/${formValues}/${endpoint}`
                }

                return axios
                    .get(url)
                    .then(res => {
                        if (res.data.dados) {
                            const dados = res.data.dados.split(",");
                            targetData[dataKey] = dados;

                        } else {
                            throw res.data;
                        }
                    })
                    .catch(err => { });
            };

            Promise.all([
                getData('concluido', "dataCocluido", this),
                getData("processamento", "dataProcessando", this),
                getData("pendente", "dataPendente", this),
                getData("all", "dataTotal", this),
            ])
                .then(() => {
                    this.updateChartData();
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        async updateChartData() {
            try {

                const somaDataProcessando = this.dataProcessando.reduce((total, value) => total + parseInt(value), 0);
                const somaDataPendentes = this.dataPendente.reduce((total, value) => total + parseInt(value), 0);

                const totalProcessando = somaDataProcessando;
                const totalPendente = somaDataPendentes;

                this.totalcontratos = this.dataTotal.reduce((total, value) => total + parseInt(value), 0)
                this.totalContratosRegistrados = this.dataCocluido.reduce((total, value) => total + parseInt(value), 0)
                this.totalContratosProcessando = totalProcessando
                this.totalContratosPendentes = totalPendente

                this.chartData = {

                    labels: this.changedPeriodo.value,
                    datasets: [
                        {
                            label: `Total de Contratos Cadastrados`,
                            backgroundColor: "#cdd3d8",
                            data: this.dataTotal
                        },
                        {
                            label: `Total Contratos Registrados`,
                            backgroundColor: "#47c363",
                            data: this.dataCocluido
                        },
                        {
                            label: `Total Contratos em Processamento`,
                            backgroundColor: "#ffa426",
                            data: this.dataProcessando
                        },
                        {
                            label: `Total Contratos Pendentes`,
                            backgroundColor: "#ec2434",
                            data: this.dataPendente
                        }
                    ]
                };
            } catch (error) {
                this.loading = false;
                console.error(error);
            }
        },
        selectedDetran(id) {
            this.estadoOptions.forEach(element => {
                if (element.id == id) {
                    element.selected = !element.selected;
                }
            });
        },
        selectedIntituicao(id) {
            this.estadoOptions.forEach(element => {
                if (element.id == id) {
                    element.selected = !element.selected;
                }
            });
        },
        openFilterModal() {
            $("#modalFiltrar").modal("show");
        },
        loadDashboardData(filters) {
            this.loadTotalContratos();
            this.loadConcluidoContratos();
            this.loadProcessandoContratos();
            this.loadPendenteContratos();
        },
        loadTotalContratos() {

            let url = '/api/total-contratos/cliente'
            if (this.userGpEcon.is_group_economic == 'S') {
                url = `/api/total-contratos/cliente/${this.userGpEcon.gp_econ_id}`
            }

            axios.get(url)
                .then(res => {
                    this.contratos.total = res.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        loadConcluidoContratos() {
            let url = '/api/total-contratos-concluido/cliente'
            if (this.userGpEcon.is_group_economic == 'S') {
                url = `/api/total-contratos-concluido/cliente/${this.userGpEcon.gp_econ_id}`
            }

            axios.get(url)
                .then(res => {
                    this.contratos.concluido = res.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        loadProcessandoContratos() {

            let url = '/api/total-contratos-processando/cliente'
            if (this.userGpEcon.is_group_economic == 'S') {
                url = `/api/total-contratos-processando/cliente/${this.userGpEcon.gp_econ_id}`
            }

            axios.get(url)
                .then(res => {
                    this.contratos.processando = res.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        loadPendenteContratos() {

            let url = '/api/total-contratos-pendente/cliente'
            if (this.userGpEcon.is_group_economic == 'S') {
                url = `/api/total-contratos-pendente/cliente/${this.userGpEcon.gp_econ_id}`
            }

            axios.get(url)
                .then(res => {
                    this.contratos.pendente = res.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        loadStates(filters) {
            let _this = this;
            const estados = [];

            let url = `/api/portal/getDetransUser`

            let bancos = [];
            if (this.userGpEcon.is_group_economic == 'S') {
                if (this.instituicaoSelecionada.length > 0) {
                    this.instituicaoSelecionada.forEach(banco => {
                        const option = this.bancoOptions.find(element => element.label === banco.label);
                        if (option) {
                            bancos.push(option.id);
                        }
                    });
                }

                url = `/api/portal/getDetransUser/${this.userGpEcon.is_group_economic}/${bancos}`
            }

            axios
                .post(url)
                .then(res => {
                    _this.detrans = Object.values(res.data.detrans);
                    _this.detrans.forEach(element => {
                        _this.estadoOptions.push({
                            name: element.estado,
                            id: element.id,
                            selected: false
                        });
                    });

                    _this.detrans.forEach(element => {
                        estados.push(element.estado);
                    });

                    _this.states = this.sortVector(estados);

                });

        },
        loadDates() {
            const currentYear = new Date();
            for (let i = parseInt(currentYear.getFullYear()); i > 2000; i--) {
                this.listYears.push(i);
            }
        },
        sortVector(vector) {
            if (vector.length) {
                return vector.sort(function (a, b) {
                    return a.localeCompare(b);
                });
            }
        },
        calcPeriodo() {
            const date_initial = this.formChange.values.date_initial;
            const date_end = this.formChange.values.date_end;
            const diffDate = new Date(date_end) - new Date(date_initial);
            const diffDias = diffDate / (1000 * 60 * 60 * 24);
            const [ano_initial, mes_initial, dia_initial] = date_initial.split("-");
            const [ano_end, mes_end, dia_end] = date_end.split("-");

            if (diffDias <= this.setDiasMes(mes_initial)) {
                this.formChange.values.periodo = "diario";
                if (mes_initial) {
                    this.changedPeriodo.value = [];
                    for (let i = parseInt(dia_initial); i <= this.setDiasMes(mes_initial); i++) {
                        this.changedPeriodo.value.push(`${i}/${this.setMesesAno(parseInt(mes_initial))}/${ano_initial}`);
                    }
                }
            } else if (diffDias > 30 && (parseInt(ano_end) - parseInt(ano_initial)) === 0 && diffDias <= 365) {
                this.formChange.values.periodo = "mensal";
                this.changedPeriodo.value = [];
                for (let i = parseInt(mes_initial); i <= parseInt(mes_end); i++) {
                    this.changedPeriodo.value.push(`${this.setMesesAno(i)}/${ano_initial}`);
                }
            } else if (parseInt(ano_end) - parseInt(ano_initial) > 0) {
                this.formChange.values.periodo = "anual";
                this.changedPeriodo.value = [];
                for (let i = parseInt(ano_initial); i <= parseInt(ano_end); i++) {
                    this.changedPeriodo.value.push(`${i}`);
                }
            }
        },

        setDiasMes(mes) {
            switch (mes) {
                case "01":
                case "03":
                case "05":
                case "07":
                case "08":
                case "10":
                case "12":
                    return 31;
                case "04":
                case "06":
                case "09":
                case "11":
                    return 30;
                case "02":
                    return 28;
                default:
                    return 0;
            }
        },

        setMesesAno(mes) {
            switch (mes) {
                case 1:
                    return "Janeiro";
                case 2:
                    return "Fevereiro";
                case 3:
                    return "Março";
                case 4:
                    return "Abril";
                case 5:
                    return "Maio";
                case 6:
                    return "Junho";
                case 7:
                    return "Julho";
                case 8:
                    return "Agosto";
                case 9:
                    return "Setembro";
                case 10:
                    return "Outubro";
                case 11:
                    return "Novembro";
                case 12:
                    return "Dezembro";
                default:
                    return "";
            }
        },
        limparFiltros() {
            this.load = true;
            this.loading = true;
            this.detransSelecionado = [];
            this.instituicaoSelecionada = [];
            this.date_initial = '';
            this.date_end = '';
            this.detranFilter = [];
            this.bancoFilter = [];
            this.formChange = {
                values: {
                    date_initial: "",
                    date_end: "",
                    periodo: "mensal"
                },
                errors: {
                    date_initial: {
                        message: "",
                        error: false
                    }
                },
                touched: {}
            };
            this.changedPeriodo = {
                value: [
                    "Janeiro",
                    "Fevereiro",
                    "Março",
                    "Abril",
                    "Maio",
                    "Junho",
                    "Julho",
                    "Agosto",
                    "Setembro",
                    "Outubro",
                    "Novembro",
                    "Dezembro"
                ]
            };
            this.loadChartData();

        },
    }
}
</script>
