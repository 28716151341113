<template>
	<div class="row">
		<div class="col-12">

			<div class="card">
				<!-- card body -->
				<div class="card-body">
					<!-- table -->
					<h4>Importação</h4>
					<!-- table -->
					<div class="card p-4">
						<div>
							<h5>Filtrar Período Desejado: </h5>
						</div>
						<div class="row">
							<div class="row">
								<div class="col-md-6 p-4">
									<div class="form-group date-custom">
										<datepicker :language="calendarLanguage" v-model="dataInicial"
											placeholder="Data Inicial" class="btn-filter" />
									</div>
								</div>
								<div class="col-md-6 p-4">
									<div class="form-group date-custom">
										<datepicker :language="calendarLanguage" v-model="dataFinal"
											placeholder="Data Final" class="btn-filter" />
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<a href="#" class="btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button"
									style="height: 30px;" @click="carregarLogs()">
									Filtrar
								</a>
								<button class="btn btn-limpar btn-filter col-md-4 col-lg-2 col-sm-5 mt-4 ml-2 "
									style="border-radius: 10px; height: 30px;" data-toggle="tooltip" data-placement="top"
									title="Limpar Filtro" @click="limparFiltros">
									Limpar Filtro <i class="fas fa-trash fa-lg"></i>
								</button>
							</div>

						</div>
						<div class="mx-auto">
							<h5>Período: {{ periodoSelecionado }}</h5>
						</div>
						<!-- Filtro -->
						<div class="export-options w-auto">
							<a href="#" class="btn btn-danger btn-filter export-button" @click="exportLogImportacaoToPdf">
								<i class="far fa-file-pdf"></i> PDF
							</a>
							<a href="#" class="btn btn-success btn-filter export-button"
								@click="exportLogImportacaoToExcel">
								<i class="far fa-file-excel"></i> EXCEL
							</a>

						</div>
						<vue-good-table :columns="columns" :rows="log_importacao" :line-numbers="true" :pagination-options="{
							enabled: true,
							mode: 'records',
							position: 'bottom',
							dropdownAllowAll: true,
							perPageDropdown: [10, 30, 50],
							nextLabel: 'Próximo',
							prevLabel: 'Anterior',
							rowsPerPageLabel: '',
							ofLabel: 'de',
							allLabel: 'Todos',
							pageLabel: '',
							perPageDropdownEnabled: true,
							compactMode: true,
						}" :sort-options="{
	enabled: true,
	initialSortBy: { field: 'created_at', type: 'desc' }
}" :search-options="{
	enabled: true,
	placeholder: 'Pesquisar',
}" styleClass="vgt-table" max-height="900px">
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field == 'actions'">
									<div class="text-left">
										<button class="btn btn-sm btn-outline-secondary"
											@click="viewItensImportacao(props.row)" title="Visualizar Itens Importado"><i
												class="far fa-eye"></i></button>
									</div>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<div v-if="!log_importacao.length && !loading" class="text-center p-3 text-muted">
						<h5>Nenhuma importação encontrada!</h5>
					</div>
					<!-- end table -->
					<div class="text-center p-4 text-muted" v-if="loading">
						<h5>Carregando...</h5>
						<p>Aguarde até que os dados sejam carregados...</p>
					</div>
					<!-- end table -->
				</div>
				<!-- end card body -->
			</div>

			<!-- MODAL ITENS IMPORTACAO -->

			<div class="modal fade" id="modalItensImportacao" tabindex="-1" aria-labelledby="modalItensImportacaoLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl modal-dialog-centered" style="max-width: 1400px;">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalItensImportacaoLabel">{{ `Itens Importados do arquivo:
								${nomeImportacao}`
							}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div class="export-options w-auto">
							<a href="#" class="btn btn-danger btn-filter export-button" @click="exportItemImportacaoToPdf">
								<i class="far fa-file-pdf"></i> PDF
							</a>

							<a href="#" class="btn btn-success btn-filter export-button"
								@click="exportItemImportacaoToExcel">
								<i class="far fa-file-excel"></i> EXCEL
							</a>
						</div>

						<div class="modal-body">

							<vue-good-table :columns="columnsItensImportacao" :rows="itensImportacao"
								:pagination-options="tablePagination" :search-options="{
									enabled: true,
									placeholder: 'Pesquisar'
								}" styleClass="vgt-table">

								<template slot="table-row" slot-scope="props">
									<span v-if="props.column.field === 'status'">
										<span class="alert-table alert alert-success"
											v-if="props.row.status === 'Importado'">Importado</span>
										<span class="alert-table alert alert-danger"
											v-if="props.row.status === 'Nao_importado'">Não
											Importado</span>
									</span>
									<span v-else>
										{{ props.formattedRow[props.column.field] }}
									</span>
								</template>
							</vue-good-table>

						</div>
					</div>
				</div>
			</div>

			<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
		</div>
	</div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';



export default {

	data() {
		return {

			itensImportacao: [],
			log_importacao: [],
			dataInicial: "",
			dataFinal: "",
			periodoSelecionado: 'Últimos 30 dias',
			calendarLanguage: ptBR,
			loading: false,
			columns: [
				{
					label: "Arquivo",
					field: "arquivo",
					sortable: true
				},
				{
					label: "Tipo",
					field: "tipo",
					sortable: true
				},
				{
					label: "Usuário de Inclusão",
					field: "name",
					sortable: true,
				},
				{
					label: "Data Criação",
					field: "created_at",
					formatFn: this.formatDate,
					sortable: true,
				},
				{
					label: "Ver contratos",
					field: "actions",
					html: true,
					sortable: false
				},
			],

			columnsItensImportacao: [

				{
					label: 'Linha',
					field: 'num_linha',
					sortable: false
				},
				{
					label: 'Chassi',
					field: 'chassi',
					sortable: false
				},
				{
					label: 'Estado',
					field: 'estado',
					sortable: false
				},
				{
					label: 'Status',
					field: 'status',
					sortable: false
				},
				{
					label: 'Motivo',
					field: 'motivo',
					sortable: false
				},
				{
					label: 'Nº Contrato',
					field: 'contrato_id',
					sortable: false
				},

				{
					label: 'Contrato Status',
					field: 'contrato_status_id',
					sortable: false
				},
				{
					label: 'Dt. de Registro no Detran',
					field: 'dt_sucesso_reg_detran',
					sortable: false
				},
				{
					label: "Criado em",
					field: "created_at",
					formatFn: this.formatDate,
					sortable: false
				},
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true
			},
			nomeImportacao: '',
		}
	},
	mounted() {
		this.carregarLogs('sig');
	},
	methods: {
		editData() {

		},

		viewItensImportacao(item) {
			$("#modalItensImportacao").modal('show');
			if (item.id) {
				this.getLogItensImportacao(item.id);
			}
		},

		getLogItensImportacao(id) {
			let _this = this;
			_this.nomeImportacao = '';
			_this.itensImportacao = [];
			_this.loading = true;
			axios.get(`/portal/logimportacaoitens/${id}`).then(response => {
				_this.nomeImportacao = response.data.nome;
				_this.itensImportacao = response.data.itens;
				_this.loading = false;

				if (response.data.length === 0) {
					$("#modalItensImportacao").modal('hide');
					_this.$swal({
						type: 'info',
						title: 'Itens Importação',
						text: 'Nenhum item encontrado para o arquivo selecionado',
						showConfirmButton: true,
						confirmButtonText: 'Fechar'
					});
				}

			})
				.catch(error => {
					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao buscar itens. Tente novamente!",
						icon: "error"
					});
				});
		},

		// Inicio - Processo para Gerar PDF/EXCEL da Log de Importação

		exportLogImportacaoToPdf() {
			// Crie um novo objeto jsPDF
			const doc = new jsPDF();

			const img = new Image();
			img.src = '/images/default-logo.png';

			// Adicione a imagem no cabeçalho da primeira página
			const marginLeft = 12;
			doc.addImage(img, 'JPEG', marginLeft, 5, 30, 13);

			// Adicione o título ao lado da imagem na primeira página
			doc.setFontSize(12); // Defina o tamanho da fonte como 14
			doc.text('Arquivos Importados', marginLeft + 65, 17);
			doc.setFontSize(8); // Restaure o tamanho da fonte para 10 para outros textos


			// Adicione a data na primeira página
			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora
			doc.text('Gerado em: ' + currentDate, marginLeft + 145, 17);

			// Defina as colunas da tabela
			const columns = [
				'Arquivo',
				'Tipo',
				'Usuário da Inclusão',
				'Data Criação'
			];

			const columnStyles = {};
			for (let i = 0; i < 5; i++) {
				columnStyles[i] = { halign: 'center', fillColor: [231, 234, 236] };
			}

			// Defina os dados da tabela (substitua isso pelos seus próprios dados)
			const data = this.log_importacao.map(item => [
				item.arquivo,
				item.tipo,
				item.name,
				this.formatDate(item.created_at),
				this.formatDate(item.created_at)
			]);

			// Crie uma tabela usando a função autoTable da biblioteca jsPDF
			doc.autoTable({
				head: [columns],
				body: data,
				theme: 'striped',
				styles: { overflow: 'linebreak' },
				margin: { top: 20 }, // Aumente o topo para dar espaço ao cabeçalho
				styles: { halign: 'center', fillColor: [236, 36, 52] },
				columnStyles: columnStyles,
			});

			// Adicione páginas adicionais (a partir da segunda página)
			const totalPages = doc.internal.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				doc.text('Página ' + i + '-' + totalPages, 10, doc.internal.pageSize.height - 10);
			}

			// Salve o arquivo PDF
			doc.save('Log-Importacao.pdf');
		},


		exportLogImportacaoToExcel() {

			// Crie um novo workbook
			const workbook = XLSX.utils.book_new();

			// Crie uma nova planilha
			const worksheet = XLSX.utils.aoa_to_sheet([]);

			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora

			// Defina o título na célula A1
			XLSX.utils.sheet_add_aoa(worksheet, [[`Result1`]], { origin: 'A1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A2' });
			XLSX.utils.sheet_add_aoa(worksheet, [['Gerado em: ' + currentDate]], { origin: 'C3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[`Arquivos Importados`]], { origin: 'A3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A4' });

			worksheet['!cols'] = [
				{ wch: 45 }, // Largura da primeira coluna (coluna A)
				{ wch: 10 }, // Largura da segunda coluna (coluna B)
				{ wch: 25 }, // Largura da terceira coluna (coluna C)
				// ... adicione mais objetos para mais colunas
			];

			// Defina as colunas e dados da planilha
			const columns = [
				'Arquivo',
				'Tipo',
				'Usuário da Inclusão',
				'Data Criação'
			];

			// Adicione as colunas na linha A2
			XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A5' });

			// Preencha os dados
			const data = this.log_importacao.map(item => [
				item.arquivo,
				item.tipo,
				item.name,
				this.formatDate(item.created_at),
			]);

			// Adicione os dados
			XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A6' });

			// Adicione a planilha ao workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Log Importação');

			// Crie um arquivo Excel blob
			const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Crie um link para download
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Log-Importacao.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);

		},
		// Fim

		//Inicio processo para Gerar PDF/EXCEL dos Items da Importação
		exportItemImportacaoToPdf() {

			const doc = new jsPDF({
				orientation: 'landscape'
			});

			const nomeArquivo = this.nomeImportacao;

			const img = new Image();
			img.src = '/images/default-logo.png';

			const marginLeft = 12;
			// Adicione a imagem no cabeçalho
			doc.addImage(img, 'JPEG', marginLeft, 2, 30, 13);

			// Adicione o título ao lado da imagem
			doc.setFontSize(12);
			doc.text(`Itens Importados do arquivo: ${nomeArquivo}`, marginLeft + 2, 21);

			doc.setFontSize(8);
			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora
			doc.text('Gerado em: ' + currentDate, marginLeft + 233, 21);

			// Defina as colunas da tabela
			const columns = [
				'Linha',
				'Chassi',
				'Estado',
				'Status',
				'Motivo',
				'Nº Contrato',
				'Contrato Status',
				'Dt. de Registro no Detran',
				'Criado em:'
			];

			const columnStyles = {};
			for (let i = 0; i < 9; i++) {
				columnStyles[i] = { halign: 'center', fillColor: [231, 234, 236] };
			}

			// Defina os dados da tabela (substitua isso pelos seus próprios dados)
			const data = this.itensImportacao.map(item => [
				item.num_linha,
				item.chassi,
				item.estado,
				item.status,
				item.motivo,
				item.contrato_id,
				item.contrato_status_id,
				item.dt_sucesso_reg_detran,
				this.formatDate(item.created_at)

			]);



			// Crie uma tabela usando a função autoTable da biblioteca jsPDF
			doc.autoTable({
				head: [columns],
				body: data,
				theme: 'grid',
				styles: { overflow: 'linebreak' },
				margin: { top: 25 }, // Aumente o topo para dar espaço ao cabeçalho
				styles: { halign: 'center', fillColor: [236, 36, 52] },
				columnStyles: columnStyles,
			});


			// Adicione páginas adicionais (a partir da segunda página)
			const totalPages = doc.internal.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				doc.text('Página ' + i + '-' + totalPages, 10, doc.internal.pageSize.height - 10);
			}

			// Salve o arquivo PDF
			doc.save(`Itens Importados do arquivo (${nomeArquivo}).pdf`);
		},

		exportItemImportacaoToExcel() {

			// Crie um novo workbook
			const workbook = XLSX.utils.book_new();

			// Crie uma nova planilha
			const worksheet = XLSX.utils.aoa_to_sheet([]);

			const nomeArquivo = this.nomeImportacao;

			const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora

			// Defina o título na célula A1
			XLSX.utils.sheet_add_aoa(worksheet, [[`Result1`]], { origin: 'A1' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A2' });
			XLSX.utils.sheet_add_aoa(worksheet, [['Gerado em: ' + currentDate]], { origin: 'E3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[`Itens Importados do arquivo: ${nomeArquivo}`]], { origin: 'B3' });
			XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A4' });


			// Ajuste a largura da coluna A (assumindo que você deseja definir a largura para 15)
			worksheet['!cols'] = [
				{ wch: 10 }, // Largura da primeira coluna (coluna A)
				{ wch: 45 }, // Largura da segunda coluna (coluna B)
				{ wch: 15 }, // Largura da terceira coluna (coluna C)
				{ wch: 20 }, // Largura da terceira coluna (coluna D)
				{ wch: 25 }, // Largura da terceira coluna (coluna E)
				{ wch: 15 }, // Largura da terceira coluna (coluna F)
				{ wch: 35 }, // Largura da terceira coluna (coluna G)
				{ wch: 25 }, // Largura da terceira coluna (coluna H)
				{ wch: 25 }, // Largura da terceira coluna (coluna I)
				// ... adicione mais objetos para mais colunas
			];

			// Defina as colunas e dados da planilha
			const columns = [
				'Linha',
				'Chassi',
				'Estado',
				'Status',
				'Motivo',
				'Nº Contrato',
				'Contrato Status',
				'Dt. de Registro no Detran',
				'Criado em:'
			];

			// Adicione as colunas na linha A2
			XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A5' });

			// Preencha os dados
			const data = this.itensImportacao.map(item => [
				item.num_linha,
				item.chassi,
				item.estado,
				item.status,
				item.motivo,
				item.contrato_id,
				item.contrato_status_id,
				item.dt_sucesso_reg_detran,
				this.formatDate(item.created_at)
			]);

			// Adicione os dados
			XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A6' });

			// Adicione a planilha ao workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Itens Importados');

			// Crie um arquivo Excel blob
			const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Crie um link para download
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Itens Importados do arquivo: ${nomeArquivo}.xlsx`;
			a.click();
			window.URL.revokeObjectURL(url);

		},

		// Fim

		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					ignoreColumn: [6],
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					ignoreColumn: [6],
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},

		exportItensImportados(type) {
			axios.post('/sua-rota-api', {

				type: type,

			}).then((response) => {
				const contentType = response.headers['content-type'];

				if (contentType === 'application/pdf') {
					// É um PDF, crie um link de download (como mostrado anteriormente)
					const pdfBlob = new Blob([response.data], { type: contentType });
					const url = window.URL.createObjectURL(pdfBlob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'Itens-Importacao.pdf';
					a.click();
					window.URL.revokeObjectURL(url);
				} else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
					// É um XLSX, crie um link de download
					const xlsxBlob = new Blob([response.data], { type: contentType });
					const url = window.URL.createObjectURL(xlsxBlob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'Itens-Importacao.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
				}
			}).catch((error) => {
				console.error('Erro ao exportar:', error);
			});
		},
		carregarLogs(signal) {
			const dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
			const dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";
			const urlApi = dtInicial === '' || dtFinal === '' ? '/portal/logimportacao' : `/portal/logimportacao/${dtInicial}/${dtFinal}`;

			// Atualiza a legenda antes de fazer a solicitação
			this.atualizarLegenda();

			if ((signal == 'sig') || (dtInicial !== '' && dtFinal !== '')) {
				this.loading = true;
				axios.get(urlApi)
					.then((res) => {
						this.log_importacao = res.data;
						this.loading = false;
					})
					.catch((err) => {
						this.logs = [];
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		atualizarLegenda() {
			if (this.dataInicial && this.dataFinal) {
				this.periodoSelecionado = `${moment(this.dataInicial).format("DD/MM/YYYY")} - ${moment(this.dataFinal).format("DD/MM/YYYY")}`;
			} else {
				this.periodoSelecionado = 'Últimos 30 dias';
			}
		},
		limparFiltros() {
			let _this = this;
			_this.dataInicial = "";
			_this.dataFinal = "";
			_this.periodoSelecionado = 'Últimos 30 dias';

			this.carregarLogs('sig')
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat("pt-BR", {
				style: "currency",
				currency: "BRL",
			}).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
		},
		formatFilterDate(date) {
			let d = new Date(date);
			let dateString = moment(String(d)).format("YYYY-MM-DD");
			return dateString;
		},
	},
	components: {
		VueGoodTable,
		Datepicker,
		SweetModal,
		SweetModalTab,
	}
}
</script>
