<template>
  <div class="row">
    <div class="col">
      <h4>Auditoria</h4>
      <!-- table -->
      <div class="card p-4">
        <!-- Filtro -->
        <div>
          <h5>Filtrar Período Desejado: </h5>
        </div>
        <div class="row">
          <div class="row">
            <div class="col-md-6 p-4">
              <div class="form-group date-custom">
                <datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial"
                  class="btn-filter" />
              </div>
            </div>
            <div class="col-md-6 p-4">
              <div class="form-group date-custom">
                <datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final"
                  class="btn-filter" />
              </div>
            </div>
          </div>
          <!--tipo_operacao-->
          <div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 p-4">
            <a href="#" data-toggle="dropdown" class="combo btn btn-light dropdown-toggle">Tipo de Operação</a>
            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
              <li class="dropdown-title">Selecione a opção:</li>
              <li>
                <div class="form-check custom-checkbox custom-control ml-0 mt-1">
                  <input type="radio" id="tipo_operacao_1" class="form-check-input" value="Tipo 1 (Registro)"
                    v-model="tipo_operacao">
                  <label for="tipo_operacao_1" class="form-check-label">Tipo 1 (Registro)</label>
                </div>
              </li>
              <li>
                <div class="form-check custom-checkbox custom-control ml-0 mt-1">
                  <input type="radio" id="tipo_operacao_2" class="form-check-input" value="Tipo 2 (Aditivo)"
                    v-model="tipo_operacao">
                  <label for="tipo_operacao_2" class="form-check-label">Tipo 2 (Aditivo)</label>
                </div>
              </li>
            </ul>
          </div>
          <!--end filtro tipo_operacao-->
          <div class="col-md-6">
            <a href="#" class="btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button"
              style="height: 30px;" @click="resetPage(), carregarLogs()">
              Filtrar
            </a>
            <button class="btn btn-limpar btn-filter col-md-4 col-lg-2 col-sm-5 mt-4 ml-2 "
              style="border-radius: 10px; height: 30px;" data-toggle="tooltip" data-placement="top"
              title="Limpar Filtro" @click="limparFiltros">
              Limpar Filtro <i class="fas fa-trash fa-lg"></i>
            </button>
          </div>



        </div>
        <div class="mx-auto">
          <h5>Período: {{ periodoSelecionado }}</h5>
        </div>
        <div>
          <div class="export-options w-auto p-1">
            <a href="#" class="btn btn-danger btn-filter export-button" @click="exportLogImportacaoToPdf">
              <i class="far fa-file-pdf"></i> PDF
            </a>
            <a href="#" class="btn btn-success btn-filter export-button" @click="exportLogImportacaoToExcel">
              <i class="far fa-file-excel"></i> EXCEL
            </a>

          </div>

          <!-- Filtro -->
          <vue-good-table :columns="columns" :rows="logs" :line-numbers="true" @on-page-change="onPageChange"
            @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" mode="remote" @on-search="onSearch"
            :totalRows="totalRecords" :pagination-options="{
              enabled: true,
              mode: 'pages',
              position: 'bottom',
              dropdownAllowAll: false,
              perPageDropdown: [10, 30, 50],
              nextLabel: 'Próximo',
              prevLabel: 'Anterior',
              rowsPerPageLabel: '',
              ofLabel: 'de',
              allLabel: 'Todos',
              pageLabel: '',
              perPageDropdownEnabled: true,
              compactMode: true,
            }" :search-options="{
              enabled: true,
              placeholder: 'Pesquisar',
            }" :group-options="{
              enabled: true,
              collapsable: true,
            }" styleClass="vgt-table">

            <!-- Cabeçalho da linha pai (grupo) -->
            <template v-slot:table-header-row="props">


              <td v-if="props.column.field === 'actions'" :colspan="props.columns">
                <div class="text-center">
                  <button title="Visualizar detalhes" class="btn btn-sm btn-outline-secondary" @click="showInfo(
                    props.row.num_contrato,
                    props.row.event,
                    props.row.model_type,
                    props.row.properties
                  )">
                    <i class="fas fa-table"></i>
                  </button>
                </div>
              </td>
            </template>

            <!-- Linhas normais (filhos) -->
            <template v-slot:table-row="props">
              <span v-if="props.column.field === 'actions'">
                <div class="text-center">
                  <button title="Visualizar detalhes" class="btn btn-sm btn-outline-secondary" @click="showInfo(
                    props.row.num_contrato,
                    props.row.event,
                    props.row.model_type,
                    props.row.properties
                  )">
                    <i class="fas fa-table"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

        </div>
      </div>
      <!-- end table -->
      <div v-if="!logs.length && !loading" class="text-center p-3 text-muted">
        <h5>Nenhum log encontrado!</h5>
      </div>
      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>
    </div><!-- col -->

    <!--   modal cadastro de tipos     -->
    <div class="modal fade" id="modalDetailAudit" tabindex="-1" aria-labelledby="modalApiReturnsLabel"
      aria-hidden="true">
      <div class="modal-dialog custom-modal modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalApiReturnsLabel">{{ `Número do contrato: ${descricao} - Evento: ${event} -
              Modelo: ${modelo}` }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">

                <vue-good-table :columns="columns_data" :rows="detail" :line-numbers="true" :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  position: 'bottom',
                  dropdownAllowAll: true,
                  perPageDropdown: [10, 30, 50],
                  nextLabel: 'Próximo',
                  prevLabel: 'Anterior',
                  rowsPerPageLabel: '',
                  ofLabel: 'de',
                  allLabel: 'Todos',
                  pageLabel: '',
                  perPageDropdownEnabled: true,
                  compactMode: true,
                }" :search-options="{
                  enabled: true,
                  placeholder: 'Pesquisar',
                }" styleClass="vgt-table"></vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!--    modal cadastro de tipos   -->

    <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
  </div><!-- row -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

export default {
  data() {
    return {
      tipo_operacao: "",
      totalRecords: 0,
      serverParams: {
        page: 1,
        per_page: 10,
        dtInicial: "",
        dtFinal: "",
        search: "",
        tipo_operacao: "",
      },
      logs: [],
      atributos: {},
      dataInicial: "",
      dataFinal: "",
      periodoSelecionado: 'Últimos 30 dias',
      calendarLanguage: ptBR,
      loading: false,
      columns_data: [
        {
          label: "Campo",
          field: "campo"
        },
        {
          label: "Valor Antigo",
          field: "antigo"
        },
        {
          label: "Valor Novo",
          field: "novo"
        }
      ],

      columns: [
        {
          label: "ID",
          field: "id"
        },
        {
          label: "Instituição",
          field: "criado_por_user.banco.nome"
        },
        {
          label: "Criado por Usuário:",
          field: "criado_por_user.profile.name"
        },
        {
          label: "Atualizado por Usuário:",
          field: "atualizado_por_user.profile.name"
        },
        {
          label: "UF",
          field: "estado"
        },
        {
          label: "Número do Contrato",
          field: "num_contrato",
        },
        {
          label: "Evento",
          field: "event",
          formatFn: this.translateDescription,
        },
        {
          label: "Tipo de Operação",
          field: "tipo_operacao",
        },
        {
          label: "Data e Hora",
          field: "created_at",
          sortable: true,
          formatFn: this.formatDate,
        },
        {
          label: "Ação",
          field: "actions",
        }
      ],
      detail: [],
      descricao: "",
      event: "",
      modelo: "",
    }
  },
  mounted() {
    this.carregarLogs();
  },
  methods: {
    onSearch: _.debounce(function (params) {
      this.updateParams({ search: params.searchTerm });
      this.carregarLogs();
      return false;
    }, 500),
    resetPage() {
      this.serverParams.page = 1;
    },
    updateData() {
      let dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
      let dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";
      let tipo_operacao = this.tipo_operacao;
      this.updateParams({ dtInicial: dtInicial, dtFinal: dtFinal, tipo_operacao: tipo_operacao })
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.carregarLogs();
    },
    onPerPageChange(params) {
      this.updateParams({
        per_page: params.currentPerPage
      });
      this.carregarLogs();
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.carregarLogs();
    },

    carregarLogs() {

      this.updateData();
      const urlApi = '/portal/log/audit';

      // Atualiza a legenda antes de fazer a solicitação
      this.atualizarLegenda();

      this.loading = true;
      axios.get(urlApi, { params: this.serverParams })
        .then((res) => {
          this.logs = res.data.data;
          this.totalRecords = res.data.total;
        })
        .catch((err) => {
          this.logs = [];
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },
    atualizarLegenda() {
      if (this.dataInicial && this.dataFinal) {
        this.periodoSelecionado = `${moment(this.dataInicial).format("DD/MM/YYYY")} - ${moment(this.dataFinal).format("DD/MM/YYYY")}`;
      } else {
        this.periodoSelecionado = 'Últimos 30 dias';
      }
    },
    limparFiltros() {
      let _this = this;
      _this.tipo_operacao = "",
        _this.serverParams.page = 1,
        _this.serverParams.per_page = 10,
        _this.dataInicial = "";
      _this.dataFinal = "";
      _this.periodoSelecionado = 'Últimos 30 dias';

      this.carregarLogs('sig')
    },

    showInfo(descricao, event, subjectType, atributos) {
      this.descricao = descricao;
      this.event = this.translateDescription(event);
      this.modelo = subjectType;

      let arr = [];

      if (atributos.attributes) {
        for (const key in atributos.attributes) {
          const novo = atributos?.attributes?.[key] || "";
          const antigo = atributos?.old?.[key] || "";

          arr.push({
            campo: key,
            antigo: antigo,
            novo: novo
          })
        }
      } else if (atributos.old) {
        for (const key in atributos.old) {

          const novo = atributos?.attributes?.[key] || "";
          const antigo = atributos?.old?.[key] || "";
          arr.push({
            campo: key,
            antigo: antigo,
            novo: novo
          })
        }
      }

      this.detail = arr;

      this.atributos = atributos;
      $("#modalDetailAudit").modal('show');
    },
    translateDescription: (value) => {
      if (value === 'created') return 'Registro criado';
      if (value === 'updated') return 'Registro atualizado';
      if (value === 'deleted') return 'Registro removido';
      return value;
    },
    formatDate: function (value) {
      return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
    },
    getNomeCampos(properties) {
      let campos = "";
      for (const key in properties.attributes) {
        campos += key + "\n";
      }
      return campos;
    },
    getValues(properties, type) {
      // Verifica se as propriedades e o tipo existem
      if (!properties || !properties[type]) {
        return '-'; // Valor padrão
      }

      // Itera sobre as chaves para criar a string de valores
      return Object.entries(properties[type])
        .map(([key, value]) => {
          if (key === "arquivo") {
            return "arquivo alterado";
          }
          return value !== null ? value : "null"; // Trata valores nulos
        })
        .join("\n"); // Junta os valores com quebras de linha
    },
    // Inicio - Processo para Gerar PDF/EXCEL da Log de Importação

    exportLogImportacaoToPdf() {
      // Inicialize o PDF
      const doc = new jsPDF("landscape");

      const img = new Image();
      img.src = '/images/default-logo.png';

      // Cabeçalho
      const marginLeft = 12;
      doc.addImage(img, 'JPEG', marginLeft, 5, 30, 15);
      doc.setFontSize(12);
      doc.text('Logs Auditoria - Período: ' + this.periodoSelecionado, marginLeft + 100, 17);
      doc.setFontSize(8);
      const currentDate = moment().format('DD/MM/YYYY HH:mm');
      doc.text('Gerado em: ' + currentDate, marginLeft + 225, 17);

      // Colunas da tabela
      const columns = [
        'ID',
        'Instituição',
        'Criado por',
        'Atualizado por',
        'UF',
        'N° Contrato',
        'Evento',
        'Tipo de Operação',
        'Data e Hora',
        'Campo',
        'Valor Antigo',
        'Valor Novo',
      ];

      // Estilos para as colunas
      const columnStyles = {};
      for (let i = 0; i < columns.length; i++) {
        columnStyles[i] = { halign: 'center', fillColor: [231, 234, 236] };
      }

      // Dados da tabela
      const data = [];
      this.logs.forEach(item => {

        // Processar o pai
        const parentRow = [
          item.id || '-',
          item.criado_por_user?.banco?.nome || '-',
          item.criado_por_user?.profile?.name || '-',
          item.atualizado_por_user?.profile?.name || '-',
          item.estado || '-',
          item.num_contrato || '-',
          this.translateDescription(item.event) || '-',
          item.tipo_operacao || '-',
          this.formatDate(item.created_at),
          this.getNomeCampos(item.properties || {}),
          this.getValues(item.properties || {}, "old"),
          this.getValues(item.properties || {}, "attributes"),
        ];
        data.push(parentRow);

        // Processar os children
        item.children?.forEach(child => {
          const childRow = [
            child.id || '-', // ID do child
            item.criado_por_user?.banco?.nome || '-', // Instituição do item pai
            item.criado_por_user?.profile?.name || '-', // Criado por Usuário do item pai
            item.atualizado_por_user?.profile?.name || '-', // Atualizado por Usuário do item pai
            item.estado || '-', // UF do item pai
            child.num_contrato || '-', // N° Contrato do child
            this.translateDescription(child.event) || '-', // Evento do child
            child.tipo_operacao || '-', // Tipo de operação do child
            this.formatDate(child.created_at), // Data e Hora do child
            this.getNomeCampos(child.properties || {}), // Campo do child
            this.getValues(child.properties || {}, "old"), // Valor Antigo do child
            this.getValues(child.properties || {}, "attributes"), // Valor Novo do child
          ];
          data.push(childRow);
        });
      });

      // Gerar a tabela
      doc.autoTable({
        head: [columns],
        body: data,
        theme: 'grid',
        styles: { overflow: 'linebreak' },
        margin: { top: 20 },
        styles: { halign: 'center', fillColor: [236, 36, 52] }, // Estilização específica
        columnStyles: columnStyles,
      });

      // Rodapé com número de páginas
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.text('Página ' + i + ' de ' + totalPages, 10, doc.internal.pageSize.height - 10);
      }

      // Salvar o PDF
      doc.save(`Log-Auditoria-Periodo-${this.periodoSelecionado}.pdf`);
    },



    // Adiciona o cabeçalho com logo, título e data
    addHeader(doc, marginLeft) {
      const img = new Image();
      img.src = '/images/default-logo.png';

      doc.addImage(img, 'JPEG', marginLeft, 5, 30, 15);

      // Título
      doc.setFontSize(12);
      doc.text(`Logs Auditoria - Período: ${this.periodoSelecionado}`, marginLeft + 100, 17);

      // Data de geração
      const currentDate = moment().format('DD/MM/YYYY HH:mm');
      doc.setFontSize(8);
      doc.text(`Gerado em: ${currentDate}`, marginLeft + 225, 17);
    },

    // Retorna as colunas da tabela
    getTableColumns() {
      return [
        'ID',
        'Instituição',
        'Criado por Usuário',
        'Atualizado por Usuário',
        'UF',
        'N° Contrato',
        'Evento',
        'Tipo de Operação',
        'Data e Hora',
        'Campo',
        'Valor Antigo',
        'Valor Novo',
      ];
    },

    // Configura estilos das colunas
    getColumnStyles(columnCount) {
      const styles = {};
      for (let i = 0; i < columnCount; i++) {
        styles[i] = { halign: 'center', fillColor: [231, 234, 236] };
      }
      return styles;
    },

    // Prepara os dados da tabela
    getTableData() {
      return this.logs.map(item => [
        item.contrato_id || '-',
        item.criado_por_user?.banco?.nome || '-',
        item.criado_por_user?.profile?.name || '-',
        item.atualizado_por_user?.profile?.name || '-',
        item.estado || '-',
        item.num_contrato || '-',
        this.translateDescription(item.event) || '-',
        item.tipo_operacao || '-',
        this.formatDate(item.created_at),
        this.getNomeCampos(item.properties || {}),
        this.getValues(item.properties || {}, "old"),
        this.getValues(item.properties || {}, "attributes"),
      ]);
    },

    // Adiciona numeração de páginas no rodapé
    addPageNumbers(doc) {
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.text(`Página ${i} de ${totalPages}`, 10, doc.internal.pageSize.height - 10);
      }
    },



    exportLogImportacaoToExcel() {

      // Crie um novo workbook
      const workbook = XLSX.utils.book_new();

      // Crie uma nova planilha
      const worksheet = XLSX.utils.aoa_to_sheet([]);

      const currentDate = moment().format('DD/MM/YYYY HH:mm'); // Data Atual/Hora

      // Defina o título na célula A1
      XLSX.utils.sheet_add_aoa(worksheet, [[`Result1`]], { origin: 'A2' });

      XLSX.utils.sheet_add_aoa(worksheet, [['Gerado em: ' + currentDate]], { origin: 'D2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Logs Auditoria - Período:' + this.periodoSelecionado]], { origin: 'C2' });
      XLSX.utils.sheet_add_aoa(worksheet, [[``]], { origin: 'A4' });

      worksheet['!cols'] = [
        { wch: 10 }, // Largura da primeira coluna (coluna A)
        { wch: 15 }, // Largura da segunda coluna (coluna B)
        { wch: 35 }, // Largura da terceira coluna (coluna C)
        { wch: 35 }, // Largura da terceira coluna (coluna D)
        { wch: 10 }, // Largura da terceira coluna (coluna E)
        { wch: 20 }, // Largura da terceira coluna (coluna F)
        { wch: 20 }, // Largura da terceira coluna (coluna G)
        { wch: 20 }, // Largura da terceira coluna (coluna H)
        { wch: 20 }, // Largura da terceira coluna (coluna I)
        { wch: 25 }, // Largura da terceira coluna (coluna J)
        { wch: 25 }, // Largura da terceira coluna (coluna K)
        { wch: 25 }, // Largura da terceira coluna (coluna L)
        // ... adicione mais objetos para mais colunas
      ];

      // Defina as colunas e dados da planilha
      const columns = [
        'ID',
        'Instituição',
        'Criado por',
        'Atualizado por',
        'UF',
        'N° Contrato',
        'Evento',
        'Tipo de Operação',
        'Data e Hora',
        'Campo',
        'Valor Antigo',
        'Valor Novo'
      ];

      // Adicione as colunas na linha A2
      XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A5' });

      const data = [];
      this.logs.forEach(item => {

        // Processar o pai
        const parentRow = [
          item.id || '-',
          item.criado_por_user?.banco?.nome || '-',
          item.criado_por_user?.profile?.name || '-',
          item.atualizado_por_user?.profile?.name || '-',
          item.estado || '-',
          item.num_contrato || '-',
          this.translateDescription(item.event) || '-',
          item.tipo_operacao || '-',
          this.formatDate(item.created_at),
          this.getNomeCampos(item.properties || {}),
          this.getValues(item.properties || {}, "old"),
          this.getValues(item.properties || {}, "attributes"),
        ];
        data.push(parentRow);

        // Processar os children
        item.children?.forEach(child => {
          const childRow = [
            child.id || '-', // ID do child
            item.criado_por_user?.banco?.nome || '-', // Instituição do item pai
            item.criado_por_user?.profile?.name || '-', // Criado por Usuário do item pai
            item.atualizado_por_user?.profile?.name || '-', // Atualizado por Usuário do item pai
            item.estado || '-', // UF do item pai
            child.num_contrato || '-', // N° Contrato do child
            this.translateDescription(child.event) || '-', // Evento do child
            child.tipo_operacao || '-', // Tipo de operação do child
            this.formatDate(child.created_at), // Data e Hora do child
            this.getNomeCampos(child.properties || {}), // Campo do child
            this.getValues(child.properties || {}, "old"), // Valor Antigo do child
            this.getValues(child.properties || {}, "attributes"), // Valor Novo do child
          ];
          data.push(childRow);
        });
      });

      // Adicione os dados
      XLSX.utils.sheet_add_aoa(worksheet, data, { origin: 'A6' });

      // Adicione a planilha ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Log Importação');

      // Crie um arquivo Excel blob
      const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Crie um link para download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Log-Auditoria - Período: ` + this.periodoSelecionado + `.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);

    },
    // Fim
  },
  components: {
    VueGoodTable,
    SweetModal,
    SweetModalTab,
    Datepicker,
  }
}
</script>
<style>
.combo {
  border-radius: 30px;
  height: 30px;
}

.group-header {
  background-color: #f1f1f1;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}
</style>