<template>
  <div class="row">
    <div class="col-12">
      <!-- dashboard start -->
      <div class="row">
        <!-- contratos -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-primary">
              <i class="fas fa-copy"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Contratos</h4>
              </div>
              <div class="card-body" v-if="contratosCount == 0 && loading">
                <div class="spinner-border spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>{{ contratosCount }}</div>
            </div>
          </div>
        </div>
        <!-- end contratos -->

        <!-- valor detran -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-warning">
              <i class="fas fa-road"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Valor Detran</h4>
              </div>
              <div class="card-body" v-if="totalValorDetran == 0 && loading">
                <div class="spinner-border spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>
                {{ totalValorDetran }}
              </div>
            </div>
          </div>
        </div>
        <!-- end valor detran -->

        <!-- valor result1 -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-danger">
              <i class="fas fa-dollar-sign"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Valor Result1</h4>
              </div>
              <div class="card-body" v-if="totalValorResult == 0 && loading">
                <div class="spinner-border spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>
                {{ totalValorResult }}
              </div>
            </div>
          </div>
        </div>
        <!-- valor result1 -->
      </div>
      <!-- dashboard end -->

      <!-- filter start -->
      <div class="card col-12">
        <div class="card-header">
          <!-- estado -->
          <div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
            <a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Detrans</a>
            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
              <li class="dropdown-title">Selecione os Detrans</li>
              <li v-if="!estadoOptions.length">
                <div class="text-center">
                  <div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="option in estadoOptions" :key="option.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input class="form-check-input" type="checkbox" :value="option.id" v-model="checkedDetrans"
                    :id="option.id" />
                  <label class="form-check-label" :for="option.id">
                    {{ option.label }}
                  </label>
                </div>
              </li>
              <div class="text-center mt-2">
                <a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
                  data-placement="top" title="Filtrar" id="inicial" @click="loadItems">
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>
          <!-- end estado -->

          <!--init filter instituicao-->
          <div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
            <a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Instituições</a>
            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
              <li class="dropdown-title">Selecione as Instituições</li>
              <li v-if="!bancoOptions.length">
                <div class="text-center">
                  <div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="option in bancoOptions" :key="option.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input class="form-check-input" type="checkbox" :value="option.id" v-model="checkedInstituicoes"
                    :id="option.id" />
                  <label class="form-check-label" :for="option.id">
                    {{ option.label }}
                  </label>
                </div>
              </li>
              <div class="text-center mt-2">
                <a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
                  data-placement="top" title="Filtrar" id="inicial" @click="loadItems">
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>
          <!--end filter instituicao-->

          <!-- status -->
          <div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
            <a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Status do Faturamento</a>
            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
              <li class="dropdown-title">Selecione o Status do Faturamento</li>
              <li v-if="!filterStatus.length">
                <div class="text-center">
                  <div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in filterStatus" :key="item.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedStatusPagamentosDetran"
                    :id="item.id" />
                  <label class="form-check-label" :for="item.id">
                    {{
                      item.desc ? item.desc : "Erro"
                    }}
                  </label>
                </div>
              </li>
              <div class="text-center">
                <a href="#" class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter" data-toggle="tooltip"
                  data-placement="top" title="Filtrar" id="inicial" @click="loadItems">
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>
          <!-- end status -->

          <!-- filtro período -->
          <div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
              <datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial"
                class="btn-filter" />
            </div>
          </div>
          <div class="col-md-2 col-lg-2 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
              <datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final" class="btn-filter" />
            </div>
          </div>
          <!-- end filtro período -->

          <!-- filter button -->
          <div class="col-md-2 col-lg-2 col-sm-12 text-center">
            <a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
              data-placement="top" title="Filtrar" id="inicial" @click="loadItems">
              <i class="fas fa-filter"></i>
            </a>
            <a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
              data-placement="top" title="Limpar Filtro" @click="clearFilter">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <!-- end filter button -->
        </div>
      </div>
      <!-- filter end -->


      <!-- LIST CONTRACTS -->
      <div class="card">
        <div class="card-body">

          <!-- table -->
          <div class="pb-4" v-if="!loading && contratos.length > 0">
            <div class="export-options">
              <a href="#" class="btn btn-danger btn-filter export-button" @click="exportDataGrid('pdf', 'Faturamento')">
                <i class="far fa-file-pdf"></i> PDF
              </a>
              <a href="#" class="btn btn-success btn-filter export-button" @click="exportDataGrid('xlsx', 'Faturamento')">
                <i class="far fa-file-excel"></i> EXCEL
              </a>
            </div>
            <vue-good-table mode="remote" :columns="columns" :rows="contratos" :globalSearch="true"
              :loading.sync="loading" :search-options="{
                enabled: true,
                skipDiacritics: true,
                placeholder: 'Digite para buscar',
              }" :sort-options="{ enabled: true, }" @on-search="onSearch" @on-sort-change="onSortChange"
              styleClass="vgt-table">

              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === '#'">
                  {{ (contratosPagination.per_page * (contratosPagination.current_page - 1)) + (props.index +
                    1) }}
                </span>
              </template>
            </vue-good-table>

            <div class="d-flex justify-content-between">
              <div class="pt-2 pr-2 pl-0">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <label for="perPage" class="input-group-text">Linhas</label>
                  </div>
                  <select class="custom-select" @change="perPageChange" v-model="selectedPerPage">
                    <option v-for="PerPagesValue in PerPagesValues" v-bind:value="PerPagesValue.value"
                      :selected="selectedPerPage === contratosPagination.per_page">
                      {{ PerPagesValue.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="pt-2 pr-0">
                <vue-pagination :pagination="contratosPagination" @paginate="selectPage()" :offset="4">
                </vue-pagination>
              </div>
            </div>
          </div>
          <!-- end table -->

          <div v-if="!contratos.length && !loading" class="text-center p-3 text-muted">
            <h5>Nenhum contrato encontrado!</h5>
          </div>

          <div class="text-center p-4 text-muted" v-if="loading">
            <h5>Carregando...</h5>
            <p>Aguarde até que os dados sejam carregados...</p>
          </div>

        </div>
      </div>
      <!-- END LIST CONTRACTS -->
      <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div>
  </div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import VuePagination from './Paginator.vue'
import _ from 'lodash';

export default {
  data() {
    return {
      selectedPerPage: 50,
      PerPagesValues: [
        { value: "10", name: '10' },
        { value: "20", name: '20' },
        { value: "30", name: '30' },
        { value: "50", name: '50' },
      ],
      contratosPagination: {
        searchTerm: '',
        total: 0,
        per_page: 50,
        from: 1,
        to: 0,
        current_page: 1,
      },
      contratosCount: 0,
      contratos: [],
      estadoOptions: [],
      bancoOptions: [],
      bancos: [],
      totalValorDetran: 0,
      totalValorResult: 0,
      filterStatus: [{
        desc: 'Cancelado',
        id: 4
      }, {
        desc: 'Cancelamento Solicitado',
        id: 3
      }, {
        desc: 'Conciliado',
        id: 1
      }, {
        desc: 'Faturado',
        id: 2
      }, {
        desc: 'Previsao',
        id: 0
      }, {
        desc: 'Pago',
        id: 6
      }, {
        desc: 'Pendente',
        id: 5
      }],
      checkedDetrans: [],
      checkedInstituicoes: [],
      checkedBancos: [],
      checkedStatusPagamentosDetran: [],
      checkedStatusPagamentosResult1: [],
      dataInicial: "",
      dataFinal: "",
      loading: false,
      calendarLanguage: ptBR,
      columns: [
				{
					label: 'Contrato Id',
          field: 'id',
          sortable: false
				},
        {
          label: 'DETRAN',
          field: 'estado',
          sortable: false
        },
        {
          label: 'Instituição',
          field: 'nome_banco',
          sortable: false
        },
        {
          label: 'Número Gravame',
          field: 'num_gravame',
          sortable: false
        },
        {
          label: 'Tipo Contrato',
          field: 'tipo_contrato',
          sortable: false
        },
        {
          label: 'Custo Registro',
          field: 'custo_contrato',
          sortable: false
        },
        {
          label: 'Valor Registro',
          field: 'valor_contrato',
          sortable: false
        },
        {
          label: 'Status Instituição',
          field: 'faturamento_banco',
          sortable: false
        },
        {
          label: 'Data Criação',
          field: 'created_at',
          sortable: false,
          formatFn: this.formatDate
        },
        {
          label: 'Registrado no detran',
          field: 'dt_sucesso_reg_detran',
          sortable: false,
          formatFn: this.formatDate
        },
      ],
      tablePagination: {
        enabled: true,
        mode: 'records',
        position: 'bottom',
        dropdownAllowAll: true,
        perPageDropdown: [10, 30, 50],
        nextLabel: 'Próximo',
        prevLabel: 'Anterior',
        rowsPerPageLabel: '',
        ofLabel: 'de',
        allLabel: 'Todos',
        pageLabel: '',
        perPageDropdownEnabled: true,
        compactMode: true
      }
    };
  },
  mounted() {
    this.loadItems();
    this.loadStates();
    this.loadInstituicao();
    this.loadTotalContratos();
    this.loadValores();
  },
  methods: {
    onSearch: _.debounce(function (params) {
      this.updateParams(params);
      this.loadItems();
      return false;
    }, 500),
    onSortChange(params) {
      this.sortField = `${params[0].type === 'desc' ? '-' : ""}${params[0].field}`
      this.loadItems();
    },
    perPageChange(event) {
      event.preventDefault();
      this.contratosPagination.per_page = event.target.value;
      this.loadItems();
    },
    updateParams(newProps) {
      this.contratosPagination = Object.assign({}, this.contratosPagination, newProps);
    },
    selectPage() {
      this.loadItems();
    },
    loadTotalContratos(){

        axios.get(`/api/admin/faturamentodetranTotalContratos`).then(res => {
            this.contratosCount = res.data

        }).catch((error) => {
            console.error('erro');

        })
    },
    loadValores(){
        axios.get(`/api/admin/faturamentodetranTotalValores`).then(res => {
            this.totalValorDetran = res.data.valorDetran
            this.totalValorResult = res.data.valorInstituicao

        }).catch((error) => {
            console.error('erro');

        })
    },
    loadItems() {
        let _this = this;
        _this.loading = true;

        let page;
        if (_this.navigate_validation === 1) {
            page = _this.pageDefault;
            _this.navigate_validation = null;
        } else {
            page = _this.contratosPagination.current_page;
        }

		_this.contratos = []

        axios.post(`/api/admin/faturamentodetran?page=${page}&per_page=${this.contratosPagination.per_page}&sort=${this.sortField}&searchTerm=${this.contratosPagination.searchTerm}`, {
            search: {
                detrans: _this.checkedDetrans,
                status_pagamento_detran: _this.checkedStatusPagamentosDetran,
                data_inicial: _this.dataInicial != "" ? _this.formatFilterDate(_this.dataInicial) : null,
                data_final: _this.dataFinal != "" ? _this.formatFilterDate(_this.dataFinal) : null,
                instituicao: _this.checkedInstituicoes
            }
        }).then((res) => {
            if (this.contratosPagination.searchTerm && res.data.data.length === 0) {
                this.contratosPagination.searchTerm = ''
                this.loadItems()
            } else {
                _this.contratosPagination = res.data
                _this.contratos = _this.contratos.concat(res.data.data);
                _this.loading = false;
            }
        }).catch((err) => {
            _this.loading = false;
        });
    },
    loadStates() {
      axios.get("/api/portal/getAllDetrans").then(res => {
        this.detrans = res.data.detrans;
        this.estadoOptions = this.detrans.map(element => ({
          label: element.estado,
          id: element.id,
          selected: false
        }));
        this.states = this.sortVector(this.estadoOptions.map(state => state.label));
      }).catch((err) => {
        console.error(err);
      });
    },
    loadInstituicao() {
      axios.get("/api/portal/getAllBancos").then(res => {
        this.bancos = res.data.bancos;
        this.bancoOptions = this.bancos.map(element => ({
          label: element.nome,
          id: element.id,
          selected: false
        }));
        this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
      }).catch((err) => {
        console.error(err);
      });
    },
    sortVector(vector) {
      if (vector.length) {
        return vector.sort(function (a, b) {
          return a.localeCompare(b);
        });
      }
      return vector;
    },
    updateItems() {
      let _this = this;
      _this.loading = true;
      _this.totalValorDetran = 0;
      _this.totalValorResult = 0;
      var filters = {
        search: {
          detrans: _this.checkedDetrans,
          status_pagamento_detran: _this.checkedStatusPagamentosDetran,
          status_pagamento_result1: _this.checkedStatusPagamentosResult1,
          data_inicial: _this.dataInicial != "" ? _this.formatFilterDate(_this.dataInicial) : null,
          data_final: _this.dataFinal != "" ? _this.formatFilterDate(_this.dataFinal) : null,
          instituicao: _this.checkedInstituicoes
        }
      };
      axios.post("/api/admin/faturamentodetran", { search: filters.search })
        .then((res) => {
          _this.contratos = [];
          _this.contratos = _this.contratos.concat(res.data);
          _this.loading = false;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },

    clearFilter() {
      let _this = this;
      _this.checkedDetrans = [];
      _this.checkedStatusPagamentosDetran = [],
        _this.checkedStatusPagamentosResult1 = [],
        _this.dataInicial = "";
      _this.dataFinal = "";
      _this.totalValorDetran = 0;
      _this.totalValorResult = 0;
      _this.loadItems();
    },

    formatCurrency: function (value) {
      return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },

    formatDate: function (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
    },

    formatFilterDate(date) {
      let d = new Date(date);
      let dateString = moment(String(d)).format('YYYY-MM-DD');
      return dateString;
    },

    exportDataGrid(type, tableName) {
      let params = type == 'pdf' ?
        {
          type: 'pdf',
          fileName: tableName,
          htmlContent: false,
          onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
          jspdf: {
            orientation: 'l',
            margins: { right: 10, left: 10, top: 40, bottom: 40 },
            autotable: {
              styles: {
                fillColor: 'inherit',
                textColor: 'inherit',
                halign: 'center',
                fillStyle: 'DF',
                lineColor: 200,
                lineWidth: 0.1
              },
              headerStyles: { halign: 'left' },
              tableWidth: 'auto'
            }
          }
        } :
        {
          type: 'excel',
          htmlContent: false,
          fileName: tableName,
          mso: { fileFormat: 'xlsx' }
        };

      let options = { tableName: tableName };

      jQuery.extend(true, options, params);

      $('#vgt-table').tableExport(options);
    },
  },
  components: {
    VueGoodTable,
    Datepicker,
    VuePagination
  },
};
</script>
