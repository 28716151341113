<template>
	<div class="card p-4">
		<div class="row">
			<div class="col">

				<!-- table -->
				<div v-if="!loading && bancos.length > 0">
					<vue-good-table :columns="columns" :rows="bancos" :pagination-options="{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,

					}" :search-options="{
	enabled: true,
	placeholder: 'Search this table',
}" styleClass="vgt-table">
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_status'">
								<span class="alert-table alert alert-success" v-if="props.row.status == 1">Ativo</span>
								<span class="alert-table alert alert-danger" v-if="props.row.status == 0">Inativo</span>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i
											class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)"><i
											class="far fa-trash-alt"></i></button>
									<button class="btn btn-sm btn-outline-danger" @click="disableData(props.row.id, props.row.index)"
										v-if="props.row.status == 1"><i class="fas fa-ban"></i></button>
									<button class="btn btn-sm btn-outline-success" @click="enableData(props.row.id, props.row.index)"
										v-if="props.row.status == 0"><i class="fas fa-check"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>
				<div v-if="!bancos.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhuma instituição financeira encontrada!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
			</div><!-- col -->
		</div><!-- row -->
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- card -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
	data() {
		return {
			bancos: [],
			loading: false,
			columns: [
				{
					label: "Nome",
					field: "nome",
				},
				{
					label: "Nome Fantasia",
					field: "nome_fantasia",
				},
				{
					label: "Criado Em",
					field: "created_at",
					formatFn: this.formatDate,
				},
				{
					label: "Status",
					field: "_status"
				},
				{
					label: "Ações",
					field: "actions",
					html: true,
				},
			],
		}
	},
	mounted() {
		this.loadItems();
	},
	methods: {
		loadItems() {
			let _this = this;
			_this.loading = true;
			axios.get("/admin/bancos").then((res) => {
				_this.bancos = _this.bancos.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		deleteData(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Excluir instituição financeira",
				text: "Tem certeza que deseja excluir essa instituição financeira?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Excluir",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete("/admin/bancos/" + ID).then((res) => {
						_this.bancos = [];

						_this.loadItems();

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Instituição Financeira excluída com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao excluir a instituição financeira. Tente novamente!",
							icon: "error"
						});

					});

				} else {

					//only close

				}//end validate isConfirmed

			});
			//end alert confirmation

		},
		disableData(ID, index) {
			let _this = this;

			_this.$swal({
				title: "Desativar Instituição Financeira",
				text: "Tem certeza que deseja desativar essa Instituição?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Desativar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.get("/admin/bancos/" + ID + "/disable").then((res) => {

						_this.bancos.map((row) => {
							if (row.id == ID) {
								row.status = 0;
							}
						});

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Instituição desativada com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao desativar a instituição. Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		editData(ID) {
			location.href = "/admin/bancos/" + ID + "/edit";
		},
		enableData(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Ativar Instituição Financeira",
				text: "Tem certeza que deseja ativar essa Instituição?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Ativar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.get("/admin/bancos/" + ID + "/enable").then((res) => {

						_this.bancos.map((row) => {
							if (row.id == ID) {
								row.status = 1;
							}
						});

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Instituição ativada com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao ativar a instituição. Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY");
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
		},
	},
	components: {
		VueGoodTable,
	}
}
</script>
