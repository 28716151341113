<template>
	<div class="tab-pane fade" id="tab-api-retorno" role="tabpanel" aria-labelledby="api-retorno-tab">
		<div class="card mt-4">

			<!-- card header -->
			<div class="card-header pad0">
				Retornos da API
				<button class="btn btn-primary btn-right" data-toggle="modal" data-target="#modalApiReturns">NOVO</button>
			</div>
			<!-- end card header -->

			<!-- card body -->
			<div class="card-body pad0">

				<!-- table -->
				<div v-if="!loading && detranretornoapis.length > 0">
					<vue-good-table :columns="columns" :rows="detranretornoapis" :pagination-options="{
						enabled: true,
						mode: 'pages',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						perPageDropdownEnabled: false,
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,
					}">
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'contrato_status'">
								{{ props.row.contrato_status[0].nome }}
							</span>
							<span v-if="props.column.field == 'actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editApiReturn(props.row)"><i
											class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger"
										@click="deleteData(props.row.id, props.row.index)"><i
											class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-if="props.column.field != 'contrato_status'">
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>

				<div v-if="!detranretornoapis.length" class="text-center p-3 text-muted">
					<h5>Nenhum retorno de encontrado!</h5>
				</div>
				<!-- end table -->

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

		</div>

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

		<!-- modal  -->
		<div class="modal fade" id="modalApiReturns" tabindex="-1" aria-labelledby="modalApiReturnsLabel"
			aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalApiReturnsLabel">{{ labelTitle }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST" v-on:submit.prevent="saveApiReturn">

							<div class="row">

								<!-- status_api -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-status_api">Status</label>
										<input type="number" id="input-status_api"
											v-bind:class="{ 'is-invalid': errors.status_api }" class="form-control"
											placeholder="Status" v-model="apireturn.status_api" required="true">
										<div class="invalid-feedback" v-if="errors.status_api">
											<p>{{ errors.status_api[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end status_api -->

								<!-- codigo_retorno -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-codigo_retorno">Código do
											Retorno</label>
										<input type="text" id="input-codigo_retorno"
											v-bind:class="{ 'is-invalid': errors.codigo_retorno }" class="form-control"
											placeholder="Código do Retorno" v-model="apireturn.codigo_retorno"
											required="true">
										<div class="invalid-feedback" v-if="errors.codigo_retorno">
											<p>{{ errors.codigo_retorno[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end codigo_retorno -->

							</div>
							<div class="row">

								<!-- descricao_retorno -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-descricao_retorno">Descrição do
											Retorno</label>
										<input type="text" id="input-descricao_retorno"
											v-bind:class="{ 'is-invalid': errors.descricao_retorno }" class="form-control"
											placeholder="Descrição do Retorno" v-model="apireturn.descricao_retorno"
											required="true">
										<div class="invalid-feedback" v-if="errors.descricao_retorno">
											<p>{{ errors.descricao_retorno[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end descricao_retorno -->
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-contrato_status">Selecione o contrato
											status que deseja vincular</label>
										<select type="text" id="input-contrato_status"
											v-bind:class="{ 'is-invalid': errors.contrato_status }" class="form-control"
											v-model="contrato_status" required="true">
											<option v-for="item in contratostatus" :value="item.id">{{ item.nome }}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.contrato_status">
											<p>{{ errors.contrato_status[0] }}</p>
										</div>
									</div><!-- form group -->
								</div>
							</div>

							<div class="text-right">
								<button type="submit" class="btn btn-primary">SALVAR</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- end modal -->

	</div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
	props: ['detrans'],
	data() {
		const detrans = JSON.parse(this.detrans)
		return {
			detranretornoapis: [],
			fullPage: true,
			isEditApiReturn: false,
			columns: [
				{
					label: "Status",
					field: "status_api",
				},
				{
					label: "Código",
					field: "codigo_retorno",
				},
				{
					label: "Descrição",
					field: "descricao_retorno",
				},
				{
					label: "Contrato Status",
					field: "contrato_status",
				},
				{
					label: "Ultima alteração",
					field: "updated_at",
					formatFn: this.formatDate,
				},
				{
					label: "Criado Em",
					field: "created_at",
					formatFn: this.formatDate,
				},
				{
					label: "Ações",
					field: "actions",
					html: true,
				}
			],
			errors: [],
			detranID: detrans.id,
			apireturn: {
				status_api: "",
				codigo_retorno: "",
				descricao_retorno: ""
			},
			apireturnID: '',
			labelTitle: 'Novo Retorno da API',
			contratostatus: [],
			contrato_status: '',
			status_retorno_id: '',
			loading: false,
			message: ''
		}
	},
	mounted() {
		this.loadItems();
		this.loadContratoStatus();
	},
	methods: {
		editApiReturn(item) {
			this.apireturn = item;
			this.isEditApiReturn = true;
			this.apireturnID = item.id;
			this.labelTitle = "Editar Retorno da API";

			$("#modalApiReturns").modal('show');
		},
		loadItems() {
			let _this = this;
			_this.detranretornoapis = [];
			_this.loading = true;
			axios.get("/admin/detranretornoapis/" + this.detranID).then((res) => {
				_this.detranretornoapis = _this.detranretornoapis.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		loadContratoStatus() {
			let _this = this;
			_this.contratostatus = [];
			axios.get("/admin/contratostatus/list").then((res) => {
				_this.contratostatus = _this.contratostatus.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
				console.error(err)
			});
		},
		deleteData(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Excluir Retorno da API",
				text: "Tem certeza que deseja excluir esse retorno da api?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Excluir",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete("/admin/detranretornoapis/" + ID).then((res) => {

						_this.detranretornoapis.splice(index, 1);
						//_this.loadItems();

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Retorno da API excluído com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao excluir o retorno da api. Tente novamente!",
							icon: "error"
						});

					});

				} else {

					//only close

				}//end validate isConfirmed

			});
			//end alert confirmation

		},
		resetForm() {
			this.apireturn = {
				status_api: '',
				codigo_retorno: '',
				descricao_retorno: ''
			};
		},
		saveApiReturn() {

			//validate if is edit
			if (this.isEditApiReturn == true) {

				//edit
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"status_api": _this.apireturn.status_api,
					"codigo_retorno": _this.apireturn.codigo_retorno,
					"descricao_retorno": _this.apireturn.descricao_retorno,
					"contrato_status": _this.contrato_status,
					"detran_id": this.detranID,
					"_method": "PATCH"
				};
				axios.post("/admin/detranretornoapis/" + this.apireturnID, dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();
					_this.loadItems();
					_this.isEditApiReturn = false;
					_this.apireturnID = "";
					$("#modalApiReturns").modal("hide");

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Retorno da API atualizado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar retorno da api. Tente novamente!",
						icon: "error"
					});
				});

			} else {

				//new
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"status_api": _this.apireturn.status_api,
					"codigo_retorno": _this.apireturn.codigo_retorno,
					"descricao_retorno": _this.apireturn.descricao_retorno,
					"detran_id": this.detranID,
					"contrato_status": _this.contrato_status,
					"last": ""
				};
				axios.post("/admin/detranretornoapis", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();
					_this.loadItems();
					$("#modalApiReturns").modal("hide");

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Retorno da Api cadastrado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar retorno da api. Tente novamente!",
						icon: "error"
					});
				});

			}
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat("pt-BR", {
				style: "currency",
				currency: "BRL",
			}).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY H:m:s");
		}
	}
}
</script>
