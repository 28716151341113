<template>
    <div class="access-denied">
      <div class="message">
        <h3>Você não tem permissão para acessar esta página.</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AccessDenied'
  }
  </script>
  
  <style scoped>
  .access-denied {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .message {
    text-align: center;
  }
  </style>
  