<template>
    <div class="row justify-content-center align-items-center">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h4>Preencha os dados abaixo</h4>
                    <a @click="newData" class="btn btn-sm btn-primary text-white">SALVAR</a>
                </div>
                <div class="card-body">
                    <h6 class="heading-small text-muted mb-4">Dados</h6>
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label class="form-control-label" for="input-valor_duda">R$ Duda</label>
                                <money v-bind:class="{ 'is-invalid': errors.valor_duda }" v-model="valor_duda"
                                    class="form-control" v-bind="valueMaskD3" maxlength="11"></money>
                                <div class="invalid-feedback" v-if="errors.valor_duda">
                                    <p>{{ errors.valor_duda[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label class="form-control-label" for="input-num_duda">Nº Duda</label>
                                <input type="text" id="input-num_duda" v-bind:class="{ 'is-invalid': errors.num_duda }"
                                    class="form-control" placeholder="Nº Duda" v-model="num_duda" required>
                                <div class="invalid-feedback" v-if="errors.num_duda">
                                    <p>{{ errors.num_duda[0] }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group date-custom">
                                <label class="form-control-label" for="input-data_emissao">Data Emissão</label>
                                <datepicker id="input-data_emissao" :language="calendarLanguage" v-model="dataEmissao"
                                    placeholder="Data Emissão" class="btn-filter" />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group date-custom">
                                <label class="form-control-label" for="input-data_vencimento">Data Vencimento</label>
                                <datepicker id="input-data_vencimento" :language="calendarLanguage"
                                    v-model="dataVencimento" placeholder="Data Vencimento" class="btn-filter" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
    </div>
</template>

<script>
import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
export default {
    data() {
        return {
            num_duda: "",
            valor_duda: "",
            dataEmissao: "",
            dataVencimento: "",
            calendarLanguage: ptBR,
            errors: [],
            message: "",
            loading: false,
            fullPage: true,
            valueMaskD3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                precision: 2,
                masked: false
            },
        }
    },
    mounted() {
    },
    methods: {
        newData() {
            let _this = this;
            _this.errors = [];
            _this.message = "";
            _this.loading = true;
            let dataInfo = {
                "num_duda": _this.num_duda,
                "valor_duda": _this.valor_duda,
                "data_emissao": moment(String(_this.dataEmissao)).format("YYYY-MM-DD"),
                "data_vencimento": moment(String(_this.dataVencimento)).format("YYYY-MM-DD")
            };

            axios.post("/admin/dudaRj", dataInfo).then((res) => {

                _this.loading = false;

                //show confirm alert
                _this.$swal({
                    title: "Sucesso",
                    text: "Duda cadastrada com sucesso!",
                    icon: "success"
                });
            }).then(() => {

                window.location.href = "/admin/dudaRj"

            }).catch((err) => {

                _this.loading = false;

                if (err.response.status == 422) {
                    this.errors = err.response.data.errors;
                }

                //show error alert
                _this.$swal({
                    title: "Erro",
                    text: "Falha ao cadastrar Duda. Tente novamente!",
                    icon: "error"
                });
            });
        },
    },
    components: {
        Datepicker,
    },
}
</script>
