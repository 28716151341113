<template>
	<ul class="pagination">
			<li class="page-item" v-if="pagination.current_page > 1">
					<button class="page-link" title="Anterior" @click="changePage(pagination.current_page - 1)">«</button>
			</li>

			<li class="page-item" v-for="page in pagesNumber" :key="page"
					:class="{ 'active': page === pagination.current_page }">
					<button class="page-link" @click="changePage(page)">{{ page }}</button>
			</li>

			<li class="page-item" v-if="pagination.current_page < pagination.last_page">
					<button class="page-link" title="Próximo" @click="changePage(pagination.current_page + 1)">»</button>
			</li>
	</ul>
</template>

<script>
export default {
	props: {
			pagination: {
					type: Object,
					required: true
			},
			offset: {
					type: Number,
					default: 4
			}
	},
	computed: {
			pagesNumber() {
					const from = Math.max(1, this.pagination.current_page - this.offset);
					const to = Math.min(this.pagination.last_page, this.pagination.current_page + this.offset * 2);
					const pagesArray = [];

					for (let page = from; page <= to; page++) {
							pagesArray.push(page);
					}

					return pagesArray;
			}
	},
	methods: {
			changePage(page) {
					this.$emit('paginate', page);
			}
	}
};
</script>

<style scoped>
.pagination {
	display: flex;
	justify-content: center;
	list-style-type: none;
	padding: 0;
}

.page-item {
	margin: 0 2px;
}

.page-link {
	padding: 8px 12px;
	background-color: #f8f9fa;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	cursor: pointer;
}

.page-link:hover {
	background-color: #e9ecef;
}
</style>
