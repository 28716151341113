<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>
					<a @click="newData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>

					<div class="">

						<div class="row">
							<!-- nome -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-nome">Nome da Instituição</label>
									<input type="text" id="input-nome" autofocus
										v-bind:class="{ 'is-invalid': errors.nome }" class="form-control"
										placeholder="Nome da Instituição" v-model="nome" required="true"
										maxlength="100">
									<div class="invalid-feedback" v-if="errors.nome">
										<p>{{ errors.nome[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome -->

							<!-- nome_fantasia -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-nome_fantasia">Nome fantasia</label>
									<input type="text" id="input-nome_fantasia" autofocus
										v-bind:class="{ 'is-invalid': errors.nome_fantasia }" class="form-control"
										placeholder="Nome fantasia" v-model="nome_fantasia" required="true"
										maxlength="25">
									<div class="invalid-feedback" v-if="errors.nome_fantasia">
										<p>{{ errors.nome_fantasia[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome_fantasia -->

							<!-- tipo_instituicao -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-tipo-instituicao">Tipo de
										Instituição</label>
									<select id="input-tipo-instituicao"
										v-bind:class="{ 'is-invalid': errors.tipo_instituicao }"
										v-model="tipo_instituicao" class="form-control"
										placeholder="Tipo de Instituição">
										<option value="1">1 - Banco</option>
										<option value="2">2 - Consórcio</option>
										<option value="3">3 - ESC (Empresa simples de crédito)</option>
										<option value="4">4 - Cooperativa de crédito</option>
										<option value="5">5 - Fintech</option>
									</select>
									<div class="invalid-feedback" v-if="errors.tipo_instituicao">
										<p>{{ errors.tipo_instituicao[0] }}</p>
									</div>
								</div>
							</div><!-- col-md-3 -->
							<!-- end tipo_instituicao -->

							<!-- cnpj -->
							<div class="col-2">
								<div class="form-group">
									<label class="form-control-label">CNPJ</label>
									<input type="tel" id="cnpj" v-bind:class="{ 'is-invalid': errors.cnpj }"
										class="form-control" placeholder="CNPJ" v-model="cnpj" maxlength="18"
										v-mask="'##.###.###/####-##'">
									<div class="invalid-feedback" v-if="errors.cnpj">
										<p>{{ errors.cnpj[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-4 -->
							<!-- end cnpj -->

							<!-- ddd_instituição-->
							<div class="col-md-1">
								<div class="form-group">
									<label class="form-control-label" for="input-ddd">DDD</label>
									<input type="tel" id="input-ddd" v-bind:class="{ 'is-invalid': errors.ddd }"
										class="form-control" placeholder="DDD" v-model="ddd" required="true"
										maxlength="2">
									<div class="invalid-feedback" v-if="errors.ddd">
										<p>{{ errors.ddd[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end ddd_instituição -->

							<!-- telefone_instituição -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-telefone">Telefone</label>
									<input type="text" id="input-telefone"
										v-bind:class="{ 'is-invalid': errors.telefone }" class="form-control"
										placeholder="Telefone" v-model="telefone_instituicao" required="true"
										maxlength="10" v-mask="'#####-####'">
									<div class="invalid-feedback" v-if="errors.telefone">
										<p>{{ errors.telefone_instituicao[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end telefone_instituição -->

							<!-- cep_agente -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-cep">CEP</label>
									<input type="text" id="input-cep" v-bind:class="{ 'is-invalid': errors.cep }"
										class="form-control" placeholder="CEP" v-model="cep" v-mask="'#####-###'"
										required="true" maxlength="9" @blur="verificarCEP('banco')">
									<div class="invalid-feedback" v-if="errors.cep">
										<p>{{ errors.cep[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end cep_agente -->

							<!-- uf_agente -->
							<div class="col-md-1">
								<div class="form-group">
									<label class="form-control-label" for="input-estado">UF</label>
									<select id="input-estado" v-bind:class="{ 'is-invalid': errors.estado }"
										class="form-control" placeholder="UF" v-model="estado" required="true"
										@change="onChangeUf('agente')" :disabled="ufBloqueado">
										<option v-for="item in states">{{ item }}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.estado">
										<p>{{ errors.estado[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end uf_agente -->

							<!-- nome_municipio_agente -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="form-control-label" for="input-cidade">Município</label>
									<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
	o municipio  -->
									<template v-if="!cepValido && !municipio_ag">
										<!-- Digitar manualmente -->
										<input type="text" id="input_municipio_ag_manual"
											v-bind:class="{ 'is-invalid': errors.municipio_ag_manual }"
											class="form-control" placeholder="Insira a cidade Manualmente"
											v-model="municipio_ag_manual" required="true" maxlength="60">
									</template>
									<!-- fim -->
									<template v-else>
										<!-- Vai ser preenchido manualmente pela API -->
										<v-select id="mySelect" label="municipio" @input="setSelected"
											:options="municipios_ag" :reduce="municipio => municipio.codigo_origem"
											v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
											v-model="municipio_ag" :disabled="municipioBloqueado"></v-select>
									</template>
									<!-- Fim -->
									<div class="invalid-feedback" v-if="errors.cidade">
										<p>{{ errors.cidade[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end nome_municipio_agente -->

							<!-- nome_logradouro_agente -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-endereco">Endereço</label>
									<input type="text" id="input-endereco"
										v-bind:class="{ 'is-invalid': errors.endereco }" class="form-control"
										placeholder="Endereço" v-model="endereco" required="true" maxlength="30">
									<div class="invalid-feedback" v-if="errors.endereco">
										<p>{{ errors.endereco[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end nome_logradouro_agente -->

							<!-- num_imovel_agente -->
							<div class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-numero">Nº</label>
									<input type="text" id="input-numero" v-bind:class="{ 'is-invalid': errors.numero }"
										class="form-control" placeholder="Nº" v-model="numero" required="true"
										maxlength="5">
									<div class="invalid-feedback" v-if="errors.numero">
										<p>{{ errors.numero[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end num_imovel_agente -->

							<!-- bairro_agente -->
							<div class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-bairro">Bairro</label>
									<input type="text" id="input-bairro" v-bind:class="{ 'is-invalid': errors.bairro }"
										class="form-control" placeholder="Bairro" v-model="bairro" required="true"
										maxlength="100">
									<div class="invalid-feedback" v-if="errors.bairro">
										<p>{{ errors.bairro[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-3 -->
							<!-- end bairro_agente -->

							<!-- complemento_imovel_agente -->
							<div class="col-md-3">
								<div class="form-group">
									<label class="form-control-label" for="input-complemento">Complemento</label>
									<input type="text" id="input-complemento"
										v-bind:class="{ 'is-invalid': errors.complemento }" class="form-control"
										placeholder="Complemento" v-model="complemento" required="true" maxlength="20">
									<div class="invalid-feedback" v-if="errors.complemento">
										<p>{{ errors.complemento[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-4 -->
							<!-- end complemento_imovel_agente -->

							<!-- codigo SERPRO-->
							<div class="col-md-2">
								<div class="form-group">
									<!-- <div class="form-group" v-if="!cepValido"></div> -->
									<label class="form-control-label" for="input_cod_serpro">Cód
										SERPRO</label>
									<input type="text" id="input_cod_serpro"
										v-bind:class="{ 'is-invalid': errors.cod_serpro }" class="form-control"
										placeholder="Código" v-model="cod_serpro" required="true" maxlength="4"
										:disabled="serproBloqueado">
									<div class="invalid-feedback" v-if="errors.cep_devedor">
										<p>{{ errors.cod_serpro }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-2 -->
							<!-- end codigo SERPRO -->

							<!-- token_id -->
							<div v-show="false" class="col-md-2">
								<div class="form-group">
									<label class="form-control-label" for="input-token_id">Token ID</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-token_id"
											v-bind:class="{ 'is-invalid': errors.token_id }" class="form-control"
											placeholder="Token ID" v-model="token_id" required="true" disabled>
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button"
												@click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
									</div>

									<div class="invalid-feedback" v-if="errors.token_id">
										<p>{{ errors.token_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_id -->

							<!-- token_api -->
							<div v-show="false" class="col-md-5">
								<div class="form-group">
									<label class="form-control-label" for="input-token_api">Token da
										API</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-token_api"
											v-bind:class="{ 'is-invalid': errors.token_api }" class="form-control"
											placeholder="Token da API" v-model="token_api" required="true" disabled>
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button"
												@click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
									</div>
									<div class="invalid-feedback" v-if="errors.token_api">
										<p>{{ errors.token_api[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end token_api -->

						</div>
						<div class="row">


						</div>
						<div class="row">





						</div><!-- row -->
					</div>

					<h6 class="heading-small text-muted mb-4">Acesso</h6>
					<div class="">
						<div class="row">

							<!-- nome  Usuario-->
							<div class="col-12">
								<div class="form-group">
									<label class="form-control-label">Nome do Usuário</label>
									<input type="text" id="nome_usuario"
										v-bind:class="{ 'is-invalid': errors.nome_usuario }" class="form-control"
										placeholder="Nome do usuário" v-model="nome_usuario">
									<div class="invalid-feedback" v-if="errors.nome_usuario">
										<p>{{ errors.nome_usuario[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-6 -->
							<!-- nome  Usuario-->

							<!-- email -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-email">E-mail</label>
									<input type="email" id="input-email" v-bind:class="{ 'is-invalid': errors.email }"
										class="form-control" placeholder="E-mail" v-model="email" required="true">
									<div class="invalid-feedback" v-if="errors.email">
										<p>{{ errors.email[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end email -->

							<!-- password -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-password">Senha</label>
									<div class="input-group mb-3">
										<input :type="inputType" id="input-password"
											v-bind:class="{ 'is-invalid': errors.password }" class="form-control"
											placeholder="Senha" v-model="password" required="true">
										<div class="input-group-prepend">
											<button class="btn btn-outline-secondary" type="button"
												@click="changeInputView()"><i :class="inputIcon"></i></button>
										</div>
										<div class="invalid-feedback" v-if="errors.password">
											<p>{{ errors.email[0] }}</p>
										</div>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end password -->

							<!-- cpf -->
							<div class="col-6">
								<div class="form-group">
									<label class="form-control-label">CPF</label>
									<input type="text" id="cpf" v-bind:class="{ 'is-invalid': errors.cpf }"
										class="form-control" placeholder="CPF" v-model="cpf" maxlength="14"
										v-mask="'###.###.###-##'">
									<div class="invalid-feedback" v-if="errors.cpf">
										<p>{{ errors.cpf[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-12 -->
							<!-- end cpf -->

							<!-- função -->
							<div class="col-6">
								<div class="form-group">
									<label class="form-control-label">Função</label>
									<input type="text" id="funcao" v-bind:class="{ 'is-invalid': errors.funcao }"
										class="form-control" placeholder="Função" v-model="funcao" maxlength="50">
									<div class="invalid-feedback" v-if="errors.funcao">
										<p>{{ errors.funcao[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-12 -->
							<!-- end função -->

							<!-- ddd -->
							<div class="col-3">
								<div class="form-group">
									<label class="form-control-label">DDD</label>
									<input type="text" id="ddd" v-bind:class="{ 'is-invalid': errors.ddd_profile }"
										class="form-control" placeholder="ddd" v-model="ddd_profile" maxlength="3">
									<div class="invalid-feedback" v-if="errors.ddd_profile">
										<p>{{ errors.ddd_profile[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-12 -->
							<!-- end ddd -->

							<!-- telefone -->
							<div class="col-9">
								<div class="form-group">
									<label class="form-control-label">Telefone</label>
									<input type="text" id="telefone" v-bind:class="{ 'is-invalid': errors.telefone }"
										class="form-control" placeholder="Telefone" v-model="telefone" maxlength="9">
									<div class="invalid-feedback" v-if="errors.telefone">
										<p>{{ errors.telefone[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-12 -->
							<!-- end telefone -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="red" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
import VMask from 'v-mask';

export default {
	data() {
		return {
			cod_serpro: "",
			cnpj: "",
			ddd: "",
			cod_serpro: "",
			complemento: "",
			bairro: "",
			numero: "",
			endereco: "",
			municipio_ag_manual: "",
			telefone_instituicao: "",
			municipios_ag: [],
			municipio_ag: '',
			estado: "",
			serproBloqueado: true,
			ufBloqueado: false,
			cepValido: false,
			cep: "",
			inputIcon: 'fas fa-eye',
			inputType: 'password',
			detrans: [],
			nome: "",
			nome_usuario: "",
			nome_fantasia: "",
			cpf: "",
			funcao: "",
			ddd_profile: "",
			telefone: "",
			token_api: "",
			token_id: "",
			email: "",
			password: "",
			detran: "",
			errors: [],
			message: "",
			tipo_instituicao: "",
			loading: false,
			fullPage: true,
			states: ["Selecione...", "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
		}
	},
	mounted() {
		this.loadDetrans();
		this.verificarCEP();
	},
	methods: {
		newData() {
			let _this = this;
			_this.errors = [];
			_this.message = "";
			_this.loading = true;
			const dataInfo = {
				"cnpj": _this.cnpj == '' ? 'null' : _this.cnpj,
				"ddd": _this.ddd,
				"cep": _this.cep,
				"estado": _this.estado,
				"cidade": _this.cod_serpro,
				"endereco": _this.endereco,
				"numero": _this.numero,
				"bairro": _this.bairro,
				"nome": _this.nome,
				"complemento": _this.complemento,
				"nome_fantasia": _this.nome_fantasia,
				"nome_usuario": _this.nome_usuario,
				"cpf": _this.cpf,
				"funcao": _this.funcao,
				"ddd_profile": _this.ddd_profile,
				"telefone": _this.telefone,
				"token_api": _this.token_api,
				"token_id": _this.token_id,
				"email": _this.email,
				"password": _this.password,
				"tipo_instituicao": _this.tipo_instituicao,
				"last": ""
			};
			axios.post("/admin/bancos", dataInfo).then((res) => {

				_this.loading = false;

				_this.$swal({
					title: "Sucesso",
					text: "Instituição Financeira cadastrada com sucesso!",
					icon: "success"
				});
			}).then((res) => {

				location.href = "/admin/bancos"
			}).catch((err) => {
				_this.loading = false;

				if (err.response.status == 422) {
					let errors = err.response.data.errors

					var errorFields = Object.keys(errors).map((key) => {
						switch (key) {
							case 'nome':
								return `O campo Nome da instituição não pode ser vazio`;
							case 'nome_fantasia':
								return 'Nome Fantasia nao pode ser maior que 25 caracteres';
							case 'token_api':
								return `O campo Token API não pode ser vazio`;
							case 'token_id':
								return `O campo Token ID não pode ser vazio ou conter letras`;
							case 'tipo_instituicao':
								return `Tipo de instituição não pode ser vazio`;
							case 'email':
								return `O campo email não pode ser vazio ou está inválido`;
							case 'password':
								return `O campo Senha não pode ser vazio ou está inválido`;
							case 'nome_usuario':
								return `O campo nome do usuário não pode ser vázio`;
							case 'cpf':
								return `O campo CPF não pode ser vazio ou está inválido`;
							case 'ddd_profile':
								return `O campo DDD não pode ser vazio ou maior que dois caracteres`;
							case 'telefone':
								return `O campo telefone precisa conter 10 números`;
							case 'funcao':
								return `O campo função não pode ser vazio`;
							case 'cep':
								return `O campo CEP não pode ser vazio`;
							case 'estado':
								return `O campo UF não pode ser vazio`;
							case 'cidade':
								return `O campo Município não pode ser vazio`;
							case 'endereco':
								return `O campo Endereço não pode ser vazio`;
							case 'bairro':
								return `O campo Bairro não pode ser vazio`;
							case 'cnpj':
								return `O campo CNPJ não pode ser vazio ou está inválido`;
						}
					}).join(' <br> ');

					_this.$swal({
						title: 'Erro',
						html: errorFields,
						icon: 'error'
					});
				} else {
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar nova instituição",
						icon: "error"
					});
				}
			});
		},
		loadDetrans() {
			let _this = this;
			_this.loading = true;
			axios.get("/admin/detrans").then((res) => {
				_this.detrans = _this.detrans.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		changeInputView() {
			if (this.inputType == 'password') {
				this.inputType = "text";
				this.inputIcon = "fas fa-eye-slash";
			} else {
				this.inputType = "password";
				this.inputIcon = "fas fa-eye";
			}
		},
		async verificarCEP() {
			let cep = this.cep.replace('-', '');
			try {
				const response = await axios.get(`/admin/consultacep/${cep}`);
				const data = response.data;

				// Se o CEP foi encontrado, preenche os campos de endereço e bloqueia os campo SERPRO,UF e Municipio
				this.cod_serpro = data.codigo_serpro != "" ? data.codigo_serpro : "";
				this.cepValido = true;
				this.serproBloqueado = data.codigo_serpro != "" ? true : false;
				this.endereco = data.logradouro;
				this.bairro = data.bairro;
				this.municipio_ag = data.localidade;
				this.estado = data.uf;
				this.ufBloqueado = true;
				this.municipioBloqueado = true;
				this.dadosApi = data.codigo_serpro != "" ? true : false;
				this.onChangeUf('agente');
			} catch (error) {
				this.cod_serpro = ''
				this.cepValido = false;
				this.serproBloqueado = false;
				this.endereco = '';
				this.bairro = '';
				this.municipio_ag = '';
				this.estado = '';
				this.ufBloqueado = false;
				this.municipioBloqueado = false;
			}
		},
		gravaInfoMunicipio() {
			let nomeMunDev = "";

			if (this.municipio_ag_manual != "") { // Se o campo de município foi preenchido manualmente
				nomeMunDev = this.municipio_ag_manual;
			} else { // Caso contrário, encontra o nome do município selecionado no v-select
				let arr = this.municipios_ag;
				for (let i = 0; i < arr.length; i++) {

					if (arr[i]['municipio'] === this.municipio_ag) {
						nomeMunDev = arr[i]['municipio'];
						break;
					}
				}
			}
		},

		onChangeUf(typeSearch) {
			let _this = this;
			var estado;

			if (typeSearch == 'agente') {
				estado = _this.estado
			} else if (typeSearch == 'user') {
				estado = _this.uf_profile
			} else {
				estado = _this.userUf
			}

			axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
				if (typeSearch == 'agente') {
					_this.municipios_ag = res.data;

				} else if (typeSearch == 'user') {
					_this.municipios_profile = res.data;

				} else {
					_this.municipios_user = res.data;
				}
			}).catch((err) => {
				_this.$swal({
					title: "Erro",
					text: "Falha ao pesquisar o estao: " + estado + " tente novamente!",
					icon: "error"
				});
			});
		},

		setSelected(item) {
			let _this = this;
			let codigo = item;
			_this.cidade = codigo;
			// _this.municipio_dev = _this.municipios_dev.municipio
		},
		resetForm() {
			this.nome = '';
			this.nome_usuario = '';
			this.cpf = '';
			this.funcao = '';
			this.ddd_profile = '';
			this.telefone = '';
			this.token_api = '';
			this.token_id = '';
			this.custo_contrato = '';
			this.valor_contrato = '';
			this.payment_method = '';
			this.payment_day = '';
			this.payment_day_limit = '';
			this.codigo_agente = '';
			this.detran = '';
			this.email = '';
			this.password = '';
			this.tipo_instituicao = '';
			this.nome_fantasia = "";
			this.cod_serpro = "";
			this.cnpj = "";
			this.ddd = "";
			this.cod_serpro = "";
			this.complemento = "";
			this.bairro = "";
			this.numero = "";
			this.endereco = "";
			this.municipio_ag_manual = "";
			this.telefone_instituicao = "";
			this.municipios_ag = [];
			this.municipio_ag = '';
			this.estado = "";
			this.serproBloqueado = true;
			this.ufBloqueado = false;
			this.cepValido = false;
			this.cep = "";
		}
	},
}
</script>
