<template>
    <div class="row">
        <div class="col-12">
            <div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px;">
                <div class="wizard-form" id="grad1">
                    <div class="row justify-content-center mt-0">
                        <div class="col-12">
                            <div class="card mt-1 mb-1 bxShadow">
                                <h5 class="text-center"><strong>Contrato de Garantia</strong></h5>
                                <div class="row">
                                    <div class="col-md-12 mx-0 mb-1">
                                        <form id="msform" class="mt-2">

                                            <!-- progressbar -->
                                            <ul :id="constituicao_gravame == '1' ? 'progressbarGravameAtivo' : 'progressbarGravame'"
                                                class="text-center">
                                                <a @click="changeStep(1)">
                                                    <li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Dados
                                                            do Contrato</strong></li>
                                                </a>
                                                <a @click="changeStep(2)">
                                                    <li v-if="constituicao_gravame == '1'"
                                                        :class="step >= 2 ? 'active' : ''" id="financeiro"><strong>Dados
                                                            do Ativo Garantidor
                                                        </strong></li>
                                                </a>

                                            </ul>
                                            <!-- end progressbar -->


                                            <fieldset v-show="step == 1">
                                                <div class="form-card">
                                                    <div class="row form-distance" style="margin-top:-15px">


                                                        <!-- conta Parte -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-conta_parte">Conta Parte</label>
                                                                <input disabled name="parte[conta]" type="text"
                                                                    id="input-conta_parte"
                                                                    v-bind:class="{ 'is-invalid': errors.conta_parte }"
                                                                    class="form-control" placeholder="Conta Parte"
                                                                    v-model="conta_parte" required maxlength="20"
                                                                    @input="validarCampos('type_numbers', 'conta_parte')">
                                                                <div class="invalid-feedback" v-if="errors.conta_parte">
                                                                    <p>{{ errors.conta_parte }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->

                                                        <!-- conta Parte -->


                                                        <!-- cpf_parte -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cpf_parte">CPF / CNPJ Parte</label>
                                                                <input disabled type="text" id="input-cpf_parte"
                                                                    v-bind:class="{ 'is-invalid': errors.cpf_parte }"
                                                                    class="form-control" placeholder="CPF / CNPJ Parte"
                                                                    v-model="cpf_parte" required="true" maxlength="14">
                                                                <div class="invalid-feedback" v-if="errors.cpf_parte">
                                                                    <p>{{ errors.cpf_parte[0] }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end cpf_parte -->

                                                        <!-- conta Parte -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-conta_contraparte">Conta
                                                                    Contraparte</label>
                                                                <input disabled name="contraparte[conta]" type="text"
                                                                    id="input-conta_contraparte"
                                                                    v-bind:class="{ 'is-invalid': errors.conta_parte }"
                                                                    class="form-control" placeholder="Conta Contaparte"
                                                                    v-model="conta_contraparte" required="true"
                                                                    maxlength="20"
                                                                    @input="validarCampos('type_numbers', 'conta_contraparte')">
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.conta_contraparte">
                                                                    <p>{{ errors.conta_contraparte }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-7 -->
                                                        <!-- conta Parte -->

                                                        <!-- contraparte -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-cpf_contraparte">CPF / CNPJ
                                                                    Contraparte</label>
                                                                <input disabled type="text" id="input-cpf_contraparte"
                                                                    v-bind:class="{ 'is-invalid': errors.cpf_contraparte }"
                                                                    class="form-control"
                                                                    placeholder="CPF / CNPJ Contraparte"
                                                                    v-model="cpf_contraparte" required="true"
                                                                    maxlength="14">
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.cpf_contraparte">
                                                                    <p>{{ errors.cpf_contraparte[0] }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-6 -->
                                                        <!-- end contraparte -->


                                                        <!-- data_assinatura -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_assinatura">Data de Assinatura do
                                                                    Contrato</label>
                                                                <input disabled name="contrato[data_assinatura]"
                                                                    type="date" id="input-data_assinatura"
                                                                    v-bind:class="{ 'is-invalid': errors.data_assinatura }"
                                                                    class="form-control"
                                                                    placeholder="Data de Assinatura do Contrato"
                                                                    v-model="data_assinatura" required="true">
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.data_assinatura">
                                                                    <p>{{ errors.data_assinatura }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end data_assinatura -->

                                                        <!-- data_vencimento -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-data_vencimento">Data de Vencimento do
                                                                    Contrato</label>
                                                                <input disabled name="contrato[data_vencimento]"
                                                                    type="date" id="input-data_vencimento"
                                                                    v-bind:class="{ 'is-invalid': errors.data_vencimento }"
                                                                    class="form-control"
                                                                    placeholder="Data de Assinatura do Contrato"
                                                                    v-model="data_vencimento" required="true">
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.data_vencimento">
                                                                    <p>{{ errors.data_vencimento }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end data_vencimento -->

                                                        <!-- Descrição do Objeto do Contrato -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-descricao_objeto">Descrição do
                                                                    Objeto do Contrato</label>
                                                                <textarea disabled name="contrato[descricao_objeto]"
                                                                    style="height: 90px" id="input-descricao_objeto"
                                                                    class="form-control"
                                                                    placeholder="Descrição do Objeto do Contrato"
                                                                    v-bind:class="{ 'is-invalid': errors.descricao_objeto }"
                                                                    v-model="descricao_objeto"
                                                                    maxlength="255">                                                                                                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                        <!-- end Descrição do Objeto do Contrato -->

                                                        <!-- pluraridade_credores -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-pluralidade_credores">Pluralidade de
                                                                    Credores</label>
                                                                <select disabled name="contrato[pluralidade_credores]"
                                                                    type="text" id="input-pluralidade_credores"
                                                                    v-bind:class="{ 'is-invalid': errors.pluralidade_credores }"
                                                                    class="form-control"
                                                                    placeholder="Pluralidade de Credores"
                                                                    v-model="pluralidade_credores" required="true">
                                                                    <option value="Sim">SIM</option>
                                                                    <option value="Não">NÃO</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.pluralidade_credores">
                                                                    <p>{{ errors.pluralidade_credores }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end pluraridade_credores -->

                                                        <!-- Lista de Credores -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-lista_credores">Lista de Credores</label>
                                                                <textarea disabled name="contrato[lista_credores]"
                                                                    style="height: 90px" id="input-lista_credores"
                                                                    class="form-control" placeholder="Lista de Credores"
                                                                    v-bind:class="{ 'is-invalid': errors.lista_credores }"
                                                                    v-model="lista_credores"
                                                                    maxlength="255">                                                                                                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                        <!-- end Lista de Credores -->


                                                        <!-- Forma de Liberação da Garantia para Execução -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-forma_liberacao">Forma de Liberação da
                                                                    Garantia para Execução</label>
                                                                <select disabled name="contrato[forma_liberacao]"
                                                                    type="text" id="input-forma_liberacao"
                                                                    v-bind:class="{ 'is-invalid': errors.forma_liberacao }"
                                                                    class="form-control"
                                                                    placeholder="Forma de Liberação da Garantia para Execução"
                                                                    v-model="forma_liberacao" required="true">
                                                                    <option value="1">Comando Único
                                                                        Parte</option>
                                                                    <option value="2">Comando
                                                                        Único Contraparte</option>
                                                                    <option value="3">
                                                                        Comando Único da Parte ou da
                                                                        Contraparte</option>
                                                                    <option value="4">Ordem Judicial
                                                                    </option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.forma_liberacao">
                                                                    <p>{{ errors.forma_liberacao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Forma de Liberação da Garantia para Execução -->


                                                        <!-- Forma de Forma de Extinção do Contrato de Garantia -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-forma_extincao">Forma de Extinção do
                                                                    Contrato de Garantia</label>
                                                                <select disabled name="contrato[forma_extincao]"
                                                                    type="text" id="input-forma_extincao"
                                                                    v-bind:class="{ 'is-invalid': errors.forma_extincao }"
                                                                    class="form-control"
                                                                    placeholder="Forma de Extinção do Contrato de Garantia"
                                                                    v-model="forma_extincao" required="true">
                                                                    <option value="1">Comando Único
                                                                        Parte</option>
                                                                    <option value="2'">Comando
                                                                        Único Contraparte</option>
                                                                    <option value="3">Duplo Comando</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.forma_extincao">
                                                                    <p>{{ errors.forma_extincao }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Forma de Extinção do Contrato de Garantia -->


                                                        <!-- Liberar Automaticamente os Ativos garantidores no Vencimento? -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-liberar_automaticamente">Liberar
                                                                    Automaticamente os Ativos garantidores no
                                                                    Vencimento?</label>
                                                                <select disabled
                                                                    name="contrato[liberar_automaticamente]" type="text"
                                                                    id="input-liberar_automaticamente"
                                                                    v-bind:class="{ 'is-invalid': errors.liberar_automaticamente }"
                                                                    class="form-control"
                                                                    placeholder="Liberar Automaticamente os Ativos garantidores no Vencimento?"
                                                                    v-model="liberar_automaticamente" required="true">
                                                                    <option value="Sim">SIM</option>
                                                                    <option value="Não">NÃO</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.liberar_automaticamente">
                                                                    <p>{{ errors.liberar_automaticamente }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Liberar Automaticamente os Ativos garantidores no Vencimento? -->

                                                        <!-- Descrição Adicional-->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-descricao_adicional">Descrição
                                                                    Adicional</label>
                                                                <textarea disabled name="contrato[descricao_adicional]"
                                                                    style="height: 90px" id="input-descricao_adicional"
                                                                    class="form-control"
                                                                    placeholder="Descrição Adicional"
                                                                    v-bind:class="{ 'is-invalid': errors.descricao_adicional }"
                                                                    v-model="descricao_adicional"
                                                                    maxlength="255">                                                                                                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                        <!-- end Descrição Adicional -->

                                                        <!-- Total da Dívida -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-total_divida">Total da Dívida</label>
                                                                <money disabled name="contrato[total_divida]"
                                                                    v-model="total_divida" class="form-control"
                                                                    v-bind="money" maxlength="14">
                                                                </money>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.total_divida">
                                                                    <p>{{ errors.total_divida }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Total da Dívida -->

                                                        <!-- Prazo -->
                                                        <div class="col-md-1">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-prazo">Prazo</label>
                                                                <input disabled name="contrato[prazo]" type="text"
                                                                    id="input-prazo"
                                                                    v-bind:class="{ 'is-invalid': errors.prazo }"
                                                                    class="form-control" placeholder="Prazo"
                                                                    v-model="prazo" required="true" maxlength="3"
                                                                    @input="validarCampos('type_numbers', 'prazo')">
                                                                <div class="invalid-feedback" v-if="errors.prazo">
                                                                    <p>{{ errors.prazo }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-7 -->
                                                        <!-- end Prazo -->

                                                        <!-- Taxa de Juros -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-taxa_juros">Taxa de Juros</label>
                                                                <money disabled name="contrato[taxa_juros]"
                                                                    v-model="taxa_juros" class="form-control"
                                                                    v-bind="percentmoney" maxlength="9">
                                                                </money>
                                                                <div class="invalid-feedback" v-if="errors.taxa_juros">
                                                                    <p>{{ errors.taxa_juros }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Taxa de Juros -->

                                                        <!-- Encargos Legais Contratuais e Multas -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-encargos_legais">Encargos Legais
                                                                    Contratuais e Multas</label>
                                                                <textarea disabled name="contrato[encargos_legais]"
                                                                    style="height: 90px" id="input-encargos_legais"
                                                                    class="form-control"
                                                                    placeholder="Encargos Legais Contratuais e Multas"
                                                                    v-bind:class="{ 'is-invalid': errors.encargos_legais }"
                                                                    v-model="encargos_legais"
                                                                    maxlength="255">                                                                                                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                        <!-- end Encargos Legais Contratuais e Multas -->

                                                        <!-- Descrição dos ativos garantidores -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-descricao_ativos">Descrição dos ativos
                                                                    garantidores</label>
                                                                <textarea disabled name="contrato[descricao_ativos]"
                                                                    style="height: 90px" id="input-descricao_ativos"
                                                                    class="form-control"
                                                                    placeholder="Descrição dos ativos garantidores"
                                                                    v-bind:class="{ 'is-invalid': errors.descricao_ativos }"
                                                                    v-model="descricao_ativos"
                                                                    maxlength="255">                                                                                                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                        <!-- end Descrição dos ativos garantidores -->

                                                        <!-- Constituição do Gravame -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-constituicao_gravame">
                                                                    Constituição do Gravame
                                                                </label>
                                                                <select disabled name="contrato[constituicao_gravame]"
                                                                    id="input-constituicao_gravame"
                                                                    v-bind:class="['form-control', { 'is-invalid': errors.constituicao_gravame }]"
                                                                    v-model="constituicao_gravame" required>
                                                                    <option value="1">Ativo</option>
                                                                    <option value="2">Conta</option>
                                                                    <option value="3">
                                                                        Conta/Gestão de Colateral</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.constituicao_gravame">
                                                                    <p>{{ errors.constituicao_gravame }}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- end Constituição do Gravame -->

                                                        <!-- Tipo de Garantia -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-tipo_garantia">Tipo de Garantia</label>
                                                                <select disabled name="contrato[tipo_garantia]"
                                                                    type="text" id="input-tipo_garantia"
                                                                    v-bind:class="{ 'is-invalid': errors.tipo_garantia }"
                                                                    class="form-control" placeholder="Tipo de Garantia"
                                                                    v-model="tipo_garantia" required="true">
                                                                    <option value="1">
                                                                        Cessão/Alienação Fiduciária
                                                                    </option>
                                                                    <option value="3">Penhor</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.tipo_garantia">
                                                                    <p>{{ errors.tipo_garantia }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Tipo de Garantia -->



                                                        <!-- Código de Controle Interno -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-controle_cod">Código de Controle
                                                                    Interno</label>
                                                                <input disabled name="contrato[controle_cod]"
                                                                    type="text" id="input-controle_cod"
                                                                    v-bind:class="{ 'is-invalid': errors.controle_cod }"
                                                                    class="form-control"
                                                                    placeholder="Código de Controle Interno"
                                                                    v-model="controle_cod" required="true"
                                                                    maxlength="10"
                                                                    @input="validarCampos('type_numContrato', 'controle_cod')">
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.controle_cod">
                                                                    <p>{{ errors.controle_cod }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-7 -->
                                                        <!-- end Código de Controle Interno -->

                                                        <!-- Duplo Comando para vinculação de garantia -->
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-duplo_comando">Duplo Comando para
                                                                    vinculação de garantia</label>
                                                                <select disabled name="contrato[duplo_comando]"
                                                                    type="text" id="input-duplo_comando"
                                                                    v-bind:class="{ 'is-invalid': errors.duplo_comando }"
                                                                    class="form-control"
                                                                    placeholder="Duplo Comando para vinculação de garantia"
                                                                    v-model="duplo_comando" required="true">
                                                                    <option value="Sim">SIM</option>
                                                                    <option value="Não">NÃO</option>
                                                                </select>
                                                                <div class="invalid-feedback"
                                                                    v-if="errors.duplo_comando">
                                                                    <p>{{ errors.duplo_comando }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Duplo Comando para vinculação de garantia -->

                                                        <!-- Disclaimer -->
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label class="form-control-label"
                                                                    for="input-disclaimer">Disclaimer</label>
                                                                <select disabled name="contrato[disclaimer]" type="text"
                                                                    id="input-disclaimer"
                                                                    v-bind:class="{ 'is-invalid': errors.disclaimer }"
                                                                    class="form-control" placeholder="Disclaimer"
                                                                    v-model="disclaimer" required="true">
                                                                    <option value="SIM">SIM</option>
                                                                </select>
                                                                <div class="invalid-feedback" v-if="errors.disclaimer">
                                                                    <p>{{ errors.disclaimer }}</p>
                                                                </div>
                                                            </div><!-- form group -->
                                                        </div><!-- col-md-3 -->
                                                        <!-- end Disclaimer -->
                                                    </div>
                                                </div>
                                                <a v-if="constituicao_gravame == '1'" @click="changeStep(2)"
                                                    class="btn-next">Próximo</a>
                                                <a v-else @click="returnTelaGravame();"
                                                    class="btn-next btn-enviar">Voltar á Gravame</a>
                                            </fieldset>
                                            <!-- end dados financeiros -->

                                            <!-- Dados do Ativo Garantidor -->
                                            <fieldset v-if="step == 2"
                                                :style="[step == 2 ? { 'display': 'block' } : { 'display': 'none' }]">
                                                <div class="form-card">
                                                    <div class="row form-distance" style="margin-top:-15px">
                                                        <div v-for="(ativo, index) in ativosGarantidores" :key="index"
                                                            class="row">

                                                            <!-- Cabeçalho numerado -->
                                                            <div class="col-12 mt-3">
                                                                <h6>Ativo Garantidor {{ index + 1 }}</h6>
                                                            </div>

                                                            <!-- Tipo IF -->
                                                            <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-tipo_if">Tipo IF</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][tipo_if]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.tipo_if" required maxlength="5">
                                                                </div>
                                                            </div>
                                                            <!-- end Tipo IF -->


                                                            <!-- Código do IF -->
                                                            <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-codigo_if">Código do IF</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][codigo_if]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.codigo_if" required
                                                                        maxlength="14">
                                                                </div>
                                                            </div>
                                                            <!-- end Código do IF -->

                                                            <!-- Tipo IF -->
                                                            <!-- <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-tipo_if">Tipo IF</label>
                                                                    <select disabled
                                                                        :name="'ativos_garantidores[' + index + '][tipo_if]'"
                                                                        class="form-control" v-model="ativo.tipo_if"
                                                                        required>
                                                                        <option value="CCB">CCB</option>
                                                                        <option value="CRA">CRA</option>
                                                                        <option value="CRI">CRI</option>
                                                                        <option value="DEB">DEB</option>
                                                                        <option value="NC">NC</option>
                                                                        <option value="NCE">NCE</option>
                                                                        <option value="OPCAO/BOX2">OPCAO/BOX2</option>
                                                                        <option value="SWAP">SWAP</option>
                                                                        <option value="TCF">TCF</option>
                                                                        <option value="TER">TER</option>
                                                                        <option value="TERMO (TCO, TIN)">TERMO (TCO,
                                                                            TIN)</option>

                                                                    </select>
                                                                </div>
                                                            </div> -->
                                                            <!-- end Tipo IF -->

                                                            <!-- Código do IF -->
                                                            <!-- <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-codigo_if">Código do IF</label>
                                                                    <select disabled
                                                                        :name="'ativos_garantidores[' + index + '][codigo_if]'"
                                                                        class="form-control" v-model="ativo.codigo_if"
                                                                        required>
                                                                        <option
                                                                            v-for="item in tipos_codigos_if[ativo.tipo_if]"
                                                                            :value='item'>{{ item }}</option>
                                                                    </select>
                                                                </div>
                                                            </div> -->
                                                            <!-- end Código do IF -->

                                                            <!-- Quantidade -->
                                                            <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-quantidade">Quantidade</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][quantidade]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.quantidade" required
                                                                        maxlength="10">
                                                                </div>
                                                            </div>
                                                            <!-- end Quantidade -->

                                                            <!-- Conta Origem -->
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-conta_origem">Conta Origem</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][conta_origem]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.conta_origem" required
                                                                        maxlength="20">
                                                                </div>
                                                            </div>
                                                            <!-- end Conta Origem -->

                                                            <!-- CPF/CNPJ Origem -->
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-cpf_cnpj_origem">CPF / CNPJ
                                                                        Origem</label>
                                                                    <div
                                                                        v-if="ativo.cpf_cnpj_origem.length != 11 || ativo.cpf_cnpj_origem.length != 18">
                                                                        <input disabled
                                                                            :name="'ativos_garantidores[' + index + '][cpf_cnpj_origem]'"
                                                                            type="text" id="input-cpf_cnpj_origem"
                                                                            class="form-control"
                                                                            placeholder="CPF / CNPJ"
                                                                            v-model="ativo.cpf_cnpj_origem"
                                                                            required="true" maxlength="18"
                                                                            v-bind:class="{ 'is-invalid': errorMessagesOrigem[index] }"
                                                                            @input="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                            @blur="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                            @keydown.space.prevent="preventSpace">
                                                                        <div class="invalid-feedback"
                                                                            v-if="errorMessagesOrigem[index]">
                                                                            <p>{{ errorMessagesOrigem[index] }}</p>
                                                                        </div>
                                                                        <div v-if="ativo.cpf_cnpj_origem.length == 10"
                                                                            style="display: hidden;">
                                                                            <input
                                                                                :name="'ativos_garantidores[' + index + '][cpf_cnpj_origem]'"
                                                                                type="hidden" id="input-cpf_cnpj_origem"
                                                                                disabled="true" class="form-control"
                                                                                placeholder="CPF / CNPJ"
                                                                                v-model="ativo.cpf_cnpj_origem"
                                                                                v-mask="'###.###.###-##'"
                                                                                required="true" maxlength="18"
                                                                                v-bind:class="{ 'is-invalid': errorMessagesOrigem[index] }"
                                                                                @input="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                                @blur="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                                @keydown.space.prevent="preventSpace">
                                                                            <div class="invalid-feedback"
                                                                                v-if="errorMessagesOrigem[index]">
                                                                                <p>{{ errorMessagesOrigem[index] }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="ativo.cpf_cnpj_origem.length == 16"
                                                                            style="display: hidden;">
                                                                            <input
                                                                                :name="'ativos_garantidores[' + index + '][cpf_cnpj_origem]'"
                                                                                type="hidden" id="input-cpf_cnpj_origem"
                                                                                disabled="true" class="form-control"
                                                                                placeholder="CPF / CNPJ"
                                                                                v-model="ativo.cpf_cnpj_origem"
                                                                                v-mask="'##.###.###/####-##'"
                                                                                required="true" maxlength="18"
                                                                                v-bind:class="{ 'is-invalid': errorMessagesOrigem[index] }"
                                                                                @input="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                                @blur="validateCpfCnpjOrigem(index, ativo.cpf_cnpj_origem)"
                                                                                @keydown.space.prevent="preventSpace">
                                                                            <div class="invalid-feedback"
                                                                                v-if="errorMessagesOrigem[index]">
                                                                                <p>{{ errorMessagesOrigem[index] }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="invalid-feedback"
                                                                        v-if="errors.cpf_cnpj_origem">
                                                                        <p>{{ errors.cpf_cnpj_origem }}</p>
                                                                    </div>
                                                                </div><!-- form group -->
                                                            </div><!-- col-md-6 -->
                                                            <!-- end CPF/CNPJ Origem -->

                                                            <!-- Conta Destino -->
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-conta_destino">Conta Destino</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][conta_destino]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.conta_destino" required
                                                                        maxlength="20">
                                                                </div>
                                                            </div>
                                                            <!-- end Conta Destino -->

                                                            <!-- CPF/CNPJ Destino -->
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-cpf_parte">CPF / CNPJ Destino</label>
                                                                    <div
                                                                        v-if="ativo.cpf_cnpj_destino.length != 11 || ativo.cpf_cnpj_destino.length != 18">
                                                                        <input disabled
                                                                            :name="'ativos_garantidores[' + index + '][cpf_cnpj_destino]'"
                                                                            type="text" id="input-cpf_cnpj_destino"
                                                                            class="form-control"
                                                                            placeholder="CPF / CNPJ"
                                                                            v-model="ativo.cpf_cnpj_destino"
                                                                            required="true" maxlength="18"
                                                                            v-bind:class="{ 'is-invalid': errorMessagesDestino[index] }"
                                                                            @input="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                            @blur="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                            @keydown.space.prevent="preventSpace">
                                                                        <div class="invalid-feedback"
                                                                            v-if="errorMessagesDestino[index]">
                                                                            <p>{{ errorMessagesDestino[index] }}</p>
                                                                        </div>
                                                                        <div v-if="ativo.cpf_cnpj_destino.length == 10"
                                                                            style="display: hidden;">
                                                                            <input
                                                                                :name="'ativos_garantidores[' + index + '][cpf_cnpj_destino]'"
                                                                                type="hidden"
                                                                                id="input-cpf_cnpj_destino"
                                                                                disabled="true" class="form-control"
                                                                                placeholder="CPF / CNPJ"
                                                                                v-model="ativo.cpf_cnpj_destino"
                                                                                v-mask="'###.###.###-##'"
                                                                                required="true" maxlength="18"
                                                                                v-bind:class="{ 'is-invalid': errorMessagesDestino[index] }"
                                                                                @input="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                                @blur="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                                @keydown.space.prevent="preventSpace">
                                                                            <div class="invalid-feedback"
                                                                                v-if="errorMessagesDestino[index]">
                                                                                <p>{{ errorMessagesDestino[index] }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="ativo.cpf_cnpj_destino.length == 16"
                                                                            style="display: hidden;">
                                                                            <input
                                                                                :name="'ativos_garantidores[' + index + '][cpf_cnpj_destino]'"
                                                                                type="hidden"
                                                                                id="input-cpf_cnpj_destino"
                                                                                disabled="true" class="form-control"
                                                                                placeholder="CPF / CNPJ"
                                                                                v-model="ativo.cpf_cnpj_destino"
                                                                                v-mask="'##.###.###/####-##'"
                                                                                required="true" maxlength="18"
                                                                                v-bind:class="{ 'is-invalid': errorMessagesDestino[index] }"
                                                                                @input="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                                @blur="validateCpfCnpjDestino(index, ativo.cpf_cnpj_destino)"
                                                                                @keydown.space.prevent="preventSpace">
                                                                            <div class="invalid-feedback"
                                                                                v-if="errorMessagesDestino[index]">
                                                                                <p>{{ errorMessagesDestino[index] }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="invalid-feedback"
                                                                        v-if="errors.cpf_cnpj_destino">
                                                                        <p>{{ errors.cpf_cnpj_destino }}</p>
                                                                    </div>
                                                                </div><!-- form group -->
                                                            </div><!-- col-md-6 -->
                                                            <!-- end CPF/CNPJ Destino -->

                                                            <!-- Grau de Penhor -->
                                                            <div class="col-md-1">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-grau_penhor">Grau de
                                                                        Penhor</label>
                                                                    <input disabled
                                                                        :name="'ativos_garantidores[' + index + '][grau_penhor]'"
                                                                        type="text" class="form-control"
                                                                        v-model="ativo.grau_penhor" required
                                                                        maxlength="4">
                                                                </div>
                                                            </div>

                                                            <!-- Eventos para o Garantido -->
                                                            <div class="col-md-2">
                                                                <div class="form-group">
                                                                    <label class="form-control-label"
                                                                        for="input-eventos_para_garantido">Eventos
                                                                        para
                                                                        o Garantido?</label>
                                                                    <select disabled
                                                                        :name="'ativos_garantidores[' + index + '][eventos_para_garantido]'"
                                                                        class="form-control"
                                                                        v-model="ativo.eventos_para_garantido" required>
                                                                        <option value="Sim">SIM</option>
                                                                        <option value="Não">NÃO</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> <!--< row -p4 >-->
                                                </div>
                                                <a @click="changeStep(1)" class="btn-previous">Anterior</a>
                                                <a @click="returnTelaGravame();" class="btn-next btn-enviar">Voltar á
                                                    Gravame</a>
                                            </fieldset>
                                            <!-- end Dados do Ativo Garantidor -->
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- card -->
        </div><!-- col-12 -->
        <vue-loading color="#ec2434" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
    </div><!-- row -->
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Money from 'v-money';
import VMask from 'v-mask';
import { validateCpfCnpj, validateCampo } from '../validarCampos/validations';

export default {
    props: ['gravame'],
    data() {
        return {
            step: 1,
            errors: [],
            conta_parte: this.getData("parte").conta,
            cpf_parte: this.getData("parte").cpf_cnpj,
            errorMessagesOrigem: [],
            errorMessagesDestino: [],
            errorMessages: {},
            conta_contraparte: this.getData("contraparte").conta,
            cpf_contraparte: this.getData("contraparte").cpf_cnpj,
            contratoGarantia: "",
            status_contrato: "",
            acao_contrato: "",
            data_assinatura: this.getData("data_assinatura"),
            data_vencimento: this.getData("data_vencimento"),
            descricao_objeto: this.getData("descricao_objeto"),
            pluralidade_credores: this.getData("pluralidade_credores"),
            lista_credores: this.getData("lista_credores"),
            forma_liberacao: this.getData("forma_liberacao"),
            forma_extincao: this.getData("forma_extincao"),
            liberar_automaticamente: this.getData("liberar_automaticamente"),
            descricao_adicional: this.getData("descricao_adicional"),
            total_divida: this.getData("total_divida"),
            prazo: this.getData("prazo"),
            taxa_juros: this.getData("taxa_juros"),
            encargos_legais: this.getData("encargos_legais"),
            descricao_ativos: this.getData("descricao_ativos"),
            constituicao_gravame: this.getData("constituicao_gravame"),
            tipo_garantia: this.getData("tipo_garantia"),
            quantidade_ativos: this.getData("quantidade_ativos"),
            controle_cod: "",
            duplo_comando: this.getData("duplo_comando"),
            disclaimer: this.getData("disclaimer"),
            ativosGarantidores: this.getData("ativos_garantidores"),
            tipo_if: "",
            tipos_codigos_if: "",
            // tipos_codigos_if: {
            //     'OPCAO/BOX2': [36, 37, 40, 83, 336, 474],
            //     'SWAP': [10, 12, 14, 38, 39, 74, 314, 474, 839],
            //     'TCF': [12, 14, 39, 314, 474, 6201],
            //     'TER': [12, 14, 314, 474],
            //     'TERMO (TCO, TIN)': [12, 14, 314, 474, 701],
            //     'CCB': [12, 60, 69, 74, 873, 874, 878],
            //     'CCE': [12, 60, 69, 74],
            //     'CRA': [12, 14, 60, 65, 66, 69, 72, 73, 74, 79, 874],
            //     'CRI': [12, 14, 60, 65, 66, 69, 72, 73, 74, 79, 874],
            //     'DEB': [12, 14, 60, 65, 66, 69, 72, 73, 74, 79, 874],
            //     'NC': [12, 60, 66, 74],
            //     'NCE': [12, 60, 69]
            // },
            fullPage: true,
            loading: false,
            codigo_if: "",
            quantidade: "",
            conta_origem: "",
            cpf_cnpj_origem: "",
            conta_destino: "",
            cpf_cnpj_destino: "",
            grau_penhor: "",
            eventos_para_garantido: "",
            ativosGarantidoresDestino: [
                { cpf_cnpj_destino: '' },
            ],
            ativosGarantidoresOrigem: [
                { cpf_cnpj_origem: '' },
            ],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            percentmoney: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' %',
                precision: 3,
                masked: false
            },
        }
    },
    mounted() {
        this.updateAtivosArray();
    },
    computed: {

    },
    watch: {
        constituicao_gravame(newValue) {
            if (newValue !== '1') {
                this.quantidade_ativos = '';
            }
        }
    },

    methods: {
        returnTelaGravame() {
            window.location.href = process.env.MIX_URL + 'gravame/';
        },
        getData(key) {
            return JSON.parse(this.gravame)[key];
        },
        sendData(id) {
            let _this = this;

            const formData = new FormData(document.getElementById('msform'))

            formData.append('contrato[taxa_juros]', _this.taxa_juros);
            formData.append('contrato[total_divida]', _this.total_divida);

            axios.post("/portal/gravame/update/" + this.getData('id'), formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

                _this.loading = false;

                //show confirm alert
                _this.$swal({
                    title: "Sucesso",
                    text: "Gravame atualizado com sucesso!",
                    icon: "success"
                }).then((res) => {
                    // Se o usuário clicar em "Confirmar"
                    if (res.status = 200) {
                        // Redireciona para a página de destino
                        window.location.href = process.env.MIX_URL + 'gravame/';

                    }
                });
            }).catch((err) => {

                _this.loading = false;

                if (err.response.status == 422) {

                    this.errors = err.response.data.errors;

                    var errorFields = Object.keys(err.response.data.errors).map((key, value) => {
                        return err.response.data.errors[key][0];
                    }).join(' <br> - ');

                    _this.$swal({
                        title: "Falha ao atualizar gravame",
                        html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
                        icon: "error"
                    });
                } else {

                    if (err.response.status == 400) {

                        _this.$swal({
                            title: "Falha ao atualizar gravame",
                            html: err.response.data.message,
                            icon: "error"
                        });
                    } else {
                        //show error alert
                        _this.$swal({
                            title: "Falha ao atualizar gravame",
                            text: "Falha ao atualizar o gravame. Tente novamente!",
                            icon: "error"
                        });
                    }
                }

            });
        },
        // Atualiza o array de ativos com base na quantidade de ativos
        updateAtivosArray() {
            const quantidade = this.quantidade_ativos;

            // Se a quantidade for maior, adiciona novos itens mantendo os existentes
            if (quantidade > this.ativosGarantidores.length) {
                for (let i = this.ativosGarantidores.length; i < quantidade; i++) {
                    this.ativosGarantidores.push({
                        tipo_if: '',
                        codigo_if: '',
                        quantidade: '',
                        conta_origem: '',
                        cpf_cnpj_origem: '',
                        conta_destino: '',
                        cpf_cnpj_destino: '',
                        grau_penhor: '',
                        eventos_para_garantido: ''
                    });
                }
            }
            // Se a quantidade for menor, remove itens do final do array
            else if (quantidade < this.ativosGarantidores.length) {
                this.ativosGarantidores.splice(quantidade);
            }
            // Carrega os dados corretamente conforme a posição no array
            for (let i = 0; i < quantidade; i++) {
                if (this.existingAtivos && this.existingAtivos[i]) {
                    // Atualiza cada campo conforme os dados existentes
                    this.ativosGarantidores[i] = {
                        ...this.ativosGarantidores[i],
                        tipo_if: this.existingAtivos[i].tipo_if || '',
                        codigo_if: this.existingAtivos[i].codigo_if || '',
                        quantidade: this.existingAtivos[i].quantidade || '',
                        conta_origem: this.existingAtivos[i].conta_origem || '',
                        cpf_cnpj_origem: this.existingAtivos[i].cpf_cnpj_origem || '',
                        conta_destino: this.existingAtivos[i].conta_destino || '',
                        cpf_cnpj_destino: this.existingAtivos[i].cpf_cnpj_destino || '',
                        grau_penhor: this.existingAtivos[i].grau_penhor || '',
                        eventos_para_garantido: this.existingAtivos[i].eventos_para_garantido || ''
                    };
                }
            }
        },
        changeStep(option) {
            this.step = option;
        },
    },


    componentes: {
        vSelect,
        Money,
        VMask,
    },
}
</script>
