<template>
	<div class="row">
		<div class="col">
			<div class="card p-4">
				<div class="row">
					<div class="col">
						<div class="col-6">
							<label class="text_checkbox" for="input-select_Month">Selecione o Mês</label>
							<v-select label="mesAno" v-model="selectedMonth" :options="months" @input="setData"></v-select>
						</div>

						<div class="col-6 pt-4">
							<label for="instituicao" class="form-control-label">Selecione a Instituição</label>
							<v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições" label="label"
								@input="setSelectInstituicao" :options="bancoOptions" v-model="instituicao">
								<template #tags="{ values, search, isOpen, removeTag }">
									<div class="vue-select__tags">
										<span v-for="option in values" :key="option.id" class="vue-select__tags-item"
											@click.stop="removeTag(option)">
											{{ option.label }}
										</span>
									</div>
								</template>
							</v-select>
						</div>

						<div class="col-6 pt-4">
							<label for="estado" class="form-control-label">Selecione o Detran</label>
							<v-select multiple id="estado" placeholder="Selecione um ou mais estados." label="label"
								@input="setSelectedState" :options="estadoOptions" v-model="detransSelecionado">
								<template #tags="{ values, search, isOpen, removeTag }">
									<div class="vue-select__tags">
										<span v-for="option in values" :key="option.id" class="vue-select__tags-item"
											@click.stop="removeTag(option)">
											{{ option.label }}
										</span>
									</div>
								</template>
							</v-select>
						</div>
					</div>

				</div>
				<div class="form_bt_exe">
					<a @click="execute" class="btn_exec_faturamento" id="btn-executar">EXECUTAR</a>
				</div>
			</div>
		</div>
		<vue-loading color="#ec2434" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import moment from 'moment';

export default {
	components: {
		vSelect,
	},
	data() {
		return {
			selectedMonth: "",
			months: [],
			dataSelecionada: "",
			gerarFaturamento: "",
			gerarPrevia: "",
			loading: false,
			instituicao: [],
			detransSelecionado: [],
			bancos: [],
			states: [],
			detrans: [],
			bancoOptions: [],
			estadoOptions: []
		};
	},
	mounted() {
		this.getMonths();
		this.loadItems();
		this.loadStates();
	},
	methods: {
		setSelectedState(item) {
			this.detransSelecionado = item.map(state => state);
		},
		setSelectInstituicao(item) {
			this.instituicao = item.map(banco => banco);
		},
		getMonths() {
			let now = new Date();
			let prevMonth = new Date(now.getFullYear(), now.getMonth() - 1);
			let months = [
				{
					mesAno: "Mês/Ano"
				},
				{
					mesAno: `${now.toLocaleString("default", {
						month: "2-digit",
					})}/${now.getFullYear()}`,
				},
				{
					mesAno: `${prevMonth.toLocaleString("default", {
						month: "2-digit",
					})}/${prevMonth.getFullYear()}`,
				},
			];
			this.months = months;
			this.selectedMonth = months[0];
		},
		execute() {
			this.loading = true;

			let formData = new FormData();
			formData.append("data_referencia", moment(String(this.dataSelecionada)).format('YYYY-MM'));

			for (let i = 0; i < this.detransSelecionado.length; i++) {
				formData.append(`detran[${i}]`, this.detransSelecionado[i].id);
			}

			for (let i = 0; i < this.instituicao.length; i++) {
				formData.append(`instituicao[${i}]`, this.instituicao[i].id);
			}

			axios.post("jobFinanceiro", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
				this.loading = false;
				this.$swal({
					title: "Sucesso",
					text: res.data.Message,
					icon: "success"
				});
			}).catch((err) => {
				this.loading = false;
				if (err.response.status == 422) {
					this.$swal({
						title: "Erro",
						text: err.response.data.errors.data_referencia[0],
						icon: "error"
					});
				} else {
					this.$swal({
						title: "Erro",
						text: 'Entre em contato com o suporte técnico.',
						icon: "error"
					});
				}
			});
		},
		setData(item) {
			let dateXpl = item.mesAno.split('/');
			this.dataSelecionada = dateXpl[1] + '-' + dateXpl[0];
		},
		loadStates() {
			axios.get("/api/portal/getAllDetrans").then(res => {
				this.detrans = res.data.detrans;
				this.estadoOptions = this.detrans.map(element => ({
					label: element.estado,
					id: element.id,
					selected: false
				}));
				this.states = this.sortVector(this.estadoOptions.map(state => state.label));
			});
		},
		loadItems() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.bancoOptions = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
					selected: false
				}));
				this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		sortVector(vector) {
			if (vector.length) {
				return vector.sort(function (a, b) {
					return a.localeCompare(b);
				});
			}
			return vector;
		},
	},
};
</script>
