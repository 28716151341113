<template>
    <div class="documentacao">
        <h2>Manual de Utilização</h2>
        <div class="text-center">
            <div class="row mt-5 justify-content-center">
                <div class="d-flex align-items-center">
                    <h4 class="mb-0">Manual de Utilização Sistema Result1 (PDF)</h4>
                    <a @click="downloadManual" class="btn-download-pdf ml-5">Download
                        <i class="far fa-file-pdf"></i>
                    </a>
                </div>
            </div>

            <div class="video-section">
                <h4>Veja o vídeo de utilização do portal:</h4>
                <div class="video-container mx-auto mt-3">
                    <video v-if="videoUrl" controls>
                        <source :src="videoUrl" type="video/mp4">
                        Seu navegador não suporta a reprodução de vídeos.
                    </video>
                    <p v-else>{{ mensagem }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'documentacao',
            itemsDocumentacao: [],
            loading: false,
            videoUrl: '',
            mensagem: 'Carregando video...'
        }
    },
    mounted() {
        this.getVideoIntroducao();
    },

    methods: {

        getVideoIntroducao() {
            axios.get("getVideo").then((res) => {
                if(res.data.url !== '') {
                    return this.videoUrl = res.data.url;
                }
                this.mensagem = 'Erro ao realizar o download do vídeo, entre em contato com o suporte.'

            }).catch((err) => {
                console.error(err)
                this.$swal({
                    title: "Erro",
                    text: "Erro ao realizar o download do vídeo, entre em contato com o suporte.",
                    icon: "error"
                });
            });
        },

        downloadManual() {
            axios.get("getManuais", { responseType: 'arraybuffer' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Manual_Sistema_Result1.pdf";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(link.href);
            }).catch((err) => {
                    console.error(err)
                    this.$swal({
                        title: "Erro",
                        text: "Erro ao realizar o download do manual, entre em contato com o suporte.",
                        icon: "error"
                    });
            })
        }
    },


}
</script>

<style scoped>
.video-section {
    margin-top: 45px;
    /* Adiciona um espaço de 30px acima */
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 740px;
    /* Limita a largura máxima */
    margin: 0 auto;
    /* Centraliza o vídeo */
}

.video-container video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
</style>
