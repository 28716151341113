<template>
  <div class="row">
    <div class="col">
      <!-- table -->
      <div class="card p-4">
        <!-- Filtro -->
				<div>
					<h5>Filtrar Período Desejado: </h5>
				</div>	
				<div class="row">								
					<div class="row">						
						<div class="col-md-6 p-4">
							<div class="form-group date-custom">
							<datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial" class="btn-filter" />							
							</div>						
						</div>
						<div class="col-md-6 p-4">
							<div class="form-group date-custom">
							<datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final" class="btn-filter" />
							</div>
						</div>
					</div>					
					<div class="col-md-6">
						<a href="#" class="btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button" style="height: 30px;"
							@click="carregarLogs()">
							Filtrar
						</a>
						<button class="btn btn-limpar btn-filter col-md-4 col-lg-2 col-sm-5 mt-4 ml-2 " style="border-radius: 10px; height: 30px;" data-toggle="tooltip"
							data-placement="top" title="Limpar Filtro" @click="limparFiltros">
							 Limpar Filtro <i class="fas fa-trash fa-lg"></i>
						</button>
					</div>
					
				</div>
				<div class="mx-auto">
					<h5>Período: {{ periodoSelecionado }}</h5>
				</div>
        <!-- Filtro -->
        <vue-good-table
            :columns="columns"
            :rows="logs"
			:line-numbers="true"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              position: 'bottom',
              dropdownAllowAll: true,
              perPageDropdown: [10, 30, 50],
              nextLabel: 'Próximo',
              prevLabel: 'Anterior',
              rowsPerPageLabel: '',
              ofLabel: 'de',
              allLabel: 'Todos',
              pageLabel: '',
              perPageDropdownEnabled: true,
              compactMode: true,              
            }"
            :search-options="{
                enabled: true,
                placeholder: 'Pesquisar',
              }"
            styleClass="vgt-table"
            >
          </vue-good-table>
      </div>
      <!-- end table -->
      <div v-if="!logs.length && !loading" class="text-center p-3 text-muted">
            <h5>Nenhum log encontrado!</h5>
      </div>
      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>
    </div><!-- col -->
    <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
  </div><!-- row -->
</template>

<script>
	import "vue-good-table/dist/vue-good-table.css";
	import { VueGoodTable } from "vue-good-table";
  import { SweetModal, SweetModalTab } from "sweet-modal-vue";
  import { ptBR } from "vuejs-datepicker/dist/locale";
  import Datepicker from "vuejs-datepicker";

  export default {
    data() {
      return {
        logs: [],
        dataInicial: "",
			  dataFinal: "",
			  periodoSelecionado: 'Últimos 30 dias',
			  calendarLanguage: ptBR,
        loading: false,
        columns: [
					{
					label: "Ação",
					field: "action",
					},
					{
					label: "Tipo",
					field: "action_type",
					},
					{
					label: "Data e Hora",
					field: "created_at",
          sortable: true,
					formatFn: this.formatDate,
					},
				],
      }
    },
    mounted() {
      this.carregarLogs('sig');
    },
    methods: {
      
   carregarLogs(signal) {
			const dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
			const dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";
			const urlApi = dtInicial === '' || dtFinal === '' ? '/admin/log/activity' : `/admin/log/activity/${dtInicial}/${dtFinal}`;

			// Atualiza a legenda antes de fazer a solicitação
			  this.atualizarLegenda();

			if ((signal == 'sig') || (dtInicial !== '' && dtFinal !== '')) {
				this.loading = true;
				axios.get(urlApi)
					.then((res) => {
						this.logs = res.data;
					})
					.catch((err) => {
						this.logs = [];
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		atualizarLegenda() {
			if (this.dataInicial && this.dataFinal) {
			this.periodoSelecionado = `${moment(this.dataInicial).format("DD/MM/YYYY")} - ${moment(this.dataFinal).format("DD/MM/YYYY")}`;
			} else {
			this.periodoSelecionado = 'Últimos 30 dias';
			}
		},
		limparFiltros() {
			let _this = this;
			_this.dataInicial = "";
			_this.dataFinal = "";
			_this.periodoSelecionado = 'Últimos 30 dias';

			this.carregarLogs('sig')
		},
      formatDate: function (value) {
				return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
			}
    },
    components: {
    		VueGoodTable,
        SweetModal,
        SweetModalTab,
        Datepicker,
		}
  }
</script>
