export const fieldNames = {
	campoObrigatorio: {
		tipo_operacao: "Tipo de Operação",
		chassi: "Chassi",
		remarcacao: "Remarcação",
		indicador_novo_usado: "Condição do Véiculo",
		placa: "Placa",
		uf_placa: "UF Placa",
		renavam: "Renavam",
		ano_fabricacao: "Ano Fabricação",
		ano_modelo: "Ano Modelo",
		num_contrato: "Nº Contrato",
		data_contrato: "Data Contrato",
		qtd_parcelas: "Qtd. Parcelas",
		num_gravame: "Nº Gravame",
		tipo_gravame: "Tipo Gravame",
		num_aditivo: "Nº Aditivo",
		data_aditivo: "Data Aditivo",
		taxa_juro_mes: "Taxa de Juros Mês",
		taxa_juro_ano: "Taxa de Juros Ano",
		taxa_juro_multa: "Taxa de Juros Multa",
		taxa_multa: "Taxa Multa",
		taxa_mora: "Taxa Mora",
		taxa_mora_dia: "Taxa Mora Dia",
		valor_taxa_contrato: "Valor Taxa Contrato",
		valor_iof: "Valor IOF",
		indice: "Indíce",
		valor_total_financiamento: "Valor Total do Financiamento",
		valor_parcela: "Valor da Parcela",
		data_vecto_primeira_parcela: "Data de Vencimeto da Primeira Parcela",
		data_vecto_ultima_parcela: "Data de Vencimento da Última Parcela",
		data_liberacao_credito: "Data de Liberação do Crédito",
		uf_liberacao_credito: "UF de Liberação do Crédito",
		municipio_liberacao_credito: "Município de Liberação do Crédito",
		num_grupo_consorcio: "Nº Grupo Consórcio",
		num_cota_consorcio: "Nº Cota Consórcio",
		cpf_cnpj_devedor: "CPF / CNPJ",
		nome_devedor: "Nome",
		nome_logradouro_devedor: "Logradouro",
		num_imovel_devedor: "Nº",
		complemento_imovel_devedor: "Complemento",
		bairro_devedor: "Bairro",
		nome_municipio_devedor: "Município",
		uf_devedor: "UF",
		cep_devedor: "CEP",
		ddd_devedor: "DDD",
		telefone_devedor: "Telefone",
		num_contrato_origem: "Nº Contrato Origem",
		num_aditivo_origem: "Nº Aditivo Origem",
		indicativo_penalidade: "Indicativo Penalidade",
		penalidade: "Penalidade",
		indicativo_comissao: "Indicativo Comissão",
		comissao: "Comissão",
		razao_social: "Razão Social",
		nome_fantasia: "Nome Fantasia",
		nome_usuario: "Nome Usuário",
		cpf: "CPF",
		funcao: "Função",
		ddd_profile: "DDD",
		telefone: "Telefone",
		email: "Email",
		password: "Senha",
		tipo: "Tipo de Grupo",
		insituicaoId: "Instituicao",
		uf: "UF",
		name: "Nome",
		permission: "Grupo de Acesso",
		banco: "Banco",
		listaGrupoEcon: "Lista Grupo Econômico"

	},
};
