<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<!-- card body -->
				<div class="card-body" style="margin-top: 3px;">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados Básicos</h6>
					<div class="row">
						<!-- grupoEcon -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Grupo Econômico?</label>
								<select id="input-grupoEcon" v-bind:class="{ 'is-invalid': errors.grupoEcon }" v-model="grupoEcon"
									class="form-control" placeholder="Grupo Econômico?">
									<option value="true">Sim</option>
									<option value="false">Não</option>
								</select>
								<div class="invalid-feedback" v-if="errors.grupoEcon">
									<p>{{ errors.grupoEcon[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end grupoEcon -->

						<!-- Lista grupoEcon -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Lista Grupo Econômico</label>
								<select id="ListaGrupoEcon" v-bind:class="{ 'is-invalid': grupoEcon === 'true' && errors.name }"
									class="form-control" placeholder="Lista Grupo Econômico" v-model="listaGrupoEcon"
									:disabled="grupoEcon === 'false'" :required="grupoEcon === 'true'">
									<option v-for="(item, key) in listaGrupoEcons" :key="key" :value="item.id">{{
									item.nome_fantasia }}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.listaGrupoEcon">
									<p>{{ errors.listaGrupoEcon[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-5 -->
						<!-- end Lista grupoEcon -->
					</div>

					<div class="row">

						<!-- name -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Nome</label>
								<input type="text" id="name" v-bind:class="{ 'is-invalid': errors.name }" class="form-control"
									placeholder="Nome" v-model="name" required="true">
								<div class="invalid-feedback" v-if="errors.name">
									<p>{{ errors.name[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-6 -->
						<!-- end name -->

						<!-- cpf -->
						<div class="col-3">
							<div class="form-group">
								<label class="form-control-label">CPF</label>
								<input type="text" id="input-cpf" v-bind:class="{ 'is-invalid': errors.cpf }" class="form-control"
									placeholder="CPF" v-model="cpf" v-mask="'###.###.###-##'" maxlength="14">
								<div class="invalid-feedback" v-if="errors.cpf">
									<p>{{ errors.cpf[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end cpf -->

						<!-- email -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">E-mail</label>
								<input type="email" id="email" v-bind:class="{ 'is-invalid': errors.email }" class="form-control"
									placeholder="E-mail" v-model="email" required="true">
								<div class="invalid-feedback" v-if="errors.email">
									<p>{{ errors.email[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end email -->

					</div>
					<div class="row">

						<!-- ddd -->
						<div class="col-1">
							<div class="form-group">
								<label class="form-control-label">DDD</label>
								<input type="tel" id="ddd" v-bind:class="{ 'is-invalid': errors.ddd_profile }" class="form-control"
									placeholder="" v-model="ddd_profile" maxlength="2">
								<div class="invalid-feedback" v-if="errors.ddd_profile">
									<p>{{ errors.ddd_profile[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end ddd -->

						<!-- telefone -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Telefone</label>
								<input type="tel" id="telefone" v-bind:class="{ 'is-invalid': errors.telefone }" class="form-control"
									placeholder="Telefone" v-model="telefone" maxlength="10" v-mask="'#####-####'">
								<div class="invalid-feedback" v-if="errors.telefone">
									<p>{{ errors.telefone[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end telefone -->

						<!-- CEP -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">CEP</label>
								<input type="text" id="input-cep" v-bind:class="{ 'is-invalid': errors.cep }" class="form-control"
									placeholder="CEP" v-model="cep" v-mask="'#####-###'" maxlength="9" @blur="verificarCEP">
								<div class="invalid-feedback" v-if="errors.cep">
									<p>{{ errors.cep[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End CEP -->

						<!-- Endereço -->
						<div class="col-7">
							<div class="form-group">
								<label class="form-control-label">Endereço</label>
								<input type="text" id="endereco" v-bind:class="{ 'is-invalid': errors.endereco }" class="form-control"
									placeholder="Endereço" v-model="endereco">
								<div class="invalid-feedback" v-if="errors.endereco">
									<p>{{ errors.endereco[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End Endereço -->

					</div><!-- row -->

					<div class="row">

						<!-- Bairro -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">Bairro</label>
								<input type="text" id="bairro" v-bind:class="{ 'is-invalid': errors.bairro }" class="form-control"
									placeholder="Bairro" v-model="bairro">
								<div class="invalid-feedback" v-if="errors.bairro">
									<p>{{ errors.bairro[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End Bairro -->

						<!-- Cidade -->
						<div class="col-4">
							<div class="form-group">
								<label class="form-control-label">Cidade</label>
								<input type="text" id="cidade" v-bind:class="{ 'is-invalid': errors.cidade }" class="form-control"
									placeholder="Cidade" v-model="cidade">
								<div class="invalid-feedback" v-if="errors.cidade">
									<p>{{ errors.cidade[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End Cidade -->

						<!-- UF -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">UF</label>
								<input type="text" id="uf" v-bind:class="{ 'is-invalid': errors.uf }" class="form-control"
									placeholder="UF" v-model="uf">
								<div class="invalid-feedback" v-if="errors.uf">
									<p>{{ errors.uf[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End UF -->

						<!-- Número -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Número</label>
								<input type="text" id="numero" v-bind:class="{ 'is-invalid': errors.numero }" class="form-control"
									placeholder="Número" v-model="numero">
								<div class="invalid-feedback" v-if="errors.numero">
									<p>{{ errors.numero[0] }}</p>
								</div>
							</div>
						</div>
						<!-- End Número -->

					</div><!-- row -->

					<div class="row">

						<!-- permission -->
						<div class="col-2">
							<div class="form-group">
								<label class="form-control-label">Grupo de Acesso</label>
								<select v-bind:class="{ 'is-valid': errors.permission }" class="form-control"
									placeholder="Grupo de Acesso" v-model="permission" required="true" :disabled="grupoEcon === 'true'">
									<option v-for="(item, key) in permissions" :key="key" :value="item.id">{{ item.name }}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.permission">
									<p>{{ errors.permission[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end permission -->

						<!-- banco -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Banco</label>
								<select id="banco" v-bind:class="{ 'is-invalid': errors.name }" class="form-control" placeholder="Banco"
									v-model="banco" :disabled="permission === 3 || grupoEcon === 'true'" required="true">
									<option v-for="(item, key) in bancos" :key="key" :value="item.id">{{ item.nome }}
									</option>
								</select>
								<div class="invalid-feedback" v-if="errors.banco">
									<p>{{ errors.banco[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-5 -->
						<!-- end banco -->


						<!-- função -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Função</label>
								<input type="text" id="funcao" v-bind:class="{ 'is-invalid': errors.funcao }" class="form-control"
									placeholder="Função" v-model="funcao" maxlength="50">
								<div class="invalid-feedback" v-if="errors.funcao">
									<p>{{ errors.funcao[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end função -->
					</div>
					<div class="row">

						<!-- Matricula -->
						<div class="col-5">
							<div class="form-group">
								<label class="form-control-label">Matricula</label>
								<input type="text" id="matricula" v-bind:class="{ 'is-invalid': errors.matricula }" class="form-control"
									placeholder="Matricula" v-model="matricula" maxlength="50">
								<div class="invalid-feedback" v-if="errors.matricula">
									<p>{{ errors.matricula[0] }}</p>
								</div>
							</div><!-- form group -->
						</div><!-- col-lg-12 -->
						<!-- end Matricula -->

					</div>
					<div class="row">
						<div class="col-md-12 text-md-right">
							<a @click="newData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="red" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>

import { fieldNames } from '../fieldNames/fieldNames';

export default {
	data() {
		return {
			name: '',
			cpf: '',
			email: '',
			ddd_profile: '',
			telefone: '',
			cep: '',
			endereco: '',
			bairro: '',
			cidade: '',
			uf: '',
			numero: '',
			bancos: [],
			funcao: '',
			matricula: '',
			errors: [],
			message: '',
			permissions: [],
			permission: '',
			banco: '',
			loading: false,
			fullPage: true,
			grupoEcon: '',
			listaGrupoEcons: [],
			listaGrupoEcon: ''
		}
	},
	mounted() {
		this.loadBancos();
		this.loadGrupoEcon();
		this.loadPermissions();
	},
	watch: {
		grupoEcon: function (newValue, oldValue) {
			// Verifique se o valor mudou de 'true' para 'false'
			if (oldValue === 'true' && newValue === 'false') {
				this.listaGrupoEcons = [];
			} else if (oldValue === 'false' && newValue === 'true') {
				this.loadGrupoEcon();
			}
			else if (newValue === 'true') {
				this.permission = ''
				this.banco = ''
			}
		},
		permission: function (newValue, oldValue) {
			if (oldValue === 4 || oldValue === 5 && newValue === 3) {
				this.bancos = [];
			} else if (oldValue === 3 && newValue === 4 || newValue === 5) {
				this.loadBancos();
			}
		}
	},

	methods: {
		loadGrupoEcon() {
			let _this = this;
			_this.loading = true;
			axios.get("/admin/grupoecon").then((res) => {
				this.listaGrupoEcons = res.data;
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		loadBancos() {
			let _this = this;
			_this.loading = true;
			axios.get("/admin/bancos").then((res) => {
				this.bancos = res.data;
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		verificarCEP() {
			let cep = this.cep.replace('-', '');

			axios.get(`/admin/consultacep/${cep}`).then((res) => {

				this.endereco = res.data.logradouro
				this.bairro = res.data.bairro
				this.cidade = res.data.localidade
				this.uf = res.data.uf
			}).catch((err) => {
			})
		},
		loadPermissions() {
			let _this = this;
			_this.loading = true;
			axios.get('/admin/permissions').then((res) => {
				_this.permissions = res.data;
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			})
		},
		newData() {
			let _this = this;
			_this.errors = [];
			_this.message = '';
			_this.loading = true;

			let isgroupecon = 0;
			if (_this.grupoEcon === 'true') {
				isgroupecon = 1;
			}

			let formData = new FormData();
			formData.append('name', _this.name);
			formData.append('email', _this.email)
			formData.append('funcao', _this.funcao)
			formData.append('ddd_profile', _this.ddd_profile)
			formData.append('telefone', _this.telefone)
			formData.append('permission', isgroupecon != 1 ? _this.permission : null)
			formData.append('cpf', _this.cpf)

			formData.append('banco', isgroupecon != 1 ? _this.banco : null)

			formData.append('cep', _this.cep)
			formData.append('endereco', _this.endereco)
			formData.append('bairro', _this.bairro)
			formData.append('cidade', _this.cidade)
			formData.append('uf', _this.uf)
			formData.append('numero', _this.numero)
			formData.append('matricula', _this.matricula)

			formData.append('isgroupecon', isgroupecon)

			let grupoEconId = 0;
			if (_this.listaGrupoEcon) {
				grupoEconId = _this.listaGrupoEcon
			}

			formData.append('grupoEconId', grupoEconId)

			axios.post('/admin/users', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				_this.$swal({
					title: 'Sucesso',
					text: 'Usuário(a) cadastrado com sucesso!',
					icon: 'success'
				}).then(() => {
					this.resetForm();
					location.href = "/admin/users"
				});
			}).catch((err) => {

				_this.loading = false;

				if (err.response.status == 422) {

					this.errors = err.response.data.errors;

					var errorFields = Object.keys(err.response.data.errors).map((key) => {
						// Verifica se o mapeamento existe para o nome da variável
						if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
							return fieldNames.campoObrigatorio[key];
						} else {
							// Caso o mapeamento não exista, retorna o nome da variável original
							return key;
						}
					}).join(', <br> - ');

					_this.$swal({
						title: "Erro",
						html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
						icon: "error"
					});
				} else {
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar novo usuario. Entre em contato com o suporte técnico!",
						icon: "error"
					});
				}
			});
		},
		resetForm() {
			this.name = '';
			this.email = '';
			this.telefone = '';
			this.cpf = '';
			this.funcao = '';
			this.ddd_profile = ''
			this.cep = ''
			this.endereco = ''
			this.bairro = ''
			this.cidade = ''
			this.uf = ''
			this.numero = ''
			this.matricula = ''
			this.message = ''
			this.permission = ''
			this.banco = ''
			this.grupoEcon = ''
			this.listaGrupoEcon = ''
			this.errors = []
		}
	}
}
</script>
