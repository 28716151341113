var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card p-4"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 p-4"},[_c('div',{staticClass:"form-group date-custom"},[_c('datepicker',{staticClass:"btn-filter",attrs:{"language":_vm.calendarLanguage,"placeholder":"Data Inicial"},model:{value:(_vm.dataInicial),callback:function ($$v) {_vm.dataInicial=$$v},expression:"dataInicial"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-6 p-4"},[_c('div',{staticClass:"form-group date-custom"},[_c('datepicker',{staticClass:"btn-filter",attrs:{"language":_vm.calendarLanguage,"placeholder":"Data Final"},model:{value:(_vm.dataFinal),callback:function ($$v) {_vm.dataFinal=$$v},expression:"dataFinal"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('a',{staticClass:"btn btn-danger col-md-5 col-lg-3 col-sm-6  mt-4 btn-filter export-button",staticStyle:{"height":"30px"},attrs:{"href":"#"},on:{"click":function($event){return _vm.carregarLogs()}}},[_vm._v("\n\t\t\t\t\t\t\tFiltrar\n\t\t\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"btn btn-limpar btn-filter col-md-4 col-lg-2 col-sm-5 mt-4 ml-2 ",staticStyle:{"border-radius":"10px","height":"30px"},attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Limpar Filtro"},on:{"click":_vm.limparFiltros}},[_vm._v("\n\t\t\t\t\t\t\t Limpar Filtro "),_c('i',{staticClass:"fas fa-trash fa-lg"})])])]),_vm._v(" "),_c('div',{staticClass:"mx-auto"},[_c('h5',[_vm._v("Período: "+_vm._s(_vm.periodoSelecionado))])]),_vm._v(" "),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.logs,"line-numbers":true,"pagination-options":{
            enabled: true,
            mode: 'records',
            position: 'bottom',
            dropdownAllowAll: true,
            perPageDropdown: [10, 30, 50],
            nextLabel: 'Próximo',
            prevLabel: 'Anterior',
            rowsPerPageLabel: '',
            ofLabel: 'de',
            allLabel: 'Todos',
            pageLabel: '',
            perPageDropdownEnabled: true,
            compactMode: true,            
          },"search-options":{
              enabled: true,
              placeholder: 'Pesquisar',
            },"styleClass":"vgt-table"}})],1),_vm._v(" "),(!_vm.logs.length && !_vm.loading)?_c('div',{staticClass:"text-center p-3 text-muted"},[_c('h5',[_vm._v("Nenhum log encontrado!")])]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"text-center p-4 text-muted"},[_c('h5',[_vm._v("Carregando...")]),_vm._v(" "),_c('p',[_vm._v("Aguarde até que os dados sejam carregados...")])]):_vm._e()]),_vm._v(" "),_c('vue-loading',{attrs:{"color":"#ec2423","active":_vm.loading,"can-cancel":false},on:{"update:active":function($event){_vm.loading=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v("Filtrar Período Desejado: ")])])}]

export { render, staticRenderFns }