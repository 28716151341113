<template>
	<div>

		<!-- WIDGETS -->
		<div class="row widgets">

			<!-- contratos -->
			<div class="col-lg-4 col-md-6 col-sm-6 col-12">
				<div class="card card-statistic-1">
					<div class="card-icon bg-primary">
						<i class="fas fa-copy"></i>
					</div>
					<div class="card-wrap">
						<div class="card-header">
							<h4>Contratos</h4>
						</div>
						<div class="card-body" v-if="contratos.length == 0 && loading">
							<div class="spinner-border spinner-border spinner-border-sm" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
						<div class="card-body" v-else>{{ contratos.length }}</div>
					</div>
				</div>
			</div>
			<!-- end contratos -->

			<!-- valor detran -->
			<div class="col-lg-4 col-md-6 col-sm-6 col-12">
				<div class="card card-statistic-1">
					<div class="card-icon bg-warning">
						<i class="fas fa-road"></i>
					</div>
					<div class="card-wrap">
						<div class="card-header">
							<h4>Valor Detran</h4>
						</div>
						<div class="card-body" v-if="totalValorDetran == 0 && loading">
							<div class="spinner-border spinner-border spinner-border-sm" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
						<div class="card-body" v-else>
							{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValorDetran) }}
						</div>
					</div>
				</div>
			</div>
			<!-- end valor detran -->

			<!-- valor result1 -->
			<div class="col-lg-4 col-md-6 col-sm-6 col-12">
				<div class="card card-statistic-1">
					<div class="card-icon bg-danger">
						<i class="fas fa-dollar-sign"></i>
					</div>
					<div class="card-wrap">
						<div class="card-header">
							<h4>Valor Result1</h4>
						</div>
						<div class="card-body" v-if="totalValorResult == 0 && loading">
							<div class="spinner-border spinner-border spinner-border-sm" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
						<div class="card-body" v-else>
							{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValorResult) }}
						</div>
					</div>
				</div>
			</div>
			<!-- valor result1 -->

		</div>
		<!-- END WIDGETS -->

		<!-- FILTERS -->
		<div class="row filters p-3">
			<div class="card col-12">
				<div class="card-header">

					<!-- estado -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Estados</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Estados</li>
							<li v-if="!filterDetrans.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in filterDetrans" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedDetrans"
										:id="item.id" />
									<label class="form-check-label" :for="item.id">
										{{ item.estado }}
									</label>
								</div>
							</li>
							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!-- end estado -->

					<!-- status -->
					<div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
						<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Status</a>
						<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
							<li class="dropdown-title">Selecione os Status</li>

							<li v-if="!filterStatus.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>

							<!-- detran -->
							<li v-if="filterStatus.length" class="dropdown-title">Detran</li>
							<li v-for="item in filterStatus" :key="item + 'Detran'">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item" v-model="checkedStatusPagamentosDetran"
										:id="item + 'Detran'" />
									<label class="form-check-label" :for="item">
										{{ item ? item : "Indisponível" }}
									</label>
								</div>
							</li>
							<!-- end detran -->

							<li>
								<hr class="ml-4 mr-4">
							</li>

							<!-- result -->
							<li v-if="filterStatus.length" class="dropdown-title">Resul1</li>
							<li v-for="item in filterStatus" :key="item + 'Result1'">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item" v-model="checkedStatusPagamentosResult1"
										:id="item + 'Resul1'" />
									<label class="form-check-label" :for="item">
										{{ item ? item : "Indisponível" }}
									</label>
								</div>
							</li>
							<!-- end result -->

							<div class="text-center mt-2">
								<a href="#" class="btn btn-danger col-md-3 btn-filter text-center" data-toggle="tooltip"
									data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
									<i class="fas fa-filter"></i>
								</a>
							</div>
						</ul>
					</div>
					<!-- end status -->

					<!-- filtro período -->
					<div class="col-md-3 col-lg-3 col-sm-12 btn-filter mt-2">
						<div class="form-gr date-custom">
							<datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial"
								class="btn-filter" />
						</div>
					</div>
					<div class="col-md-3 col-lg-3 col-sm-12 btn-filter mt-2">
						<div class="form-gr date-custom">
							<datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final" class="btn-filter" />
						</div>
					</div>
					<!-- end filtro período -->

					<!-- filter button -->
					<div class="col-md-2 col-lg-2 col-sm-12 text-center">
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
							<i class="fas fa-filter"></i>
						</a>
						<a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip"
							data-placement="top" title="Limpar Filtro" @click="clearFilter">
							<i class="fas fa-times"></i>
						</a>
					</div>
					<!-- end filter button -->

				</div>
			</div>
		</div>
		<!-- END FILTERS -->

		<!-- LIST CONTRACTS -->
		<div class="card">
			<div class="card-body">

				<!-- table -->
				<div class="pb-4" v-if="!loading && contratos.length > 0">
					<div class="export-options" style="z-index: 1 !important;">
						<a href="#" class="btn btn-danger btn-filter export-button" @click="exportDataGrid('pdf', 'Faturamento')">
							<i class="far fa-file-pdf"></i> PDF
						</a>
						<a href="#" class="btn btn-success btn-filter export-button" @click="exportDataGrid('xlsx', 'Faturamento')">
							<i class="far fa-file-excel"></i> EXCEL
						</a>
					</div>
					<vue-good-table :columns="tableColumns" :rows="contratos" :pagination-options="tablePagination" :search-options="{
						enabled: true,
						placeholder: 'Search this table',
					}" :sort-options="{
	enabled: true,
	initialSortBy: { field: 'created_at', type: 'desc' }
}" styleClass="vgt-table">

						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'faturamento_detran'">
								<div>
									{{ props.row.faturamentodetran ? props.row.faturamentodetran.status : "Não Faturado" }}
								</div>
							</span>
							<span v-else-if="props.column.field === 'faturamento_banco'">
								<div>
									{{ props.row.faturamentobanco ? props.row.faturamentobanco.status : "Não Faturado" }}
								</div>
							</span>
							<span v-else-if="props.column.field === 'detran_banco.custo_contrato'">
								<div v-if="props.row.tipo_operacao == 1 || props.row.tipo_operacao == 2">
									{{ formatCurrency(props.row.detran_banco.custo_contrato) }}
								</div>
								<div v-if="props.row.tipo_operacao == 3 || props.row.tipo_operacao == 4">
									{{ formatCurrency(props.row.detran_banco.custo_aditivo) }}
								</div>
								<!-- {{ formatCurrency(props.row.detran_banco.custo_contrato) }} -->
							</span>
							<span v-else-if="props.column.field === 'detran_banco.valor_contrato'">
								{{ formatCurrency(props.row.detran_banco.valor_contrato) }}
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>
				<!-- end table -->

				<div v-if="!contratos.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum contrato encontrado!</h5>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>
		</div>
		<!-- END LIST CONTRACTS -->

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
	props: ['detrans', 'detransBanco'],
	components: {
		Datepicker
	},
	data() {
		return {
			calendarLanguage: ptBR,
			checkedDetrans: [],
			checkedStatusPagamentosDetran: [],
			checkedStatusPagamentosResult1: [],
			contratos: [],
			dataInicial: "",
			dataFinal: "",
			fullPage: true,
			filterDetrans: JSON.parse(this.detrans),
			filterStatus: ['Não Faturado', 'Pago', 'Pendente'],
			loading: false,
			totalValorDetran: 0,
			totalValorResult: 0,
			tableColumns: [
				{
					label: 'Estado',
					field: 'detrans.estado',
					sortable: true
				},
				{
					label: 'Chassi',
					field: 'chassi',
					sortable: true
				},
				{
					label: 'Custo Registro',
					field: 'detran_banco.custo_contrato',
					type: 'decimal',
					sortable: true
				},

				{
					label: 'Valor Registro',
					field: 'detran_banco.valor_contrato',
					type: 'decimal',
					sortable: true
				},
				{
					label: 'Situação',
					field: 'faturamento_detran',
					type: 'string',
					sortable: true
				},
				{
					label: 'Status Result1',
					field: 'faturamento_banco',
					type: 'string',
					sortable: true
				},
				{
					label: 'Data Criação',
					field: 'created_at',
					type: 'date',
					sortable: true,
					dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
				},
				{
					label: 'Data de registro',
					field: 'data_registro',
					sortable: true,
					type: 'date',
					dateInputFormat: "yyyy-MM-dd HH:mm:ss",
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
				}
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true
			}
		}
	},
	mounted() {
		this.loadItems();
	},
	methods: {
		clearFilter() {
			let _this = this;
			_this.checkedDetrans = [];
			_this.checkedStatusPagamentosDetran = [],
				_this.checkedStatusPagamentosResul1 = [],
				_this.dataInicial = "";
			_this.dataFinal = "";
			_this.loadItems();
		},
		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
		},
		formatFilterDate(date) {
			let d = new Date(date);
			let dateString = moment(String(d)).format('YYYY-MM-DD');
			return dateString;
		},

		loadItems() {
			let _this = this;
			_this.contratos = [];
			_this.loading = true;
			axios.get("/api/portal/faturamentodetran")
				.then((res) => {
					_this.contratos = _this.contratos.concat(res.data);
					_this.validaValores();
					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
				});
		},
		validaValores() {
			let _this = this;
			_this.contratos.map((row) => {
				_this.totalValorDetran += row.detran_banco.custo_contrato;
				_this.totalValorResult += row.detran_banco.valor_contrato;
			});
		},
		updateItems() {
			let _this = this;
			_this.loading = true;
			_this.totalValorDetran = 0;
			_this.totalValorResult = 0;

			/* const filters = {
				 search: {
					 detrans:  _this.checkedDetrans,
					 status_pagamento_detran: _this.checkedStatusPagamentosDetran,
					 status_pagamento_result1: _this.checkedStatusPagamentosResul1,
					 data_inicial: _this.dataInicial != "" ? _this.formatFilterDate(_this.dataInicial) : null,
					 data_final: _this.dataFinal != "" ? _this.formatFilterDate(_this.dataFinal) : null
				 }
				 // search: [
				 //   {
				 //     field: "detrans",
				 //     value: _this.checkedDetrans,
				 //   },
				 //   {
				 //     field: "status_pagamento_detran",
				 //     value: _this.checkedStatusPagamentosDetran,
				 //   },
				 //   {
				 //     field: "status_pagamento_result1",
				 //     value: _this.checkedStatusPagamentosResul1,
				 //   },
				 //   {
				 //     field: "data_inicial",
				 //     value: _this.dataInicial != "" ? _this.formatFilterDate(_this.dataInicial) : null,
				 //   },
				 //   {
				 //     field: "data_final",
				 //     value: _this.dataFinal != "" ? _this.formatFilterDate(_this.dataFinal) : null,
				 //   },
				 //],
			 };*/

			axios.
				post("/api/portal/faturamentodetran", {
					search: [
						{
							field: "detrans",
							value: _this.checkedDetrans,
						},
						{
							field: "status_pagamento_detran",
							value: _this.checkedStatusPagamentosDetran,
						},
						{
							field: "data_inicial",
							value: _this.dataInicial !== "" ? _this.formatFilterDate(_this.dataInicial) : null,
						},
						{
							field: "data_final",
							value: _this.dataFinal !== "" ? _this.formatFilterDate(_this.dataFinal) : null,
						}
					]
				})
				.then((res) => {
					_this.contratos = [];
					_this.contratos = _this.contratos.concat(res.data);
					_this.validaValores();
					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
				});
		},
	}
};
</script>
