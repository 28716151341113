<template>
	<div class="card p-4">
		<div class="col">
			<button type="button" class="btn btn-success btn-right btn-sincroniza-modelo"
							@click="syncModelos"><i :class="iconSyncModelo"></i> {{ labelSync }}
			</button>
		</div>
		<div class="row">
			<div class="col">

				<!-- table -->
				<div v-if="!loading && modelos.length > 0">
					<vue-good-table
						:columns="columns"
						:rows="modelos"
						:pagination-options="{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,

						}"
						:search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)">
                                        <i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger"
													@click="deleteData(props.row.id, props.row.index)"><i
										class="far fa-trash-alt"></i></button>

								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>
				<div v-if="!modelos.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum modelo encontrado!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
			</div><!-- col -->
		</div><!-- row -->
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>


		<!--   modal cadastro de modelos     -->
		<div class="modal fade" id="modalNovaModelo" tabindex="-1" aria-labelledby="modalApiReturnsLabel"
				 aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalApiReturnsLabel">Modelos</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetForm">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST" v-on:submit.prevent="save">

							<div class="row">

								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-status_api">Marcas</label>
										<select id="marca" class="form-control w-auto" v-model="modelo.marca_id"
														required="true">
											<option value=""></option>
											<option v-for="m in marcas" :value="m.id" v-bind:key="m.id">{{
													m.nome
												}}
											</option>
										</select>
									</div>
								</div>

								<!-- modelo -->
								<div class="col-md-6 pl-0">
									<div class="form-group">
										<label class="form-control-label" for="input-status_api">Modelo</label>
										<input type="text" id="input-modelo"
													 class="form-control" required="true" autofocus v-model="modelo.nome">
										<div class="invalid-feedback">
											<p></p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-6 -->
							</div>  <!-- modelo -->
							<div class="text-right">
								<button type="submit" class="btn btn-primary">{{ labelSaveEdit }}</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div><!--    modal cadastro de modelos   -->
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- card -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";

export default {
	data() {
		return {

			modelos: [],
			marcas: [],
			labelTitle: "Novo modelo",
			isEditModelo: false,
			loading: false,
			labelSaveEdit: "Salvar",
			modelo: {
				nome: "",
				marca_id: ""
			},
			modeloReturnID: '',
			modeloItens: '',
			iconSyncModelo: "fas fa-sync",
			labelSync: "Sincronizar",

			columns: [
				{
					label: "Marca",
					field: "marcas.nome",
				},
				{
					label: "Modelo",
					field: "nome",
				},
				{
					label: "Criado Em",
					field: 'created_at',
					type: 'date',
					dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
				},
				{
					label: "Ações",
					field: "actions",
					width: '100px',
					html: true,
				},
			],
		}
	},
	mounted() {
		this.loadItems();
		this.loadMarcas();
	},
	methods: {

		showLoadingSyncInit: function () {
			this.iconSyncModelo = "fas fa-sync fa-spin";
			this.labelSync = " Sincronizando...";
		},
		showLoadingSyncEnd: function () {
			this.iconSyncModelo = "fas fa-sync";
			this.labelSync = " Sincronizar";
		},

		syncModelos() {

			this.loading = true;
			this.showLoadingSyncInit()

			axios.get("sincroniza-modelos").then((res) => {
				// handle success
				this.showLoadingSyncEnd();
				this.loading = false;
				this.loadItems();

				//show confirm alert
				this.$swal({
					title: "Sucesso",
					text: "Sincronia realizada com sucesso!",
					icon: "success"
				}).then(function () {

				});
			})
				.catch((err) => {
					// handle error
					this.showLoadingSyncEnd();
					this.loading = false;
					//show error alert
					this.$swal({
						title: "Erro",
						text: "Falha ao sincronizar. Tente novamente mais tarde!",
						icon: "error"
					});

				});
		},

		editData(item) {

			this.modelo = item;
			this.isEditModelo = true;
			this.modeloReturnID = item.id;
			// this.labelTitle = "Editar modelo";
			//this.labelSaveEdit = "Salvar edição"

			$("#modalNovaModelo").modal('show');
		},
		resetForm() {
			this.modelo = {
				nome: '',
			};

			this.isEditModelo = false
			this.modeloReturnID = ""
		},
		save() {
			//validate if is edit
			if (this.isEditModelo == true) {

				//edit
				let _this = this;
				$("#modalNovaModelo").modal("hide");
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				const dataInfo = {
					"modelo": _this.modelo.nome,
					"marca_id": _this.modelo.marca_id,
				};

				axios.patch(`/admin/modelos/${this.modeloReturnID}`, dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();
					_this.loadItems();
					_this.modelo = ""
					_this.modeloReturnID = false;
					_this.isEditModelo = false;

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Modelo atualizada com sucesso!",
						icon: "success"
					}).then(function () {

					});

				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao atualizar Modelo. Tente novamente!",
						icon: "error"
					});
				});
			} //edit
			else {
				//new
				let _this = this;
				$("#modalNovaModelo").modal("hide");
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				const dataInfo = {
					"modelo": _this.modelo.nome,
					"marca_id": _this.modelo.marca_id,
				};

				axios.post("/admin/modelos", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();
					_this.loadItems();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Modelo cadastrada com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar modelo. Tente novamente!",
						icon: "error"
					});
				});

			} //else save
		},

		loadItems() {

			let _this = this;
			_this.loading = true;
			_this.modelos = [];
			axios.get("/admin/modelos").then((res) => {
				_this.modelos = _this.modelos.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		loadMarcas() {

			let _this = this;
			_this.loading = true;
			_this.odelos = [];

			axios.get("/admin/marcas").then((res) => {
				_this.marcas = _this.marcas.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},

		deleteData(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Excluir modelo",
				text: "Tem certeza que deseja excluir essa modelo?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Excluir",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete(`/admin/modelos/${ID}`).then((res) => {

						_this.modelos.splice(index, 1);
						// _this.total = _this.total - 1;
						_this.loadItems();

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Modelo excluída com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao excluir modelo Tente novamente!",
							icon: "error"
						});
					});
				}
			});
			//end alert confirmation*/
		},

		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY");
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
		},
	},
	components: {
		VueGoodTable,
	}
}
</script>
