require('./bootstrap');

window.Vue = require('vue');

//para esconder a mensagem
//You are running Vue in development mode.
Vue.config.productionTip = false
//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MOMENT
Vue.use(require('vue-moment'));

//VUE-GOOD-TABLE
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

//VUE-JS DATEPICKER
import Datepicker from 'vuejs-datepicker';
Vue.use(Datepicker);

//Vue2Dropzone PDF Lote
import vue2Dropzone from 'vue2-dropzone';
Vue.component('vue-dropzone', vue2Dropzone);

//V-SELECT
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

//V-money
import money from 'v-money';
Vue.use(money, { precision: 4 });

//v-mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Test server side paginator
Vue.component('vue-search', require('./components/VueSearchComponent').default);
Vue.component('pagination', require('./components/Paginator'));

//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
import adminBarChartComponent from './components/admin/dashboard/charts/bar';
import adminDoughnutChartComponent from './components/admin/dashboard/charts/doughnut';
Vue.component('adminwidgets-component', adminWidgetsComponent);
Vue.component('adminbarchart-component', adminBarChartComponent);
Vue.component('admindoughnutchart-component', adminDoughnutChartComponent);

//financeiro
import adminFinanceiroFaturamentoComponent from './components/admin/financeiro/faturamento';
Vue.component('financeirofaturamento-component', adminFinanceiroFaturamentoComponent);

//admin - contratos view
import adminContratoViewAC from './components/admin/contratos/view/ac';
// import adminContratoViewAP from './components/admin/contratos/view/ap';
import adminContratoViewPB from './components/admin/contratos/view/pb';
import adminContratoViewSC from './components/admin/contratos/view/sc';
import adminContratoViewSP from './components/admin/contratos/view/sp';
import adminContratoViewMG from './components/admin/contratos/view/mg';
import adminContratoViewBA from './components/admin/contratos/view/ba';
import adminContratoViewPE from './components/admin/contratos/view/pe';
import adminContratoViewES from './components/admin/contratos/view/es';
import adminContratoViewRJ from './components/admin/contratos/view/rj';


Vue.component('admin-contratoview-ac', adminContratoViewAC);
// Vue.component('admin-contratoview-ap', adminContratoViewAP);
Vue.component('admin-contratoview-pb', adminContratoViewPB);
Vue.component('admin-contratoview-sc', adminContratoViewSC);
Vue.component('admin-contratoview-sp', adminContratoViewSP);
Vue.component('admin-contratoview-mg', adminContratoViewMG);
Vue.component('admin-contratoview-ba', adminContratoViewBA);
Vue.component('admin-contratoview-pe', adminContratoViewPE);
Vue.component('admin-contratoview-es', adminContratoViewES);
Vue.component('admin-contratoview-rj', adminContratoViewRJ);

import adminContratoView from './components/admin/contratos/show.vue';
Vue.component('admin-contratoview', adminContratoView);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
import LogApiComponent from './components/admin/logs/api';
import LogFailedComponent from './components/admin/logs/failed';
import LogAuditComponent from './components/admin/logs/audit';
import LogContractAuditComponent from './components/admin/logs/contract_audit';
Vue.component('logaccess-component', LogAccessComponent);
Vue.component('logactivity-component', LogActivityComponent);
Vue.component('logerror-component', LogErrorComponent);
Vue.component('logapi-component', LogApiComponent);
Vue.component('logfailed-component', LogFailedComponent);
Vue.component('logaudit-component', LogAuditComponent);
Vue.component('logcontractaudit-component', LogContractAuditComponent);

//grupoEcon
import grupoEconListComponent from './components/admin/grupoecon/list';
import grupoEconAddComponent from './components/admin/grupoecon/add';
import grupoEconEditComponent from './components/admin/grupoecon/edit';
Vue.component('grupoeconlist-component', grupoEconListComponent);
Vue.component('grupoeconadd-component', grupoEconAddComponent);
Vue.component('grupoeconedit-component', grupoEconEditComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//bancos
import bancosListComponent from './components/admin/bancos/list';
import bancosAddComponent from './components/admin/bancos/add';
import bancosEditComponent from './components/admin/bancos/edit';
Vue.component('bancoslist-component', bancosListComponent);
Vue.component('bancosadd-component', bancosAddComponent);
Vue.component('bancosedit-component', bancosEditComponent);

//detrans
import detransListComponent from './components/admin/detrans/list';
import detransAddComponent from './components/admin/detrans/add';
import detransEditComponent from './components/admin/detrans/edit';
Vue.component('detranslist-component', detransListComponent);
Vue.component('detransadd-component', detransAddComponent);
Vue.component('detransedit-component', detransEditComponent);

//DudasRj
import dudasListComponent from './components/admin/dudasRj/list';
import dudasAddComponent from './components/admin/dudasRj/add';
import dudasEditComponent from './components/admin/dudasRj/edit';
Vue.component('dudaslist-component', dudasListComponent);
Vue.component('dudasadd-component', dudasAddComponent);
Vue.component('dudasedit-component', dudasEditComponent);

//ftpSC
import ftpScListComponent from './components/admin/ftpSc/list'
Vue.component('ftpsclist-component', ftpScListComponent);

//ftpPE
import ftpPeListComponent from './components/admin/ftpPe/list'
Vue.component('ftppelist-component', ftpPeListComponent);

//detran cabecalho api
import detrancabecalhoapisListComponent from './components/admin/detrans/cabecalhoapis/list';
Vue.component('detrancabecalhoapislist-component', detrancabecalhoapisListComponent);

//detran retornos api
import detranretornoapisListComponent from './components/admin/detrans/retornoapis/list';
Vue.component('detranretornoapislist-component', detranretornoapisListComponent);

// financeiro / contratostatus
import contratostatusListComponent from './components/admin/financeiro/contratostatus/list';
Vue.component('contrato-status-list-component', contratostatusListComponent);

//detran banco
import detran_bancoListComponent from './components/admin/detran_banco/list';
import detran_bancoAddComponent from './components/admin/detran_banco/add';
import detran_bancoEditComponent from './components/admin/detran_banco/edit';
Vue.component('detran_bancolist-component', detran_bancoListComponent);
Vue.component('detran_bancoadd-component', detran_bancoAddComponent);
Vue.component('detran_bancoedit-component', detran_bancoEditComponent);

//contratostatus_detranretornoapi
import contratostatus_detranretornoapiListComponent from './components/admin/detrans/contratostatus_detranretornoapi/list';
import contratostatus_detranretornoapiAddComponent from './components/admin/detrans/contratostatus_detranretornoapi/add';
import contratostatus_detranretornoapiEditComponent from './components/admin/detrans/contratostatus_detranretornoapi/edit';
Vue.component('contratostatus_detranretornoapilist-component', contratostatus_detranretornoapiListComponent);
Vue.component('contratostatus_detranretornoapiadd-component', contratostatus_detranretornoapiAddComponent);
Vue.component('contratostatus_detranretornoapiedit-component', contratostatus_detranretornoapiEditComponent);

// relatorios
import faturamentoDetranListComponent from "./components/admin/faturamento_detran/list";
import relatorioContratosListComponent from './components/admin/contratos/list';
import relatoriocontratosViewComponent from './components/admin/contratos/show'
import relatorioContasAPagarComponent from './components/admin/contas_a_pagar/list';
Vue.component('faturamentodetranlist-component', faturamentoDetranListComponent);
Vue.component('relatoriocontratoslist-component', relatorioContratosListComponent);
Vue.component('relatoriocontratosview-component', relatoriocontratosViewComponent);
Vue.component('relatoriocontasapagarlist-component', relatorioContasAPagarComponent);

//municipios
import municipiosListComponent from './components/admin/municipios/list';
import municipiosAddComponent from './components/admin/municipios/add';
import municipiosEditComponent from './components/admin/municipios/edit';
Vue.component('municipiosist-component', municipiosListComponent);
Vue.component('municipiosadd-component', municipiosAddComponent);
Vue.component('municipiosedit-component', municipiosEditComponent);

//permissions
import permissionAddComponent from "./components/admin/permissions/add";
import permissionEditComponent from "./components/admin/permissions/edit";
import permissionListComponent from "./components/admin/permissions/list";
Vue.component('permissions-add-component', permissionAddComponent);
Vue.component('permissions-edit-component', permissionEditComponent);
Vue.component('permissions-list-component', permissionListComponent);

//veiculos
import veiculosListComponent from "./components/admin/veiculos/list";
Vue.component('veiculos-list-component', veiculosListComponent);

//marcas de veiculos
import marcaListComponent from "./components/admin/marcas/list";
Vue.component('marcas-list-component', marcaListComponent);

//Tipos de veiculos [moto, carro, caminhao]
import tipoListComponent from "./components/admin/tipos/list";
Vue.component('tipos-list-component', tipoListComponent);

//Cores de veiculos
import corListComponent from "./components/admin/cores/list";
Vue.component('cores-list-component', corListComponent);

//modelos de veiculos
import modeloListComponent from "./components/admin/modelos/list";
Vue.component('modelo-list-component', modeloListComponent);

/** PORTAL */

//dashboard
import portalWidgetsComponent from './components/portal/dashboard/widgets';
import lineChart from './components/portal/dashboard/line-chart';
Vue.component('portalwidgets-component', portalWidgetsComponent);
Vue.component('line-chart', lineChart);

//profile
import profileBancoComponent from './components/portal/profile';
Vue.component('portalbancoprofile-component', profileBancoComponent);

//contratos
import contratosPortalListComponent from './components/portal/contratos/list';
import contratosPortalAddComponent from './components/portal/contratos/add';
import contratosPortalEditComponent from './components/portal/contratos/edit'
import contratosPortalShowComponent from './components/portal/contratos/show'
import contratosPortalMultiComponent from './components/portal/contratos/multi';
import contratosPortalUploadComponent from './components/portal/contratos/upload';

import contratosPortalConsultaSpComponent from './components/portal/contratos/consulta/index';
import contratosPortalConsultaMgComponent from './components/portal/contratos/consulta/index';

Vue.component('contratosportallist-component', contratosPortalListComponent);
Vue.component('contratosportaladd-component', contratosPortalAddComponent);
Vue.component('contratosportaledit-component', contratosPortalEditComponent);
Vue.component('contratosportalshow-component', contratosPortalShowComponent);
Vue.component('contratosportalmulti-component', contratosPortalMultiComponent);
Vue.component('contratosportalupload-component', contratosPortalUploadComponent);

Vue.component('contratosportalconsultasp-component', contratosPortalConsultaSpComponent);
Vue.component('contratosportalconsultamg-component', contratosPortalConsultaMgComponent);

import contratosPortalConsultaErrorComponent from './components/portal/contratos/consulta/error';
Vue.component('contratosportalconsultaerro-component', contratosPortalConsultaErrorComponent);

//forms add
import contratoFormAddAC from './components/portal/contratos/forms/add/ac';
import contratoFormAddAL from './components/portal/contratos/forms/add/al';
import contratoFormAddAP from './components/portal/contratos/forms/add/ap';
import contratoFormAddCE from './components/portal/contratos/forms/add/ce';
import contratoFormAddBA from './components/portal/contratos/forms/add/ba';
import contratoFormAddES from './components/portal/contratos/forms/add/es';
import contratoFormAddGO from './components/portal/contratos/forms/add/go';
import contratoFormAddMG from './components/portal/contratos/forms/add/mg';
import contratoFormAddMT from './components/portal/contratos/forms/add/mt';
import contratoFormAddMS from './components/portal/contratos/forms/add/ms';
import contratoFormAddPB from './components/portal/contratos/forms/add/pb';
import contratoFormAddPE from './components/portal/contratos/forms/add/pe';
import contratoFormAddSC from './components/portal/contratos/forms/add/sc';
import contratoFormAddSP from './components/portal/contratos/forms/add/sp';
import contratoFormAddRJ from './components/portal/contratos/forms/add/rj';
import contratoFormAddRO from './components/portal/contratos/forms/add/ro';
import contratoFormAddRR from './components/portal/contratos/forms/add/rr';
import contratoFormAddRS from './components/portal/contratos/forms/add/rs';
import contratoFormAddSE from './components/portal/contratos/forms/add/se';


Vue.component('contrato-formaddcomponent-ac', contratoFormAddAC);
Vue.component('contrato-formaddcomponent-al', contratoFormAddAL);
Vue.component('contrato-formaddcomponent-ap', contratoFormAddAP);
Vue.component('contrato-formaddcomponent-ba', contratoFormAddBA);
Vue.component('contrato-formaddcomponent-ce', contratoFormAddCE);
Vue.component('contrato-formaddcomponent-es', contratoFormAddES);
Vue.component('contrato-formaddcomponent-go', contratoFormAddGO);
Vue.component('contrato-formaddcomponent-mg', contratoFormAddMG);
Vue.component('contrato-formaddcomponent-mt', contratoFormAddMT);
Vue.component('contrato-formaddcomponent-ms', contratoFormAddMS);
Vue.component('contrato-formaddcomponent-pb', contratoFormAddPB);
Vue.component('contrato-formaddcomponent-pe', contratoFormAddPE);
Vue.component('contrato-formaddcomponent-sc', contratoFormAddSC);
Vue.component('contrato-formaddcomponent-sp', contratoFormAddSP);
Vue.component('contrato-formaddcomponent-rj', contratoFormAddRJ);
Vue.component('contrato-formaddcomponent-ro', contratoFormAddRO);
Vue.component('contrato-formaddcomponent-rr', contratoFormAddRR);
Vue.component('contrato-formaddcomponent-rs', contratoFormAddRS);
Vue.component('contrato-formaddcomponent-se', contratoFormAddSE);

//forms edit
import contratoFormEditAC from './components/portal/contratos/forms/edit/ac';
import contratoFormEditAL from './components/portal/contratos/forms/edit/al';
import contratoFormEditAP from './components/portal/contratos/forms/edit/ap';
import contratoFormEditBA from './components/portal/contratos/forms/edit/ba';
import contratoFormEditCE from './components/portal/contratos/forms/edit/ce';
import contratoFormEditES from './components/portal/contratos/forms/edit/es';
import contratoFormEditGO from './components/portal/contratos/forms/edit/go';
import contratoFormEditMG from './components/portal/contratos/forms/edit/mg';
import contratoFormEditMT from './components/portal/contratos/forms/edit/mt';
import contratoFormEditMS from './components/portal/contratos/forms/edit/ms';
import contratoFormEditPB from './components/portal/contratos/forms/edit/pb';
import contratoFormEditPE from './components/portal/contratos/forms/edit/pe';
import contratoFormEditSC from './components/portal/contratos/forms/edit/sc';
import contratoFormEditSP from './components/portal/contratos/forms/edit/sp';
import contratoFormEditRJ from './components/portal/contratos/forms/edit/rj';
import contratoFormEditRO from './components/portal/contratos/forms/edit/ro';
import contratoFormEditRR from './components/portal/contratos/forms/edit/rr';
import contratoFormEditRS from './components/portal/contratos/forms/edit/rs';
import contratoFormEditSE from './components/portal/contratos/forms/edit/se';

Vue.component('contrato-formeditcomponent-ac', contratoFormEditAC);
Vue.component('contrato-formeditcomponent-al', contratoFormEditAL);
Vue.component('contrato-formeditcomponent-ap', contratoFormEditAP);
Vue.component('contrato-formeditcomponent-ba', contratoFormEditBA);
Vue.component('contrato-formeditcomponent-ce', contratoFormEditCE);
Vue.component('contrato-formeditcomponent-es', contratoFormEditES);
Vue.component('contrato-formeditcomponent-go', contratoFormEditGO);
Vue.component('contrato-formeditcomponent-mg', contratoFormEditMG);
Vue.component('contrato-formeditcomponent-mt', contratoFormEditMT);
Vue.component('contrato-formeditcomponent-ms', contratoFormEditMS);
Vue.component('contrato-formeditcomponent-pb', contratoFormEditPB);
Vue.component('contrato-formeditcomponent-pe', contratoFormEditPE);
Vue.component('contrato-formeditcomponent-sc', contratoFormEditSC);
Vue.component('contrato-formeditcomponent-sp', contratoFormEditSP);
Vue.component('contrato-formeditcomponent-rj', contratoFormEditRJ);
Vue.component('contrato-formeditcomponent-ro', contratoFormEditRO);
Vue.component('contrato-formeditcomponent-rr', contratoFormEditRR);
Vue.component('contrato-formeditcomponent-rs', contratoFormEditRS);
Vue.component('contrato-formeditcomponent-se', contratoFormEditSE);

//forms multi
import contratoMultiAC from './components/portal/contratos/multi/ac';
import contratoMultiBA from './components/portal/contratos/multi/ba';
import contratoMultiES from './components/portal/contratos/multi/es';
import contratoMultiMG from './components/portal/contratos/multi/mg';
import contratoMultiPB from './components/portal/contratos/multi/pb';
import contratoMultiPE from './components/portal/contratos/multi/pe';
import contratoMultiSC from './components/portal/contratos/multi/sc';
import contratoMultiSP from './components/portal/contratos/multi/sp';

Vue.component('contrato-multicomponent-ac', contratoMultiAC);
Vue.component('contrato-multicomponent-ba', contratoMultiBA);
Vue.component('contrato-multicomponent-es', contratoMultiES);
Vue.component('contrato-multicomponent-mg', contratoMultiMG);
Vue.component('contrato-multicomponent-pb', contratoMultiPB);
Vue.component('contrato-multicomponent-pe', contratoMultiPE);
Vue.component('contrato-multicomponent-sc', contratoMultiSC);
Vue.component('contrato-multicomponent-sp', contratoMultiSP);

import AccessDeniedComponent from './components/portal/acesso/AccessDenied';
Vue.component('access-denied', AccessDeniedComponent);

import UploadImageComponent from './components/portal/contratos/forms/uploadImages/upload';
Vue.component('upload-image', UploadImageComponent);

import DocumentacaoComponent from './components/portal/documentacao/documentacao';
Vue.component('documentacao', DocumentacaoComponent);


import ManuaisComponent from './components/portal/manuais/manual';
Vue.component('manual-utilizacao', ManuaisComponent);

//financeiro
import financeiroFaturamentoDetranListComponenet from "./components/portal/financeiro/faturamento_detran/list";
import financeiroContasAPagarComponenet from "./components/portal/financeiro/contas_a_pagar/list";
import financeiroFaturamentoB3Component from './components/portal/financeiro/faturamento_b3';
import Vue from 'vue';
Vue.component('financeirofaturamentodetranlist-component', financeiroFaturamentoDetranListComponenet);
Vue.component('financeirocontasapagarlist-component', financeiroContasAPagarComponenet);
Vue.component('financeirofaturamentob3-component', financeiroFaturamentoB3Component);

//Gerar  Previsão de Faturamento
import previsaoDeFaturamentoComponent from './components/admin/gerar_previsao_de_faturamento/list';
Vue.component('gerar-previsao-faturamento-component', previsaoDeFaturamentoComponent);


// Log->Importacao
import logimportacaoListComponent from './components/portal/log/importacao/list';
Vue.component('log-importacao-list-component', logimportacaoListComponent);
import logAccessComponent from './components/portal/log/access';
Vue.component('log-access-component', logAccessComponent);
import logAuditComponent from './components/portal/log/audit.vue';
Vue.component('log-audit-component', logAuditComponent);

//GRAVAME

import gravameComponent from './components/portal/gravame/index';
Vue.component('gravame-component', gravameComponent);

import gravameAddComponent from './components/portal/gravame/add';
Vue.component('gravame-component-add', gravameAddComponent);

import gravameEditComponent from './components/portal/gravame/edit';
Vue.component('gravame-component-edit', gravameEditComponent);

import gravameShowComponent from './components/portal/gravame/show';
Vue.component('gravame-component-show', gravameShowComponent);

//RENDER
const app = new Vue({
    el: '#app',
    methods: {
        MakeUrl(path) {
            return BaseUrl(path);
        }
    }
});
