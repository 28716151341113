<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px;">
				<div class="wizard-form" id="grad1">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-1 mb-1 bxShadow">
								<h5 class="text-center"><strong>{{ titleform }}</strong></h5>
								<div class="row">
									<div class="col-md-12 mx-0 mb-1">
										<form id="msform" class="mt-2">										

											<!-- progressbar -->
											<ul id="progressbarRJ" class="text-center" :class="progressbarRJ">
												
												<a @click="changeStep(1);">
													<li :class="step >= 1 ? 'active' : ''" id="arqAnexo"><strong>Imagens Digitalizadas</strong></li>
												</a>
											</ul>
											<!-- end progressbar -->
									
											<!-- arquvios anexo -->
											<fieldset v-if="step == 1">
												<div class="form-card">
													<div class="row form-distance" style="margin-top:-15px">														

											<!-- arquivo contrato -->
														<div class="col-md-12">
															<div class="form-group-contract">
																<div>
																	<h3>Imagens Digitalizadas Anexadas ao Contrato:</h3>
																	<div class="container-arquivos">
                                                                        <div  style="margin-top:10px" v-if="attachedFiles.length === 0">
                                                                            <h6>Nenhuma Imagem Anexada</h6>
                                                                        </div>
																		<div v-else v-for="(file, index) in attachedFiles" :key="index">
																			<div class="card-arquivos-anexo">
																				<div class="file-info">
																					<p class="file-name" :title="file.nome_arquivo">{{ file.nome_arquivo }}</p>																				
																					<p class="file-last-modified">Anexado em: {{ formatarData(file.created_at) }}</p>
																				</div>	
																				<div class="acoes-arquivo">
																					<span class="clear-icon icon-clear-arq" @click="downloadFile(file.id)">
																						<i class="fas fa-regular fa-download"></i>
                                                                                    </span>
																					<span class="clear-icon icon-clear-arq" @click="removeFile(file.id, file.nome_arquivo)">
																						<i class="fas fa-trash"></i>
																					</span>	
																			</div>																																																						</div>
																		</div>
																	</div>
																</div>																
																<br />

																<label class="import-arquivos custom-label" for="attach">
																	Anexe a Imagem Desejada  <i class="fas fa-paperclip" style="margin-left: 10px;"></i>
																</label>
																<input id="attach" ref="attach" accept=".png, .jpg, .jpeg" class="form-control" lang="pt"
																	name="attach" type="file" v-on:change="handleFileImage()" style="display: none;" />																
																<div style="margin-bottom: 15px;">
																	<span class="span-text-tamanho">Tamanho máximo permitido: 4mb</span>
																	<br />
																	<span class="span-text-tamanho">Formatos de arquivos permitidos: JPG, JPEG e PNG.</span>
																</div>														
																													
															</div>
															
														</div>
														<!-- end arquivo contrato -->
													</div>
												</div>												
											</fieldset>
											<!-- end arquivos anexo -->
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div><!-- card -->
		</div><!-- col-12 -->
		<vue-loading color="#ec2434" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


export default {
	props: ["contrato",'permissions'],
	data() {
		return {
            download: '',           
			attachedFiles: [],           
			titleform: "",
            progressbarRJ: "",
			selected_municipio_liberacao_credito: this.getData("municipio_liberacao_credito"),			
			url_estado: "SP",			
			step: 1,
			message: "",
			loading: false,
			fullPage: true,		
			contrato_id: this.getData("id"),
			detran_id: this.getData("detran_id"),
            uf_licenciamento: this.getData("uf_licenciamento"),
            num_contrato_origem: this.getData("num_contrato_origem"),
            chassi: this.getData("chassi"),
            data_contrato: this.formatData(this.getData("data_contrato")),

		}
	},
	mounted() {		
		this.onChangeUf('devedor')		
		this.b64decode(this.arquivo);
        this.loadFilesTabSelected();        
		this.titleform = 'Envie as imagens digitalizadas referente ao contrato Nº ' + this.num_contrato_origem + ' - Chassi: '
        + this.chassi + ' - Data do Contrato: ' + this.data_contrato ;        
	},

	computed: {
       
    },
	watch: {
        
		
	},

	methods: {

		loadFilesTabSelected(){
			this.loading = true;
			this.loadArquivosContratos();
		},

		loadArquivosContratos() {
			axios.get(`/portal/getcontratoarquivos/${this.contrato_id}/${this.detran_id}` ).then(response => {
				this.attachedFiles = response.data;
				this.loading = false;
			})
		},
		

		formatarData(data) {
			return moment(data).format('DD/MM/YYYY - HH:mm');
  		},

        formatData(data) {
			return moment(data).format('DD/MM/YYYY');
  		},

		async downloadFile(id) {
			const base64Data = await this.getFile(id);
			this.startDownload(base64Data);                
		},
		
		async getFile(id) {
			this.loading = true;
            try{
                const response = await axios.get(`/portal/downloadcontratoarquivo/${id}`);
			    return response.data;
            }catch(err){
                _this.loading = false;
                //show error alert
                 _this.$swal({
                    title: "Erro",
                    text: "Falha ao baixar o arquivo. Tente novamente!",
                    icon: "error"
                 });
            }
		},

		startDownload(base64Data) {
			this.loading = false;
			const link = document.createElement('a');
			link.href = base64Data.arquivo;
			link.download = base64Data.nome_arquivo;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},       
    
		removeFile(id, name) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Excluir Arquivo",
                text: `Tem certeza que deseja excluir o arquivo ${name}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Excluir",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                //remove file
				_this.loading = true;
				axios.delete(`/portal/deletecontratoarquivo/${id}`).then((res) => {                        

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Arquivo excluído com sucesso!",
                            icon: "success"
                        });
						_this.loadArquivosContratos();

                    }).catch(error => {

						_this.loading = false;
                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao excluir o arquivo. Tente novamente!",
                            icon: "error"
                        });

                    });

                }//end validate isConfirmed

            });
            //end alert confirmation
        },

		handleFileImage() {
		this.loading = true;
		const input = this.$refs.attach;
		const file = input.files[0];

		const maxSizeInBytes = 4 * 1024 * 1024; // 4MB em bytes - Define o tamanho máximo do Arquivoa ser anexado.
		if (file.size > maxSizeInBytes) {
			this.loading = false;
			this.$swal({
				title: "Erro",
				html: "Arquivo precisa ter no máximo 4MB de tamanho!",
				icon: "error"
			});
			return;
		}


		this.saveFiles(this.contrato_id, this.detran_id, file);

		input.value = '';
		},	
	

		/*funcao que adiciona zeros a esquerda*/
		addLeadingZeros(number) {

			let leadingZeros = "";

			if (number !== 'null' && number !== 'undefined' && number !== "") {

				if (number.length === 1) leadingZeros = '000' + number;
				if (number.length === 2) leadingZeros = '00' + number;
				if (number.length === 3) leadingZeros = '0' + number;

				return leadingZeros ? leadingZeros : number;
			}
			//return number;
		},

		getData(key) {
			return JSON.parse(this.contrato)[key] == null || JSON.parse(this.contrato)[key] == "null" ? "" : JSON.parse(this.contrato)[key];
		},
		
		saveFiles(contrato_id, detran_id, file) {
			let formDataImage = new FormData();

			formDataImage.append("arquivo", file);
			formDataImage.append("contrato_id", contrato_id);
			formDataImage.append("detran_id", detran_id);

			axios.post("/portal/contratoarquivos/", formDataImage, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
				this.$swal({
					title: "Sucesso",
					text: "Imagem digitalizada anexada com sucesso",
					icon: "success"
				})
				this.loadArquivosContratos();
			}).catch((err => {
				this.$swal({
					title: "Erro",
					text: "Falha ao salvar imagem. Tente novamente!",
					icon: "error"
				});
			}));
		},		

		onChangeUf(typeSearch, clickSearch) {
			let _this = this;
			var estado;
			if (typeSearch == 'agente') {
				estado = _this.uf_agente
			} else {
				estado = _this.uf_devedor
			}

			axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
				if (typeSearch == 'agente') {
					_this.municipios_ag = res.data;

					if (clickSearch !== true) {
						//forma mais rapida de mostrar o nome do Municipio Agente
						let codMunicipioAgente = this.getData("nome_municipio_agente").toString();
						codMunicipioAgente = this.addLeadingZeros(codMunicipioAgente);

						_this.municipios_ag.forEach(function (nome, i) {
							if (nome.codigo_origem === codMunicipioAgente) {
								_this.municipio_ag = nome.municipio
							}
						});
					} else {
						_this.municipio_ag = "";
						_this.nome_municipio_agente = "";
					}
				} else {
					_this.municipios_dev = res.data;

					if (clickSearch !== true) {

						//forma mais rapida de mostrar o nome do Municipio Comprador
						let codMunicipioComprador = this.getData("nome_municipio_devedor").toString();
						codMunicipioComprador = this.addLeadingZeros(codMunicipioComprador);

						_this.municipios_dev.forEach(function (nome, i) {
							if (nome.codigo_origem == codMunicipioComprador) {
								_this.municipio_dev = nome.municipio
								_this.municipio_dev_manual = nome.municipio
							}
						});
					} else {
						_this.municipio_dev = "";
						_this.nome_municipio_devedor = "";
					}
				}
			}).catch((err) => {

				_this.$swal({
					title: "Erro",
					text: "Falha ao pesquisar o estao: " + estado + " tente novamente!",
					icon: "error"
				});
			});
		},
				
		b64decode(b64String) {
			if (b64String)
				this.arquivo = atob(b64String.split(',')[1]);
		},		       
		
	},
	componentes: {
		vSelect,
		
	}
}
</script>
