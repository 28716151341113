<template>
  <div class="tab-pane fade" id="tab-api-cabecalho" role="tabpanel" aria-labelledby="api-cabecalho-tab">
    <div class="card mt-4">

      <!-- card header -->
      <div class="card-header pad0">
        Cabeçalhos da API
        <button class="btn btn-primary btn-right" data-toggle="modal" data-target="#modalApiHeaders">NOVO</button>
      </div>
      <!-- end card header -->

      <!-- card body -->
      <div class="card-body pad0">

        <!-- table -->
				<div v-if="!loading && detrancabecalhoapis.length > 0">
					<vue-good-table
						:columns="columns"
						:rows="detrancabecalhoapis"
						:pagination-options="{
							enabled: true,
							mode: 'pages',
							position: 'bottom',
							dropdownAllowAll: true,
							perPageDropdown: [10, 30, 50],
							perPageDropdownEnabled: false,
							nextLabel: 'Próximo',
							prevLabel: 'Anterior',
							rowsPerPageLabel: '',
							ofLabel: 'de',
							allLabel: 'Todos',
							pageLabel: '',
							perPageDropdownEnabled: true,
							compactMode: true,
						}"
						>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
							<div class="text-center">
								<button class="btn btn-sm btn-outline-secondary" @click="editApiHeader(props.row)"><i class="far fa-edit"></i></button>
							</div>
							</span>
							<span v-else>
							{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>

				<div v-if="!detrancabecalhoapis.length" class="text-center p-3 text-muted">
					<h5>Nenhum cabeçalho da api encontrado!</h5>
				</div>
				<!-- end table -->

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

      </div>

    </div>

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

    <!-- modal  -->
    <div class="modal fade" id="modalApiHeaders" tabindex="-1" aria-labelledby="modalApiHeadersLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalApiHeadersLabel">{{labelTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form method="POST" v-on:submit.prevent="saveApiHeader">

              <div class="row">
                	<!-- nome -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-nome">Nome</label>
											<input type="text" id="input-nome" v-bind:class="{'is-invalid': errors.nome}" class="form-control" placeholder="Nome" v-model="apiheader.nome" required="true">
											<div class="invalid-feedback" v-if="errors.nome">
												<p>{{ errors.nome }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end nome -->

									<!-- tipo -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-tipo">Tipo</label>
											<input type="text" id="input-tipo" v-bind:class="{'is-invalid': errors.tipo}" class="form-control" placeholder="Tipo" v-model="apiheader.tipo" required="true">
											<div class="invalid-feedback" v-if="errors.tipo">
												<p>{{ errors.tipo }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end tipo -->

									<!-- valor -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-valor">Valor</label>
											<input type="text" id="input-valor" v-bind:class="{'is-invalid': errors.valor}" class="form-control" placeholder="Valor" v-model="apiheader.valor" required="true">
											<div class="invalid-feedback" v-if="errors.valor">
												<p>{{ errors.valor }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end valor -->
              </div>

              <div class="text-right">
                <button type="submit" class="btn btn-primary">SALVAR</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->

  </div>
</template>

<script>
	import "vue-good-table/dist/vue-good-table.css";
	import { VueGoodTable } from "vue-good-table";

  export default {
    props: ['detrans'],
    data() {
			const detrans = JSON.parse(this.detrans)
      return {
				detrancabecalhoapis: [],
        fullPage: true,
        isEditApiHeader: false,
				columns: [
					{
						label: "Nome",
						field: "nome",
					},
					{
						label: "Tipo",
						field: "tipo",
					},
					{
						label: "Valor",
						field: "valor",
					},
					{
						label: "Ultima edição",
						field: "updated_at",
						formatFn: this.formatDate,
					},
					{
						label: "Criado Em",
						field: "created_at",
						formatFn: this.formatDate,
					},
					{
						label: "Ações",
						field: "actions",
						html: true,
					}
				],
				errors: [],
				detranID: detrans.id,
        apiheader: {
          nome: "",
					tipo: "",
					valor: ""
        },
        apiheaderID: '',
        labelTitle: 'Novo Cabeçalho da API',
        loading: false,
        message: ''
      }
    },
    mounted() {
      this.loadItems();
    },
    methods: {
      editApiHeader(item){
        this.apiheader = item;
        this.isEditApiHeader = true;
        this.apiheaderID = item.id;
        this.labelTitle = "Editar Cabeçalho da API";

        $("#modalApiHeaders").modal('show');
      },
      loadItems() {
				let _this = this;
				_this.detrancabecalhoapis = [];
				_this.loading = true;
				axios.get("/admin/detrancabecalhoapis/"+this.detranID).then((res) => {
					_this.detrancabecalhoapis = _this.detrancabecalhoapis.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
					console.error(err)
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Cabeçalho da API",
					text: "Tem certeza que deseja excluir esse cabeçalho da api?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					if (alertConfirm.isConfirmed) {

						axios.delete("/admin/detrancabecalhoapis/"+ID).then((res) => {

							_this.detrancabecalhoapis.splice(index, 1);
							_this.total = _this.total - 1;

							_this.$swal({
								title: "Sucesso",
								text: "Cabeçalho da API excluído com sucesso!",
								icon: "success"
							});

						}).catch(error => {

							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir o cabeçalho da api. Tente novamente!",
								icon: "error"
							});

						});

					} else {

					}

				});
			},
      resetForm(){
        this.apiheader = {
          nome: '',
          tipo: '',
          valor: ''
        };
      },
      saveApiHeader(){
				let _this = this;
				_this.errors = [];
				_this.loading = true;

        if (this.isEditApiHeader == true){
					let dataInfo = {
						"nome" : _this.apiheader.nome,
						"tipo" : _this.apiheader.tipo,
						"valor" : _this.apiheader.valor,
						"detran_id": this.detranID,
						"_method": "PATCH"
					};
					axios.post("/admin/detrancabecalhoapis/"+this.apiheaderID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();
						_this.loadItems();
						_this.isEditApiHeader = false;
						_this.apiheaderID = "";
						$("#modalApiHeaders").modal("hide");

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Cabeçalho da API atualizado com sucesso!",
							icon: "success"
						});
					}).catch((err) => {

						_this.loading = false;
						if (err.response.status == 422) {
							this.errors = err.response.data.errors;
					  }

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao cadastrar cabeçalho da api. Tente novamente!",
							icon: "error"
						});
					});

        } else {

					let dataInfo = {
						"nome" : _this.apiheader.nome,
						"tipo" : _this.apiheader.tipo,
						"valor" : _this.apiheader.valor,
						"detran_id": this.detranID,
						"last": ""
					};
					axios.post("/admin/detrancabecalhoapis", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();
						_this.loadItems();
						$("#modalApiHeaders").modal("hide");

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Cabeçalho da Api cadastrado com sucesso!",
							icon: "success"
						});
					}).catch((err) => {

						_this.loading = false;

						if (err.response.status == 422) {
							this.errors = err.response.data.errors;
					  }

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao cadastrar cabeçalho da api. Tente novamente!",
							icon: "error"
						});
					});

        }
        //end validate if is edit
      },
			formatDate: function (value) {
				return moment(String(value)).format("DD/MM/YYYY H:m:s");
			}
    }
  }
</script>
