<template>
    <div class="row">
        <div class="col-12">
            <div class="card">

                <!-- card header -->
                <div class="card-header">
                    <h4>Preencha os dados abaixo</h4>
                    <a @click="newData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
                </div>
                <!-- end card header -->

                <!-- card body -->
                <div class="card-body">

                    <!-- form -->
                    <h6 class="heading-small text-muted mb-4">Dados</h6>
                    <div class="">
                        <div class="row">

                            <!-- razao -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-razao-social">Razão Social</label>
                                    <input type="text" id="input-razao-social" autofocus
                                        v-bind:class="{ 'is-invalid': errors.razao }" class="form-control"
                                        placeholder="Razão Social" v-model="razao" required="true" maxlength="100">
                                    <div class="invalid-feedback" v-if="errors.razao">
                                        <p>{{ errors.razao[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-md-6 -->
                            <!-- end razao -->

                            <!-- nome_fantasia -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-nome_fantasia">Nome fantasia</label>
                                    <input type="text" id="input-nome_fantasia" autofocus
                                        v-bind:class="{ 'is-invalid': errors.nome_fantasia }" class="form-control"
                                        placeholder="Nome fantasia" v-model="nome_fantasia" required="true"
                                        maxlength="25">
                                    <div class="invalid-feedback" v-if="errors.nome_fantasia">
                                        <p>{{ errors.nome_fantasia[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-md-6 -->
                            <!-- end nome_fantasia -->

                            <!-- tipo -->
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-tipo-grupoEcon">Tipo de
                                        Grupo</label>
                                    <select id="input-tipo-grupoEcon" v-bind:class="{ 'is-invalid': errors.tipo }"
                                        v-model="tipo" class="form-control" placeholder="Tipo de Instituição">
                                        <option value="horizontal">Horizontal</option>
                                        <option value="vertical">Vertical</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.tipo">
                                        <p>{{ errors.tipo[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-md-3 -->
                            <!-- end tipo -->

                            <div class="col-6 pt-4">
                                <label for="instituicao" class="form-control-label">Selecione a Instituição</label>
                                <v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições"
                                    label="label" @input="setSelectInstituicao" :options="bancoOptions"
                                    v-model="instituicao">
                                    <template #tags="{ values, search, isOpen, removeTag }">
                                        <div class="vue-select__tags">
                                            <span v-for="option in values" :key="option.id"
                                                class="vue-select__tags-item" @click.stop="removeTag(option)">
                                                {{ option.label }}
                                            </span>
                                        </div>
                                    </template>
                                </v-select>
                            </div>

                        </div>
                    </div>

                    <h6 class="heading-small text-muted mb-4">Acesso</h6>
                    <div class="">
                        <div class="row">

                            <!-- nome  Usuario-->
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-control-label">Nome do Usuário</label>
                                    <input type="text" id="nome_usuario"
                                        v-bind:class="{ 'is-invalid': errors.nome_usuario }" class="form-control"
                                        placeholder="Nome do usuário" v-model="nome_usuario">
                                    <div class="invalid-feedback" v-if="errors.nome_usuario">
                                        <p>{{ errors.nome_usuario[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-lg-6 -->
                            <!-- nome  Usuario-->

                            <!-- email -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-email">E-mail</label>
                                    <input type="email" id="input-email" v-bind:class="{ 'is-invalid': errors.email }"
                                        class="form-control" placeholder="E-mail" v-model="email" required="true">
                                    <div class="invalid-feedback" v-if="errors.email">
                                        <p>{{ errors.email[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-md-6 -->
                            <!-- end email -->

                            <!-- password -->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-password">Senha</label>
                                    <div class="input-group mb-3">
                                        <input :type="inputType" id="input-password"
                                            v-bind:class="{ 'is-invalid': errors.password }" class="form-control"
                                            placeholder="Senha" v-model="password" required="true">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-outline-secondary" type="button"
                                                @click="changeInputView()"><i :class="inputIcon"></i></button>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.password">
                                            <p>{{ errors.email[0] }}</p>
                                        </div>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-md-6 -->
                            <!-- end password -->

                            <!-- cpf -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-control-label">CPF</label>
                                    <input type="text" id="cpf" v-bind:class="{ 'is-invalid': errors.cpf }"
                                        class="form-control" placeholder="CPF" v-model="cpf" maxlength="14"
                                        v-mask="'###.###.###-##'">
                                    <div class="invalid-feedback" v-if="errors.cpf">
                                        <p>{{ errors.cpf[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-lg-12 -->
                            <!-- end cpf -->

                            <!-- função -->
                            <div class="col-6">
                                <div class="form-group">
                                    <label class="form-control-label">Função</label>
                                    <input type="text" id="funcao" v-bind:class="{ 'is-invalid': errors.funcao }"
                                        class="form-control" placeholder="Função" v-model="funcao" maxlength="50">
                                    <div class="invalid-feedback" v-if="errors.funcao">
                                        <p>{{ errors.funcao[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-lg-12 -->
                            <!-- end função -->

                            <!-- ddd -->
                            <div class="col-3">
                                <div class="form-group">
                                    <label class="form-control-label">DDD</label>
                                    <input type="text" id="ddd" v-bind:class="{ 'is-invalid': errors.ddd_profile }"
                                        class="form-control" placeholder="ddd" v-model="ddd_profile" maxlength="3">
                                    <div class="invalid-feedback" v-if="errors.ddd_profile">
                                        <p>{{ errors.ddd_profile[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-lg-12 -->
                            <!-- end ddd -->

                            <!-- telefone -->
                            <div class="col-9">
                                <div class="form-group">
                                    <label class="form-control-label">Telefone</label>
                                    <input type="text" id="telefone" v-bind:class="{ 'is-invalid': errors.telefone }"
                                        class="form-control" placeholder="Telefone" v-model="telefone" maxlength="9">
                                    <div class="invalid-feedback" v-if="errors.telefone">
                                        <p>{{ errors.telefone[0] }}</p>
                                    </div>
                                </div><!-- form group -->
                            </div><!-- col-lg-12 -->
                            <!-- end telefone -->

                        </div>
                    </div>
                    <!-- end form -->

                </div><!-- card body -->
            </div><!-- card -->
        </div><!-- col-12 -->

        <vue-loading color="red" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
    </div><!-- row -->
</template>

<script>
import VMask from 'v-mask';
import { fieldNames } from '../../portal/contratos/forms/fieldNames/fieldNames';

export default {
    data() {
        return {
            bancoOptions: [],
            instituicao: [],
            inputIcon: 'fas fa-eye',
            inputType: 'password',
            razao: "",
            nome_usuario: "",
            nome_fantasia: "",
            cpf: "",
            funcao: "",
            ddd_profile: "",
            telefone: "",
            email: "",
            password: "",
            errors: [],
            message: "",
            tipo: "",
            loading: false,
            fullPage: true
        }
    },
    mounted() {
        this.getAllBancos();
    },
    methods: {
        setSelectInstituicao(item) {
            this.instituicao = item.map(banco => banco);
        },
        getAllBancos() {
            axios.get("/api/portal/getAllBancos").then(res => {
                this.bancos = res.data.bancos;
                this.bancoOptions = this.bancos.map(element => ({
                    label: element.nome,
                    id: element.id,
                    selected: false
                }));
                this.bancos = this.sortVector(this.bancoOptions.map(banco => banco.label));
            }).catch((err) => {
                console.error(err);
                this.loading = false;
            });
        },
        newData() {
            let _this = this;
            _this.errors = [];
            _this.message = "";
            _this.loading = true;

            let formData = new FormData();

            for (let i = 0; i < this.instituicao.length; i++) {
                formData.append(`id_banco[${i}]`, this.instituicao[i].id);
            }

            formData.append('razao_social', _this.razao)
            formData.append('nome_fantasia', _this.nome_fantasia)
            formData.append('nome_usuario', _this.nome_usuario)
            formData.append('cpf', _this.cpf)
            formData.append('funcao', _this.funcao)
            formData.append('ddd_profile', this.ddd_profile)
            formData.append('telefone', _this.telefone)
            formData.append('email', _this.email)
            formData.append('password', _this.password)
            formData.append('tipo', _this.tipo)

            axios.post("/admin/grupoecon", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((res) => {

                    _this.loading = false;

                    _this.$swal({
                        title: "Sucesso",
                        text: "Grupo Econômico cadastrado com sucesso!",
                        icon: "success"
                    });

                }).catch((err) => {
                    _this.loading = false;

                    if (err.response.status == 422) {

                        this.errors = err.response.data.errors;

                        var errorFields = Object.keys(err.response.data.errors).map((key) => {

                            if (fieldNames.campoObrigatorio.hasOwnProperty(key)) {
                                return fieldNames.campoObrigatorio[key];
                            } else {
                                return key;
                            }
                        }).join(', <br> - ');

                        _this.$swal({
                            title: "Erro",
                            html: `O(s) seguinte(s) campo(s) está(ão) preenchido(s) incorretamente:<br> - ${errorFields}`,
                            icon: "error"
                        });
                    } else {

                        if (err.response.status == 400) {

                            _this.$swal({
                                title: "Erro",
                                html: err.response.data.errors,
                                icon: "error"
                            });
                        } else {
                            //show error alert
                            _this.$swal({
                                title: "Erro",
                                text: "Falha ao cadastrar o contrato. Tente novamente!",
                                icon: "error"
                            });
                        }
                    }
                });
        },
        changeInputView() {
            if (this.inputType == 'password') {
                this.inputType = "text";
                this.inputIcon = "fas fa-eye-slash";
            } else {
                this.inputType = "password";
                this.inputIcon = "fas fa-eye";
            }
        },
        resetForm() {
            this.razao = '';
            this.nome_usuario = '';
            this.cpf = '';
            this.funcao = '';
            this.ddd_profile = '';
            this.telefone = '';
            this.email = '';
            this.password = '';
            this.tipo = '';
            this.nome_fantasia = "";
        }
    },
}
</script>
