<template>
  <div class="row">
    <div class="col-12">
      <!-- dashboard start -->
      <div class="row">
        <!-- contratos widget -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-primary">
              <i class="fas fa-copy"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Contratos</h4>
              </div>
              <div class="card-body" v-if="qtdContrato == -1">
                <div
                  class="spinner-border spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>{{ 1 }}</div>
            </div>
          </div>
        </div>
        <!-- end contratos widget -->

        <!-- valor b3 widget -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-warning">
              <i class="fas fa-road"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Valor B3</h4>
              </div>
              <div class="card-body" v-if="custoContrato == -1">
                <div
                  class="spinner-border spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>
                {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0) }}
              </div>
            </div>
          </div>
        </div>
        <!-- end valor detran widget -->

        <!-- valor result1 widget -->
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card card-statistic-1">
            <div class="card-icon bg-danger">
              <i class="fas fa-dollar-sign"></i>
            </div>
            <div class="card-wrap">
              <div class="card-header">
                <h4>Valor Result1</h4>
              </div>
              <div class="card-body" v-if="valorContrato == -1">
                <div
                  class="spinner-border spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="card-body" v-else>
                {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(0) }}
              </div>
            </div>
          </div>
        </div>
        <!-- valor result1 widget -->
      </div>
      <!-- dashboard end -->

      <!-- filter start -->
      <div class="card col-12">
        <div class="card-header">
          <div class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2">
            <a
              href="#"
              data-toggle="dropdown"
              class="btn btn-light dropdown-toggle"
              >Estados</a
            >
            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
              <li class="dropdown-title">Selecione os Estados</li>
              <li v-if="!detrans.length">
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in detrans" :key="item.id">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item.id"
                    v-model="checkedDetrans"
                    :id="item.id"
                  />
                  <label class="form-check-label" :for="item.id">
                    {{
                      item.estado ? item.estado : item.nome ? item.nome : "Erro"
                    }}
                  </label>
                </div>
              </li>
              <div class="text-center">
              <a href="#" class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter" data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
                <i class="fas fa-filter"></i>
              </a>
              </div>
            </ul>
          </div>

          <div
            class="card-header-action dropdown btn-filter col-md-2 col-lg-2 col-sm-12 mt-2"
          >
            <a
              href="#"
              data-toggle="dropdown"
              class="btn btn-light dropdown-toggle"
              >Status</a
            >
            <ul
              class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter"
            >
            <li class="dropdown-title">Selecione os Status</li>
              <li class="dropdown-title">B3</li>
              <li v-if="!statusPagamento.length">
                <div class="text-center">
                  <div
                    class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </li>
              <li v-for="item in statusPagamento" :key="item + 'Detran'">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    v-model="checkedStatusPagamentosDetran"
                    :id="item + 'Detran'"
                  />
                  <label class="form-check-label" :for="item">
                    {{ item ? item : "Indisponível" }}
                  </label>
                </div>
              </li>
              <li><hr class="ml-4 mr-4"></li>
              <li class="dropdown-title">Resul1</li>
              <li v-for="item in statusPagamento" :key="item +'Result1'">
                <div class="form-check custom-checkbox custom-control ml-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    v-model="checkedStatusPagamentosResul1"
                    :id="item + 'Resul1'"
                  />
                  <label class="form-check-label" :for="item">
                    {{ item ? item : "Indisponível" }}
                  </label>
                </div>
              </li>
              <div class="text-center">
                <a
                  href="#"
                  class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filtrar"
                  id="inicial"
                  @click="updateItems"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </ul>
          </div>

          <!-- filtro período -->
          <div class="col-md-3 col-lg-3 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
                <datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial" class="btn-filter"/>
            </div>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 btn-filter mt-2">
            <div class="form-gr date-custom">
                <datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final" class="btn-filter"/>
            </div>
          </div>
          <!-- end filtro período -->

          <!-- filter button -->
          <div class="col-md-2 col-lg-2 col-sm-12 text-center">
              <a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial" @click="updateItems">
                <i class="fas fa-filter"></i>
              </a>
              <a href="#" class="btn btn-danger col-md-5 col-lg-5 col-sm-12 mt-2 btn-filter" data-toggle="tooltip" data-placement="top" title="Limpar Filtro" @click="limparFiltros">
                <i class="fas fa-times"></i>
              </a>
          </div>
          <!-- end filter button -->
        </div>
      </div>
      <!-- filter end -->


      <div class="card">
          <!-- card body -->
          <div class="card-body">
            <!-- table -->
            <div class="pb-4" v-if="!loading && contratos.length > 0">
                <div class="export-options">
                    <a
                    href="#"
                    class="btn btn-danger btn-filter export-button"
                    @click="exportDataGrid('pdf', 'Faturamento')"
                    >
                        <i class="far fa-file-pdf"></i> PDF
                    </a>
                    <a
                    href="#"
                    class="btn btn-success btn-filter export-button"
                    @click="exportDataGrid('xlsx', 'Faturamento')"
                    >
                        <i class="far fa-file-excel"></i> EXCEL
                    </a>
                </div>
              <vue-good-table
                :columns="columns"
                :rows="contratos"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  position: 'bottom',
                  dropdownAllowAll: true,
                  perPageDropdown: [10, 30, 50],
                  nextLabel: 'Próximo',
                  prevLabel: 'Anterior',
                  rowsPerPageLabel: '',
                  ofLabel: 'de',
                  allLabel: 'Todos',
                  pageLabel: '',
                  perPageDropdownEnabled: true,
                  compactMode: true,

                }"
                :search-options="{
                    enabled: true,
                    placeholder: 'Search this table',
                }"
                  styleClass="vgt-table">
                <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'faturamento_detran'">
                  <div>
                    {{ props.row.faturamentodetran ?  props.row.faturamentodetran.status :"Não Faturado" }}
                  </div>
                </span>
                <span v-else-if="props.column.field == 'faturamento_banco'">
                  <div>
                    {{ props.row.faturamentobanco ?  props.row.faturamentobanco.status :"Não Faturado" }}
                  </div>
                </span>
                <span v-else-if="props.column.field == 'custo_contrato'">
                  {{ formatCurrency(props.row.bancos.detrans[0].custo_contrato) }}
                </span>
                <span v-else-if="props.column.field == 'valor_contrato'">
                  {{ formatCurrency(props.row.bancos.detrans[0].valor_contrato) }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              </vue-good-table>
            </div>
            <div
              v-if="!contratos.length && !loading"
              class="text-center p-3 text-muted"
            >
              <h5>Nenhum contrato encontrado!</h5>
            </div>
            <!-- end table -->
            <div class="text-center p-4 text-muted" v-if="loading">
              <h5>Carregando...</h5>
              <p>Aguarde até que os dados sejam carregados...</p>
            </div>
            <!-- end table -->
          </div>
          <!-- end card body -->
        </div>
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
      </div>
    </div>
</template>
<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

export default {
  data() {
    return {
      contratos: [],
      detrans: [],
      bancos: [],
      statusPagamento: ['Não Faturado', 'Pago', 'Pendente'],
      checkedDetrans: [],
      checkedBancos: [],
      checkedStatusPagamentosDetran: [],
      checkedStatusPagamentosResul1: [],
      custoContrato: -1,
      valorContrato: -1,
      qtdContrato: -1,
      dataInicial: "",
      dataFinal: "",
      loading: false,
      calendarLanguage: ptBR,
      columns: [
        {
          label: 'Estado',
          field: 'detrans.estado',
          sortable: false
        },
        {
          label: 'Nº Gravame',
          field: 'num_gravame',
          sortable: false
        },
        {
          label: 'Custo Registro',
          field: 'custo_contrato',
          sortable: false
        },
        {
          label: 'Valor Registro',
          field: 'valor_contrato',
          sortable: false
        },
        {
          label: 'Status B3',
          field: 'faturamento_detran',
          sortable: false
        },
        {
          label: 'Status Result1',
          field: 'faturamento_banco',
          sortable: false
        },
        {
          label: 'Data Criação',
          field: 'created_at',
          sortable: false,
          formatFn: this.formatDate
        },
      ]
    };
  },
  async mounted() {
    this.loadItems();
    this.loadFilters();
    this.loadDashboardData();
  },
  methods: {
    loadItems() {
      let _this = this;
      _this.loading = true;
      axios
        .get("/api/portal/faturamentodetran")
        .then((res) => {
          res.data.map((row) => {
            if (row.b3 == 'true'){
              _this.contratos.push(row)
            }
          })
          //_this.contratos = res.data;
          _this.loading = false;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    updateItems() {
      let _this = this;
      _this.loading = true;
      _this.custoContrato = 0;
      _this.valorContrato = 0;
      _this.qtdContrato = 0;
      let filters = {
        search: [
          {
            field: "detrans",
            value: _this.checkedDetrans,
          },
          {
            field: "status_pagamento_detran",
            value: _this.checkedStatusPagamentosDetran,
          },
          {
            field: "status_pagamento_result1",
            value: _this.checkedStatusPagamentosResul1,
          },
          {
            field: "data_inicial",
            value: _this.dataInicial != "" ? _this.formatFilterDate(_this.dataInicial) : null,
          },
          {
            field: "data_final",
            value: _this.dataFinal != "" ? _this.formatFilterDate(_this.dataFinal) : null,
          },
        ],
      };
      _this.loadDashboardData(filters);
      axios
        .post("/api/portal/faturamentodetran", {
          search: filters.search
        })
        .then((res) => {
          _this.contratos = res.data;
          _this.loading = false;
        })
        .catch((err) => {
          _this.loading = false;
        });
    },
    loadFilters() {
      let _this = this;
      _this.loadDetrans();
    },
    loadDetrans() {
      let _this = this;
      axios
        .get("/api/portal/detrans")
        .then((res) => {
          _this.detrans = res.data;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    loadDashboardData(filters) {
      let _this = this;
      axios
        .post("/api/portal/dashboarddata", filters ? filters : { search: [] })
        .then((res) => {
          _this.custoContrato = res.data.custo_contrato;
          _this.valorContrato = res.data.valor_contrato;
          _this.qtdContrato = res.data.qtd_contrato;
        });
    },
    limparFiltros() {
      let _this = this;
      _this.checkedDetrans = [];
      _this.checkedStatusPagamentosDetran = [],
      _this.checkedStatusPagamentosResul1 = [],
      _this.dataInicial = "";
      _this.dataFinal = "";
      _this.updateItems();
    },
    formatCurrency: function(value){
      return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
    },
    formatDate: function(value){
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
    },
    formatFilterDate(date){
      let d = new Date(date);
      let dateString = moment(String(d)).format('YYYY-MM-DD');
      return dateString;
    },
    exportDataGrid(type, tableName){
        let params = type == 'pdf' ?
            {
                type: 'pdf',
                fileName: tableName,
                htmlContent: false,
                onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
                jspdf: {
                    orientation: 'l',
                    margins: {right: 10, left: 10, top: 40, bottom: 40},
                    autotable: {
                        styles: {
                            fillColor: 'inherit',
                            textColor: 'inherit',
                            halign: 'center',
                            fillStyle: 'DF',
                            lineColor: 200,
                            lineWidth: 0.1},
                            headerStyles: {halign: 'left'},
                            tableWidth: 'auto'
                    }
                        }
            } :
            {
                type: 'excel',
                htmlContent: false,
                fileName: tableName,
                mso:  {fileFormat: 'xlsx' }
            };

        let options = { tableName: tableName };

        jQuery.extend(true, options, params);

        $('#vgt-table').tableExport(options);
    },
  },
  components: {
    VueGoodTable,
    Datepicker
  },
};
</script>
