<template>
    <section class="section dashboard">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-warning">
                        <i class="fas fa-money-check-alt"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Instituições Financeiras</h4>
                        </div>
                        <div class="card-body">
                            <div class="spinner-border spinner-border spinner-border-sm" role="status" v-if="loading">
                            </div>
                            <span v-else>{{ bancos }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="card card-statistic-1">
                    <div class="card-icon bg-warning">
                        <i class="fas fa-road"></i>
                    </div>
                    <div class="card-wrap">
                        <div class="card-header">
                            <h4>Detrans</h4>
                        </div>
                        <div class="card-body">
                            <div class="spinner-border spinner-border spinner-border-sm" role="status" v-if="loading">
                            </div>
                            <span v-else>{{ detrans }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="card card-statistic">
                    <div class="card-stats">
                        <div class="card-stats-title">
                            Contratos
                            <v-select multiple placeholder="Selecione o Detran" label="label"
                             @change="setSelectedState" :options="estadoOptions" v-model="detransSelecionadoUf" >
                                <template #tags="{ values, search, isOpen, removeTag }">
                                    <div class="vue-select__tags">
                                    <span v-for="option in values" :key="option.id" class="vue-select__tags-item"
                                        @click.stop="removeTag(option)">
                                        {{ option.label }}
                                    </span>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                        <div class="card-stats-items">
                            <div class="card-stats-item">
                                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count"
                                    role="status" v-if="loading"></div>
                                <div class="card-stats-item-count" v-else>{{ total }}</div>
                                <div class="card-stats-item-label">Total</div>
                            </div>
                            <div class="card-stats-item">
                                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count"
                                    role="status" v-if="loading"></div>
                                <div class="card-stats-item-count" v-else>{{ concluido }}</div>
                                <div class="card-stats-item-label">Concluído</div>
                            </div>
                            <div class="card-stats-item">
                                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count"
                                    role="status" v-if="loading"></div>
                                <div class="card-stats-item-count" v-else>{{ processando }}</div>
                                <div class="card-stats-item-label">Processando</div>
                            </div>
                            <div class="card-stats-item">
                                <div class="spinner-border spinner-border spinner-border-sm card-stats-item-count"
                                    role="status" v-if="loading"></div>
                                <div class="card-stats-item-count" v-else>{{ pendente }}</div>
                                <div class="card-stats-item-label">Pendentes</div>
                            </div>
                        </div>
                        <div class="card-wrap height-5"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- charts -->
        <div class="row">
            <div class="col-md-8">

                <!-- contas a pagar -->
                <div class="card mt-2 text-primary">

                    <div class="card-header p-4">
                        Total de Contratos emitidos em {{ year }}
                    </div>

                    <div class="card-body" v-if="contratosAno.length > 0">
                        <adminbarchart-component :chartdata="barChart.chartData" :options="barChart.chartOptions" />
                    </div>
                    <div class="card-body p-4 text-center" v-else>
                        <div class="spinner-border spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <!-- end contas a pagar -->

            </div>

            <div class="col-md-4">

                <div class="card mt-2">
                    <div class="card-header text-primary">
                        Contas a Pagar e Receber
                    </div>

                    <div class="card-body p-4"
                        v-if="valor_total_fechamento_detran > 0 && valor_total_fechamento_result1 > 0">
                        <admindoughnutchart-component :chartdata="doughnutChart.chartData"
                            :options="doughnutChart.chartOptions" />
                    </div>
                    <div class="card-body p-4 text-center" v-else>
                        <div class="spinner-border spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>

                <div class="card mt-2 p-4 bg-primary text-center" style="font-size: 22px;">
                    <b>SALDO<br>
                        {{ saldo }}</b>
                </div>

            </div>
        </div>
        <!-- end charts -->
    </section>
</template>
<script>
import { watch } from 'vue';

export default {
    data() {
        return {
            bancos: [],
            detrans: [],
            detranFilter: '',
            fechamentos:[],
            fechamentosDetran:[],
            fechamentosResult1:[],
            contratosAno: [],
            total: 0,
            concluido: 0,
            processando: 0,
            pendente: 0,
            loading: false,
            valor_total_fechamento_detran: 0,
            valor_total_fechamento_result1: 0,
            barChart: '',
            doughnutChart: '',
            year: moment().format('YYYY'),
            estadoOptions: [],
            checkedDetrans: [],
            detransSelecionado: [],
            detransSelecionadoUf: [],
            saldo:0
        }
    },
    mounted() {
        this.loadDashboardData();
        this.loadItems();
        this.loadChartData();
        this.loadChart();
        this.loadStates();
    },
    watch: {
            detransSelecionadoUf(newVal) {
            if(newVal !== undefined) {
                this.setSelectedStateId(newVal);
            }
        }
    },
    methods: {
        setSelectedState(item) {
			this.detransSelecionadoUf = item.map(state => state);
		},
        setSelectedStateId(item) {
			this.detransSelecionado = item.map(state => state.id);
            this.loadDashboardData()
		},
        loadDashboardData() {
            if(this.detransSelecionado[0] === undefined) {
                this.detransSelecionado = 'all'
            }

            this.loadTotalContratos()
            this.loadConcluidoContratos()
            this.loadProcessandoContratos()
            this.loadPendenteContratos()
        },
        loadTotalContratos() {
            this.loading = true;
            let url = `/api/total-contratos/admin/0/${this.detransSelecionado}/${new Date().getFullYear()}-01-01`

            axios.get(url)
                .then(res => {
                    this.loading = false;
                    this.total = res.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        loadConcluidoContratos() {
            this.loading = true;
            let url = `/api/total-contratos-concluido/admin/0/${this.detransSelecionado}/${new Date().getFullYear()}-01-01`

            axios.get(url)
                .then(res => {
                    this.loading = false;
                    this.concluido = res.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        loadProcessandoContratos() {
            this.loading = true;
            let url = `/api/total-contratos-processando/admin/0/${this.detransSelecionado}/${new Date().getFullYear()}-01-01`

            axios.get(url)
                .then(res => {
                    this.loading = false;
                    this.processando = res.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        loadPendenteContratos() {
            this.loading = true;
            let url = `/api/total-contratos-pendente/admin/0/${this.detransSelecionado}/${new Date().getFullYear()}-01-01`

            axios.get(url)
                .then(res => {
                    this.loading = false;
                    this.pendente = res.data;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        loadStates() {
            this.loading = true;
            axios.get("/api/portal/getAllDetrans")
            .then(res => {
                this.loading = false;
				let detrans = res.data.detrans;
				this.estadoOptions = detrans.map(element => ({
					label: element.estado,
					id: element.id,
					selected: false
				}));
			}).catch((err)=>{
                this.loading = false;
                console.error(err);

            });

        },
        loadItems() {
            let _this = this;
            _this.loading = true;
            axios.get("/admin/dashboard/search?detran=" + _this.detranFilter)
            .then((res) => {
                _this.bancos = res.data.bancos;
                _this.detrans = res.data.detrans;
                _this.loading = false;
            }).catch((err) => {
                _this.loading = false;
            });
        },
        loadChartData() {
            let _this = this;
            _this.loading = true;

            let formValues = ["mensal", _this.year + "-01-01", _this.year + "-12-31"];

            axios.get("/api/admin/dashboard/grafico/todos/" + formValues + "/all").then((res) => {

                var dados = res.data.dados.split(",");
                //var dados = '2023';
                _this.contratosAno = dados;

                _this.barChart = {
                    chartData: {
                        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
                        datasets: [{
                            label: "Contratos por mês",
                            backgroundColor: ["#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434", "#ec2434"],
                            data: dados
                        }]
                    },
                    chartOptions: {
                        responsive: true,
                        maintainAspectRatio: false
                    }
                };

            }).catch((err) => {
                _this.loading = false;
            });
        },
        loadChart() {
            let _this = this;
            _this.loading = true;
            axios
                .get("/api/portal/contasapagar")
                .then((res) => {
                    _this.fechamentos = res.data;
                    _this.loading = false;

                    _this.fechamentos.forEach((item) => {

                        if (item.tipo == "Detran") {

                            _this.valor_total_fechamento_detran = item.valor_fechamento;

                        } else {
                            _this.valor_total_fechamento_result1 = item.valor_fechamento;
                        }

                        _this.doughnutChart = {
                            chartData: {
                                hoverBackgroundColor: "red",
                                hoverBorderWidth: 10,
                                labels: ["Contas a Pagar", "Contas a Receber"],
                                datasets: [{
                                    label: "Valor",
                                    backgroundColor: ["#E46651", "#ffa426"],
                                    data: [this.valor_total_fechamento_detran, this.valor_total_fechamento_result1]
                                }]
                            },
                            chartOptions: {
                                hoverBorderWidth: 20
                            },
                        };

                    });

                    this.saldo = this.formatCurrency(this.valor_total_fechamento_result1 - this.valor_total_fechamento_detran)

                })
                .catch((err) => console.error(err));
        },
        formatCurrency: function (value) {
            return Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(value);
        }
    }
}
</script>
