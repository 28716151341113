<template>
    <div class="documentacao">
        <h2>Manual de Utilização</h2>
        <div class="text-center">
            <div class="row mt-5 justify-content-center">
                <div class="d-flex align-items-center">
                    <h4 class="mb-0">Manual de Utilização Sistema Result1 (PDF)</h4>
                    <a @click="downloadManual" class="btn-download-pdf ml-5">Download
                        <i class="far fa-file-pdf"></i>
                    </a>
                </div>
            </div>

            <div class="video-section">
                <h4>Veja o vídeo de utilização do portal:</h4>
                <div class="video-container mx-auto mt-3">
                    <iframe src="https://www.youtube.com/embed/jxVrg1qdAHI?si=rxOSzPCbn0Lf5ty6"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: 'documentacao',
            itemsDocumentacao: [],
            loading: false,
        }
    },

    mounted() {
        this.getListaDocumentacao();
    },

    methods: {

        downloadManual() {
            // Caminho do arquivo dentro da pasta public
            const filePath = '/documents/Manual_Sistema_Result1.pdf'; // Caminho relativo na URL pública

            // Cria um elemento de link temporário
            const link = document.createElement('a');
            link.href = filePath;
            link.download = 'Manual_Sistema_Result1.pdf';
            link.target = '_blank';

            // Dispara o clique no link temporário
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },


}
</script>

<style scoped>
.video-section {
    margin-top: 45px;
    /* Adiciona um espaço de 30px acima */
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 740px;
    /* Limita a largura máxima */
    margin: 0 auto;
    /* Centraliza o vídeo */
}

.video-container::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    /* Proporção 16:9 */
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
