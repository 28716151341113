<template>
	<div class="card p-4">
		<div class="row">
			<div class="col">
				<!-- table -->
				<div v-if="!loading && detrans.length > 0">
				<vue-good-table
				:columns="columns"
				:rows="detrans"
				:pagination-options="{
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true,
				}"
				:search-options="{
					enabled: true,
					placeholder: 'Search this table',
				}"
				styleClass="vgt-table"
				>
				<template slot="table-row" slot-scope="props">
					<span v-if="props.column.field == 'actions'">
					<div class="text-center">
						<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i class="far fa-edit"></i></button>
						<button class="btn btn-sm btn-outline-danger" @click="deleteData(props.row.id, props.row.index)"><i class="far fa-trash-alt"></i></button>
					</div>
					</span>
					<span v-else>
					{{ props.formattedRow[props.column.field] }}
					</span>
				</template>
				</vue-good-table>
				</div>
				<div v-if="!detrans.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum detran encontrado!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
			</div><!-- col -->
		</div><!-- row -->
		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div>
</template>

<script>
	import "vue-good-table/dist/vue-good-table.css";
	import { VueGoodTable } from "vue-good-table";

	export default {
		data() {
			return {
				detrans: [],
				loading: false,
				columns: [
					{
					label: "Estado",
					field: "estado",
					},
					{
					label: "API URL",
					field: "api_url_prod",
					},
					{
					label: "Criado Em",
					field: "created_at",
					formatFn: this.formatDate,
					},
					{
					label: "Ações",
					field: "actions",
					html: true,
					},
				],
			}
		},
		mounted() {
			this.loadItems();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.detrans = [],
				_this.loading = true;
				axios.get("/admin/detrans").then((res) => {
					_this.detrans = _this.detrans.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				_this.$swal({
					title: "Excluir detrans",
					text: "Tem certeza que deseja excluir esse detran?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					if (alertConfirm.isConfirmed) {
						axios.delete("/admin/detrans/"+ID).then((res) => {
							_this.$swal({
								title: "Sucesso",
								text: "Detran excluído com sucesso!",
								icon: "success"
							}).then(() => {
								_this.loadItems();
							})
						}).catch(error => {
							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir o detran. Tente novamente!",
								icon: "error"
							});

						});
					}
				});
			},
			editData(ID){
				location.href="/admin/detrans/"+ID+"/edit";
			},
			formatDate: function (value) {
				return moment(String(value)).format("DD/MM/YYYY H:m:s");
			},
		},
		components: {
    		VueGoodTable,
		}
	}
</script>
