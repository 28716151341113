<template>
    <div class="row">
        <div class="col">
            <!-- tabela -->
            <div class="card p-4">
                <div>
                    <h5>Filtrar Período Desejado: </h5>
                </div>
                <div class="row">
                    <div class="col-md-2 p-2">
                        <div class="form-group date-custom">
                            <datepicker :language="calendarLanguage" v-model="dataInicial" placeholder="Data Inicial"
                                class="btn-filter w-100" />
                        </div>
                    </div>
                    <div class="col-md-2 p-2">
                        <div class="form-group date-custom">
                            <datepicker :language="calendarLanguage" v-model="dataFinal" placeholder="Data Final"
                                class="btn-filter w-100" />
                        </div>
                    </div>
                    <div class="col-md-2 p-2">
                        <div class="dropdown btn-filter w-100">
                            <button data-toggle="dropdown" class="btn btn-light dropdown-toggle w-100">Tipo de
                                Data</button>
                            <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
                                <li class="dropdown-title">Selecione o Tipo de Data desejada:</li>
                                <li v-if="!tipo_periodo.length">
                                    <div class="text-center">
                                        <div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
                                            role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </li>
                                <li v-for="item in tipo_periodo" :key="item.id">
                                    <div class="form-check custom-checkbox custom-control ml-3">
                                        <input class="form-check-input" type="radio" :value="item.id"
                                            v-model="selectedPeriodo" :id="'periodo_' + item.id" />
                                        <label class="form-check-label" :for="'periodo_' + item.id">
                                            {{ item.nome ? item.nome : "Erro" }}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2 p-2">
                        <a href="#" class="btn btn-danger w-100 mt-2 btn-filter export-button" @click="carregarDudas()">
                            Filtrar
                        </a>
                    </div>
                    <div class="col-md-2 p-2">
                        <button class="btn btn-limpar btn-filter w-100 mt-2" style="border-radius: 15px;"
                            data-toggle="tooltip" data-placement="top" title="Limpar Filtro" @click="limparFiltros">
                            Limpar Filtro <i class="fas fa-trash fa-lg"></i>
                        </button>
                    </div>
                </div>

                <div class="mx-auto">
                    <h5>Período: {{ periodoSelecionado }}</h5>
                </div>

                <vue-good-table :columns="colunas" :rows="dudas" :pagination-options="opcoesPaginacao"
                    :search-options="opcoesBusca" @on-search="handleSearch" styleClass="vgt-table">
                    <template slot="table-row" slot-scope="props">

                        <span v-if="props.column.field == 'actions'">
                            <div class="text-center" v-if="!props.row.contrato_id">
                                <button class="btn btn-sm btn-outline-secondary" @click="editData(props.row.id)"><i
                                        class="far fa-edit"></i></button>
                            </div>
                        </span>

                    </template>
                </vue-good-table>
            </div>
            <div v-if="!dudas.length && !loading" class="text-center p-3 text-muted">
                <h5>Nenhuma duda encontrada!</h5>
            </div>
            <!-- fim da tabela -->
            <div class="text-center p-4 text-muted" v-if="loading">
                <h5>Carregando...</h5>
                <p>Aguarde até que os dados sejam carregados...</p>
            </div>
        </div><!-- col -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- row -->
</template>
<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
    data() {
        return {
            dudas: [],
            loading: false,
            dataInicial: "",
            dataFinal: "",
            periodoSelecionado: 'Últimos 30 dias',
            calendarLanguage: ptBR,
            tipoData: "created_at",
            tipo_periodo: [
                { id: 1, nome: 'data criação' },
                { id: 2, nome: 'data utilização' },
                { id: 3, nome: 'data emissão' },
                { id: 4, nome: 'data vencimento' }
            ],
            selectedPeriodo: null,
            colunas: [
                {
                    label: "Nº Duda",
                    field: "num_duda",
                },
                {
                    label: "R$ Duda",
                    field: "valor_duda",
                    formatFn: this.formatCurrency
                },
                {
                    label: "Contrato Id",
                    field: "contrato_id",
                },
                {
                    label: "Nº Chassi",
                    field: "chassi",
                },
                {
                    label: "Nº Contrato",
                    field: "num_contrato",
                },
                {
                    label: "Dt Emissão",
                    field: "data_emissao",
                    formatFn: this.formatarData,
                },
                {
                    label: "Dt Vencimento",
                    field: "data_vencimento",
                    formatFn: this.formatarData,
                },
                {
                    label: "Dt Utilização",
                    field: "data_utilizacao",
                    formatFn: this.formatarDataHr,
                },
                {
                    label: "Criado Em",
                    field: "created_at",
                    formatFn: this.formatarDataHr,
                },
                {
                    label: "Ações",
                    field: "actions",
                    html: true,
                },
            ],
            descricaoErro: false,
            opcoesPaginacao: {
                enabled: true,
                mode: "records",
                position: "bottom",
                dropdownAllowAll: true,
                perPageDropdown: [10, 30, 50],
                nextLabel: "Próximo",
                prevLabel: "Anterior",
                rowsPerPageLabel: "",
                ofLabel: "de",
                allLabel: "Todos",
                pageLabel: "",
                perPageDropdownEnabled: true,
                compactMode: true,
            },
            opcoesBusca: {
                enabled: true,
                placeholder: "Pesquisar",
            },
            pesquisa: ''
        };
    },
    mounted() {
        this.carregarDudas();
    },
    methods: {
        handleSearch(searchTerm) {

            this.pesquisa = searchTerm['searchTerm']
            if (this.pesquisa != '') {
                this.carregarDudas();
            }
        },
        carregarDudas() {
            const dtInicial = this.dataInicial ? moment(this.dataInicial).format("YYYY-MM-DD") : "";
            const dtFinal = this.dataFinal ? moment(this.dataFinal).format("YYYY-MM-DD") : "";

            this.atualizarLegenda();

            this.loading = true;
            const params = {
                dataInicial: dtInicial,
                dataFinal: dtFinal,
                tipoData: this.selectedPeriodo,
                termo: this.pesquisa
            };

            axios.post("/admin/getAllDudas", params)
                .then((res) => {
                    this.dudas = res.data;
                })
                .catch((err) => {
                    this.dudas = [];
                })
                .finally(() => {
                    this.loading = false;
                });

        },
        atualizarLegenda() {
            if (this.dataInicial && this.dataFinal) {
                this.periodoSelecionado = `${moment(this.dataInicial).format("DD/MM/YYYY")} - ${moment(this.dataFinal).format("DD/MM/YYYY")}`;
            } else {
                this.periodoSelecionado = 'Últimos 30 dias';
            }
        },
        limparFiltros() {
            let _this = this;
            _this.dataInicial = "";
            _this.dataFinal = "";
            _this.periodoSelecionado = 'Últimos 30 dias';

            this.carregarDudas('sig')
        },
        formatarDataHr(valor) {
            if (valor) {
                return moment(String(valor)).format("DD/MM/YYYY HH:mm:ss");
            }
        },
        formatarData(valor) {
            if (valor) {
                return moment(String(valor)).format("DD/MM/YYYY");
            }
        },
        formatCurrency(value) {
            return `R$ ${parseFloat(value).toFixed(2)}`;
        },
        editData(ID) {
            location.href = `/admin/duda/edit/${ID}`;
        },
    },
    components: {
        VueGoodTable,
        Datepicker,
    },
};
</script>
