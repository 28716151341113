<template>
  <div>

      <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="rows"
              :globalSearch="true"
              :isLoading="false"
              :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  placeholder: 'Digite para buscar',
               }"
              @on-search="onSearch"
              styleClass="vgt-table"

      >

          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === '#'">
             {{ (users.per_page * (users.current_page - 1)) + (props.index + 1) }}
            </span>

          <span v-if="props.column.field == 'type'">
                  <div v-if="props.row.b3 == 'true'">
                    B3
                  </div>
                  <div v-else>
                    Detran
                  </div>
          </span>
            <span v-if="props.column.field == 'visualizar'">
                  <div class="dropdown col-md-4 col-lg-4 col-sm-6 p-0">
                    <button class="btn btn-outline-primary btn-filter dropdown-toggle" @click="resized()"
                            style="position: absolute;z-index: 999;" type="button" id="dropdownActions" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="true" title="Botão de ações">
                      <span>Ações</span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownActions">
                      <a class="dropdown-item" @click="resendContract(props.row)" title="Reenviar Contrato">
                        <i class="fas fa-sync"></i>
                        <span>Re-enviar contrato</span>
                      </a>
                      <a class="dropdown-item" title="Visualizar Contrato"
                         @click="viewContract(props.row.id, props.row.detrans.estado)">
                        <i class="fas fa-eye fa-2x"></i>
                        <span>Visualizar contrato</span>
                      </a>
                      <a class="dropdown-item" title="Editar contrato"
                         @click="editContract(props.row.id, props.row.detrans.estado)">
                        <i class="fas fa-edit"></i>
                        <span>Editar Contrato</span>
                      </a>
                      <a class="dropdown-item" @click="uploadNovoPdf(props.row.id, props.row.detrans.estado)"
                         title="Upload Anexo">
                        <i class="fas fa-file-pdf"></i>
                        <span>Upload Anexo</span>
                      </a>
                      <hr v-if="props.row.detrans.estado == 'MG'" />
                      <a class="dropdown-item" @click="searchContract(props.row.id, 'MG')"
                         title="Consultar Situação Gravame e Contrato" v-if="props.row.detrans.estado == 'MG'">
                        <i class="fas fa-search"></i>
                        <span>Consultar Situação</span>
                      </a>
                      <a class="dropdown-item" @click="changeStatus(props.row, 'MG', 13)"
                         title="Cancelar Registro de Contrato"
                         v-if="props.row.detrans.estado == 'MG' && props.row.contrato_status_id == 1">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar Registro</span>
                      </a>
                      <a class="dropdown-item" @click="changeStatus(props.row, 'MG', 14)"
                         title="Cancelar Registro de Contrato"
                         v-if="props.row.detrans.estado == 'MG' && props.row.contrato_status_id == 1">
                        <i class="fas fa-ban"></i>
                        <span>Baixa de Contrato</span>
                      </a>
                      <hr v-if="props.row.detrans.estado == 'BA'" />
                      <a class="dropdown-item" @click="cancelContract(props.row, 'BA')" title="Cancelar Contrato"
                         v-if="props.row.detrans.estado == 'BA'">
                        <i class="fas fa-ban"></i>
                        <span>Cancelar contrato</span>
                      </a>
                      <a class="dropdown-item" @click="searchContract(props.row.id, 'BA')" title="Procurar Contrato"
                         v-if="props.row.detrans.estado == 'BA'">
                        <i class="fas fa-search"></i>
                        <span>Procurar contrato</span>
                      </a>
                      <hr />
                      <a class="dropdown-item" title="Gerar espelho do contrato"
                         @click="gerarCertidao(props.row.chassi, props.row.id, props.row.detrans.estado)">
                        <i class="fas fa-file-pdf danger"></i>
                        <span>Gerar Espelho</span>
                      </a>
                      <a class="dropdown-item" title="Gerar espelho do contrato"
                         @click="gerarCertidaoResumida(props.row.chassi, props.row.id, props.row.detrans.estado)">
                        <i class="fas fa-file-pdf danger"></i>
                        <span>Gerar Espelho Resumido</span>
                      </a>
                    </ul>
                  </div>

                </span>
            <span v-else-if="props.column.field == 'custo_contrato'">
                  {{ formatCurrency(props.row.bancos.detrans[0].custo_contrato) }}
                </span>
            <span v-else-if="props.column.field == 'valor_contrato'">
                  {{ formatCurrency(props.row.bancos.detrans[0].valor_contrato) }}
                </span>
            <span v-if="props.column.field === 'arquivo'">
                  <span v-if="props.row.arquivo !== null && props.row.arquivo !== ''"
                        title="Registro de contrato com arquivo">
                    <i class="fa fa-file-pdf fa-1x" style="color:red"></i>
                  </span>
                  <span v-else title="Registro de contrato sem aquivo enviado">
                    <i class="fa fa-file-pdf fa-1x"></i>
                  </span>
                </span>

            <div v-else-if="props.column.field == 'contrato_status'" class="badge badge-light" @click="verDetalheStatus(props.row)">
              <a href="javascript:void(0)">
                <i class="fas fa-eye fa-2x"></i>
                <span>&nbsp;&nbsp;</span>
                <!-- <div v-for="item in contratoStatus" :key="item.id">
                  <div v-if="props.row.contrato_status_id == item.id">
                    {{item.nome}}
                  </div>
                </div> -->
                {{  props.row.contrato_status.nome }}
              </a>
            </div>

            <span v-else @click="resizedM()">
                {{props.formattedRow[props.column.field]}}
            </span>
          </template>
      </vue-good-table>

      <vue-pagination :pagination="users"
                      @paginate="getRecords()"
                      @search="osmair()"
                      :offset="4">
      </vue-pagination>
  </div>
</template>

<script>
    import Vue from 'vue';
    import VueGoodTable from 'vue-good-table';
    import VuePagination from './Paginator';
    // import the styles
    import 'vue-good-table/dist/vue-good-table.css'

    Vue.use(VueGoodTable);
    export default {
        props: ['endpoint'],
        data() {
            return {
                users: {
                    searchTerm: '',
                    total: 0,
                    per_page: 5,
                    from: 1,
                    to: 0,
                    current_page: 1
                },
                offset: 4,
                columns: [
                  {
                    label: '#',
                    field: '#'
                  },
                  {
                    label: "Tipo",
                    field: "type",
                    sortable: false,
                    filterable: true
                  },
                  {
                    label: "Nº Contrato",
                    field: "num_contrato",
                    filterable: true
                  },
                  {
                    label: "Chassi",
                    field: "chassi",
                    filterable: true
                  },
                  {
                    label: "Estado",
                    field: "detrans.estado",
                    filterable: true
                  },
                  {
                    label: "Nº Gravame",
                    field: "num_gravame",
                    filterable: true
                  },
                  {
                    label: "Status",
                    field: "contrato_status",
                    filterable: true
                  },
                  {
                    label: 'Created At',
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
                    filterable: true
                  },
                  {
                    label: " ",
                    field: "arquivo",
                    tdClass: 'text-center',
                    sortable: true,
                    filterable: true
                  },
                  {
                    label: "Ações",
                    field: "visualizar",
                    tdClass: 'text-center',
                    sortable: false,
                    html: true,
                  },

                ],
                rows: []
            }
        },
        components: {
            VuePagination,
        },

        mounted() {
            this.getRecords()
        },
        methods: {

            getRecords() {
                return axios.get(`${this.endpoint}?searchTerm=${this.users.searchTerm}&page=${this.users.current_page}&per_page=${this.users.per_page}`).then((response) => {
                    this.rows = response.data.users.data
                    this.users = response.data.users
                })
            },
            updateParams(newProps) {
                this.users = Object.assign({}, this.users, newProps);
            },
            onSearch: _.debounce(function (params) {
                this.updateParams(params);
                this.getRecords();
                return false;
            }, 500)
        }
    }
</script>
