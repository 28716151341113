<template>
  <div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
    <div class="card mt-4">
      <div class="row">
        <div class="col-12">
          <div class="card">

            <!-- card header -->
            <div class="card-header">
              <h4>Preencha os dados abaixo</h4>

              <a @click="updateData" class="btn btn-sm btn-primary text-white btn-right">SALVAR</a>
            </div>
            <!-- end card header -->

            <!-- card body -->
            <div class="card-body">

              <!-- form -->
              <h6 class="heading-small text-muted mb-4">Dados</h6>
              <div class="row">

                <!-- estado -->
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="form-control-label" for="input-estado">Estado</label>
                    <input id="input-estado" class="form-control" v-model="estado" required disabled>
                    <div class="invalid-feedback" v-if="errors.estado">
                      <p>{{ errors.estado }}</p>
                    </div>
                  </div><!-- form group -->
                </div><!-- col-md-2 -->
                <!-- end estado -->

                <!-- model -->
                <div class="col-md-10">
                  <div class="form-group">
                    <label class="form-control-label" for="input-model">Model</label>
                    <input type="text" id="input-model" class="form-control" v-model="model" required>
										<span>*Atenção!! Cuidado ao alterar este campo, a alteração poderá afetar outros sistemas.</span>
                    <div class="invalid-feedback" v-if="errors.model">
                      <p>{{ errors.model }}</p>
                    </div>
                  </div><!-- form group -->
                </div><!-- col-md-10 -->
                <!-- end model -->

                <!-- api envolope homolog -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-api_url_envelop_homolog">API Envelope Homologação</label>
											<input type="text" id="input-api_url_envelop_homolog" v-bind:class="{'is-invalid': errors.api_url_envelop_homolog}" class="form-control" placeholder="API Envelope Homologação" v-model="api_url_envelop_homolog" required="true">
											<div class="invalid-feedback" v-if="errors.api_url_envelop_homolog">
												<p>{{ errors.api_url_envelop_homolog }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end api envelope homolog -->

									<!-- api url homolog -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-api_url_homolog">API Homologação</label>
											<input type="text" id="input-api_url_homolog" v-bind:class="{'is-invalid': errors.api_url_homolog}" class="form-control" placeholder="API Homologação" v-model="api_url_homolog" required="true">
											<div class="invalid-feedback" v-if="errors.api_url_homolog">
												<p>{{ errors.api_url_homolog }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end api url homolog -->

									<!-- api envolope prod -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-api_url_envelop_prod">API Envelope Produção</label>
											<input type="text" id="input-api_url_envelop_prod" v-bind:class="{'is-invalid': errors.api_url_envelop_prod}" class="form-control" placeholder="API Envelope Produção" v-model="api_url_envelop_prod" required="true">
											<div class="invalid-feedback" v-if="errors.api_url_envelop_prod">
												<p>{{ errors.api_url_envelop_prod }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end api envelope prod -->

									<!-- api url prod -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-api_url_prod">API Produção</label>
											<input type="text" id="input-api_url_prod" v-bind:class="{'is-invalid': errors.api_url_prod}" class="form-control" placeholder="API Produção" v-model="api_url_prod" required="true">
											<div class="invalid-feedback" v-if="errors.api_url_prod">
												<p>{{ errors.api_url_prod }}</p>
											</div>
										</div><!-- form group -->
									</div><!-- col-md-6 -->
									<!-- end api url prod -->

              </div><!-- row -->
              <!-- end form -->

            </div><!-- card body -->
          </div><!-- card -->
        </div><!-- col-12 -->

        <vue-loading color="#212121" v-model="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
      </div><!-- row -->
    </div><!-- card -->
  </div><!-- tab-pane -->
</template>

<script>
import axios from 'axios';

export default {
  props: ["detrans"],
  data() {
		const detrans = JSON.parse(this.detrans)
    return {
			detranID:detrans.id,
      estado: detrans.estado,
      model: detrans.model,
      api_url_prod: detrans.api_url_prod,
			api_url_envelop_prod: detrans.api_url_envelop_prod,
			api_url_homolog: detrans.api_url_homolog,
			api_url_envelop_homolog: detrans.api_url_envelop_homolog,
      errors: [],
      loading: false,
      fullPage: true,
      states: ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RO','RS','RR','SC','SE','SP','TO']
    }
  },
  methods: {
    updateData() {
      this.errors = [];
      this.loading = true;
      const dataInfo = {
        estado: this.estado,
        model: this.model,
        api_url_prod: this.api_url_prod,
        api_url_envelop_prod: this.api_url_envelop_prod,
        api_url_homolog: this.api_url_homolog,
        api_url_envelop_homolog: this.api_url_envelop_homolog,
        _method: "PATCH"
      };
      axios.post(`/admin/detrans/${this.detranID}`, dataInfo)
        .then(() => {
          this.loading = false;
          this.$swal({
            title: "Sucesso",
            text: "Detrans atualizado com sucesso!",
            icon: "success"
          }).then(() => {
            location.href = `/admin/detrans/${this.detranID}/edit`;
          });
        })
        .catch((err) => {
          this.loading = false;
					if (err.response.status == 422) {
							this.errors = err.response.data.errors;
					}
          this.$swal({
            title: "Erro",
            text: "Falha ao cadastrar Detrans. Tente novamente!",
            icon: "error"
          });
        });
    }
  }
}
</script>
