<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-4 pb-4">

				<div class="wizard-form" id="grad1">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-3 mb-3 bxShadow">
								<h5 class="text-center"><strong>Preencha as informações abaixo para enviar o
										Contrato</strong></h5>
								<div class="row">
									<div class="col-md-12 mx-0">
										<form id="msform">
											<!-- progressbar -->
											<ul id="progressbar" class="text-center">
												<li :class="step >= 1 ? 'active' : ''" id="geral"><strong>Gerais e
														Veículo</strong></li>
												<li :class="step >= 2 ? 'active' : ''" id="financeiro"><strong>Dados
														Financeiros</strong></li>
												<li :class="step >= 3 ? 'active' : ''" id="agente"><strong>Agente</strong>
												</li>
												<li :class="step >= 4 ? 'active' : ''" id="comprador">
													<strong>Comprador</strong></li>
											</ul>
											<!-- end progressbar -->

											<!-- gerais e veículos -->
											<fieldset v-if="step == 1">
												<div class="form-card">
													<div class="row p-4">

														<!-- tipo_operacao -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_operacao">Tipo de Operação</label>
																<select disabled id="input-tipo_operacao"
																	v-bind:class="{ 'is-invalid': errors.tipo_operacao }"
																	v-model="tipo_operacao" class="form-control"
																	placeholder="Tipo de Operação">
																	<option value="1">1 - Inclusão do registro de contrato
																	</option>
																	<option value="2">2 - Alteração do registro de contrato
																	</option>
																	<option value="3">3 - Inclusão do aditivo de contrato
																	</option>
																	<option value="4">4 - Alteração do aditivo de contrato
																	</option>
																</select>
																<div class="invalid-feedback" v-if="errors.tipo_operacao">
																	<p>{{ errors.tipo_operacao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_operacao -->

														<!-- num_contrato_origem -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato_origem">Nº Contrato
																	Origem</label>
																<input disabled type="text" id="input-num_contrato_origem"
																	v-bind:class="{ 'is-invalid': errors.num_contrato_origem }"
																	class="form-control" placeholder="Nº Contrato Origem"
																	v-model="num_contrato_origem" required="true"
																	maxlength="14">
																<div class="invalid-feedback"
																	v-if="errors.num_contrato_origem">
																	<p>{{ errors.num_contrato_origem[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end num_contrato_origem -->

														<!-- num_aditivo_origem -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_aditivo_origem">Nº Aditivo Origem</label>
																<input disabled type="text" id="input-num_aditivo_origem"
																	v-bind:class="{ 'is-invalid': errors.num_aditivo_origem }"
																	class="form-control" placeholder="Nº Aditivo Origem"
																	v-model="num_aditivo_origem" required="true"
																	maxlength="14">
																<div class="invalid-feedback"
																	v-if="errors.num_aditivo_origem">
																	<p>{{ errors.num_aditivo_origem[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end num_aditivo_origem -->

														<!-- chassi -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-chassi">Chassi</label>
																<input disabled type="text" id="input-chassi"
																	v-bind:class="{ 'is-invalid': errors.chassi }"
																	class="form-control" placeholder="Chassi"
																	v-model="chassi" required="true" maxlength="21">
																<div class="invalid-feedback" v-if="errors.chassi">
																	<p>{{ errors.chassi[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end chassi -->

														<!-- remarcacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-remarcacao">Remarcado?</label>
																<select disabled id="input-remarcacao"
																	v-bind:class="{ 'is-invalid': errors.remarcacao }"
																	v-model="remarcacao" class="form-control"
																	placeholder="Remarcação">
																	<option value="1">Remarcado</option>
																	<option value="2">Não Remarcado</option>
																</select>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end remarcacao -->

														<!-- uf_licenciamento -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_licenciamento">UF Licenciamento</label>
																<select disabled id="input-uf_licenciamento"
																	v-bind:class="{ 'is-invalid': errors.uf_licenciamento }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_licenciamento" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_licenciamento">
																	<p>{{ errors.uf_licenciamento[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- uf_placa -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-uf_placa">UF
																	Placa</label>
																<select disabled id="input-uf_placa"
																	v-bind:class="{ 'is-invalid': errors.uf_placa }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_placa" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_placa">
																	<p>{{ errors.uf_placa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end uf_placa -->

														<!-- placa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-placa">Placa</label>
																<input disabled type="text" id="input-placa"
																	v-bind:class="{ 'is-invalid': errors.placa }"
																	class="form-control" placeholder="Placa" v-model="placa"
																	required="true" maxlength="7">
																<div class="invalid-feedback" v-if="errors.placa">
																	<p>{{ errors.placa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end placa -->

														<!-- renavam -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-renavam">Renavam</label>
																<input disabled type="tel" id="input-renavam"
																	v-bind:class="{ 'is-invalid': errors.renavam }"
																	class="form-control" placeholder="Renavam"
																	v-model="renavam" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.renavam">
																	<p>{{ errors.renavam[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end renavam -->

														<!-- ano_fabricacao -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ano_fabricacao">Ano Fabricação</label>
																<input disabled type="tel" id="input-ano_fabricacao"
																	v-bind:class="{ 'is-invalid': errors.ano_fabricacao }"
																	class="form-control" placeholder="Ano Fabricação"
																	v-model="ano_fabricacao" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ano_fabricacao">
																	<p>{{ errors.ano_fabricacao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_fabricacao -->

														<!-- ano_modelo -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label" for="input-ano_modelo">Ano
																	Modelo</label>
																<input disabled type="text" id="input-ano_modelo"
																	v-bind:class="{ 'is-invalid': errors.ano_modelo }"
																	class="form-control" placeholder="Ano Modelo"
																	v-model="ano_modelo" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ano_modelo">
																	<p>{{ errors.ano_modelo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ano_modelo -->

													</div>
												</div>
												<a @click="changeStep(2)" class="btn-next">Próximo</a>
											</fieldset>
											<!-- end gerais e veículos -->

											<!--- dados financeiros -->
											<fieldset v-if="step == 2">
												<div class="form-card">
													<div class="row p-4">

														<!-- num_contrato -->
														<div class="col-md-7">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_contrato">Nº Contrato</label>
																<input disabled type="text" id="input-num_contrato"
																	v-bind:class="{ 'is-invalid': errors.num_contrato }"
																	class="form-control" placeholder="Nº Contrato"
																	v-model="num_contrato" required="true" maxlength="20">
																<div class="invalid-feedback" v-if="errors.num_contrato">
																	<p>{{ errors.num_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-7 -->
														<!-- end num_contrato -->

														<!-- data_contrato -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_contrato">Data Contrato</label>
																<input disabled type="date" id="input-data_contrato"
																	v-bind:class="{ 'is-invalid': errors.data_contrato }"
																	class="form-control" placeholder="Data Contrato"
																	v-model="data_contrato" required="true">
																<div class="invalid-feedback" v-if="errors.data_contrato">
																	<p>{{ errors.data_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_contrato -->

														<!-- qtd_parcelas -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-qtd_parcelas">Qtd Parcelas</label>
																<input disabled type="tel" id="input-qtd_parcelas"
																	v-bind:class="{ 'is-invalid': errors.qtd_parcelas }"
																	class="form-control" placeholder="Qtd Parcelas"
																	v-model="qtd_parcelas" required="true" maxlength="3">
																<div class="invalid-feedback" v-if="errors.qtd_parcelas">
																	<p>{{ errors.qtd_parcelas[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end qtd_parcelas -->

														<!-- num_gravame -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-num_gravame">Nº
																	Gravame</label>
																<input disabled type="tel" id="input-num_gravame"
																	v-bind:class="{ 'is-invalid': errors.num_gravame }"
																	class="form-control" placeholder="Nº Gravame"
																	v-model="num_gravame" required="true" maxlength="8">
																<div class="invalid-feedback" v-if="errors.num_gravame">
																	<p>{{ errors.num_gravame[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_gravame -->

														<!-- tipo_gravame -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-tipo_gravame">Tipo Gravame</label>
																<select disabled id="input-tipo_gravame"
																	v-bind:class="{ 'is-invalid': errors.tipo_gravame }"
																	v-model="tipo_gravame" class="form-control"
																	placeholder="Remarcação">
																	<option value="1">Arrendamento Mercantil</option>
																	<option value="2">Reserva de Domínio</option>
																	<option value="3">Alienação Fidunciária</option>
																	<option value="4">Penhor</option>
																</select>
																<div class="invalid-feedback" v-if="errors.tipo_gravame">
																	<p>{{ errors.tipo_gravame[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end tipo_gravame -->

														<!-- taxa_juro_mes -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_mes">Taxa de Juros Mês</label>
																<input disabled type="tel" id="input-taxa_juro_mes"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_mes }"
																	class="form-control" placeholder="Taxa de Juros Mês"
																	v-model="taxa_juro_mes" required="true" maxlength="6">
																<div class="invalid-feedback" v-if="errors.taxa_juro_mes">
																	<p>{{ errors.taxa_juro_mes[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_mes -->

														<!-- taxa_juro_ano -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_ano">Taxa de Juros Ano</label>
																<input disabled type="tel" id="input-taxa_juro_ano"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_ano }"
																	class="form-control" placeholder="Taxa de Juros Ano"
																	v-model="taxa_juro_ano" required="true" maxlength="6">
																<div class="invalid-feedback" v-if="errors.taxa_juro_ano">
																	<p>{{ errors.taxa_juro_ano[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_ano -->

														<!-- taxa_juro_multa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_juro_multa">Taxa de Juros Multa</label>
																<select disabled type="text" id="input-taxa_juro_multa"
																	v-bind:class="{ 'is-invalid': errors.taxa_juro_multa }"
																	class="form-control" placeholder="Taxa de Juros Multa"
																	v-model="taxa_juro_multa" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback" v-if="errors.taxa_juro_multa">
																	<p>{{ errors.taxa_juro_multa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_juro_multa -->

														<!-- taxa_mora_dia -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_mora_dia">Taxa Mora Dia</label>
																<select disabled type="text" id="input-taxa_mora_dia"
																	v-bind:class="{ 'is-invalid': errors.taxa_mora_dia }"
																	class="form-control" placeholder=""
																	v-model="taxa_mora_dia" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback" v-if="errors.taxa_mora_dia">
																	<p>{{ errors.taxa_mora_dia[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_mora_dia -->

														<!-- taxa_multa -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-taxa_multa">Taxa Multa</label>
																<input disabled type="tel" id="input-taxa_multa"
																	v-bind:class="{ 'is-invalid': errors.taxa_multa }"
																	class="form-control" placeholder="Taxa Multa"
																	v-model="taxa_multa" required="true" maxlength="6">
																<div class="invalid-feedback" v-if="errors.taxa_multa">
																	<p>{{ errors.taxa_multa[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_multa -->

														<!-- taxa_mora -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-taxa_mora">Taxa
																	Mora</label>
																<input disabled type="tel" id="input-taxa_mora"
																	v-bind:class="{ 'is-invalid': errors.taxa_mora }"
																	class="form-control" placeholder="Taxa Mora"
																	v-model="taxa_mora" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.taxa_mora">
																	<p>{{ errors.taxa_mora[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end taxa_mora -->

														<!-- indicativo_penalidade -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_penalidade">Indicativo
																	Penalidade</label>
																<select disabled type="text"
																	id="input-indicativo_penalidade"
																	v-bind:class="{ 'is-invalid': errors.indicativo_penalidade }"
																	class="form-control" placeholder=""
																	v-model="indicativo_penalidade" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_penalidade">
																	<p>{{ errors.indicativo_penalidade[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end indicativo_penalidade -->

														<!-- penalidade -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-penalidade">Penalidade</label>
																<input disabled type="text" id="input-penalidade"
																	v-bind:class="{ 'is-invalid': errors.penalidade }"
																	class="form-control" placeholder="Penalidade"
																	v-model="penalidade" required="true" maxlength="50">
																<div class="invalid-feedback" v-if="errors.penalidade">
																	<p>{{ errors.penalidade[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end penalidade -->

														<!-- indicativo_comissao -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indicativo_comissao">Indicativo
																	Comissão</label>
																<select disabled type="text" id="input-indicativo_comissao"
																	v-bind:class="{ 'is-invalid': errors.indicativo_comissao }"
																	class="form-control" placeholder=""
																	v-model="indicativo_comissao" required="true">
																	<option value="NAO">Não</option>
																	<option value="SIM">Sim</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.indicativo_comissao">
																	<p>{{ errors.indicativo_comissao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end indicativo_comissao -->

														<!-- comissao -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-comissao">Comissão</label>
																<input disabled type="tel" id="input-comissao"
																	v-bind:class="{ 'is-invalid': errors.comissao }"
																	class="form-control" placeholder="Comissão"
																	v-model="comissao" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.comissao">
																	<p>{{ errors.comissao[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end comissao -->

														<!-- valor_taxa_contrato -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_taxa_contrato">Valor Taxa
																	Contrato</label>
																<input disabled type="tel" id="input-valor_taxa_contrato"
																	v-bind:class="{ 'is-invalid': errors.valor_taxa_contrato }"
																	class="form-control" placeholder="Valor Taxa Contrato"
																	v-model="valor_taxa_contrato" required="true"
																	maxlength="9">
																<div class="invalid-feedback"
																	v-if="errors.valor_taxa_contrato">
																	<p>{{ errors.valor_taxa_contrato[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_taxa_contrato -->

														<!-- valor_total_financiamento -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_total_financiamento">Valor Total do
																	Financiamento</label>
																<input disabled type="tel"
																	id="input-valor_total_financiamento"
																	v-bind:class="{ 'is-invalid': errors.valor_total_financiamento }"
																	class="form-control"
																	placeholder="Valor Total do Financiamento"
																	v-model="valor_total_financiamento" required="true"
																	maxlength="9">
																<div class="invalid-feedback"
																	v-if="errors.valor_total_financiamento">
																	<p>{{ errors.valor_total_financiamento[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_total_financiamento -->

														<!-- valor_iof -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_iof">Valor IOF</label>
																<input disabled type="tel" id="input-valor_iof"
																	v-bind:class="{ 'is-invalid': errors.valor_iof }"
																	class="form-control" placeholder="Valor IOF"
																	v-model="valor_iof" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.valor_iof">
																	<p>{{ errors.valor_iof[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_iof -->

														<!-- valor_parcela -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-valor_parcela">Valor da Parcela</label>
																<input disabled type="tel" id="input-valor_parcela"
																	v-bind:class="{ 'is-invalid': errors.valor_parcela }"
																	class="form-control" placeholder="Valor da Parcela"
																	v-model="valor_parcela" required="true" maxlength="9">
																<div class="invalid-feedback" v-if="errors.valor_parcela">
																	<p>{{ errors.valor_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end valor_parcela -->

														<!-- data_vecto_primeira_parcela -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_primeira_parcela">Data de
																	Vencimeto da Primeira Parcela</label>
																<input disabled type="date"
																	id="input-data_vecto_primeira_parcela"
																	v-bind:class="{ 'is-invalid': errors.data_vecto_primeira_parcela }"
																	class="form-control"
																	placeholder="data_vecto_primeira_parcela"
																	v-model="data_vecto_primeira_parcela" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_primeira_parcela">
																	<p>{{ errors.data_vecto_primeira_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_vecto_primeira_parcela -->

														<!-- data_vecto_ultima_parcela -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_vecto_ultima_parcela">Data de Vencimento
																	da Última Parcela</label>
																<input disabled type="date"
																	id="input-data_vecto_ultima_parcela"
																	v-bind:class="{ 'is-invalid': errors.data_vecto_ultima_parcela }"
																	class="form-control"
																	placeholder="data_vecto_ultima_parcela"
																	v-model="data_vecto_ultima_parcela" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_vecto_ultima_parcela">
																	<p>{{ errors.data_vecto_ultima_parcela[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end data_vecto_ultima_parcela -->

														<!-- data_liberacao_credito -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_liberacao_credito">Data de Liberação do
																	Crédito</label>
																<input disabled type="date"
																	id="input-data_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.data_liberacao_credito }"
																	class="form-control"
																	placeholder="data_liberacao_credito"
																	v-model="data_liberacao_credito" required="true">
																<div class="invalid-feedback"
																	v-if="errors.data_liberacao_credito">
																	<p>{{ errors.data_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end data_liberacao_credito -->

														<!-- uf_liberacao_credito -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_liberacao_credito">UF de Liberação do
																	Crédito</label>
																<select disabled id="input-uf_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.uf_liberacao_credito }"
																	class="form-control" placeholder="UF Placa"
																	v-model="uf_liberacao_credito" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback"
																	v-if="errors.uf_liberacao_credito">
																	<p>{{ errors.uf_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end uf_liberacao_credito -->

														<!-- municipio_liberacao_credito -->
														<div class="col-md-9">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-municipio_liberacao_credito">Município de
																	Liberação do Crédito</label>
																<input disabled type="text"
																	id="input-municipio_liberacao_credito"
																	v-bind:class="{ 'is-invalid': errors.municipio_liberacao_credito }"
																	class="form-control"
																	placeholder="Município de Liberação do Crédito"
																	v-model="municipio_liberacao_credito" required="true"
																	maxlength="25">
																<div class="invalid-feedback"
																	v-if="errors.municipio_liberacao_credito">
																	<p>{{ errors.municipio_liberacao_credito[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end municipio_liberacao_credito -->

														<!-- indice -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-indice">Indíce</label>
																<input disabled type="text" id="input-indice"
																	v-bind:class="{ 'is-invalid': errors.indice }"
																	class="form-control"
																	placeholder="Índice. Ex: SELIC, IGPM, IPCA, entre outros."
																	v-model="indice" required="true" maxlength="10">
																<div class="invalid-feedback" v-if="errors.indice">
																	<p>{{ errors.indice[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end indice -->

														<!-- num_grupo_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_grupo_consorcio">Nº Grupo
																	Consórcio</label>
																<input disabled type="text" id="input-num_grupo_consorcio"
																	v-bind:class="{ 'is-invalid': errors.num_grupo_consorcio }"
																	class="form-control" placeholder="Nº Grupo Consórcio"
																	v-model="num_grupo_consorcio" required="true"
																	maxlength="6">
																<div class="invalid-feedback"
																	v-if="errors.num_grupo_consorcio">
																	<p>{{ errors.num_grupo_consorcio[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_grupo_consorcio -->

														<!-- num_cota_consorcio -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_cota_consorcio">Nº Cota Consórcio</label>
																<input disabled type="tel" id="input-num_cota_consorcio"
																	v-bind:class="{ 'is-invalid': errors.num_cota_consorcio }"
																	class="form-control" placeholder="Nº Cota Consórcio"
																	v-model="num_cota_consorcio" required="true"
																	maxlength="6">
																<div class="invalid-feedback"
																	v-if="errors.num_cota_consorcio">
																	<p>{{ errors.num_cota_consorcio[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_cota_consorcio -->

														<!-- num_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label" for="input-num_aditivo">Nº
																	Aditivo</label>
																<input disabled type="text" id="input-num_aditivo"
																	v-bind:class="{ 'is-invalid': errors.num_aditivo }"
																	class="form-control" placeholder="Nº Aditivo"
																	v-model="num_aditivo" required="true" maxlength="20">
																<div class="invalid-feedback" v-if="errors.num_aditivo">
																	<p>{{ errors.num_aditivo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end num_aditivo -->

														<!-- data_aditivo -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-data_aditivo">Data Aditivo</label>
																<input disabled type="date" id="input-data_aditivo"
																	v-bind:class="{ 'is-invalid': errors.data_aditivo }"
																	class="form-control" placeholder="data_aditivo"
																	v-model="data_aditivo" required="true">
																<div class="invalid-feedback" v-if="errors.data_aditivo">
																	<p>{{ errors.data_aditivo[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end data_aditivo -->

													</div>
												</div>
												<a @click="changeStep(1)" class="btn-previous">Anterior</a>
												<a @click="changeStep(3)" class="btn-next">Próximo</a>
											</fieldset>
											<!-- end dados financeiros -->

											<!-- agente -->
											<fieldset v-if="step == 3">
												<div class="form-card">
													<div class="row p-4">

														<!-- nome_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_agente">Nome do Agente</label>
																<input disabled type="text" id="input-nome_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_agente }"
																	class="form-control" placeholder="Nome do Agente"
																	v-model="nome_agente" required="true" maxlength="40">
																<div class="invalid-feedback" v-if="errors.nome_agente">
																	<p>{{ errors.nome_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_agente -->

														<!-- cnpj_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cnpj_agente">CNPJ do Agente</label>
																<input disabled type="text" id="input-cnpj_agente"
																	v-bind:class="{ 'is-invalid': errors.cnpj_agente }"
																	class="form-control" placeholder="CNPJ do Agente"
																	v-model="cnpj_agente" required="true" maxlength="14">
																<div class="invalid-feedback" v-if="errors.cnpj_agente">
																	<p>{{ errors.cnpj_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end cnpj_agente -->

														<!-- nome_logradouro_agente -->
														<div class="col-md-6">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_logradouro_agente">Logradouro</label>
																<input disabled type="text"
																	id="input-nome_logradouro_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_logradouro_agente }"
																	class="form-control" placeholder="Logradouro"
																	v-model="nome_logradouro_agente" required="true"
																	maxlength="30">
																<div class="invalid-feedback"
																	v-if="errors.nome_logradouro_agente">
																	<p>{{ errors.nome_logradouro_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-6 -->
														<!-- end nome_logradouro_agente -->

														<!-- num_imovel_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-num_imovel_agente">Nº</label>
																<input disabled type="text" id="input-num_imovel_agente"
																	v-bind:class="{ 'is-invalid': errors.num_imovel_agente }"
																	class="form-control" placeholder="Nº"
																	v-model="num_imovel_agente" required="true"
																	maxlength="5">
																<div class="invalid-feedback"
																	v-if="errors.num_imovel_agente">
																	<p>{{ errors.num_imovel_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end num_imovel_agente -->

														<!-- complemento_imovel_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-complemento_imovel_agente">Complemento</label>
																<input disabled type="text"
																	id="input-complemento_imovel_agente"
																	v-bind:class="{ 'is-invalid': errors.complemento_imovel_agente }"
																	class="form-control" placeholder="Complemento"
																	v-model="complemento_imovel_agente" required="true"
																	maxlength="20">
																<div class="invalid-feedback"
																	v-if="errors.complemento_imovel_agente">
																	<p>{{ errors.complemento_imovel_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end complemento_imovel_agente -->

														<!-- bairro_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-bairro_agente">Bairro</label>
																<input disabled type="text" id="input-bairro_agente"
																	v-bind:class="{ 'is-invalid': errors.bairro_agente }"
																	class="form-control" placeholder="Bairro"
																	v-model="bairro_agente" required="true" maxlength="20">
																<div class="invalid-feedback" v-if="errors.bairro_agente">
																	<p>{{ errors.bairro_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end bairro_agente -->

														<!-- nome_municipio_agente -->
														<div class="col-md-4">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-nome_municipio_agente">Município</label>
																<input disabled type="text" id="input-nome_municipio_agente"
																	v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
																	class="form-control" placeholder="Município"
																	v-model="nome_municipio_agente" required="true"
																	maxlength="40">
																<div class="invalid-feedback"
																	v-if="errors.nome_municipio_agente">
																	<p>{{ errors.nome_municipio_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-4 -->
														<!-- end nome_municipio_agente -->

														<!-- uf_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-uf_agente">UF</label>
																<select disabled id="input-uf_agente"
																	v-bind:class="{ 'is-invalid': errors.uf_agente }"
																	class="form-control" placeholder="UF"
																	v-model="uf_agente" required="true">
																	<option v-for="item in states">{{ item }}</option>
																</select>
																<div class="invalid-feedback" v-if="errors.uf_agente">
																	<p>{{ errors.uf_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end uf_agente -->

														<!-- cep_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-cep_agente">CEP</label>
																<input disabled type="text" id="input-cep_agente"
																	v-bind:class="{ 'is-invalid': errors.cep_agente }"
																	class="form-control" placeholder="CEP"
																	v-model="cep_agente" required="true" maxlength="8">
																<div class="invalid-feedback" v-if="errors.cep_agente">
																	<p>{{ errors.cep_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-3 -->
														<!-- end cep_agente -->

														<!-- ddd_agente -->
														<div class="col-md-2">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-ddd_agente">DDD</label>
																<input disabled type="tel" id="input-ddd_agente"
																	v-bind:class="{ 'is-invalid': errors.ddd_agente }"
																	class="form-control" placeholder="DDD"
																	v-model="ddd_agente" required="true" maxlength="4">
																<div class="invalid-feedback" v-if="errors.ddd_agente">
																	<p>{{ errors.ddd_agente[0] }}</p>
																</div>
															</div><!-- form group -->
														</div><!-- col-md-2 -->
														<!-- end ddd_agente -->

														<!-- telefone_agente -->
														<div class="col-md-3">
															<div class="form-group">
																<label class="form-control-label"
																	for="input-telefone_agente">Telefone</label>
																<input disabled type="text" id="input-telefone_agente"
																v-bind:class="{'is-invalid': errors.telefone_agente}"
																class="form-control" placeholder="Telefone"
																v-model="telefone_agente" required="true" maxlength="9">
															<div class="invalid-feedback" v-if="errors.telefone_agente">
																<p>{{ errors.telefone_agente[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end telefone_agente -->

												</div>
											</div>
											<a @click="changeStep(2)" class="btn-previous">Anterior</a>
											<a @click="changeStep(4)" class="btn-next">Próximo</a>
										</fieldset>
										<!-- end agente -->

										<!-- comprador -->
										<fieldset v-if="step == 4">
											<div class="form-card">
												<div class="row p-4">

													<!-- cpf_cnpj_devedor -->
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-control-label"
																for="input-cpf_cnpj_devedor">CPF / CNPJ</label>
															<input disabled type="text" id="input-cpf_cnpj_devedor"
																v-bind:class="{'is-invalid': errors.cpf_cnpj_devedor}"
																class="form-control" placeholder="CPF / CNPJ"
																v-model="cpf_cnpj_devedor" required="true"
																maxlength="14">
															<div class="invalid-feedback"
																v-if="errors.cpf_cnpj_devedor">
																<p>{{ errors.cpf_cnpj_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-6 -->
													<!-- end cpf_cnpj_devedor -->

													<!-- nome_devedor -->
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-control-label"
																for="input-nome_devedor">Nome</label>
															<input disabled type="text" id="input-nome_devedor"
																v-bind:class="{'is-invalid': errors.nome_devedor}"
																class="form-control" placeholder="Nome"
																v-model="nome_devedor" required="true" maxlength="40">
															<div class="invalid-feedback" v-if="errors.nome_devedor">
																<p>{{ errors.nome_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-6 -->
													<!-- end nome_devedor -->

													<!-- nome_logradouro_devedor -->
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-control-label"
																for="input-nome_logradouro_devedor">Logradouro</label>
															<input disabled type="text"
																id="input-nome_logradouro_devedor"
																v-bind:class="{'is-invalid': errors.nome_logradouro_devedor}"
																class="form-control" placeholder="Logradouro"
																v-model="nome_logradouro_devedor" required="true"
																maxlength="30">
															<div class="invalid-feedback"
																v-if="errors.nome_logradouro_devedor">
																<p>{{ errors.nome_logradouro_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-6 -->
													<!-- end nome_logradouro_devedor -->

													<!-- num_imovel_devedor -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-num_imovel_devedor">Nº</label>
															<input disabled type="text" id="input-num_imovel_devedor"
																v-bind:class="{'is-invalid': errors.num_imovel_devedor}"
																class="form-control" placeholder="Nº"
																v-model="num_imovel_devedor" required="true"
																maxlength="5">
															<div class="invalid-feedback"
																v-if="errors.num_imovel_devedor">
																<p>{{ errors.num_imovel_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-2 -->
													<!-- end num_imovel_devedor -->

													<!-- complemento_imovel_devedor -->
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-control-label"
																for="input-complemento_imovel_devedor">Complemento</label>
															<input disabled type="text"
																id="input-complemento_imovel_devedor"
																v-bind:class="{'is-invalid': errors.complemento_imovel_devedor}"
																class="form-control" placeholder="Complemento"
																v-model="complemento_imovel_devedor" required="true"
																maxlength="20">
															<div class="invalid-feedback"
																v-if="errors.complemento_imovel_devedor">
																<p>{{ errors.complemento_imovel_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-4 -->
													<!-- end complemento_imovel_devedor -->

													<!-- bairro_devedor -->
													<div class="col-md-3">
														<div class="form-group">
															<label class="form-control-label"
																for="input-bairro_devedor">Bairro</label>
															<input disabled type="text" id="input-bairro_devedor"
																v-bind:class="{'is-invalid': errors.bairro_devedor}"
																class="form-control" placeholder="Bairro"
																v-model="bairro_devedor" required="true" maxlength="20">
															<div class="invalid-feedback" v-if="errors.bairro_devedor">
																<p>{{ errors.bairro_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end bairro_devedor -->

													<!-- nome_municipio_devedor -->
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-control-label"
																for="input-nome_municipio_devedor">Município</label>
															<input disabled type="text"
																id="input-nome_municipio_devedor"
																v-bind:class="{'is-invalid': errors.nome_municipio_devedor}"
																class="form-control" placeholder="Município"
																v-model="nome_municipio_devedor" required="true"
																maxlength="40">
															<div class="invalid-feedback"
																v-if="errors.nome_municipio_devedor">
																<p>{{ errors.nome_municipio_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-4 -->
													<!-- end nome_municipio_devedor -->

													<!-- uf_devedor -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-uf_devedor">UF</label>
															<select disabled id="input-uf_devedor"
																v-bind:class="{'is-invalid': errors.uf_devedor}"
																class="form-control" placeholder="UF"
																v-model="uf_devedor" required="true">
																<option v-for="item in states">{{item}}</option>
															</select>
															<div class="invalid-feedback" v-if="errors.uf_devedor">
																<p>{{ errors.uf_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-2 -->
													<!-- end uf_devedor -->

													<!-- cep_devedor -->
													<div class="col-md-3">
														<div class="form-group">
															<label class="form-control-label"
																for="input-cep_devedor">CEP</label>
															<input disabled type="text" id="input-cep_devedor"
																v-bind:class="{'is-invalid': errors.cep_devedor}"
																class="form-control" placeholder="CEP"
																v-model="cep_devedor" required="true" maxlength="8">
															<div class="invalid-feedback" v-if="errors.cep_devedor">
																<p>{{ errors.cep_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end cep_devedor -->

													<!-- ddd_devedor -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-ddd_devedor">DDD</label>
															<input disabled type="tel" id="input-ddd_devedor"
																v-bind:class="{'is-invalid': errors.ddd_devedor}"
																class="form-control" placeholder="DDD"
																v-model="ddd_devedor" required="true" maxlength="4">
															<div class="invalid-feedback" v-if="errors.ddd_devedor">
																<p>{{ errors.ddd_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-2 -->
													<!-- end ddd_devedor -->

													<!-- telefone_devedor -->
													<div class="col-md-3">
														<div class="form-group">
															<label class="form-control-label"
																for="input-telefone_devedor">Telefone</label>
															<input disabled type="text" id="input-telefone_devedor"
																v-bind:class="{'is-invalid': errors.telefone_devedor}"
																class="form-control" placeholder="Telefone"
																v-model="telefone_devedor" required="true"
																maxlength="9">
															<div class="invalid-feedback"
																v-if="errors.telefone_devedor">
																<p>{{ errors.telefone_devedor[0] }}</p>
															</div>
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end telefone_devedor -->

												</div>
											</div>
											<a @click="changeStep(3)" class="btn-previous">Anterior</a>
										</fieldset>
										<!-- end comprador -->
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div><!-- card -->
	</div><!-- col-12 -->

	<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
</div><!-- row --></template>

<script>
export default {
	props: ["contrato"],
	data() {
		return {
			tipo_operacao: this.getData("tipo_operacao"),
			sequencia_contrato: this.getData("sequencia_contrato"),
			num_contrato_origem: this.getData("num_contrato_origem"),
			num_aditivo_origem: this.getData("num_aditivo_origem"),
			chassi: this.getData("chassi"),
			remarcacao: this.getData("remarcacao"),
			uf_licenciamento: this.getData("uf_licenciamento"),
			uf_placa: this.getData("uf_placa"),
			placa: this.getData("placa"),
			renavam: this.getData("renavam"),
			ano_fabricacao: this.getData("ano_fabricacao"),
			ano_modelo: this.getData("ano_modelo"),
			nome_agente: this.getData("nome_agente"),
			cnpj_agente: this.getData("cnpj_agente"),
			num_contrato: this.getData("num_contrato"),
			data_contrato: this.getData("data_contrato"),
			data_contato: this.getData("data_contato"),
			qtd_parcelas: this.getData("qtd_parcelas"),
			num_gravame: this.getData("num_gravame"),
			tipo_gravame: this.getData("tipo_gravame"),
			taxa_juro_mes: this.getData("taxa_juro_mes"),
			taxa_juro_ano: this.getData("taxa_juro_ano"),
			taxa_juro_multa: this.getData("taxa_juro_multa"),
			taxa_mora_dia: this.getData("taxa_mora_dia"),
			taxa_multa: this.getData("taxa_multa"),
			taxa_mora: this.getData("taxa_mora"),
			indicativo_penalidade: this.getData("indicativo_penalidade"),
			penalidade: this.getData("penalidade"),
			indicativo_comissao: this.getData("indicativo_comissao"),
			comissao: this.getData("comissao"),
			valor_taxa_contrato: this.getData("valor_taxa_contrato"),
			valor_total_financiamento: this.getData("valor_total_financiamento"),
			valor_iof: this.getData("valor_iof"),
			valor_parcela: this.getData("valor_parcela"),
			data_vecto_primeira_parcela: this.getData("data_vecto_primeira_parcela"),
			data_vecto_ultima_parcela: this.getData("data_vecto_ultima_parcela"),
			data_liberacao_credito: this.getData("data_liberacao_credito"),
			uf_liberacao_credito: this.getData("uf_liberacao_credito"),
			municipio_liberacao_credito: this.getData("municipio_liberacao_credito"),
			indice: this.getData("indice"),
			num_grupo_consorcio: this.getData("num_grupo_consorcio"),
			num_cota_consorcio: this.getData("num_cota_consorcio"),
			num_aditivo: this.getData("num_aditivo"),
			data_aditivo: this.getData("data_aditivo"),
			nome_logradouro_agente: this.getData("nome_logradouro_agente"),
			num_imovel_agente: this.getData("num_imovel_agente"),
			complemento_imovel_agente: this.getData("complemento_imovel_agente"),
			bairro_agente: this.getData("bairro_agente"),
			nome_municipio_agente: this.getData("nome_municipio_agente"),
			uf_agente: this.getData("uf_agente"),
			cep_agente: this.getData("cep_agente"),
			ddd_agente: this.getData("ddd_agente"),
			telefone_agente: this.getData("telefone_agente"),
			cpf_cnpj_devedor: this.getData("cpf_cnpj_devedor"),
			nome_devedor: this.getData("nome_devedor"),
			nome_logradouro_devedor: this.getData("nome_logradouro_devedor"),
			num_imovel_devedor: this.getData("num_imovel_devedor"),
			complemento_imovel_devedor: this.getData("complemento_imovel_devedor"),
			bairro_devedor: this.getData("bairro_devedor"),
			nome_municipio_devedor: this.getData("nome_municipio_devedor"),
			uf_devedor: this.getData("uf_devedor"),
			cep_devedor: this.getData("cep_devedor"),
			ddd_devedor: this.getData("ddd_devedor"),
			telefone_devedor: this.getData("telefone_devedor"),
			attach: "",
			states: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			errors: [],
			step: 1,
			message: "",
			loading: false,
			fullPage: true
		}
	},
	mounted() {
	},
	methods: {
		getData(key) {
			return JSON.parse(this.contrato)[key];
		},
		changeStep(option) {
			this.step = option;
		}
	}
}
</script>
