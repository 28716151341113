<template>
	<div>

		<ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" id="geral-tab" data-toggle="pill" href="#tab-geral" role="tab"
					aria-controls="tab-geral" aria-selected="true">Dados</a>
			</li>
			<li class="nav-item" v-if="userIsGroupEcon != 'S'">
				<a class="nav-link" id="notificacao-tab" data-toggle="pill" href="#tab-notificacao" role="tab"
					aria-controls="tab-notificacao" aria-selected="false">Configurar Notificações</a>
			</li>
			<li class="nav-item" v-if="userIsGroupEcon != 'S'">
				<a class="nav-link" id="agent-code-tab" data-toggle="pill" href="#tab-agent-code" role="tab"
					aria-controls="tab-agent-code" aria-selected="false" v-if="userCan.list">Código Agente</a>
			</li>
			<li class="nav-item" v-if="userIsGroupEcon != 'S'">
				<a class="nav-link" id="users-tab" data-toggle="pill" href="#tab-users" role="tab" aria-controls="tab-users"
					aria-selected="false" v-if="userCan.list">Usuários</a>
			</li>
			<li class="nav-item" v-if="userIsGroupEcon == 'S'">
				<a class="nav-link" id="gpEcon-tab" data-toggle="pill" href="#tab-gpEcon" role="tab"
					aria-controls="tab-gpEcon" aria-selected="false" v-if="userCan.list">
					Grupo Econômico - {{ nameGroup }}</a>
			</li>
		</ul>

		<div class="tab-content" id="pills-tabContent">

			<!-- GENERAL -->
			<div class="tab-pane fade show active" id="tab-geral" role="tabpanel" aria-labelledby="geral-tab">
				<div class="row">
					<div class="col-md-12">

						<div class="card">

							<!-- card header -->
							<div class="card-header">
								<h4>Visualize/Edite os dados abaixo</h4>
							</div>
							<!-- end card header -->
							<div class="row">
								<div class="col-md-12 text-md-right mb-4 mt-3">
									<a @click="editProf()"
										class="btn btn-sm btn-primary text-white btn-right mr-4 ">ATUALIZAR</a>
								</div>
							</div>

							<!-- card body -->
							<div class="card-body">
								<template v-if="userCan.list && userIsGroupEcon != 'S'">
									<!-- form -->
									<h6 class="heading-small text-muted mb-4">Dados da Instituição</h6>

									<div class="pl-lg-4">

										<div class="row">
											<!-- name -->
											<div class="col-3">
												<div class="form-group">
													<label class="form-control-label">Razão Social</label>
													<input type="text" id="name"
														v-bind:class="{ 'is-invalid': errors.name }" class="form-control"
														placeholder="Nome" v-model="name" maxlength="40"
														:disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.name">
														<p>{{ errors.name[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-lg-4 -->
											<!-- end name -->

											<!-- name_fantasy -->
											<div class="col-3">
												<div class="form-group">
													<label class="form-control-label">Nome Fantasia</label>
													<input type="text" id="name_fantasy"
														v-bind:class="{ 'is-invalid': errors.name_fantasy }"
														class="form-control" placeholder="Nome" v-model="name_fantasy"
														maxlength="25" :disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.name_fantasy">
														<p>{{ errors.name_fantasy[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-lg-4 -->
											<!-- end name -->

											<!-- cnpj -->
											<div class="col-3">
												<div class="form-group">
													<label class="form-control-label">CNPJ</label>
													<input type="tel" id="cnpj" v-bind:class="{ 'is-invalid': errors.cnpj }"
														class="form-control" placeholder="CNPJ" v-model="cnpj"
														maxlength="18" :disabled="editProfile"
														v-mask="'##.###.###/####-##'">
													<div class="invalid-feedback" v-if="errors.cnpj">
														<p>{{ errors.cnpj[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-lg-4 -->
											<!-- end cnpj -->

											<!-- ddd_agente -->
											<div class="col-md-1">
												<div class="form-group">
													<label class="form-control-label" for="input-ddd">DDD</label>
													<input type="tel" id="input-ddd"
														v-bind:class="{ 'is-invalid': errors.ddd }" class="form-control"
														placeholder="DDD" v-model="ddd" required="true" maxlength="2"
														:disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.ddd">
														<p>{{ errors.ddd[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-2 -->
											<!-- end ddd_agente -->

											<!-- telefone_agente -->
											<div class="col-md-2">
												<div class="form-group">
													<label class="form-control-label" for="input-telefone">Telefone</label>
													<input type="text" id="input-telefone"
														v-bind:class="{ 'is-invalid': errors.telefone }"
														class="form-control" placeholder="Telefone" v-model="telefone"
														required="true" maxlength="10" :disabled="editProfile"
														v-mask="'#####-####'">
													<div class="invalid-feedback" v-if="errors.telefone">
														<p>{{ errors.telefone[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-3 -->
											<!-- end telefone_agente -->
										</div>
										<div class="row">

											<!-- cep_agente -->
											<div class="col-md-2">
												<div class="form-group">
													<label class="form-control-label" for="input-cep">CEP</label>
													<input type="text" id="input-cep"
														v-bind:class="{ 'is-invalid': errors.cep }" class="form-control"
														placeholder="CEP" v-model="cep" v-mask="'#####-###'" required="true"
														maxlength="9" @blur="verificarCEP('banco')" :disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.cep">
														<p>{{ errors.cep[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-3 -->
											<!-- end cep_agente -->

											<!-- uf_agente -->
											<div class="col-md-1">
												<div class="form-group">
													<label class="form-control-label" for="input-estado">UF</label>
													<select id="input-estado" v-bind:class="{ 'is-invalid': errors.estado }"
														class="form-control" placeholder="UF" v-model="estado"
														required="true" @change="onChangeUf('agente')"
														:disabled="ufBloqueado">
														<option v-for="item in states">{{ item }}</option>
													</select>
													<div class="invalid-feedback" v-if="errors.estado">
														<p>{{ errors.estado[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-2 -->
											<!-- end uf_agente -->

											<!-- nome_municipio_agente -->
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-control-label" for="input-cidade">Município</label>
													<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
											o municipio  -->
													<template v-if="!cepValido && !municipio_ag">
														<!-- Digitar manualmente -->
														<input type="text" id="input_municipio_ag_manual"
															v-bind:class="{ 'is-invalid': errors.municipio_ag_manual }"
															class="form-control" placeholder="Insira a cidade Manualmente"
															v-model="municipio_ag_manual" required="true" maxlength="60">
													</template>
													<!-- fim -->
													<template v-else>
														<!-- Vai ser preenchido manualmente pela API -->
														<v-select id="mySelect" label="municipio" @input="setSelected"
															:options="municipios_ag"
															:reduce="municipio => municipio.codigo_origem"
															v-bind:class="{ 'is-invalid': errors.nome_municipio_agente }"
															v-model="municipio_ag"
															:disabled="municipioBloqueado"></v-select>
													</template>
													<!-- Fim -->
													<div class="invalid-feedback" v-if="errors.cidade">
														<p>{{ errors.cidade[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-4 -->
											<!-- end nome_municipio_agente -->

											<!-- nome_logradouro_agente -->
											<div class="col-md-5">
												<div class="form-group">
													<label class="form-control-label" for="input-endereco">Endereço</label>
													<input type="text" id="input-endereco"
														v-bind:class="{ 'is-invalid': errors.endereco }"
														class="form-control" placeholder="Endereço" v-model="endereco"
														required="true" maxlength="30" :disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.endereco">
														<p>{{ errors.endereco[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-6 -->
											<!-- end nome_logradouro_agente -->

										</div>
										<div class="row">

											<!-- num_imovel_agente -->
											<div class="col-md-2">
												<div class="form-group">
													<label class="form-control-label" for="input-numero">Nº</label>
													<input type="text" id="input-numero"
														v-bind:class="{ 'is-invalid': errors.numero }" class="form-control"
														placeholder="Nº" v-model="numero" required="true" maxlength="5"
														:disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.numero">
														<p>{{ errors.numero[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-2 -->
											<!-- end num_imovel_agente -->

											<!-- bairro_agente -->
											<div class="col-md-5">
												<div class="form-group">
													<label class="form-control-label" for="input-bairro">Bairro</label>
													<input type="text" id="input-bairro"
														v-bind:class="{ 'is-invalid': errors.bairro }" class="form-control"
														placeholder="Bairro" v-model="bairro" required="true"
														maxlength="100" :disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.bairro">
														<p>{{ errors.bairro[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-3 -->
											<!-- end bairro_agente -->

											<!-- complemento_imovel_agente -->
											<div class="col-md-3">
												<div class="form-group">
													<label class="form-control-label"
														for="input-complemento">Complemento</label>
													<input type="text" id="input-complemento"
														v-bind:class="{ 'is-invalid': errors.complemento }"
														class="form-control" placeholder="Complemento" v-model="complemento"
														required="true" maxlength="20" :disabled="editProfile">
													<div class="invalid-feedback" v-if="errors.complemento">
														<p>{{ errors.complemento[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-4 -->
											<!-- end complemento_imovel_agente -->

											<!-- codigo SERPRO-->
											<div class="col-md-2">
												<div class="form-group">
													<!-- <div class="form-group" v-if="!cepValido"></div> -->
													<label class="form-control-label" for="input_cod_serpro">Cód
														SERPRO</label>
													<input type="text" id="input_cod_serpro"
														v-bind:class="{ 'is-invalid': errors.cod_serpro }"
														class="form-control" placeholder="Código" v-model="cod_serpro"
														required="true" maxlength="4" :disabled="serproBloqueado">
													<div class="invalid-feedback" v-if="errors.cep_devedor">
														<p>{{ errors.cod_serpro }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-2 -->
											<!-- end codigo SERPRO -->

											<!-- token_id -->
											<div v-show="false" class="col-md-2">
												<div class="form-group">
													<label class="form-control-label" for="input-token_id">Token ID</label>
													<div class="input-group mb-3">
														<input :type="inputType" id="input-token_id"
															v-bind:class="{ 'is-invalid': errors.token_id }"
															class="form-control" placeholder="Token ID" v-model="token_id"
															required="true" disabled>
														<div class="input-group-prepend">
															<button class="btn btn-outline-secondary" type="button"
																@click="changeInputView()"><i
																	:class="inputIcon"></i></button>
														</div>
													</div>

													<div class="invalid-feedback" v-if="errors.token_id">
														<p>{{ errors.token_id[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-6 -->
											<!-- end token_id -->

											<!-- token_api -->
											<div v-show="false" class="col-md-5">
												<div class="form-group">
													<label class="form-control-label" for="input-token_api">Token da
														API</label>
													<div class="input-group mb-3">
														<input :type="inputType" id="input-token_api"
															v-bind:class="{ 'is-invalid': errors.token_api }"
															class="form-control" placeholder="Token da API"
															v-model="token_api" required="true" disabled>
														<div class="input-group-prepend">
															<button class="btn btn-outline-secondary" type="button"
																@click="changeInputView()"><i
																	:class="inputIcon"></i></button>
														</div>
													</div>
													<div class="invalid-feedback" v-if="errors.token_api">
														<p>{{ errors.token_api[0] }}</p>
													</div>
												</div><!-- form group -->
											</div><!-- col-md-6 -->
											<!-- end token_api -->

										</div><!-- row -->
									</div>

									<div class="row">
										<div class="col-md-12 text-md-right mb-4" v-show="btnEdit">
											<a @click="updateData(); gravaInfoMunicipio();"
												class="btn btn-sm btn-primary text-white btn-right">Atualizar dados
												Instituição</a>
										</div>
									</div>


									<hr class="my-4" />
								</template>

								<!-- divider
								Access
								<h6 class="heading-small text-muted mb-4">Acesso</h6> -->
								<h6 class="heading-small text-muted mb-4">Dados do Usuário</h6>
								<div class="pl-lg-4">
									<div class="row">

										<div class="col-5">
											<div class="form-group">
												<label class="form-control-label">Nome Usuário(a)</label>
												<input type="text" id="profileName"
													v-bind:class="{ 'is-invalid': errors.profileName }" class="form-control"
													placeholder="Nome" v-model="profileName" maxlength="40"
													:disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.profileName">
													<p>{{ errors.profileName[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->

										<!-- cpf -->
										<div class="col-3">
											<div class="form-group">
												<label class="form-control-label">CPF</label>
												<input type="text" id="input-cpf"
													v-bind:class="{ 'is-invalid': errors.cpf }" class="form-control"
													placeholder="CPF" v-model="cpf" v-mask="'###.###.###-##'"
													required="true" maxlength="14" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.cpf">
													<p>{{ errors.cpf[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end cpf -->

										<!-- email -->
										<div class="col-4">
											<div class="form-group">
												<label class="form-control-label">E-mail</label>
												<input type="email" id="email" v-bind:class="{ 'is-invalid': errors.email }"
													class="form-control" placeholder="E-mail" v-model="email"
													:disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.email">
													<p>{{ errors.email[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end email -->

									</div>
									<div class="row">

										<!-- ddd -->
										<div class="col-1">
											<div class="form-group">
												<label class="form-control-label">DDD</label>
												<input type="tel" id="ddd_profile"
													v-bind:class="{ 'is-invalid': errors.ddd_profile }" class="form-control"
													placeholder="" v-model="ddd_profile" maxlength="2"
													:disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.ddd_profile">
													<p>{{ errors.ddd_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end ddd -->

										<!-- telefone -->
										<div class="col-2">
											<div class="form-group">
												<label class="form-control-label">Telefone</label>
												<input type="tel" id="telefone_profile"
													v-bind:class="{ 'is-invalid': errors.telefone }" class="form-control"
													placeholder="Telefone" v-model="telefone_profile" maxlength="10"
													v-mask="'#####-####'" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.telefone_profile">
													<p>{{ errors.telefone_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end telefone -->

										<!-- cep_profile -->
										<div class="col-md-2">
											<div class="form-group">
												<label class="form-control-label" for="input-cep_profile">CEP</label>
												<input type="text" id="input-cep_profile"
													v-bind:class="{ 'is-invalid': errors.cep_profile }" class="form-control"
													placeholder="CEP" v-model="cep_profile" v-mask="'#####-###'"
													required="true" maxlength="9" @blur="verificarCEP('user')"
													:disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.cep_profile">
													<p>{{ errors.cep_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-md-3 -->
										<!-- end cep_profile -->

										<!-- Endereço -->
										<div class="col-md-7">
											<div class="form-group">
												<label class="form-control-label"
													for="input-endereco_profile">Endereço</label>
												<input type="text" id="input-endereco_profile"
													v-bind:class="{ 'is-invalid': errors.endereco_profile }"
													class="form-control" placeholder="Endereço" v-model="endereco_profile"
													required="true" maxlength="30" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.endereco_profile">
													<p>{{ errors.endereco_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-md-6 -->
										<!-- end Endereço -->

									</div><!-- row -->

									<div class="row">

										<!-- bairro_agente -->
										<div class="col-md-4">
											<div class="form-group">
												<label class="form-control-label" for="input-bairro_profile">Bairro</label>
												<input type="text" id="input-bairro_profile"
													v-bind:class="{ 'is-invalid': errors.bairro_profile }"
													class="form-control" placeholder="Bairro" v-model="bairro_profile"
													required="true" maxlength="100" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.bairro_profile">
													<p>{{ errors.bairro_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-md-3 -->
										<!-- end bairro_agente -->

										<!-- nome_municipio_agente -->
										<div class="col-md-5">
											<div class="form-group">
												<label class="form-control-label"
													for="input-cidade_profile">Município</label>
												<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
											o municipio  -->
												<template v-if="!cepValidoProfile && !municipio_profile">
													<!-- Digitar manualmente -->
													<input type="text" id="input_municipio_profile_manual"
														v-bind:class="{ 'is-invalid': errors.municipio_profile_manual }"
														class="form-control" placeholder="Insira a cidade Manualmente"
														v-model="municipio_profile_manual" required="true" maxlength="60">
												</template>
												<!-- fim -->
												<template v-else>
													<!-- Vai ser preenchido manualmente pela API -->
													<v-select id="mySelect" label="municipio" @input="setSelected"
														:options="municipios_profile"
														:reduce="municipio => municipio.codigo_origem"
														v-bind:class="{ 'is-invalid': errors.cidade_profile }"
														v-model="municipio_profile"
														:disabled="municipioBloqueado_profile"></v-select>
												</template>
												<!-- Fim -->
												<div class="invalid-feedback" v-if="errors.cidade_profile">
													<p>{{ errors.cidade_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-md-4 -->
										<!-- end nome_municipio_agente -->

										<div class="col-md-1">
											<div class="form-group">
												<label class="form-control-label" for="input-uf_profile">UF</label>
												<select id="input-uf_profile"
													v-bind:class="{ 'is-invalid': errors.uf_profile }" class="form-control"
													placeholder="UF" v-model="uf_profile" required="true"
													@change="onChangeUf('user')" :disabled="ufBloqueadoProfile">
													<option v-for="item in states">{{ item }}</option>
												</select>
												<div class="invalid-feedback" v-if="errors.uf_profile">
													<p>{{ errors.uf_profile[0] }}</p>
												</div>
											</div>
										</div>

										<!-- Número -->
										<div class="col-2">
											<div class="form-group">
												<label class="form-control-label">Nº</label>
												<input type="text" id="numero_profile"
													v-bind:class="{ 'is-invalid': errors.numero_profile }"
													class="form-control" placeholder="Nº" v-model="numero_profile"
													:disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.numero_profile">
													<p>{{ errors.numero_profile[0] }}</p>
												</div>
											</div>
										</div>
										<!-- End Número -->

									</div><!-- row -->
									<div class="row">

										<!-- função -->
										<div class="col-5">
											<div class="form-group">
												<label class="form-control-label">Função</label>
												<input type="text" id="funcao_profile"
													v-bind:class="{ 'is-invalid': errors.funcao_profile }"
													class="form-control" placeholder="Função" v-model="funcao_profile"
													maxlength="50" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.funcao_profile">
													<p>{{ errors.funcao_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end função -->

										<!-- Matricula -->
										<div class="col-3">
											<div class="form-group">
												<label class="form-control-label">Matricula</label>
												<input type="text" id="matricula_profile"
													v-bind:class="{ 'is-invalid': errors.matricula_profile }"
													class="form-control" placeholder="Matricula" v-model="matricula_profile"
													maxlength="50" required="true" :disabled="editProfile">
												<div class="invalid-feedback" v-if="errors.matricula_profile">
													<p>{{ errors.matricula_profile[0] }}</p>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end Matricula -->

										<!-- password -->
										<div class="col-4">
											<div class="form-group">
												<label class="form-control-label">Senha</label>
												<div class="input-group mb-3">
													<input :type="inputType" id="password"
														v-bind:class="{ 'is-invalid': errors.password }"
														class="form-control"
														placeholder="Digite a senha somente se for alterar!"
														v-model="password" required="true" :disabled="editProfile">
													<div class="input-group-prepend">
														<button class="btn btn-outline-secondary" type="button"
															@click="changeInputView()"><i :class="inputIcon"></i></button>
													</div>
												</div>
											</div><!-- form group -->
										</div><!-- col-lg-12 -->
										<!-- end password -->

									</div>
									<div class="row">
										<div class="col-md-12 text-md-right mb-4" v-show="btnEdit">
											<a @click="updateDataUser()"
												class="btn btn-sm btn-primary text-white btn-right">Atualizar dados
												Usuário</a>
										</div>
									</div>
								</div>
								<!-- end form -->

							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- END GENERAL -->

			<!-- NOTIFICATION -->
			<div v-if="userIsGroupEcon != 'S'" class="tab-pane fade" id="tab-notificacao" role="tabpanel"
				aria-labelledby="notificacao-tab">
				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<!-- card header -->
							<div class="card-header">
								<h4>Configure as opções de notificação abaixo</h4>
								<a @click="sendNotification()"
									class="btn btn-sm btn-primary text-white btn-right">ATUALIZAR</a>
							</div>
							<!-- end card header -->
							<div class="card-body">
								<div class="row">
									<!-- Portal -->
									<div class="col-lg-6">
										<h6 class="heading-small text-muted mb-4">E-mail</h6>
										<div class="pl-lg-1">
											<div class="row">
												<div class="col">
													<!-- Checkbox para selecionar todas as opções do Portal -->
													<input class="check-box" type="checkbox" id="check-email"
														v-model="selectAllEmail"
														@change="selectAllOptions('email', selectAllEmail)" />
													<label for="check-email" class="label_check_box">SELECIONAR TODAS AS
														OPÇÕES:</label>

													<div class="check_box_div" v-for="item in listaItens" :key="item.id">
														<input class="check-box" type="checkbox"
															:id="'input-' + item.id + '-email'" v-model="item.email" />
														<label :for="'input-' + item.id + '-email'"
															class="label_check_box">{{ item.nome }}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- Fim Portal -->
									<!-- Portal -->
									<div class="col-lg-6">
										<h6 class="heading-small text-muted mb-4">Portal</h6>
										<div class="pl-lg-1">
											<div class="row">
												<div class="col">
													<!-- Checkbox para selecionar todas as opções do Portal -->
													<input class="check-box" type="checkbox" id="check-portal"
														v-model="selectAllPortal"
														@change="selectAllOptions('portal', selectAllPortal)" />
													<label for="check-portal" class="label_check_box">SELECIONAR TODAS AS
														OPÇÕES:</label>

													<div class="check_box_div" v-for="item in listaItens" :key="item.id">
														<input class="check-box" type="checkbox"
															:id="'input-' + item.id + '-push'" v-model="item.push" />
														<label :for="'input-' + item.id + '-push'"
															class="label_check_box">{{ item.nome }}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- card body -->
						</div>
					</div>
				</div>
			</div>
			<!-- END NOTIFICATION -->

			<!-- AGENT CODE -->
			<div v-if="userIsGroupEcon != 'S'" class="tab-pane fade" id="tab-agent-code" role="tabpanel"
				aria-labelledby="agent-code-tab">
				<div class="row">
					<div class="col-md-12">

						<div class="card">

							<!-- card header -->
							<div class="card-header">
								<h4>Códigos do Agente por Estado (Detran)</h4>
							</div>
							<!-- end card header -->

							<div class="card-body">

								<vue-good-table :columns="tableColumns" :rows="detranBanco"
									:pagination-options="tablePagination" :search-options="{
										enabled: true,
										placeholder: 'Search this table',
									}" styleClass="vgt-table">
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == 'actions'">
											<div class="text-center">
												<button class="btn btn-sm btn-outline-secondary"
													@click="editCodigoAgente(props.row)"><i
														class="far fa-edit"></i></button>
											</div>
										</span>
										<span v-else>
											{{ props.formattedRow[props.column.field] }}
										</span>
									</template>
								</vue-good-table>

							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- END AGENT CODE -->

			<!-- USERS -->
			<div v-if="userIsGroupEcon != 'S'" class="tab-pane fade" id="tab-users" role="tabpanel"
				aria-labelledby="users-tab">
				<div class="row">
					<div class="col-md-12">

						<div class="card">

							<!-- card header -->
							<div class="card-header">
								<h4>Usuários</h4>

								<a data-toggle="modal" data-target="#modalUser"
									class="btn btn-sm btn-primary text-white btn-right" v-if="userCan.create">NOVO</a>

							</div>
							<!-- end card header -->

							<div class="card-body">

								<vue-good-table :columns="tableUserColumns" :rows="users"
									:pagination-options="tablePagination" :search-options="{
										enabled: true,
										placeholder: 'Search this table',
									}" styleClass="vgt-table">
									<template slot="table-row" slot-scope="props">
										<span v-if="props.column.field == '_status'">
											<span class="alert-table alert alert-success"
												v-if="props.row.status == 1">Ativo</span>
											<span class="alert-table alert alert-danger"
												v-if="props.row.status == 0">Inativo</span>
										</span>
										<span v-if="props.column.field == 'actions'">
											<div class="text-center">
												<!-- <a class="btn btn-sm btn-outline-secondary text-secondary" @click="editUser(props.row)" v-if="userCan.edit"><i class="far fa-edit"></i></a> -->
												<button @click="openEditModal(props.row.id)" title="Editar Usuário(a)"
													class="btn btn-sm btn-outline-secondary far fa-edit"></button>
												<!-- <button data-toggle="modal" data-target="#modalUserEdit" class="btn btn-sm btn btn-sm btn-outline-secondary far fa-edit"></button> -->
												<a v-show="false" class="btn btn-sm btn-outline-danger text-danger"
													@click="deleteUser(props.row.id, props.row.index)"
													v-if="userCan.delete"><i class="far fa-trash-alt"></i></a>
												<a class="btn btn-sm btn-outline-danger text-danger"
													title="Desabilitar Usuário(a)"
													@click="disableUser(props.row.id, props.row.index)"
													v-if="userCan.disable && props.row.status == 1"><i
														class="fas fa-ban"></i></a>
												<a class="btn btn-sm btn-outline-success text-success"
													title="Habilitar Usuário(a)"
													@click="enableUser(props.row.id, props.row.index)"
													v-if="userCan.disable && props.row.status == 0"><i
														class="fas fa-check"></i></a>
											</div>
										</span>
										<span v-else>
											{{ props.formattedRow[props.column.field] }}
										</span>
									</template>
								</vue-good-table>

							</div>

						</div>

					</div>
				</div>
			</div>
			<!-- END USERS -->

			<!-- Grupo Econ -->
			<div v-if="userIsGroupEcon == 'S'" class="tab-pane fade" id="tab-gpEcon" role="tabpanel"
				aria-labelledby="gpEcon-tab">
				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<div class="card-header">
								<h4>Insituições Vinculadas ao Grupo Econômico</h4>
								<a v-show="false" data-toggle="modal" data-target="#modalNovaInstituicao"
									class="btn btn-sm btn-primary text-white btn-right" v-if="userCan.create">NOVO</a>
							</div>
							<vue-good-table :columns="columnsBancos" :rows="bancos" :pagination-options="tablePagination"
								:search-options="{
									enabled: true,
									placeholder: 'Pesquise',
								}" styleClass="vgt-table">

								<template slot="table-row" slot-scope="props">
									<span v-if="props.column.field == 'action'">
										<div class="text-center">
											<button class="btn btn-sm btn-outline-danger"
												@click="deleteInstituicaoGrupoEcon(props.row)"><i
													class="far fa-trash-alt"></i></button>
										</div>
									</span>
									<span v-else>
										{{ props.formattedRow[props.column.field] }}
									</span>
								</template>
							</vue-good-table>
						</div>
					</div>
				</div>
			</div>
			<!-- END Grupo Econ -->

		</div><!-- tab content -->

		<!-- modal  -->
		<div class="modal fade" id="modalCodigoAgente" tabindex="-1" aria-labelledby="modalCodigoAgenteLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalCodigoAgenteLabel">Editar Código do Agente</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST" v-on:submit.prevent="updateCodigoAgente">

							<div class="row">

								<!-- status_api -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-estado_banco">Estado</label>
										<input type="text" disabled='disabled' id="input-estado_codAgente"
											v-model="modalAgente.estado" class="form-control" placeholder="Estado"
											required="true">

									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end status_api -->

								<!-- codigo_retorno -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-codigo_agente">Código do Agente</label>
										<input type="text" ref="modalAgenteCodigo" id="input-codigo_agente"
											class="form-control" v-model="modalAgente.codigo" maxlength="20"
											placeholder="Código do agente" required>

									</div><!-- form group -->
								</div><!-- col-md-6 -->
								<!-- end codigo_retorno -->
								<div class="col-md-12 text-right">
									<button type="submit" class="btn btn-primary">SALVAR</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- end modal -->

		<!-- modal new user -->
		<div class="modal fade" id="modalUser" tabindex="-1" aria-labelledby="modalUserLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalUserLabel">{{ modalUserTitle }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close"
							@click="resetCloseNewUser()">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST" v-on:submit.prevent="saveNewUser()">

							<div class="row">

								<!-- name -->
								<div class="col-md-5">
									<div class="form-group">
										<label class="form-control-label" for="input-new-name">Nome</label>
										<input type="text" id="input-new-name" class="form-control" v-model="userName"
											placeholder="" required="true">
									</div>
								</div>
								<!-- end name -->

								<!-- cpf -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-cpf">CPF</label>
										<input type="text" id="input-new-cpf" class="form-control" v-model="userCpf"
											placeholder="" required="true" maxlength="14" v-mask="'###.###.###-##'">
									</div>
								</div>
								<!-- end cpf -->

								<!-- email -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-new-email">E-mail</label>
										<input type="email" id="input-new-email" class="form-control" v-model="userEmail"
											placeholder="" required="true"
											v-bind:class="{ 'is-invalid': errors.userEmail }">
										<div class="invalid-feedback" v-if="errors.userEmail">
											<p>{{ errors.userEmail[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end email -->

							</div>
							<div class="row">

								<!-- ddd -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-ddd">DDD</label>
										<input type="tel" id="input-new-ddd" class="form-control" v-model="userDdd"
											placeholder="" required="true" maxlength="3">
									</div>
								</div>
								<!-- end ddd -->

								<!-- telefone -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-telefone">Telefone</label>
										<input type="text" id="input-new-telefone" class="form-control"
											v-model="userTelefone" placeholder="Telefone" maxlength="10"
											v-mask="'#####-####'" required="true">
									</div>
								</div>
								<!-- end telefone -->
							</div>

							<div class="row">

								<!-- cep -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-cep">CEP</label>
										<input type="text" id="input-new-cep"
											v-bind:class="{ 'is-invalid': errors.userCep }" class="form-control"
											placeholder="CEP" v-model="userCep" v-mask="'#####-###'" required="true"
											maxlength="9" @blur="verificarCEP('newUser')">
										<div class="invalid-feedback" v-if="errors.userCep">
											<p>{{ errors.userCep[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end cep -->

								<!-- uf -->
								<div class="col-md-1">
									<div class="form-group">
										<label class="form-control-label" for="input-new-uf">UF</label>
										<input type="text" id="input-new-uf" class="form-control" v-model="userUf"
											placeholder="" required="true" maxlength="50" @change="onChangeUf('newUser')">
									</div>
								</div>
								<!-- end uf -->

								<!-- municipio -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-cidade_profile">Município</label>
										<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
											o municipio  -->
										<template v-if="!cepValidadoUser && !municipio_user">
											<!-- Digitar manualmente -->
											<input type="text" id="input_municipio_user_manual_new"
												v-bind:class="{ 'is-invalid': errors.municipio_user_manual }"
												class="form-control" placeholder="Insira a cidade Manualmente"
												v-model="municipio_user_manual" required="true" maxlength="60">
										</template>
										<!-- fim -->
										<template v-else>
											<!-- Vai ser preenchido manualmente pela API -->
											<v-select id="mySelect" label="municipio" @input="setSelected"
												:options="municipios_user" :reduce="municipio => municipio.codigo_origem"
												v-bind:class="{ 'is-invalid': errors.userCidade }" v-model="municipio_user"
												:disabled="municipioBloqueado_user"></v-select>
										</template>
										<!-- Fim -->
										<div class="invalid-feedback" v-if="errors.userCidade">
											<p>{{ errors.userCidade[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end municipio -->


								<!-- endereco -->
								<div class="col-md-5">
									<div class="form-group">
										<label class="form-control-label" for="input-new-endereco">Endereço</label>
										<input type="text" id="input-userEndereco"
											v-bind:class="{ 'is-invalid': errors.userEndereco }" class="form-control"
											placeholder="Endereço" v-model="userEndereco" required="true" maxlength="30">
									</div>
								</div>
								<!-- end endereco -->

								<!-- numero -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-numero_2">Número</label>
										<input type="text" id="input-new-numero_2" class="form-control" v-model="userNumero"
											placeholder="Nº" required="true" maxlength="50">
									</div>
								</div>
								<!-- end numero -->

								<!-- bairro -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-new-bairro">Bairro</label>
										<input type="text" id="input-new-bairro" class="form-control" v-model="userBairro"
											placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end bairro -->

							</div>
							<div class="row">

								<!-- função -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-funcao_2">Função</label>
										<input type="text" id="input-new-funcao_2" class="form-control" v-model="userFuncao"
											placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end função -->

								<!-- matricula -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-matricula_2">Matricula</label>
										<input type="text" id="input-new-matricula_2" class="form-control"
											v-model="userMatricula" placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end matricula -->

								<!-- permission -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label">Grupo de Acesso</label>
										<select v-bind:class="{ 'is-valid': errors.userPermission }" class="form-control"
											placeholder="Grupo de Acesso" v-model="userPermission">
											<option v-for="(item, key) in groupAcessList" :key="key" :value="item.id">{{
												item.name }}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.userPermission">
											<p>{{ errors.userPermission[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end permission -->

								<div class="col-md-12 text-right">
									<button type="submit" class="btn btn-primary">SALVAR</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- end modal new user -->


		<!-- modal edit user -->
		<div class="modal fade" id="modalUserEdit" tabindex="-1" aria-labelledby="modalUserEditLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalUserEditLabel">Editar Usuário(a)</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close"
							@click="resetUserFormEdit()">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST" v-on:submit.prevent="saveEditUser()">

							<div class="row">

								<!-- name -->
								<div class="col-md-5">
									<div class="form-group">
										<label class="form-control-label" for="input-new-name_2">Nome</label>
										<input type="text" id="input-new-name_2" class="form-control" v-model="userNameEdit"
											placeholder="" required="true">
									</div>
								</div>
								<!-- end name -->

								<!-- cpf -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-cpf_2">CPF</label>
										<input type="text" id="input-new-cpf_2" class="form-control" v-model="userCpfEdit"
											placeholder="" required="true" maxlength="14" v-mask="'###.###.###-##'">
									</div>
								</div>
								<!-- end cpf -->

								<!-- email -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-new-email_2">E-mail</label>
										<input type="email" id="input-new-email_2" class="form-control"
											v-model="userEmailEdit" placeholder="" required="true">
									</div>
								</div>
								<!-- end email -->

							</div>
							<div class="row">

								<!-- ddd -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-ddd_2">DDD</label>
										<input type="tel" id="input-new-ddd_2" class="form-control" v-model="userDddEdit"
											placeholder="" required="true" maxlength="3"
											v-bind:class="{ 'is-invalid': errors.userDdd }">
										<div class="invalid-feedback" v-if="errors.userDdd">
											<p>{{ errors.userDdd[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end ddd -->

								<!-- telefone -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-telefone_2">Telefone</label>
										<input type="text" id="input-new-telefone_2" class="form-control"
											v-model="userTelefoneEdit" placeholder="Telefone" maxlength="10"
											v-mask="'#####-####'" required="true">
									</div>
								</div>
								<!-- end telefone -->

							</div>
							<div class="row">

								<!-- cep -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-cep_2">CEP</label>
										<input type="text" id="input-new-cep_2"
											v-bind:class="{ 'is-invalid': errors.userCep }" class="form-control"
											placeholder="CEP" v-model="userCep" v-mask="'#####-###'" required="true"
											maxlength="9" @blur="verificarCEP('newUser')">
										<div class="invalid-feedback" v-if="errors.userCep">
											<p>{{ errors.userCep[0] }}</p>
										</div>
									</div>
								</div>
								<!-- end cep -->

								<!-- uf -->
								<div class="col-md-1">
									<div class="form-group">
										<label class="form-control-label" for="input-new-uf_2">UF</label>
										<input type="text" id="input-new-uf_2" class="form-control" v-model="userUf"
											placeholder="" required="true" maxlength="50" @change="onChangeUf('newUser')">
									</div>
								</div>
								<!-- end uf -->

								<!-- municipio -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-cidade_profile">Município</label>
										<!--Aplicado um if/else para caso o cep nao seja encontrado abra o campo para digitar
											o municipio  -->
										<template v-if="!cepValidadoUser && !municipio_user">
											<!-- Digitar manualmente -->
											<input type="text" id="input_municipio_user_manual"
												v-bind:class="{ 'is-invalid': errors.municipio_user_manual }"
												class="form-control" placeholder="Insira a cidade Manualmente"
												v-model="municipio_user_manual" required="true" maxlength="60">
										</template>
										<!-- fim -->
										<template v-else>
											<!-- Vai ser preenchido manualmente pela API -->
											<v-select id="mySelect" label="municipio" @input="setSelected"
												:options="municipios_user" :reduce="municipio => municipio.codigo_origem"
												v-bind:class="{ 'is-invalid': errors.userCidade }" v-model="municipio_user"
												:disabled="municipioBloqueado_user"></v-select>
										</template>
										<!-- Fim -->
										<div class="invalid-feedback" v-if="errors.userCidade">
											<p>{{ errors.userCidade[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-md-4 -->
								<!-- end municipio -->

								<!-- endereco -->
								<div class="col-md-5">
									<div class="form-group">
										<label class="form-control-label" for="input-new-endereco_2">Endereço</label>
										<input type="text" id="input-userEndereco_2"
											v-bind:class="{ 'is-invalid': errors.userEndereco }" class="form-control"
											placeholder="Endereço" v-model="userEndereco" required="true" maxlength="30">
									</div>
								</div>
								<!-- end endereco -->

								<!-- numero -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-new-numero">Número</label>
										<input type="text" id="input-new-numero" class="form-control"
											v-model="userNumeroEdit" placeholder="Nº" required="true" maxlength="50">
									</div>
								</div>
								<!-- end numero -->

								<!-- bairro -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-new-bairro_2">Bairro</label>
										<input type="text" id="input-new-bairro_2" class="form-control" v-model="userBairro"
											placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end bairro -->

							</div>
							<div class="row">

								<!-- função -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-funcao">Função</label>
										<input type="text" id="input-new-funcao" class="form-control"
											v-model="userFuncaoEdit" placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end função -->

								<!-- matricula -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-new-matricula">Matricula</label>
										<input type="text" id="input-new-matricula" class="form-control"
											v-model="userMatriculaEdit" placeholder="" required="true" maxlength="50">
									</div>
								</div>
								<!-- end matricula -->

								<!-- permission -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label">Grupo de Acesso</label>
										<select v-bind:class="{ 'is-valid': errors.userPermissionEdit }"
											class="form-control" placeholder="Grupo de Acesso" v-model="userPermissionEdit"
											required="true">
											<option v-for="(item, key) in groupAcessList" :key="key" :value="item.id">{{
												item.name }}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.userPermissionEdit">
											<p>{{ errors.userPermissionEdit[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end permission -->

								<div class="col-md-12 text-right">
									<button type="submit" class="btn btn-primary">SALVAR</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- end modal edit user -->

		<!-- nova Insituicao do GrupoEcon -->
		<div class="modal fade" id="modalNovaInstituicao" tabindex="-1" aria-labelledby="modalNovaInstituicaoLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalNovaInstituicaoLabel">Nova Instituição</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form method="POST">
							<div class="row">
								<div class="col-6 pt-4">
									<label for="instituicao" class="form-control-label">Selecione a Instituição</label>
									<v-select multiple id="instituicao" placeholder="Selecione uma ou mais Instituições"
										label="label" @input="setSelectInstituicao" :options="bancoOptions"
										v-model="instituicao">
										<template #tags="{ values, search, isOpen, removeTag }">
											<div class="vue-select__tags">
												<span v-for="option in values" :key="option.id"
													class="vue-select__tags-item" @click.stop="removeTag(option)">
													{{ option.label }}
												</span>
											</div>
										</template>
									</v-select>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- fim -->

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- col-12 -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
	props: ['auth', 'banco', 'permissions'],
	data() {
		const auth = JSON.parse(this.auth);

		let banco = ''
		if (auth.is_group_economic != 1) {
			banco = JSON.parse(this.banco);
		}

		return {
			nameGroup: '',
			instituicao: [],
			bancos: [],
			bancoOptions: [],
			userIsGroupEcon: auth.is_group_economic == 1 ? "S" : "N",
			idGroupEcon: auth.grupo_economico_id,
			editProfile: true,
			btnEdit: false,
			groupAcess: '',
			groupAcessList: [],

			id: banco != '' ? banco.id : '',
			name: banco != '' ? banco.nome : '',
			name_fantasy: banco != '' ? banco.nome_fantasia : '',
			cnpj: banco != '' ? banco.cnpj : '',
			ddd: banco != '' ? banco.ddd : '',
			telefone: banco != '' ? banco.telefone : '',
			cep: banco != '' ? banco.cep : '',
			estado: banco != '' ? banco.estado : '',
			cidade: banco != '' ? banco.cidade : '',
			endereco: banco != '' ? banco.endereco : '',
			numero: banco != '' ? banco.numero : '',
			bairro: banco != '' ? banco.bairro : '',
			complemento: banco != '' ? banco.complemento : '',
			token_id: banco != '' ? banco.token_id : '',
			token_api: banco != '' ? banco.token_api : '',
			cod_serpro: banco != '' ? banco.cidade : '',

			serproBloqueado: true,
			idProfile: auth.id,
			profileName: auth.profile.name,
			cpf: auth.profile.cpf,
			email: auth.email,
			ddd_profile: auth.profile.ddd_profile,
			telefone_profile: auth.profile.telefone,
			cep_profile: auth.profile.cep,
			endereco_profile: auth.profile.endereco,
			bairro_profile: auth.profile.bairro,
			cidade_profile: auth.profile.cidade,
			uf_profile: auth.profile.uf,
			numero_profile: auth.profile.numero,
			funcao_profile: auth.profile.funcao,
			matricula_profile: auth.profile.matricula,
			password: '',
			codSerProProfile: '',

			userName: '',
			userCpf: '',
			userEmail: '',
			userDdd: '',
			userTelefone: '',
			userCep: '',
			userEndereco: '',
			userBairro: '',
			userCidade: '',
			userUf: '',
			userNumero: '',
			userFuncao: '',
			userMatricula: '',
			userPassword: '',
			userCodSerPro: '',
			userPermission: '',

			userNameEdit: '',
			userCpfEdit: '',
			userEmailEdit: '',
			userDddEdit: '',
			userTelefoneEdit: '',
			userNumeroEdit: '',
			userFuncaoEdit: '',
			userMatriculaEdit: '',
			userPasswordEdit: '',
			userCodSerProEdit: '',
			userPermissionEdit: '',

			cepValidadoUser: false,
			ufBloqueadoUser: false,
			municipioBloqueado_user: false,
			municipio_user_manual: '',
			municipios_user: [],
			municipio_user: '',

			cepValidoProfile: false,
			ufBloqueadoProfile: false,
			municipioBloqueado_profile: false,
			municipio_profile_manual: '',
			municipios_profile: [],
			municipio_profile: '',

			cepValido: false,
			ufBloqueado: false,
			municipioBloqueado: false,
			municipio_ag_manual: "",
			municipios_ag: [],
			municipio_ag: '',

			userCan: '',
			errors: [],
			states: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			message: '',
			loading: false,
			fullPage: true,
			inputIcon: 'fas fa-eye',
			inputType: 'password',
			detranBanco: banco.detrans,
			id_detran_banco: '',
			estado_detran_banco: '',
			codigo_agente: '',
			nomeMunDev: '',
			modalAgente: {
				estado: '',
				codigo: ''
			},
			tableColumns: [
				{
					label: "Estado",
					field: "detran.estado",
				}, {
					label: "Código do Agente",
					field: "codigo_agente",
				}, {
					label: "Ações",
					field: "actions",
					html: true,
					sortable: false
				},
			],
			tableUserColumns: [
				{
					label: "Nome",
					field: "nome",
				},
				{
					label: "E-mail",
					field: "email",
				},
				{
					label: "DDD",
					field: "ddd",
				},
				{
					label: "Telefone",
					field: "telefone",
				},
				{
					label: "Grupo de Acesso",
					field: "permission",
				},
				{
					label: "Status",
					field: "_status"
				},
				{
					label: "Ações",
					field: "actions",
					html: true,
					sortable: false
				},
			],
			columnsBancos: [
				{
					label: 'Razão Socail',
					field: "razao_banco"
				},
				{
					label: 'Fantasia',
					field: 'fantasia_banco'
				},
				{
					label: 'CNPJ',
					field: 'cnpj_banco',
				},
				{
					label: 'Cod. Agente',
					field: 'codAgente_banco'
				},
				{
					label: 'Endereço',
					field: 'endereco_banco'
				},
				{
					label: 'Bairro',
					field: 'bairro_banco'
				},
				{
					label: "Número",
					field: "numero_banco",
				},
				{
					label: "Complemento",
					field: "complemento_banco",
				},
				{
					label: "UF",
					field: "estado_banco",
				},
				{
					label: "DDD",
					field: "ddd_banco",
				},
				{
					label: "Telefone",
					field: "telefone_banco",
				},
				// {
				// 	label: "Ações",
				// 	field: "action",
				// 	html: true,
				// },
			],
			tablePagination: {
				enabled: true,
				mode: 'records',
				position: 'bottom',
				dropdownAllowAll: true,
				perPageDropdown: [10, 30, 50],
				nextLabel: 'Próximo',
				prevLabel: 'Anterior',
				rowsPerPageLabel: '',
				ofLabel: 'de',
				allLabel: 'Todos',
				pageLabel: '',
				perPageDropdownEnabled: true,
				compactMode: true
			},
			modalUserTitle: 'Novo Usuário(a)',
			modalEditUserTitle: 'Editar Usuário(a)',
			user: {},
			userEdited: false,
			userID: '',
			users: [],
			listaItens: [],
			selecionados: [],
			selectAllPortal: false,
			selectAllEmail: false,
			userId: '',


		}
	},
	mounted() {
		this.userCan = JSON.parse(this.permissions);
		if (this.userCan.list) {
			this.loadUsers();
		}

		if (this.userIsGroupEcon != 'S') {
			this.verificarCEP('banco');
		}
		this.verificarCEP('user');
		this.carregarListaNotificacao();
		this.dadosNotificacao();
		this.loadGroupAcess();

		if (this.userIsGroupEcon == "S") {
			this.getAllBancos()
			this.loadGrupoEcon()
		}
	},

	computed: {
		selectedPermission() {
			return this.groupAcessList.find(item => item.id === this.userPermission);
		}
	},
	methods: {
		setSelectInstituicao(item) {
			this.instituicao = item.map(banco => banco);
		},
		deleteInstituicaoGrupoEcon(props) {
			let _this = this;

			_this.$swal({
				title: "Remover Instituição",
				text: "Tem certeza que deseja remover a Instituição?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Remover",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					axios.delete(`/portal/grupoecon/removeInstituicao/${props.id_gpEconBanco}`).then((res) => {

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Instituição removida com sucesso!",
							icon: "success"
						});

						this.getAllBancos()
						this.loadGrupoEcon()

					}).catch(error => {
						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao remover a Instituição. Tente novamente!",
							icon: "error"
						});

					});
				}
			});
		},
		loadGrupoEcon() {
			let _this = this;
			_this.bancos = []
			_this.loading = true

			axios
				.get(`/portal/grupoecon/${this.idGroupEcon}`)
				.then((res) => {

					for (let key in res.data) {
						if (res.data.hasOwnProperty(key)) {
							const item = res.data[key];
							if (res.data[key].fantasia_grupoecon === '') {
								_this.nameGroup = res.data[key].razao_grupoecon;
							} else {
								_this.nameGroup = res.data[key].fantasia_grupoecon;
							}
							if (Array.isArray(item.infoBancos)) {
								item.infoBancos.forEach((info) => {
									if (typeof info === 'object') {
										info.vgt_id = key;
										_this.bancos.push(info);
									}
								});
							}
						}
					}
					_this.loading = false;
				})
				.catch((err) => {
					_this.loading = false;
					console.error(err);
				});
		},
		getAllBancos() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancoOptions = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
					selected: false
				}));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		carregarListaNotificacao() {
			axios.get('lista/notificacao').then((res) => {
				this.listaItens = res.data.map(item => ({
					...item,
					email: false,
					push: false
				}));
			}).catch((error) => {
				console.error('Erro ao carregar lista de itens:', error);
			});
		},
		dadosNotificacao() {
			axios.get('getdata/notificacao').then((res) => {
				const dados = res.data;
				this.listaItens = this.listaItens.map(item => {
					const dadosItem = dados.find(dado => dado.notificacao_id === item.id);

					// Verifica se dadosItem foi encontrado antes de acessar suas propriedades
					if (dadosItem) {
						return {
							...item,
							email: dadosItem.email === 1,
							push: dadosItem.push === 1,
						};
					} else {
						// Caso não tenha sido encontrado, retorna o item original da listaItens
						return item;
					}
				});
			}).catch((error) => {
				console.error('Erro ao obter dados da notificação:', error);
			});
		},
		sendNotification() {
			axios.post('postdata/notificacao', {
				notificacoes: this.listaItens.map(item => ({
					notificacao_id: item.id,
					email: item.email ? 1 : 0, // Transforma true em 1 e false em 0
					push: item.push ? 1 : 0 // Transforma true em 1 e false em 0
				}))
			})
				.then((res) => {
					// Código de sucesso aqui
					this.$swal({
						title: "Sucesso",
						text: "Notificações cadastradas com sucesso!",
						icon: "success"
					});
					window.location.reload();
				})
				.catch((error) => {
					// Código de erro aqui
					this.$swal({
						title: "Erro",
						text: "Falha ao gravar as notificações. Tente novamente!",
						icon: "error"
					});
				});
		},
		selectAllOptions(group, value) {
			this.listaItens.forEach((item) => {
				if (group === 'portal') {
					item.push = value;
				} else if (group === 'email') {
					item.email = value;
				}
			});
		},
		loadGroupAcess() {
			let _this = this;
			_this.loading = true;
			axios.get('/portal/permissions').then((res) => {

				_this.groupAcessList = res.data.filter(item => item.id !== 3);
				_this.loading = false;

			}).catch((err) => {
				_this.loading = false;
			})
		},
		loadTableData() {
			axios.get('/portal/users').then((res) => {
				this.users = res.data;
			}).catch((err) => {
				console.error('Erro ao carregar dados da tabela', err);
			});
		},
		openEditModal(idUser) {

			axios.get(`users/getUser/` + idUser)
				.then((response) => {
					this.userNameEdit = response.data.nome;
					this.userCpfEdit = response.data.cpf;
					this.userEmailEdit = response.data.email;
					this.userDddEdit = response.data.ddd;
					this.userTelefoneEdit = response.data.telefone;
					this.userCep = response.data.cep;
					this.userEndereco = response.data.endereco;
					this.userBairro = response.data.bairro;
					this.municipio_user = response.data.municipio;
					this.userUf = response.data.uf
					this.userNumeroEdit = response.data.numero;
					this.userFuncaoEdit = response.data.funcao;
					this.userMatriculaEdit = response.data.matricula;
					this.userPermissionEdit = response.data.tp_grupo_acesso;
					this.userId = response.data.userId;

					$('#modalUserEdit').modal('show'); // Abra o modal
				})
				.catch((error) => {
					console.error('Erro ao buscar detalhes do perfil', error);
				});
		},
		updateNotificacao() {
			let _this = this;
			_this.errors = [];
			_this.message = '';
			_this.loading = true;
			let dataUser = {
				'id': _this.token_id,
				'registro-email': _this.contratoRegisEmail,
				'erro-email': _this.contratoErroEmail,
				'cred10-email': _this.credVenc10Email,
				'cred20-email': _this.credVenc20Email,
				'cred30-email': _this.credVenc30Email,
				'registro-push': _this.contratoRegisPush,
				'erro-push': _this.contratoErroPush,
				'cred10-push': _this.credVenc10Push,
				'cred20-push': _this.credVenc20Push,
				'cred30-push': _this.credVenc30Push,
			};
			axios.post('/portal/perfil/update', dataUser).then((res) => {

				_this.loading = false;

				//show confirm alert
				_this.$swal({
					title: 'Sucesso',
					text: 'Notificação atualizada com sucesso!',
					icon: 'success'
				}).then(() => {
					// recarrega a página
					location.reload();
				});
			})
				.catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: 'Erro',
						text: 'Falha ao atualizar Notificação. Tente novamente!',
						icon: 'error'
					});
				});
		},
		editProf() {
			this.editProfile = false;
			this.btnEdit = true;
		},
		changeInputView() {
			if (this.inputType == 'password') {
				this.inputType = "text";
				this.inputIcon = "fas fa-eye-slash";
			} else {
				this.inputType = "password";
				this.inputIcon = "fas fa-eye";
			}
		},
		deleteUser(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Excluir Usuário(a)",
				text: "Tem certeza que deseja excluir esse(a) usuário(a)?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Excluir",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.delete("/portal/users/" + ID).then((res) => {

						_this.users.splice(index, 1);

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Usuário(a) excluído com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao excluir a usuário(a). Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		disableUser(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Desativar Usuário(a)",
				text: "Tem certeza que deseja desativar esse(a) usuário(a)?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Desativar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.post("/portal/users/" + ID + "/disable").then((res) => {

						//_this.users.splice(index, 1);
						_this.users.map((row) => {
							if (row.id == ID) {
								row.status = 0;
							}
						});

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Usuário(a) desativado com sucesso!",
							icon: "success"
						}).then(() => {
							_this.loadTableData();
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao desativar a usuário(a). Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		enableUser(ID, index) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Ativar Usuário(a)",
				text: "Tem certeza que deseja ativar esse(a) usuário(a)?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Ativar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.post("/portal/users/" + ID + "/enable").then((res) => {

						//_this.users.splice(index, 1);
						_this.users.map((row) => {
							if (row.id == ID) {
								row.status = 1;
							}
						});

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Usuário(a) ativado com sucesso!",
							icon: "success"
						}).then(() => {
							_this.loadTableData();
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao ativar a usuário(a). Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		loadUsers() {
			let _this = this;
			_this.loading = true;
			axios.get('/portal/users').then((res) => {
				_this.users = _this.users.concat(res.data);
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},

		saveEditUser() {

			let _this = this;
			_this.loading = true;
			let formData = new FormData();

			formData.append('nome', _this.userNameEdit),
				formData.append('email', _this.userEmailEdit),
				formData.append('cpf', _this.userCpfEdit),
				formData.append('funcao', _this.userFuncaoEdit),
				formData.append('ddd', _this.userDddEdit),
				formData.append('telefone', _this.userTelefoneEdit),
				formData.append('cep', _this.userCep),
				formData.append('endereco', _this.userEndereco),
				formData.append('bairro', _this.userBairro),
				formData.append('cidade', _this.municipio_user ?? _this.municipio_user_manual),
				formData.append('uf', _this.userUf),
				formData.append('numero', _this.userNumeroEdit),
				formData.append('matricula', _this.userMatriculaEdit),
				formData.append('permission', _this.userPermissionEdit),
				formData.append('_method', 'PATCH')

			axios.post('/portal/users/' + _this.userId + '/update', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;


				$("#modalUser").modal('hide');


				//show confirm alert
				_this.$swal({
					title: 'Sucesso',
					text: 'Usuário(a) atualizado com sucesso!',
					icon: 'success'
				}).then(() => {
					_this.openEditModal(userId);
				});
				_this.loadTableData();

			}).catch((err) => {

				_this.loading = false;

				//show error alert
				_this.$swal({
					title: 'Erro',
					text: 'Falha ao atualizar usuário(a). Tente novamente!',
					icon: 'error'
				});
			});
		},

		saveNewUser() {

			let _this = this;
			_this.loading = true;
			let formData = new FormData();

			formData.append('name', _this.userName),
				formData.append('email', _this.userEmail),
				formData.append('cpf', _this.userCpf),
				formData.append('funcao', _this.userFuncao),
				formData.append('ddd', _this.userDdd),
				formData.append('telefone', _this.userTelefone),
				formData.append('cep', _this.userCep),
				formData.append('endereco', _this.userEndereco),
				formData.append('bairro', _this.userBairro),
				formData.append('cidade', _this.municipio_user ?? _this.municipio_user_manual),
				formData.append('uf', _this.userUf),
				formData.append('numero', _this.userNumero),
				formData.append('matricula', _this.userMatricula),
				formData.append('permission', _this.userPermission),
				formData.append('portaUser', true),
				formData.append('password', _this.userPassword);
			if (this.userIsGroupEcon == 'S') {
				formData.append('isgroupecon', 1),
					formData.append('grupoEconId', this.idGroupEcon);
			} else {
				formData.append('isgroupecon', 0),
					formData.append('banco', this.id)
			}

			axios.post('/portal/newUserPortal/', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				if (res.data) {
					_this.users.push(res.data);
					$("#modalUser").modal('hide');

					_this.$swal({
						title: 'Sucesso',
						text: 'Usuário(a) cadastrado com sucesso!',
						icon: 'success'
					}).then(() => {
						_this.loadTableData();
					});

					_this.resetCloseNewUser();
				} else {
					_this.$swal({
						title: 'Erro',
						html: `Houve um erro na inclusão do usuário.`,
						icon: 'error'
					});
				}



			}).catch((err) => {

				_this.loading = false;

				if (err.response && err.response.status !== 500) {
					let errors = err.response.data;

					if (typeof errors === 'object' && errors !== null) {
						var errorFields = Object.keys(errors.errors).map((key) => {
							if (Array.isArray(errors.errors[key])) {
								return errors.errors[key].map(error => `${error}`);
							} else {
								return `${errors.errors[key]}`;
							}
						}).join(', <br> - ');

						_this.$swal({
							title: 'Erro',
							html: `As seguintes informações preenchidas estão incorretas:<br> - ${errorFields}`,
							icon: 'error'
						});
					} else {
						_this.$swal({
							title: 'Erro',
							text: err.response.data,
							icon: 'error'
						});
					}
				}
			});

		},

		resetUserForm() {
			this.user.name = "";
			this.user.email = "";
			this.user.password = "";
			this.userID = "";
			this.userEdited = false;
			this.modalUserTitle = "Novo Usuário(a)";
			if (this.municipio_user == null) {
				this.municipio_user_manual = "";
			} else {
				this.municipio_user = "";
			}
		},
		resetCloseNewUser() {

			this.userName = "";
			this.userCpf = "";
			this.userEmail = "";
			this.userDdd = "";
			this.userTelefone = "";
			this.userCep = "";
			this.userEndereco = "";
			this.userBairro = "";
			if (this.municipio_user == null) {
				this.municipio_user_manual = "";
			} else {
				this.municipio_user = "";
			}
			this.userUf = "";
			this.userNumero = "";
			this.userFuncao = "";
			this.userMatricula = "";
			this.userPassword = "";
			this.userCodSerPro = "";
			this.userPermission = "";
		},
		resetUserFormEdit() {
			this.userCep = "";
			if (this.municipio_user == null) {
				this.municipio_user_manual = "";
			} else {
				this.municipio_user = "";
			}
			this.userUf = "";
			this.userBairro = "";
			this.userEndereco = "";

		},
		updateData() {
			let _this = this;
			_this.loading = true;
			let formData = new FormData();

			formData.append('name', _this.name)
			formData.append('name_fantasy', _this.name_fantasy)
			formData.append('cnpj', _this.cnpj == '' ? 'null' : _this.cnpj)
			formData.append('ddd', _this.ddd)
			formData.append('telefone', _this.telefone)
			formData.append('cep', _this.cep)
			formData.append('estado', _this.estado)
			formData.append('cidade', _this.cod_serpro)
			formData.append('endereco', _this.endereco)
			formData.append('numero', _this.numero)
			formData.append('bairro', _this.bairro)
			formData.append('complemento', _this.complemento)
			formData.append('_method', "PATCH")

			axios.post('/portal/perfil/update/' + _this.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				_this.$swal({
					title: 'Sucesso',
					text: 'Dados da Instituição Financeira atualizados com sucesso!',
					icon: 'success'
				}).then(() => {
					location.reload();
				});
			}).catch((err) => {

				_this.loading = false;

				if (err.response.status != 500) {
					let errors = err.response.data.errors

					var errorFields = Object.keys(errors).map((key) => {
						return `${errors[key][0]}`;
					}).join(' <br> ');

					_this.$swal({
						title: 'Erro',
						html: ` As seguinte informações da instituição financeira estão com erro: <br> ${errorFields}`,
						icon: 'error'
					});
				} else {
					_this.$swal({
						title: 'Erro',
						text: 'Falha ao atualizar perfil. Tente novamente!',
						icon: 'error'
					});
				}
			});
		},
		updateDataUser() {
			let _this = this;
			_this.loading = true;
			let formData = new FormData();

			formData.append('nome', _this.profileName)
			formData.append('cpf', _this.cpf)
			formData.append('email', _this.email)
			formData.append('ddd', _this.ddd_profile)
			formData.append('telefone', _this.telefone_profile)
			formData.append('cep', _this.cep_profile)
			formData.append('endereco', _this.endereco_profile)
			formData.append('bairro', _this.bairro_profile)
			formData.append('cidade', _this.municipio_profile_manual == "" ? _this.municipio_profile : _this.municipio_profile_manual)
			formData.append('uf', _this.uf_profile)
			formData.append('numero', _this.numero_profile)
			formData.append('funcao', _this.funcao_profile)
			formData.append('matricula', _this.matricula_profile)
			formData.append('senha', _this.password)
			formData.append('_method', "PATCH")

			axios.post('/portal/perfil/updateUser/' + _this.idProfile, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				_this.$swal({
					title: 'Sucesso',
					text: 'Dados da Instituição Financeira atualizados com sucesso!',
					icon: 'success'
				}).then(() => {
					location.reload();
				});
			}).catch((err) => {

				_this.loading = false;

				if (err.response.status != 500) {
					let errors = err.response.data.errors

					var errorFields = Object.keys(errors).map((key) => {
						return `${errors[key][0]}`;
					}).join(' <br> ');

					_this.$swal({
						title: 'Erro',
						html: ` As seguinte informações do usuário estão com erro: <br> ${errorFields}`,
						icon: 'error'
					});
				} else {
					_this.$swal({
						title: 'Erro',
						text: 'Falha ao atualizar perfil. Tente novamente!',
						icon: 'error'
					});
				}
			});
		},
		editCodigoAgente(item) {
			this.id_detran_banco = item.id;

			this.modalAgente.codigo = item.codigo_agente;
			this.modalAgente.estado = item.detran.estado;

			$("#modalCodigoAgente").modal('show');
		},
		updateCodigoAgente() {
			let _this = this;
			_this.loading = true;

			let formData = new FormData();

			formData.append('id', _this.id_detran_banco)
			formData.append('codigo_agente', _this.modalAgente.codigo)
			formData.append('_method', "PATCH")

			axios.post('/portal/perfil/updateCodigoAgente', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {

				_this.loading = false;

				_this.detranBanco.map((row) => {
					if (row.id == _this.id_detran_banco) {
						row.codigo_agente = _this.modalAgente.codigo
					}
				});

				$("#modalCodigoAgente").modal('hide');

				_this.$swal({
					title: 'Sucesso',
					text: 'Código do Agente atualizado com sucesso!',
					icon: 'success'
				});
			}).catch((err) => {

				_this.loading = false;

				if (err.response.status != 500) {
					let errors = err.response.data.errors

					var errorFields = Object.keys(errors).map((key) => {
						return `${errors[key][0]}`;
					}).join(' <br> ');

					_this.$swal({
						title: 'Erro',
						html: ` As seguinte informações estão com erro: <br> ${errorFields}`,
						icon: 'error'
					});
				} else {

					_this.$swal({
						title: 'Erro',
						text: 'Falha ao atualizar o Código do Agente. Tente novamente!',
						icon: 'error'
					});
				}
			});
		},

		// Função para verificar o cep e realizar os devidos bloqueios de campos e preenchimentos
		async verificarCEP(param) {
			let cep;

			if (param == "banco") {
				cep = this.cep.replace('-', '');
			} else if (param == 'user') {
				cep = this.cep_profile.replace('-', '');
			} else {
				cep = this.userCep.replace('-', '');
			}

			try {
				const response = await axios.get(`/portal/consultacep/${cep}`);
				const data = response.data;

				if (param == "banco") {

					this.serproBloqueado = data.codigo_serpro != "" ? true : false
					this.cepValido = true;
					this.endereco = data.logradouro;
					this.bairro = data.bairro;
					this.municipio_ag = data.localidade;
					this.estado = data.uf;
					this.ufBloqueado = true;
					this.municipioBloqueado = true;
					this.cod_serpro = data.codigo_serpro;
				} else if (param == 'user') {

					this.cepValidoProfile = true
					this.endereco_profile = data.logradouro;
					this.bairro_profile = data.bairro
					this.municipio_profile = data.localidade;
					this.uf_profile = data.uf
					this.ufBloqueadoProfile = true
					this.municipioBloqueado_profile = true
					this.codSerProProfile = data.codigo_serpro;
				} else {

					this.cepValidadoUser = true
					this.ufBloqueadoUser = true
					this.municipioBloqueado_user = true
					this.userEndereco = data.logradouro
					this.userBairro = data.bairro
					this.municipio_user = data.localidade
					this.userUf = data.uf
					this.onChangeUf('newUser');
					this.userCodSerPro = data.codigo_serpro;
				}
			} catch (error) {
				if (param == "banco") {
					this.numero = '';
					this.serproBloqueado = true;
					this.cepValido = false;
					this.endereco = '';
					this.bairro = '';
					this.municipio_ag = '';
					this.estado = '';
					this.ufBloqueado = false;
					this.municipioBloqueado = false;
					this.cod_serpro = '';
				} else if (param == 'user') {
					this.cepValidoProfile = false
					this.endereco_profile = '';
					this.bairro_profile = ''
					this.cidade_profile = '';
					this.uf_profile = ''
					this.ufBloqueadoProfile = false
					this.municipioBloqueado_profile = false
					this.codSerProProfile = data.codigo_serpro;
				} else {
					this.cepValidadoUser = false
					this.ufBloqueadoUser = false
					this.municipioBloqueado_user = false
					this.userEndereco = ''
					this.userBairro = ''
					this.userCidade = ''
					this.userUf = ''
					this.userCodSerPro = data.codigo_serpro;
				}
			}
		},
		gravaInfoMunicipio() {
			let nomeMunDev = "";

			if (this.municipio_ag_manual != "") { // Se o campo de município foi preenchido manualmente
				nomeMunDev = this.municipio_ag_manual;
			} else { // Caso contrário, encontra o nome do município selecionado no v-select
				let arr = this.municipios_ag;
				for (let i = 0; i < arr.length; i++) {

					if (arr[i]['municipio'] === this.municipio_ag) {
						nomeMunDev = arr[i]['municipio'];
						break;
					}
				}
			}
		},

		onChangeUf(typeSearch) {
			let _this = this;
			var estado;

			if (typeSearch == 'agente') {
				estado = _this.estado
			} else if (typeSearch == 'user') {
				estado = _this.uf_profile
			} else {
				estado = _this.userUf
			}

			axios.get("/api/portal/detrancidades/search/" + estado).then((res) => {
				if (typeSearch == 'agente') {
					_this.municipios_ag = res.data;

				} else if (typeSearch == 'user') {
					_this.municipios_profile = res.data;

				} else {
					_this.municipios_user = res.data;
				}
			}).catch((err) => {
				_this.$swal({
					title: "Erro",
					text: "Falha ao pesquisar o estao: " + estado + " tente novamente!",
					icon: "error"
				});
			});
		},

		setSelected(item) {
			let _this = this;
			let codigo = item;
			_this.cidade = codigo;
			// _this.municipio_dev = _this.municipios_dev.municipio
		},
	},
	components: {
		VueGoodTable,
	}
}
</script>
