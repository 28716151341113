<template>
	<div class="row">
		<div class="col-12">
			<div class="card pt-1 pb-1" style="margin-top:-35px; margin-bottom: 5px;">
				<div class="wizard-form" id="grad1">
					<div class="row justify-content-center mt-0">
						<div class="col-12">
							<div class="card mt-1 mb-1 bxShadow">
								<h5 class="text-center"><strong>Resultado da Consulta DETRAN {{ this.estado }} - {{
									this.title
								}}</strong>
								</h5>
								<div class="row">
									<div class="col-md-12 mx-0 mb-1">
										<form id="msform" class="mt-2">
											<div class="form-card" style="padding">
												<div class="row form-distance">

													<!-- UFPlaca -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label" for="input-UFPlaca">UF
																Placa</label>
															<input type="text" disabled="true" id="input-UFPlaca"
																v-model="UFPlaca" class="form-control">
														</div><!-- form group -->
													</div><!-- col-md-6 -->
													<!-- end UFPlaca -->

													<!--placa-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-placa">Placa</label>
															<input type="text" id="input-placa" class="form-control"
																disabled v-model="placa">
														</div>
													</div>
													<!--end placa-->

													<!--renavam-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-renavam">Renavam</label>
															<input type="text" id="input-renavam" class="form-control"
																disabled v-model="renavam">
														</div>
													</div>
													<!--end renavam-->

													<!-- anoFabricacao -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label" for="input-anoFabricacao">Ano
																Fabricação</label>
															<input type="tel" id="input-anoFabricacao" class="form-control"
																v-model="anoFabricacao" disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-2 -->
													<!-- end anoFabricacao -->

													<!-- anoModelo -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label" for="input-anoModelo">Ano
																Modelo</label>
															<input type="text" id="input-anoModelo" class="form-control"
																v-model="anoModelo" disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-2 -->
													<!-- end anoModelo -->

													<!--idenRemarcacao-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-idenRemarcacao">Remarcado?</label>
															<input type="text" id="input-idenRemarcacao"
																class="form-control" disabled v-model="idenRemarcacao">
														</div>
													</div>
													<!--end idenRemarcacao-->

												</div>
												<div class="row form-distance">

													<!--situacao-->
													<div class="col-md-2" v-if="estado != 'SC' && estado != 'RJ'">
														<div class="form-group">
															<label class="form-control-label" for="input-situacao">Situacao
																Contrato</label>
															<input type="text" id="input-situacao" class="form-control"
																disabled v-model="situacao">
														</div>
													</div>
													<!--end situacao-->

													<!--flagContrato-->
													<div class="col-md-2" v-if="estado == 'SP'">
														<div class="form-group">
															<label class="form-control-label" for="input-flagContrato">Flag
																de Contrato</label>
															<input type="text" id="input-flagContrato" class="form-control"
																disabled v-model="flagContrato">
														</div>
													</div>
													<!--end flagContratoe-->

													<!--numeroContrato-->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroContrato">Número de Contrato</label>
															<input type="text" id="input-numeroContrato"
																class="form-control" disabled v-model="numeroContrato">
														</div>
													</div>
													<!--end numeroContrato-->

													<!-- dataContrato -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label" for="input-dataContrato">Data
																do Contrato</label>
															<input type="date" id="input-dataContrato" class="form-control"
																v-model="dataContrato" disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataContrato -->

													<!--tipoGravame-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label" for="input-tipoGravame">Tipo
																Gravame</label>
															<input type="text" id="input-tipoGravame" class="form-control"
																disabled v-model="tipoGravame">
														</div>
													</div>
													<!--end tipoGravame-->

													<!--numeroGravame-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroGravame">Número Gravame</label>
															<input type="text" id="input-numeroGravame" class="form-control"
																disabled v-model="numeroGravame">
														</div>
													</div>
													<!--end numeroGravame-->

													<!--situacaoGravame-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-situacaoGravame">Situação Gravame</label>
															<input type="text" id="input-situacaoGravame"
																class="form-control" disabled v-model="situacaoGravame">
														</div>
													</div>
													<!--end situacaoGravame-->

													<!--codigo agente-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-codigoAgente">Código do Agente</label>
															<input type="text" id="input-codigoAgente" class="form-control"
																disabled v-model="codigoAgente">
														</div>
													</div>
													<!--end codigio agente-->

													<!--SequencialAditivo-->
													<div class="col-md-2" v-if="estado == 'SC'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-SequencialAditivo">Sequencial de
																Aditivo</label>
															<input type="text" id="input-SequencialAditivo"
																class="form-control" disabled v-model="SequencialAditivo">
														</div>
													</div>
													<!--end SequencialAditivo-->

													<!--SequencialContrato-->
													<div class="col-md-2" v-if="estado == 'SC'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-SequencialContrato">Sequencial de
																Contrato</label>
															<input type="text" id="input-SequencialContrato"
																class="form-control" disabled v-model="SequencialContrato">
														</div>
													</div>
													<!--end SequencialContrato-->

												</div>
												<div class="row form-distance">

													<!-- cnpjAgente -->
													<div class="col-md-3">
														<div class="form-group">
															<label class="form-control-label" for="input-cnpjAgente">CNPJ do
																Agente</label>
															<input type="text" id="input-cnpjAgente" class="form-control"
																v-model="cnpjAgente" v-mask="'##.###.###/####-##'"
																disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-6 -->
													<!-- end cnpjAgente -->

													<!--nomeFinanciado-->
													<div class="col-md-5">
														<div class="form-group">
															<label class="form-control-label"
																for="input-nomeFinanciado">Nome Devedor</label>
															<input type="text" id="input-nomeFinanciado"
																class="form-control" disabled v-model="nomeFinanciado">
														</div>
													</div>
													<!--end nomeFinanciado-->

													<!--tipoDocumentoFinanciado-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-tipoDocumentoFinanciado">Tipo Doc
																Financiado</label>
															<input type="text" id="input-tipoDocumentoFinanciado"
																class="form-control" disabled
																v-model="tipoDocumentoFinanciado">
														</div>
													</div>
													<!--end tipoDocumentoFinanciado-->

													<!--numeroDocumentoFinanciado-->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroDocumentoFinanciado">Número Doc
																Devedor</label>
															<input type="text" id="input-numeroDocumentoFinanciado"
																class="form-control" disabled
																v-model="numeroDocumentoFinanciado">
														</div>
													</div>
													<!--end numeroDocumentoFinanciado-->

												</div>
												<div class="row form-distance">

													<!-- dataCancelamento -->
													<div class="col-md-2" v-if="estado == 'SP'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-dataCancelamento">Data Chancela</label>
															<input type="date" id="input-dataCancelamento"
																class="form-control" v-model="dataCancelamento"
																disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataCancelamento -->

													<!-- dataEmissao -->
													<div class="col-md-2" v-if="estado == 'SP'">
														<div class="form-group">
															<label class="form-control-label" for="input-dataEmissao">Data
																Emissão</label>
															<input type="date" id="input-dataEmissao" class="form-control"
																v-model="dataEmissao" disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataEmissao -->

													<!-- dataInclusaoGravame -->
													<div class="col-md-2" v-if="estado == 'SP'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-dataInclusaoGravame">Data Inclusão
																Gravame</label>
															<input type="date" id="input-dataInclusaoGravame"
																class="form-control" v-model="dataInclusaoGravame"
																disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataInclusaoGravame -->

													<!-- dataQuitacao -->
													<div class="col-md-2" v-if="estado == 'SP'">
														<div class="form-group">
															<label class="form-control-label" for="input-dataQuitacao">Data
																Quitação do
																Contrato</label>
															<input type="date" id="input-dataQuitacao" class="form-control"
																v-model="dataQuitacao" disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataQuitacao -->

													<!-- dataVigenciaContrato -->
													<div class="col-md-2">
														<div class="form-group">
															<label class="form-control-label"
																for="input-dataVigenciaContrato">Data Vigência do
																Contrato</label>
															<input type="date" id="input-dataVigenciaContrato"
																class="form-control" v-model="dataVigenciaContrato"
																disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataVigenciaContrato -->

													<!-- dataHoraAtualizacao -->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-dataHoraAtualizacao">Dt. Hr. Atualizacao do
																Contrato</label>
															<input type="text" id="input-dataHoraAtualizacao"
																class="form-control" v-model="dataHoraAtualizacao"
																disabled="true">
														</div><!-- form group -->
													</div><!-- col-md-3 -->
													<!-- end dataHoraAtualizacao -->

													<!--nsu-->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label" for="input-nsu">NSU</label>
															<input type="text" id="input-nsu" class="form-control" disabled
																v-model="nsu">
														</div>
													</div>
													<!--end nsu-->

													<!--origemContrato-->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-origemContrato">Origem do Contrato</label>
															<input type="text" id="input-origemContrato"
																class="form-control" disabled v-model="origemContrato">
														</div>
													</div>
													<!--end origemContrato-->

													<!--sequencial-->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-sequencial">Sequencial</label>
															<input type="text" id="input-sequencial" class="form-control"
																disabled v-model="sequencial">
														</div>
													</div>
													<!--end sequencial-->

													<!--situacaoTaxaContrato-->
													<div class="col-md-2" v-if="estado == 'BA'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-situacaoTaxaContrato">Taxa do
																Contrato</label>
															<input type="text" id="input-situacaoTaxaContrato"
																class="form-control" disabled
																v-model="situacaoTaxaContrato">
														</div>
													</div>
													<!--end situacaoTaxaContrato-->

													<!--numeroContratoAditivo-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroContratoAditivo">Número Aditivo de
																Contrato</label>
															<input type="text" id="input-numeroContratoAditivo"
																class="form-control" disabled
																v-model="numeroContratoAditivo">
														</div>
													</div>
													<!--end numeroContratoAditivo-->

													<!--numeroRegistroAditivoDoContratoSircof-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroRegistroAditivoDoContratoSircof">Núm.
																Reg. Aditivo Contrato Sircof</label>
															<input type="text"
																id="input-numeroRegistroAditivoDoContratoSircof"
																class="form-control" disabled
																v-model="numeroRegistroAditivoDoContratoSircof">
														</div>
													</div>
													<!--end numeroRegistroAditivoDoContratoSircof-->

													<!--numeroRegistroContratoSircof-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-numeroRegistroContratoSircof">Núm. Reg.
																Contrato Sircof</label>
															<input type="text" id="input-numeroRegistroContratoSircof"
																class="form-control" disabled
																v-model="numeroRegistroContratoSircof">
														</div>
													</div>
													<!--end numeroRegistroContratoSircof-->

													<!--situacaoAditivoContrato-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-situacaoAditivoContrato">Situação Aditivo
																Contrato</label>
															<input type="text" id="input-situacaoAditivoContrato"
																class="form-control" disabled
																v-model="situacaoAditivoContrato">
														</div>
													</div>
													<!--end situacaoAditivoContrato-->

													<!--csituacaoVeiculo-->
													<div class="col-md-2" v-if="estado == 'MG'">
														<div class="form-group">
															<label class="form-control-label"
																for="input-situacaoVeiculo">Situação Veiculo</label>
															<input type="text" id="input-situacaoVeiculo"
																class="form-control" disabled v-model="situacaoVeiculo">
														</div>
													</div>
													<!--end situacaoVeiculo-->

												</div>
												<div class="row form-distance">

													<!--descricao_completa-->
													<div class="col-md-12">
														<div class="form-group">
															<label class="form-control-label"
																for="input-descricao_completa">Descrição</label>
															<textarea style="height: 90px" id="input-descricao_completa"
																class="form-control" placeholder="Descrição"
																v-model="descricao_completa" maxlength="255" disabled>
			                        </textarea>
															<p>Data e hora de consulta no detran {{ dthrconsulta }}</p>
														</div>
													</div>
													<!--end descricao_completa-->

												</div>
											</div>
											<div class="col-md-12 text-right">
												<a :href=pathReturn type="submit" class="btn btn-primary">Voltar</a>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['consulta'],
	data() {
		const consulta = JSON.parse(this.consulta);
		return {

			estado: consulta.estado,

			UFPlaca: consulta.UFPlaca,
			anoFabricacao: consulta.anoFabricacao,
			anoModelo: consulta.anoModelo,
			chassi: consulta.chassi,
			cnpjAgente: consulta.cnpjAgente,
			codigoAgente: consulta.codigoAgente,

			dataCancelamento: this.validateDate(consulta.dataCancelamento),
			dataEmissao: this.validateDate(consulta.dataEmissao),
			dataInclusaoGravame: this.validateDate(consulta.dataInclusaoGravame),
			dataQuitacao: this.validateDate(consulta.dataQuitacao),
			dataVigenciaContrato: this.validateDate(consulta.dataVigenciaContrato),

			flagContrato: consulta.flagContrato,
			idenRemarcacao: consulta.idenRemarcacao,
			nomeFinanciado: consulta.nomeFinanciado,
			numeroContrato: consulta.numeroContrato,
			numeroDocumentoFinanciado: consulta.numeroDocumentoFinanciado,
			numeroGravame: consulta.numeroGravame,

			placa: consulta.placa,
			renavam: consulta.renavam,
			situacao: consulta.situacao,
			tipoDocumentoFinanciado: consulta.tipoDocumentoFinanciado,
			tipoGravame: consulta.tipoGravame,
			descricao: consulta.descricao == undefined ? '' : consulta.descricao,
			descricao_completa: '',

			dthrconsulta: this.validateDatehour(consulta.dt_hr_consulta),

			pathReturn: process.env.MIX_URL + 'contratos',

			title: '',

			numeroContratoAditivo: consulta.numeroContratoAditivo,
			numeroRegistroAditivoDoContratoSircof: consulta.numeroRegistroAditivoDoContratoSircof,
			numeroRegistroContratoSircof: consulta.numeroRegistroContratoSircof,
			situacaoAditivoContrato: consulta.situacaoAditivoContrato,
			situacaoGravame: consulta.situacaoGravame,
			situacaoVeiculo: consulta.situacaoVeiculo,

			cnpjCredor: consulta.cnpjCredor,
			dataContrato: this.validateDate(consulta.dataContrato),
			dataHoraAtualizacao: this.validateDatehour(consulta.dataHoraAtualizacao),
			nsu: consulta.nsu,
			origemContrato: consulta.origemContrato,
			sequencial: consulta.sequencial,
			situacaoTaxaContrato: consulta.situacaoTaxaContrato,

			SequencialAditivo: consulta.SequencialAditivo,
			SequencialContrato: consulta.SequencialContrato,

		}
	},
	mounted() {
		this.buildTitle()
		this.buildDescription()
	},
	methods: {
		validateDate(date) {
			var temp = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
			return String(temp);
		},
		validateDatehour(dthr) {
			var temp = moment(dthr, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:SS');
			return String(temp);
		},
		buildDescription() {
			var desc = '-';
			if (this.chassi != undefined) {
				desc = this.chassi
			}
			this.descricao_completa = `RESULTADO DA CONSULTA DE GRAVAME NO DETRAN ${this.estado} REALIZADO PARA O CHASSI ${desc} \n${this.descricao}`
		},
		buildTitle() {
			this.title = this.chassi != undefined ? `Chassi: ${this.chassi}` : `Atenção ${this.descricao}`
		}
	}
}
</script>
