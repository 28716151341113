<template>
    <div class="card p-4">
        <div class="row">
            <div class="col">

                <!-- table -->
                <div v-if="!loading && tipos.length > 0">
                    <vue-good-table
                        :columns="columns"
                        :rows="tipos"
                        :pagination-options="{
						enabled: true,
						mode: 'records',
						position: 'bottom',
						dropdownAllowAll: true,
						perPageDropdown: [10, 30, 50],
						nextLabel: 'Próximo',
						prevLabel: 'Anterior',
						rowsPerPageLabel: '',
						ofLabel: 'de',
						allLabel: 'Todos',
						pageLabel: '',
						perPageDropdownEnabled: true,
						compactMode: true,

						}"
                        :search-options="{
							enabled: true,
							placeholder: 'Search this table',
						}"
                        styleClass="vgt-table"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field ==='actions'">
								<div class="text-center">
									<button class="btn btn-sm btn-outline-secondary" @click="editData(props.row)">
                                        <i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger"
                                            @click="deleteData(props.row.id, props.row.index)"><i
                                        class="far fa-trash-alt"></i></button>
								</div>
							</span>
                            <span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
                        </template>
                    </vue-good-table>
                </div>
                <div v-if="!tipos.length && !loading" class="text-center p-3 text-muted">
                    <h5>Nenhuma tipo encontrado!</h5>
                </div>
                <!-- end table -->
                <div class="text-center p-4 text-muted" v-if="loading">
                    <h5>Carregando...</h5>
                    <p>Aguarde até que os dados sejam carregados...</p>
                </div>
            </div><!-- col -->
        </div><!-- row -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>


        <!--   modal cadastro de tipos     -->
        <div class="modal fade" id="modalNovoTipo" tabindex="-1" aria-labelledby="modalApiReturnsLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalApiReturnsLabel">{{labelTitle}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetForm">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form method="POST" v-on:submit.prevent="save">

                            <div class="row">

                                <!-- tipo -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-control-label" for="input-status_api">Tipo</label>
                                        <input type="text" id="input-tipo"
                                               class="form-control"  required autofocus v-model="tipo.nome">
                                        <div class="invalid-feedback" >
                                            <p></p>
                                        </div>
                                    </div><!-- form group -->
                                </div><!-- col-md-6 -->
                            </div>  <!-- tipo -->
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary">{{ labelSaveEdit }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div><!--    modal cadastro de tipos   -->
        <vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
    </div><!-- card -->
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";

export default {
    data() {
        return {
            tipos: [],
            fullPage: true,
            labelTitle: "Novo Tipo",
            isEditTipo: false,
            loading: false,
            labelSaveEdit: "Salvar",
            tipo: {
                nome: ""
            },
            tipoReturnID: '',
            tipoItens: '',

            columns: [
                {
                    label: "Tipo",
                    field: "nome",
                },
                {
                    label: "Criado Em",
                    field: 'created_at',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
                    dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
                },
                {
                    label: "Ações",
                    field: "actions",
                    width: '100px',
                    html: true,
                },
            ],
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {

        editData(item) {

            this.tipo = item;
            this.isEditTipo = true;
            this.tipoReturnID = item.id;
            this.labelTitle = "Editar tipo";
            //this.labelSaveEdit = "Salvar edição"

            $("#modalNovoTipo").modal('show');
        },

        resetForm(){
            this.tipo = {
                nome: '',
            };
            this.isEditTipo = false
            this.tipoReturnID = ""
        },

        save(){
                //validate if is edit
                if (this.isEditTipo === true){

                    //edit
                    let _this = this;
                    _this.errors = [];
                    _this.message = "";
                    _this.loading = true;
                    $("#modalNovoTipo").modal("hide")

                    const dataInfo = {
                        "tipo" : _this.tipo.nome,
                    };

                    axios.patch(`/admin/tipos/${this.tipoReturnID}`, dataInfo).then((res) => {

                        _this.loading = false
                        _this.tipo = ""
                        _this.isEditTipo = false
                        _this.tipoReturnID = ""
                        _this.resetForm()
                        _this.loadItems()

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Tipo atualizada com sucesso!",
                            icon: "success"
                        }).then(function(){

                        });

                    }).catch((err) => {

                        _this.loading = false;

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao atualizar Tipo. Tente novamente!",
                            icon: "error"
                        });
                    });
                } //edit
                 else {
                    //new
                    let _this = this;
                    _this.errors = [];
                    _this.message = "";
                    _this.loading = true;
                    $("#modalNovoTipo").modal("hide")

                    const dataInfo = {
                        "tipo" : _this.tipo.nome,
                    };

                    axios.post("/admin/tipos", dataInfo).then((res) => {

                        _this.loading = false;
                        _this.resetForm()
                        _this.loadItems();

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Tipo cadastrada com sucesso!",
                            icon: "success"
                        });
                    }).catch((err) => {

                        _this.loading = false;

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao cadastrar tipo. Tente novamente!",
                            icon: "error"
                        });
                    });

                } //else save
        },

        loadItems() {
            let _this = this;
                _this.loading = true;
                _this.tipos = [];
            axios.get("/admin/tipos").then((res) => {
                _this.tipos = _this.tipos.concat(res.data);
                _this.loading = false;
            }).catch(() => {
                _this.loading = false;
            });
        },
        deleteData(ID, index) {
            let _this = this;

            //show alert confirmation
            _this.$swal({
                title: "Excluir tipo",
                text: "Tem certeza que deseja excluir essa tipo?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Excluir",
                cancelButtonText: "Cancelar"
            }).then((alertConfirm) => {

                //validate isConfirmed
                if (alertConfirm.isConfirmed) {

                    //remove user
                    axios.delete(`/admin/tipos/${ID}`).then(() => {

                        _this.tipos.splice(index, 1);
                       // _this.total = _this.total - 1;
                        _this.loadItems();

                        //show confirm alert
                        _this.$swal({
                            title: "Sucesso",
                            text: "Tipo excluído com sucesso!",
                            icon: "success"
                        });

                    }).catch(error => {

                        //show error alert
                        _this.$swal({
                            title: "Erro",
                            text: "Falha ao excluir tipo Tente novamente!",
                            icon: "error"
                        });
                    });
                }
            });
            //end alert confirmation*/
        },

        formatDate: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        formatCurrency: function (value) {
            return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
        },
    },
    components: {
        VueGoodTable,
    }
}
</script>
