<template>
	<div class="row" style="margin-top: -30px;">
		<span>
			<!-- <h6 style="margin-left: 18px;">
				{{ title1 }}
			</h6> -->
		</span>
		<div class="col-12">
			<!-- dashboard cards -->

			<div class="row" v-if="userCan.create">
				<div class="col-lg-3 col-md-6 col-sm-6 col-12" @click="filtraTodosContratos" style="cursor: pointer;">
					<div class="card card-statistic-1">
						<div class="card-icon bg-secondary">
							<i class="fas fa-file-alt"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4 style="margin-top:-10px">Contratos Cadastrados</h4>
							</div>
							<div class="card-body">
								<div v-if="total == -1" class="spinner-border spinner-border spinner-border-sm"
									role="status"></div>
								<div v-else>{{ total }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-6 col-sm-6 col-12" @click="filtraRegistrados" style="cursor: pointer;">
					<div class="card card-statistic-1">
						<div class="card-icon bg-success">
							<i class="fas fa-clipboard-check"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4 style="margin-top:-10px">Contratos Registrados</h4>
							</div>
							<div class="card-body">
								<div v-if="concluido == -1" class="spinner-border spinner-border spinner-border-sm"
									role="status"></div>
								<div v-else>{{ concluido }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12" @click="filtraEmProcessamento" style="cursor: pointer;">
					<div class="card card-statistic-1">
						<div class="card-icon bg-warning">
							<i class="fas fa-hourglass-half"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4 style="margin-top:-10px">Contratos em Processamento</h4>
							</div>
							<div class="card-body">
								<div v-if="processando == -1" class="spinner-border spinner-border spinner-border-sm"
									role="status">
								</div>
								<div v-else>{{ processando }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6 col-12" @click="filtraPendentes" style="cursor: pointer;">
					<div class="card card-statistic-1">
						<div class="card-icon bg-primary">
							<i class="fas fa-history"></i>
						</div>
						<div class="card-wrap">
							<div class="card-header">
								<h4 style="margin-top:-10px">Contratos Pendentes</h4>
							</div>
							<div class="card-body">
								<div v-if="pendente == -1" class="spinner-border spinner-border spinner-border-sm"
									role="status"></div>
								<div v-else>{{ pendente }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end dashboard cards -->

			<!-- filter start -->
			<div class="card col-12">
				<div class="card-header">
					<div class="row col-12 justify-content-start">
						<!--filtro estado-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Estados</a>
							<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
								<li class="dropdown-title">Selecione os Estados</li>
								<li v-if="!detrans.length">
									<div class="text-center">
										<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
											role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</li>
								<li v-for="item in detrans" :key="item.id">
									<div class="form-check custom-checkbox custom-control ml-3">
										<input class="form-check-input" type="checkbox" :value="item.id"
											v-model="checkedDetrans" :id="'estado_' + item.id" />
										<label class="form-check-label" :for="'estado_' + item.id">
											{{ item.estado ? item.estado : item.nome ? item.nome : "Erro" }}
										</label>
									</div>
								</li>
								<div class="text-center">
									<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
										data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
										@click="changeDisabledLoading">
										<i class="fas fa-filter"></i>
									</button>
								</div>
							</ul>
						</div>
						<!--en filtro estado-->

						<!--filtro numero do contrato-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<div class="form-gr date-custom">
								<input type="text" v-model="numero_contrato" placeholder="Número do Contrato"
									class="btn-filter" @keyup.enter="changeDisabledLoading" />
							</div>
						</div>
						<!--filtro numero do contrato-->

						<!--filtro chassi-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<div class="form-gr date-custom">
								<input type="text" v-model="chassi" placeholder="Número do Chassi" class="btn-filter"
									@keyup.enter="changeDisabledLoading" />
							</div>
						</div>
						<!--filtro chassi-->

						<!--filtro numero do grave-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<div class="form-gr date-custom">
								<input type="text" v-model="numero_gravame" placeholder="Número do Gravame"
									class="btn-filter" @keyup.enter="changeDisabledLoading" />
							</div>
						</div>
						<!--filtro numero do grave-->

						<!--filtro periodo-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<button data-toggle="dropdown" class="btn btn-light dropdown-toggle">Tipo de
								Data</button>
							<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
								<li class="dropdown-title">Selecione o Tipo de Data desejada:</li>
								<li v-if="!tipo_periodo.length">
									<div class="text-center">
										<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
											role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</li>
								<li v-for="item in tipo_periodo" :key="item.id">
									<div class="form-check custom-checkbox custom-control ml-3">
										<input class="form-check-input" type="radio" :value="item.id"
											v-model="selectedPeriodo" :id="'periodo_' + item.id" />
										<label class="form-check-label" :for="'periodo_' + item.id">
											{{ item.nome ? item.nome : "Erro" }}
										</label>
									</div>
								</li>
							</ul>
						</div>
						<!--end filtro periodo-->

						<!--filtro periodo-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<button data-toggle="dropdown" class="btn btn-light dropdown-toggle"
								:disabled="selectedPeriodo.length === 0">Tipo de
								Período</button>
							<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
								<li class="dropdown-title">Selecione o Período desejado:</li>
								<li v-if="!periodo.length">
									<div class="text-center">
										<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
											role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</li>
								<li v-for="item in periodo" :key="item.id">
									<div class="form-check custom-checkbox custom-control ml-3">
										<input class="form-check-input" type="radio" :value="item.id"
											v-model="selectedTypePeriodo" :id="'periodo_' + item.id" />
										<label class="form-check-label" :for="'periodo_' + item.id">
											{{ item.nome ? item.nome : "Erro" }}
										</label>
									</div>
								</li>
							</ul>
						</div>
						<!--end filtro periodo-->


						<!--Data Inicial -->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-3 pr-4">
							<div class="form-gr date-custom ml-1" style="position: relative">
								<datepicker :language="calendarLanguage" v-model="dataInicialPeriodo"
									placeholder="Data Inicial" style="width: 100%; padding-right: -30px;"
									:disabled="selectedTypePeriodo !== 4">
								</datepicker>
								<span class="clear-icon" @click="clearDate('dataInicialPeriodo')"
									style="position: absolute; top: 3px; right: 15px;"><i
										class="fas fa-trash"></i></span>
							</div>
						</div>
						<!-- Fim Data Inicial -->

						<!-- Data Final -->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-3 pr-4">
							<div class="form-gr date-custom ml-1" style="position: relative">
								<datepicker :language="calendarLanguage" v-model="dataFinalPeriodo"
									placeholder="Data Final"
									style="width: 100%; padding-right: -30px; position: absolute; z-index: 1000;"
									:disabled="selectedTypePeriodo !== 4">
								</datepicker>
								<span class="clear-icon" @click="clearDate('dataFinalPeriodo')"
									style="position: absolute; top: 3px; right: 15px;"><i
										class="fas fa-trash"></i></span>
							</div>
						</div>
						<!-- Fim Data Final -->

						<div id="filtroAvancado" class="collapse card col-12">
							<div class="row justify-content-start">

								<!--filtro Status-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Situação do
										Contrato</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
										<li class="dropdown-title">Selecione a Situação do Contrato</li>
										<li v-if="!contratoStatus.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="item in contratoStatus" :key="item.id">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="checkbox" :value="item.id"
													v-model="checkedStatus" :id="'status_' + item.id" />
												<label class="form-check-label" :for="'status_' + item.id">
													{{ item.nome ? item.nome : "Indisponível" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--end filtro Status-->

								<!--filtro tipo_operacao-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<button data-toggle="dropdown" class="btn btn-light dropdown-toggle">Tipo de
										Operação</button>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
										<li class="dropdown-title">Selecione o Tipo de operação</li>
										<li v-if="!tipo_operacao.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="item in tipo_operacao" :key="item.id">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="checkbox" :value="item.id"
													v-model="checkedOperacao" :id="'operacao_' + item.id" />
												<label class="form-check-label" :for="'operacao_' + item.id">
													{{ item.nome ? item.nome : "Erro" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--end filtro tipo_operacao-->

								<!--filtro numero do contrato eletronico -->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="numero_contrato_eletronico"
											placeholder="Número Eletrônico do Contrato (Nº do Assentamento)"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro numero do contrato eletronico-->

								<!--filtro arquivo-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Arquivo
										anexado?</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
										<li class="dropdown-title">Selecione a opção:</li>
										<li>
											<div class="form-check custom-checkbox custom-control ml-3">
												<input type="radio" id="anexo" class="form-check-input" value="sim"
													v-model="arquivo">
												<label for="anexo" class="form-check-label">Sim</label>
											</div>
										</li>
										<li>
											<div class="form-check custom-checkbox custom-control ml-3">
												<input type="radio" id="anexo" class="form-check-input" value="nao"
													v-model="arquivo">
												<label for="anexo" class="form-check-label">Não</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--end filtro arquivo-->


								<!--filtro cpf cnpj devedor -->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="cnpj_cpf_dev" placeholder="CPF ou CNPJ Devedor"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro cpf cnpj devedor -->

								<!--filtro nome_devedor-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="nome_devedor" placeholder="Nome do Devedor"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--end filtro nome_devedor-->

								<!--filtro uf_devedor-->
								<!-- <div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
								<div class="form-gr date-custom">
									<v-select id="filtro" placeholder="Estado Devedor" label="states" @input="setSelectedState"
										:options="states" v-model="uf_devedor"></v-select>
								</div>
							</div> -->

								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<button data-toggle="dropdown" class="btn btn-light dropdown-toggle">Estado do
										Devedor</button>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
										<li class="dropdown-title">Selecione o Estado</li>
										<li v-if="!states_devedor.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="item in states_devedor" :key="item.nome">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="radio" :value="item.nome"
													v-model="uf_devedor" :id="'stateDev_' + item.nome"
													@change="handleUfChange(item.nome)" />
												<label class="form-check-label" :for="'stateDev_' + item.nome">
													{{ item.nome ? item.nome : "Erro" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--end filtro uf_devedor-->


								<!--filtro nome_municipio_devedor-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Município
										do
										Devedor</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter marca-dropdown"
										style="width: 350px;">
										<li class=" dropdown-title">Selecione o Município do Devedor</li>
										<li v-if="!municipios.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="item in municipios" :key="item.id">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="radio" :value="item.codigo_origem"
													v-model="nome_municipio_devedor"
													:id="'status_' + item.codigo_origem" />
												<label class="form-check-label" :for="'status_' + item.codigo_origem">
													{{ item.municipio ? item.municipio : "Indisponível" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--end filtro nome_municipio_devedor-->

								<!--filtro Renavam-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="renavam_veiculo" placeholder="RENAVAM"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro Renavam-->

								<!--filtro Placa-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="placa_veiculo" placeholder="Placa do Veículo"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro Placa-->

								<!--filtro Ano-Fabricação-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="ano_veiculo_fab" placeholder="Ano de Fabricação"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro Ano-->

								<!--filtro Ano-Modelo-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<div class="form-gr date-custom">
										<input type="text" v-model="ano_veiculo_modelo" placeholder="Ano do Modelo"
											class="btn-filter" @keyup.enter="changeDisabledLoading" />
									</div>
								</div>
								<!--filtro Ano-->

								<!--filtro Tipo de contrato(gravame)-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Tipo de
										Gravame</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
										<li class="dropdown-title">Selecione o Tipo de Contrato:</li>
										<li v-if="!tipo_contrato.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="item in tipo_contrato" :key="item.id">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="checkbox" :value="item.id"
													v-model="checkedTipoContrato" :id="'tipocontrato_' + item.id" />
												<label class="form-check-label" :for="'tipocontrato_' + item.id">
													{{ item.estado ? item.estado : item.nome ? item.nome : "Erro" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--filtro Tipo de contrato(gravame)-->

								<!--filtro espécie-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown"
										class="btn btn-light dropdown-toggle">Espécie/Tipo de
										Veículo</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter marca-dropdown"
										style="width: 300px;">
										<li class="dropdown-title">Selecione abaixo:</li>
										<li v-if="!tipos.length">
											<div class="text-center">
												<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
													role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</div>
										</li>
										<li v-for="tipo in tipos" :key="tipo.id">
											<div class="form-check custom-checkbox custom-control ml-3">
												<input class="form-check-input" type="checkbox" :value="tipo.id"
													v-model="checkedTipos" :id="'tipo_' + tipo.id" />
												<label class="form-check-label" :for="'tipo_' + tipo.id">
													{{ tipo.descricao ? tipo.descricao : "Erro" }}
												</label>
											</div>
										</li>
										<div class="text-center">
											<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
												data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
												@click="changeDisabledLoading">
												<i class="fas fa-filter"></i>
											</button>
										</div>
									</ul>
								</div>
								<!--filtro espécie-->

								<!--filtro Marca-->
								<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
									<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Marca</a>
									<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter marca-dropdown"
										style="width: 300px;">
										<li v-if="checkedMarcas.length > 0" class="selected-models">
											<div class="selected-model">
										<li class="dropdown-title">Modelos selecionados: </li>
										<div style="margin-bottom: 15px;">
											<div v-for="marcasId in checkedMarcas" :key="marcasId"
												class="dropdown-selecionado">{{
													getMarcaNameById(marcasId) }}</div>
										</div>
								</div>
								</li>
								<li class="dropdown-title">Selecione as Marcas</li>
								<li v-if="!marcas.length">
									<div class="text-center">
										<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1"
											role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</li>
								<li v-for="item in marcas" :key="item.id">
									<div class="form-check custom-checkbox custom-control ml-3">
										<input class="form-check-input" type="checkbox" :value="item.id"
											v-model="checkedMarcas" :id="'marca_' + item.id" />
										<label class="form-check-label" :for="'marca_' + item.id">
											{{ item.nome ? item.nome : "Erro" }}
										</label>
									</div>
								</li>
								<div class="text-center">
									<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
										data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
										@click="changeDisabledLoading">
										<i class="fas fa-filter"></i>
									</button>
								</div>
								</ul>
							</div>
							<!--filtro Marca-->

							<!--filtro Modelo-->
							<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
								<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Modelo</a>
								<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter modelo-dropdown"
									style="width: 400px;">
									<li v-if="checkedModelos.length > 0" class="selected-models">
										<div class="selected-model">
									<li class="dropdown-title">Modelos selecionados: </li>
									<div style="margin-bottom: 15px;">
										<div v-for="modelId in checkedModelos" :key="modelId"
											class="dropdown-selecionado">
											{{
												getModelNameById(modelId) }}</div>
									</div>
							</div>
							</li>
							<li class="dropdown-title">Selecione os Modelos:</li>
							<li v-if="!modelos.length">
								<div class="text-center">
									<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
							</li>
							<li v-for="item in modelos" :key="item.id">
								<div class="form-check custom-checkbox custom-control ml-3">
									<input class="form-check-input" type="checkbox" :value="item.id"
										v-model="checkedModelos" :id="'modelo_' + item.id" />
									<label class="form-check-label" :for="'modelo_' + item.id">
										{{ item.nome ? item.nome : "Erro" }}
									</label>
								</div>
							</li>
							<div class="text-center">
								<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
									data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
									@click="changeDisabledLoading">
									<i class="fas fa-filter"></i>
								</button>
							</div>
							</ul>
						</div>
						<!--filtro Modelo-->

						<!--filtro instituicao-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<div class="form-gr date-custom" v-if="this.userGpEcon.is_group_economic == 'S'">
								<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Nome Credor</a>
								<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter">
									<li class="dropdown-title">Selecione uma ou mais Instituições:</li>
									<div v-for="item in bancoOption" :key="item.id"
										class="form-check custom-checkbox custom-control ml-3">
										<input class="form-check-input" type="checkbox" :value="item.id"
											v-model="selectedInstituicao" :id="'instituicao_' + item.id" />
										<label class="form-check-label" :for="'instituicao_' + item.id">{{ item.label
											}}</label>
									</div>
									<div class="text-center">
										<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
											data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
											@click="changeDisabledLoading">
											<i class="fas fa-filter"></i>
										</button>
									</div>
								</ul>
							</div>
							<div class="form-gr date-custom" v-else>
								<input type="text" v-model="nome_agente" placeholder="Nome do Credor" class="btn-filter"
									@keyup.enter="changeDisabledLoading" disabled />
							</div>
						</div>
						<!--end filtro instituicao-->

						<!--filtro nome_agente_cadastro-->
						<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
							<a href="#" data-toggle="dropdown" class="btn btn-light dropdown-toggle">Agente de
								Cadastro</a>
							<ul class="dropdown-menu dropdown-menu-sm dropdown-menu-left dropdown-filter marca-dropdown"
								style="width: 300px;">
								<li v-if="checkedAgente.length > 0" class="selected-agente">
									<div class="selected-model">
								<li class="dropdown-title">Agente(s) selecionado(s): </li>
								<div style="margin-bottom: 15px;">
									<div v-for="agenteId in checkedAgente" :key="agenteId" class="dropdown-selecionado">
										{{
											getAgenteNameById(agenteId) }}</div>
								</div>
						</div>
						</li>
						<li class="dropdown-title">Selecione o(s) Agentes(s)</li>
						<li v-if="!agente.length">
							<div class="text-center">
								<div class="spinner-border spinner-border-sm text-danger mt-1 mb-1" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div>
						</li>
						<li v-for="item in agente" :key="item.id">
							<div class="form-check custom-checkbox custom-control ml-3">
								<input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedAgente"
									:id="'agente_' + item.id" />
								<label class="form-check-label" :for="'agente_' + item.id">
									{{ item.name ? item.name : "Erro" }}
								</label>
							</div>
						</li>
						<div class="text-center">
							<button class="btn btn-danger col-md-2 col-lg-2 col-sm-4 mt-2 btn-filter"
								data-toggle="tooltip" data-placement="top" title="Filtrar" id="inicial"
								@click="changeDisabledLoading">
								<i class="fas fa-filter"></i>
							</button>
						</div>
						</ul>
					</div>
					<!--end filtro nome_agente_cadastro-->

				</div>
			</div>

			<!--filtro avançado-->
			<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">
				<div class="form-gr date-custom">
					<button class="btn btn-danger btn-filter float-left" style="margin: 0 0 0 10px;"
						data-toggle="collapse" data-target="#filtroAvancado" aria-expanded="false">
						Filtros Avançados
					</button>
				</div>
			</div>
			<!--filtro avançado-->

			<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">

			</div>

			<div class="dropdown btn-filter col-md-3 col-lg-3 col-sm-6 mt-2 p-2 pl-4 pr-4">

			</div>


			<!-- filter button -->
			<div class="col-md-3 col-lg-3 col-sm-12 mt-2 p-2 pl-4 pr-4">
				<div class="row pad0">
					<div class="col-md-12 pad0 pl-3 pr-3">
						<div v-if="userCan.create" class="dropdown float-right" style="margin-top: -2px;">
							<!-- botão de adicionar com dropdown -->
							<button class="btn btn-danger btn-filter dropdown-toggle mr-2" type="button"
								id="dropdownContract" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="fas fa-plus fa-lg"></i>
							</button>
							<div class="dropdown-menu dropdown-menu-right"
								style="border: 1px solid rgba(0,0,0,.15) !important;"
								aria-labelledby="dropdownContract">
								<a v-if="userGpEcon.is_group_economic != 'S'" class="dropdown-item"
									@click="newContract">Contrato
									Convencional</a>
								<a v-if="userGpEcon.is_group_economic == 'S'" class="dropdown-item"
									@click="openModalConvencional">Contrato Convencional</a>
								<a class="dropdown-item" @click="multiContract" v-show="false">Contrato Multi
									Veículos</a>
								<!-- <a class="dropdown-item" @click="openModalImport">Importar</a> -->
							</div>
						</div>
						<!-- botão de filtrar -->
						<button class="btn btn-danger btn-filter float-right mr-2 px-2" style="margin-top: -2px;"
							data-toggle="tooltip" data-placement="top" title="Filtrar" @click="changeDisabledLoading">
							<i class="fas fa-filter"></i> Filtrar
						</button>
						<!-- botão de limpar filtro -->
						<button class="btn btn-limpar btn-filter float-right mr-2" style="margin-top: -2px;"
							data-toggle="tooltip" data-placement="top" title="Limpar Filtro" @click="limparFiltros">
							<i class="fas fa-trash fa-lg"></i> Limpar Filtros
						</button>
					</div>
				</div>
			</div>
			<!-- end filter button -->
			<!-- end filter button -->
		</div>
		<!-- end filtro período -->
	</div>
	</div>
	<!-- filter end -->

	<template v-if="userCan.view">
		<!-- card -->
		<div class="card contracts-filter">
			<!-- card body -->
			<div class="card-body">
				<!-- <div class="row" style="display: flex; justify-content: left;">
					<span>
						<h6 style="margin-left: 18px;">
							{{ title2 }}
						</h6>
					</span>
				</div> -->
				<!-- table -->
				<div class="pb-4" v-if="!loading && contratos.length > 0">
					<div class="export-options w-auto">
						<a href="javascript:void(0)" class="btn btn-danger btn-filter export-button"
							@click="exportDataGrid('pdf', 'Contratos')">
							<i class="far fa-file-pdf"></i> PDF
						</a>
						<a href="javascript:void(0)" class="btn btn-success btn-filter export-button"
							@click="exportDataGrid('xlsx', 'Contratos')">
							<i class="far fa-file-excel"></i> EXCEL
						</a>
						<a v-if="userCan.create" href="/portal/contratos/upload"
							class="btn badge badge-light btn-filter export-button">
							<i style="color:red;" class="far fa-file-pdf"></i><span style="color:red;"> UPLOADS</span>
						</a>
					</div>
					<!--              :isLoading.sync="isLoading"-->

					<vue-good-table mode="remote" :columns="columns" :rows="contratos" :globalSearch="true"
						:isLoading.sync="isLoading" :search-options="{
							enabled: true,
							skipDiacritics: true,
							placeholder: 'Digite para buscar',
						}" :group-options="{
							enabled: true,
							collapsable: true
						}" :sort-options="{ enabled: true, }" @on-search="onSearch" @on-sort-change="onSortChange"
						styleClass="vgt-table">

						<div slot="emptystate" class="d-flex justify-content-center">Carregando Contratos...</div>

						<template slot="loadingContent">
							<div class="d-flex justify-content-center" style="margin-top: 55px;">
								<div class="spinner-border text-danger" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div>
						</template>

						<template template v-slot:table-header-row="props">

							<span v-if="props.column.field == 'type'">
								<div v-if="props.row.nome_fantasia === true">{{ props.row.nome_fantasia }}</div>
								<div v-else>{{ props.row.nome }}</div>
							</span>
							<span v-if="props.column.field == 'visualizar'">
								<div class="dropdown col-md-4 col-lg-4 col-sm-6 d-flex align-items-center mr-4">
									<button class="btn btn-outline-primary btn-filter dropdown-toggle"
										@click="resized()" style="position: absolute;z-index: 999;" type="button"
										id="dropdownActions" data-toggle="dropdown" aria-haspopup="true"
										aria-expanded="true" title="Botão de ações"
										v-if="props.row.contrato_status.id === 1 || userCan.create">
										<span>Ações</span>
									</button>
									<ul class="dropdown-menu" aria-labelledby="dropdownActions">
										<a v-if="userCan.resend" class="dropdown-item"
											@click="resendContract(props.row)" title="Reenviar Contrato">
											<i class="fas fa-sync"></i>
											<span>Re-enviar contrato</span>
										</a>
										<a v-if="userCan.create" class="dropdown-item" title="Visualizar Contrato"
											@click="viewContract(props.row.id, props.row.detrans.estado)">
											<i class="fas fa-eye fa-2x"></i>
											<span>Visualizar contrato</span>
										</a>
										<a v-if="userCan.edit" class="dropdown-item" title="Editar contrato"
											@click="editContract(props.row.id, props.row.detrans.estado, props.row.bancoId)">
											<i class="fas fa-edit"></i>
											<span>Editar Contrato</span>
										</a>
										<a v-if="props.row.detrans.estado == 'RJ'" class="dropdown-item"
											title="Enviar Imagem Digitalizada"
											@click="uploadImage(props.row.id, props.row.detrans.estado)">
											<i class="fas fa-image"></i>
											<span>Anexar Imagem Digitalizada</span>
										</a>
										<a v-if="userCan.create" class="dropdown-item"
											@click="uploadNovoPdf(props.row.id, props.row.detrans.estado)"
											title="Upload Anexo">
											<i class="fas fa-file-pdf"></i>
											<span>Upload Anexo</span>
										</a>
										<hr v-if="props.row.detrans.estado == 'MG'" />

										<a class="dropdown-item" @click="changeStatus(props.row, 'MG', 13)"
											title="Cancelar Registro de Contrato"
											v-if="props.row.detrans.estado == 'MG' && props.row.contrato_status_id === 1">
											<i class="fas fa-ban"></i>
											<span>Cancelar Registro</span>
										</a>
										<a class="dropdown-item" @click="changeStatus(props.row, 'MG', 14)"
											title="Cancelar Registro de Contrato"
											v-if="props.row.detrans.estado == 'MG' && props.row.contrato_status_id === 1">
											<i class="fas fa-ban"></i>
											<span>Baixa de Contrato</span>
										</a>
										<hr v-if="props.row.detrans.estado === 'BA'" />
										<a class="dropdown-item" @click="cancelContract(props.row, 'BA')"
											title="Cancelar Contrato"
											v-if="props.row.detrans.estado == 'BA' && userCan.create">
											<i class="fas fa-ban"></i>
											<span>Cancelar contrato</span>
										</a>
										<hr v-if="props.row.contrato_status.id === 1" />
										<a class="dropdown-item" title="Gerar espelho do contrato"
											@click="gerarCertidao(props.row.chassi, props.row.id, props.row.detrans.estado)"
											v-if="props.row.contrato_status.id === 1">
											<i class="fas fa-file-pdf danger"></i>
											<span>Gerar Espelho</span>
										</a>
										<a class="dropdown-item" title="Gerar espelho do contrato"
											@click="gerarCertidaoResumida(props.row.chassi, props.row.id, props.row.detrans.estado)"
											v-if="props.row.contrato_status.id === 1">
											<i class="fas fa-file-pdf danger"></i>
											<span>Gerar Espelho Resumido</span>
										</a>

										<hr v-if="props.row.detrans.estado == 'ES'" />
										<a class="dropdown-item" @click="getSNG(props.row, 'ES')"
											title="Anexados referente ao contrato"
											v-if="props.row.detrans.estado == 'ES'">
											<i class="fas fa-search"></i>
											<span>Anexados referente ao contrato</span>
										</a>
										<a class="dropdown-item" @click="getSNG(props.row.id, 'ES')"
											title="Contrato na SNG" v-if="props.row.detrans.estado == 'ES'">
											<i class="fas fa-search"></i>
											<span>Contrato na SNG.</span>
										</a>
										<a class="dropdown-item" @click="getContratoRegistrado(props.row.id, 'ES')"
											title="Contrato registrado no DETRAN-ES"
											v-if="props.row.detrans.estado == 'ES'">
											<i class="fas fa-search"></i>
											<span>Contrato registrado no DETRAN-ES.</span>
										</a>
										<a class="dropdown-item" @click="getContratoEfetivado(props.row.id, 'ES')"
											title="Efetivação do registro no DETRAN-ES"
											v-if="props.row.detrans.estado == 'ES'">
											<i class="fas fa-search"></i>
											<span>Efetivação do registro no DETRAN-ES.</span>
										</a>

										<hr v-if="props.row.contrato_status.id === 1" />
										<a class="dropdown-item"
											@click="getConsultaContratoMg(props.row.id, props.row.chassi, props.row.num_gravame)"
											title="Consulta Situação Gravame e Contrato"
											v-if="props.row.detrans.estado == 'MG' && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Contrato</span>
										</a>
										<a class="dropdown-item"
											@click="getConsultaContratoBa(props.row.id, props.row.chassi)"
											title="Procurar Contrato"
											v-if="props.row.detrans.estado == 'BA' && userCan.create && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Contrato</span>
										</a>
										<a class="dropdown-item" @click="getConsultaContratoSp(props.row.chassi, 'SP')"
											title="Consulta Contrato"
											v-if="props.row.detrans.estado == 'SP' && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Contrato</span>
										</a>
										<a class="dropdown-item"
											@click="getConsultaContratoSc(props.row.id, props.row.chassi, props.row.num_gravame, 'SC')"
											title="Consulta Contrato"
											v-if="props.row.detrans.estado == 'SC' && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Contrato</span>
										</a>
										<a class="dropdown-item"
											@click="getConsultaContratoRj001(props.row.id, props.row.chassi)"
											title="Consulta Contrato"
											v-if="props.row.detrans.estado == 'RJ' && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Dados SNG</span>
										</a>
										<a class="dropdown-item"
											@click="getConsultaContratoRj003(props.row.id, props.row.chassi)"
											title="Consulta Contrato"
											v-if="props.row.detrans.estado == 'RJ' && props.row.contrato_status.id === 1">
											<i class="fas fa-search"></i>
											<span>Consulta Registro de Contrato</span>
										</a>
                                        <!-- bloco comentado pois mesmo apos a POC nos detrans nao nos foi informado como realizar o proceso de cancelamento -->
										<!-- <a class="dropdown-item" @click="canceContratoDetran(props.row)"
											title="Cancelar Contrato"
											v-if="props.row.detrans.estado == 'AP' && props.row.contrato_status.id === 1 && userCan.canc">
											<i class="fa fa-ban"></i>
											<span>Cancelar contato</span>
										</a>
										<a class="dropdown-item" @click="canceContratoDetran(props.row)"
											title="Cancelar Contrato"
											v-if="props.row.detrans.estado == 'RR' && props.row.contrato_status.id === 1 && userCan.canc">
											<i class="fa fa-ban"></i>
											<span>Cancelar contato</span>
										</a> -->
									</ul>
								</div>
							</span>
							<span v-else-if="props.column.field == 'custo_contrato'">
								{{ formatCurrency(props.row.bancos.detrans[0].custo_contrato) }}
							</span>
							<span v-else-if="props.column.field == 'valor_contrato'">
								{{ formatCurrency(props.row.bancos.detrans[0].valor_contrato) }}
							</span>
							<span v-if="props.column.field === 'arquivo'">
								<a v-if="props.row.id === contratoId && (props.row.arquivo_preenchido === '0'
									|| props.row.arquivo_preenchido === 0)" :title="titleArquivo">
									<i class="fa fa-file-pdf fa-1x" :style="{ 'color': colorArquivo }"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="props.row.detran_id !== 1 && (props.row.arquivo_preenchido === '1' || props.row.arquivo_preenchido === 1) && userCan.create"
									:title="'Baixar Registro de Contrato ' + props.row.chassi"
									:download="'Contrato ' + props.row.chassi"
									@click="logUrl(props.row.detrans.estado, props.row.id)">
									<i class="fa fa-file-pdf fa-1x" style="color: #00FF00"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsOrange.includes(props.row.id) && props.row.arquivo_preenchido === 1
										|| props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsOrange.includes(props.row.id)"
									title="Arquivo não disparado ao Detran até o momento"
									:download="'Contrato ' + props.row.chassi"
									@click="logUrl(props.row.detrans.estado, props.row.id)">
									<i class="fa fa-file-pdf fa-1x" style="color: orange"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsRed.includes(props.row.id) && props.row.arquivo_preenchido === 1
										|| props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsRed.includes(props.row.id)" :title="titleDescricao[props.row.id]"
									:download="'Contrato ' + props.row.chassi">
									<i class="fa fa-file-pdf fa-1x" style="color: red"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsGreen.includes(props.row.id) && props.row.arquivo_preenchido === 1
										|| props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsGreen.includes(props.row.id)"
									:title="'Enviado com sucesso ao Detran SC - Baixar Registro de Contrato' + props.row.chassi"
									:download="'Contrato ' + props.row.chassi">
									<i class="fa fa-file-pdf fa-1x" style="color: #00FF00"></i>
								</a>
								<a v-else title="Registro de contrato sem arquivo enviado">
									<i class="fa fa-file-pdf fa-1x"></i>
								</a>
							</span>

							<div v-else-if="props.column.field === 'contrato_status'" class="badge badge-light"
								@click="verDetalheStatus(props.row)">
								<a href="javascript:void(0)"><i class="fas fa-eye fa-2x"></i>
									<span>&nbsp;&nbsp;</span>
									{{ props.row.contrato_status.nome }}
								</a>
							</div>

							<span v-else @click="resizedM()">
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>

						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === '#'">
								{{ (contratosPagination.per_page * (contratosPagination.current_page - 1)) +
									(props.index +
										1) }}
							</span>

							<span v-if="props.column.field == 'type'">
								<div v-if="props.row.nome_fantasia === true">{{ props.row.nome_fantasia }}</div>
								<div v-else>{{ props.row.nome }}</div>
							</span>
							<span v-if="props.column.field == 'visualizar'">
								<div class="dropdown col-md-4 col-lg-4 col-sm-6 p-0">
									<button class="btn btn-outline-primary btn-filter dropdown-toggle"
										@click="resized()" style="position: absolute;z-index: 999;" type="button"
										id="dropdownActions" data-toggle="dropdown" aria-haspopup="true"
										aria-expanded="true" title="Botão de ações"
										v-if="props.row.contrato_status.id === 1 || userCan.create">
										<span>Ações</span>
									</button>
									<ul class="dropdown-menu" aria-labelledby="dropdownActions">
										<a v-if="userCan.create" class="dropdown-item" title="Visualizar Contrato"
											@click="viewContract(props.row.id, props.row.detrans.estado)">
											<i class="fas fa-eye fa-2x"></i>
											<span>Visualizar contrato</span>
										</a>
									</ul>
								</div>
							</span>
							<span v-else-if="props.column.field == 'custo_contrato'">
								{{ formatCurrency(props.row.bancos.detrans[0].custo_contrato) }}
							</span>
							<span v-else-if="props.column.field == 'valor_contrato'">
								{{ formatCurrency(props.row.bancos.detrans[0].valor_contrato) }}
							</span>
							<span v-if="props.column.field === 'arquivo'">
								<a v-if="props.row.id === contratoId && (props.row.arquivo_preenchido === 0
									|| props.row.arquivo_preenchido === 0)" :title="titleArquivo">
									<i class="fa fa-file-pdf fa-1x" :style="{ 'color': colorArquivo }"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="props.row.detran_id !== 1 && (props.row.arquivo_preenchido === 1 || props.row.arquivo_preenchido === 1) && userCan.create"
									:title="'Baixar Registro de Contrato ' + props.row.chassi"
									:download="'Contrato ' + props.row.chassi"
									@click="logUrl(props.row.detrans.estado, props.row.id)">
									<i class="fa fa-file-pdf fa-1x" style="color: #00FF00"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsOrange.includes(props.row.id) && props.row.arquivo_preenchido === 1
                                             || props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsOrange.includes(props.row.id)"
									title="Arquivo não disparado ao Detran até o momento"
									:download="'Contrato ' + props.row.chassi"
									@click="logUrl(props.row.detrans.estado, props.row.id)">
									<i class="fa fa-file-pdf fa-1x" style="color: orange"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsRed.includes(props.row.id) && props.row.arquivo_preenchido === 1
										|| props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsRed.includes(props.row.id)" :title="titleDescricao[props.row.id]"
									:download="'Contrato ' + props.row.chassi">
									<i class="fa fa-file-pdf fa-1x" style="color: red"></i>
								</a>
								<a :href="'/portal/contratos/' + props.row.detrans.estado + '/' + props.row.id + '/download'"
									v-else-if="contratoIdsGreen.includes(props.row.id) && props.row.arquivo_preenchido === 1
										|| props.row.arquivo_preenchido === 1 && userCan.create && contratoIdsGreen.includes(props.row.id)"
									:title="'Enviado com sucesso ao Detran SC - Baixar Registro de Contrato' + props.row.chassi"
									:download="'Contrato ' + props.row.chassi">
									<i class="fa fa-file-pdf fa-1x" style="color: #00FF00"></i>
								</a>
								<a v-else title="Registro de contrato sem arquivo enviado">
									<i class="fa fa-file-pdf fa-1x"></i>
								</a>
							</span>

							<div v-else-if="props.column.field === 'contrato_status'" class="badge badge-light"
								@click="verDetalheStatus(props.row)">
								<a href="javascript:void(0)"><i class="fas fa-eye fa-2x"></i>
									<span>&nbsp;&nbsp;</span>
									{{ props.row.contrato_status.nome }}
								</a>
							</div>

							<span v-else @click="resizedM()">
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>

					<div class="d-flex justify-content-between">
						<div class="pt-2 pr-2 pl-0">
							<div class="input-group">
								<div class="input-group-prepend">
									<label for="perPage" class="input-group-text">Linhas</label>
								</div>
								<select class="custom-select" @change="perPageChange" v-model="selectedPerPage">
									<option v-for="PerPagesValue in PerPagesValues" v-bind:value="PerPagesValue.value"
										:selected="selectedPerPage === contratosPagination.per_page">
										{{ PerPagesValue.name }}
									</option>
								</select>
							</div>
						</div>

						<div class="pt-2 pr-0">
							<vue-pagination :pagination="contratosPagination" @paginate="selectPage()"
								:disabledLoading="disabledLoading" :offset="4">
							</vue-pagination>
						</div>
					</div>

					<sweet-modal ref="modal" heigth="60px" :hide-close-button="true">
						<div class="row">
							<div class="col-12">
								<div class="activities" v-if="datalheStatus.length > 0">
									<div class="activity" v-for="detalhe in datalheStatus" v-bind:key="detalhe.id">
										<div class="activity-icon bg-primary text-white shadow-primary">
											<i class="fas fa-comment-alt"></i>
										</div>
										<div class="activity-detail">
											<div class="mb-2">
												<span class="text-job text-primary text-center"
													v-if="detalhe.detran_retorno_api_id != undefined">
													Status API: {{ detalhe.detran_retorno_apis.status_api }}
													<div class="bullet"></div>
													{{ detalhe.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
													<div class="bullet"></div> {{ 'Ip do usuário: ' + detalhe.ip_user }}
												</span>
												<span class="text-job text-primary text-center" v-else>
													Status Atividade:
													<div class="bullet"></div> {{ detalhe.created_at
														| moment("DD/MM/YYYY HH: mm: ss") }}
												</span>
											</div>
											<p v-if="detalhe.detran_retorno_api_id != undefined">
												{{ detalhe.detran_retorno_apis.descricao_retorno }}
											</p>
											<p v-else>{{ detalhe.action }}</p>
										</div>
									</div>
								</div>
								<div v-else-if="!detalheStatusLoading && !datalheStatus.length"
									class="text-center p-3 text-muted">
									<h5>Nenhuma informação de retorno</h5>
								</div>
								<div class="d-flex justify-content-center" v-else>
									<div class="spinner-border text-danger" role="status"></div>
								</div>
							</div>
						</div>
						<button slot="button" class="btn btn-danger" @click="fecharModal">Fechar</button>
					</sweet-modal>

					<sweet-modal ref="modalsng" heigth="60px" :hide-close-button="true">
						<div class="row">
							<div class="col-12">
								{{ returnSng }}
							</div>
						</div>
						<button slot="button" class="btn btn-danger" @click="fecharModalSng">Fechar</button>
					</sweet-modal>
					<sweet-modal ref="modalcontratoregistrado" heigth="60px" :hide-close-button="true">
						<div class="row">
							<div class="col-12">
								{{ returnContratoregistrado }}
							</div>
						</div>
						<button slot="button" class="btn btn-danger" @click="fecharModalRegistrado">Fechar</button>
					</sweet-modal>
					<sweet-modal ref="modalcontratoefetivado" heigth="60px" :hide-close-button="true">
						<div class="row">
							<div class="col-12">
								{{ returnContratoefetivado }}
							</div>
						</div>
						<button slot="button" class="btn btn-danger"
							@click="fecharModalContratoEfetivado">Fechar</button>
					</sweet-modal>
				</div> <!--loading e contratos-->
				<div v-if="!contratos.length && !loading" class="text-center p-3 text-muted">
					<h5>Nenhum contrato encontrado!</h5>
				</div>
				<!-- end table -->
				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>
				<!-- end table -->
			</div>
			<!-- end card body -->
		</div>
	</template>

	<!-- MODAL IMPORTAR CONTRATOS -->
	<div class="modal fade" id="modalImportarContratos" tabindex="-1" aria-labelledby="modalImportarContratosLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="d-flex justify-content-center">
					<div style="margin-top: 30px;">
						<h5 id="modalImportarContratosLabel">Importar Contratos</h5>
					</div>
				</div>
				<div style="margin-left: 150px; margin-right: 150px;">
					<span style="display: block; border-top: 1px solid rgb(216, 216, 216);"></span>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12" v-if="userGpEcon.is_group_economic == 'S'">
							<div class="form-group">
								<v-select id="instituicao" placeholder="Selecione a Instituição" label="label"
									@input="setSelectInstituicao" :options="bancoOption">
								</v-select>
							</div><!-- form group -->
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group text-left" style="display: flex; justify-content: space-between;">
								<div class="col-md-6 d-flex justify-content-center" style="display: inline-block;">
									<label class="form-control-label btn btn-primary" for="fileContrato"
										style="background-color:#ec2434 ;border-radius: 5px;color: #fff;cursor: pointer;padding: 6px 20px">
										Selecione o arquivo
									</label>
									<input type="file" style="display: none" class="form-control" id="fileContrato"
										ref="fileContrato" @change="handleFileUploadContrato">
								</div>
								<div class="col-md-6 d-flex justify-content-center" style="display: inline-block;">
									<a href="javascript:void(0);" class="form-control-label btn btn-primary"
										style="width: 110px; height: 38px; background-color:#ec2434 ;border-radius: 5px;color: #fff;cursor: pointer;padding: 6px 20px"
										@click="importTXTCSV()">Importar</a>
								</div>
							</div>
							<div style="margin-left: 40px;">
								<label>Arquivo selecionado:</label>
								<input type="text" disabled style="border: none;" v-model="nameFile" />
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<!-- END MODAL IMPORTAR CONTRATOS -->

	<!-- modalcontrtatos -->

	<div class="modal fade" id="modalCadastoContratos" tabindex="-1" aria-labelledby="modalCadastoContratosLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="d-flex justify-content-center">
					<div style="margin-top: 30px;">
						<h5 id="modalCadastoContratosLabel">Inclusão de Contrato Convencional</h5>
					</div>
				</div>
				<div style="margin-left: 150px; margin-right: 150px;">
					<span style="display: block; border-top: 1px solid rgb(216, 216, 216);"></span>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<v-select id="instituicao" placeholder="Selecione a Instituição" label="label"
									@input="setSelectInstituicao" :options="bancoOption" required>
								</v-select>
							</div><!-- form group -->
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<v-select id="instituicao" placeholder="Selecione o Detran" label="label"
									@input="setSelectDetran" :options="detransOption" required>
								</v-select>
							</div><!-- form group -->
						</div>
					</div>
					<div class="row row align-items-center justify-content-center">
						<div class="col-md-6 d-flex justify-content-center" style="display: inline-block;">
							<a href="javascript:void(0);" class="form-control-label btn "
								style="width: 110px; height: 38px; background-color:#3bb557 ;border-radius: 5px;color: #fff;cursor: pointer;padding: 6px 20px"
								@click="newContractGpEcon()">Ok</a>
						</div>
						<div class="col-md-6 d-flex justify-content-center" style="display: inline-block;">
							<a href="javascript:void(0);" class="form-control-label btn"
								style="width: 110px; height: 38px; background-color:#777777 ;border-radius: 5px;color: #fff;cursor: pointer;padding: 6px 20px"
								@click="closeModalConvencinal()">Cancelar</a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	</div>

	<!-- modalcontratos end -->
	<!-- MODAL UPLOAD DE ANEXO  -->
	<div class="modal fade" id="modalUploadNovoPdf" tabindex="-1" aria-labelledby="modalUploadNovoPdfLabel"
		aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="modalUploadNovoPdfLabel">Upload de Anexo do Contrato</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearUploadPdf">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form method="POST" v-on:submit.prevent="pdfUpload">
						<div class="row">

							<!-- arquivo -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label">Upload PDF</label>
									<input class="form-control" type="file" accept="application/pdf" id="attach"
										name="attach" required ref="attach" lang="pt"
										v-on:change="handleFileUpload()" />
								</div>
							</div><!-- col-md-6 -->
							<!-- end arquivo  -->

							<div class="col-md-12 text-right">
								<button type="submit" class="btn btn-primary">ENVIAR</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- END MODAL UPLOAD DE ANEXO  -->
	<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import VuePagination from '../../Paginator.vue';
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import vSelect from 'vue-select';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';

export default {
	props: ['instituicao', 'permissions', 'notifications', 'user'],
	data() {
		const user = JSON.parse(this.user)
		let intituicaoData = ''
		if (user.is_group_economic != 'S') {
			intituicaoData = JSON.parse(this.instituicao)
		}
		return {
			selectedTypePeriodo: "",
			title1: '',
			title2: '',
			legendFilter: 'Localizar',
			showSelectedFilters: false,
			nameBank: intituicaoData.nome_fantasia ?? intituicaoData.nome,
			selectedInstituicao: [],
			detransOption: [],
			detransBancoInst: [],
			banco: '',
			bancoOption: [],
			bancoSelecionado: [],
			detranSelecionado: [],
			userGpEcon: JSON.parse(this.user),
			processamentoIds: [],
			registradosIds: [],
			pendentesIds: [],
			titleArquivo: "",
			colorArquivo: "#606266",
			contratoId: [],
			titleDescricao: [],
			contratoIdsRed: [], // IDs que correspondem à condição com cor vermelha
			contratoIdsGreen: [], // IDs que correspondem à condição com cor verde
			contratoIdsOrange: [], // IDs que correspondem à condição com cor laranja
			nameFile: "",
			returnSng: "",
			returnContratoregistrado: "",
			returnContratoefetivado: "",
			sortField: "",
			disabledLoading: false,
			contratos: [],
			detrans: [],
			userCan: '',
			contratoStatus: [],
			checkedDetrans: [],
			checkedStatus: [],
			checkedOperacao: [],
			nome_municipio_devedor: '',
			endpoint: '/api/portal/contratos',
			contratosPagination: {
				searchTerm: '',
				total: 0,
				per_page: 10,
				from: 1,
				to: 0,
				current_page: 1,
			},
			pageDefault: false,
			navigate_validation: false,
			offset: 4,
			arquivos: [{ id: 1, anexo: 'Não' }, { id: 2, anexo: 'Sim' }],
			selectedPerPage: 10,
			PerPagesValues: [
				{ value: "10", name: '10' },
				{ value: "20", name: '20' },
				{ value: "30", name: '30' },
				{ value: "50", name: '50' },
			],
			tipo_operacao:
				[
					{ id: 1, nome: '1- Inclusão do registro' },
					{ id: 2, nome: '2- Alteração do registro' },
					{ id: 3, nome: '3- Inclusão do aditivo' },
					{ id: 4, nome: '4- Alteração do aditivo' },

				],

			tipo_periodo:
				[
					{ id: 1, nome: 'Data do Cadastro' },
					{ id: 2, nome: 'Data do Contrato' },
					{ id: 3, nome: 'Data do Registro' },
					{ id: 4, nome: 'Data do Envio da Imagem' },
					{ id: 5, nome: 'Data da Baixa' },
					{ id: 6, nome: 'Data do Cancelamento' },

				],
			periodo:
				[
					{ id: 1, nome: 'Últimos 7 dias' },
					{ id: 2, nome: 'Últimos 15 dias' },
					{ id: 3, nome: 'Últimos 30 dias' },
					{ id: 4, nome: 'Período Personalizado' },

				],


			states_devedor:
				[
					{ id: 1, nome: 'AC' },
					{ id: 2, nome: 'AL' },
					{ id: 3, nome: 'AM' },
					{ id: 4, nome: 'AP' },
					{ id: 5, nome: 'BA' },
					{ id: 6, nome: 'CE' },
					{ id: 7, nome: 'DF' },
					{ id: 8, nome: 'ES' },
					{ id: 9, nome: 'GO' },
					{ id: 10, nome: 'MA' },
					{ id: 11, nome: 'MT' },
					{ id: 12, nome: 'MS' },
					{ id: 13, nome: 'MG' },
					{ id: 14, nome: 'PA' },
					{ id: 15, nome: 'PB' },
					{ id: 16, nome: 'PR' },
					{ id: 17, nome: 'PE' },
					{ id: 18, nome: 'PI' },
					{ id: 19, nome: 'RJ' },
					{ id: 20, nome: 'RN' },
					{ id: 21, nome: 'RO' },
					{ id: 22, nome: 'RS' },
					{ id: 23, nome: 'RR' },
					{ id: 24, nome: 'SC' },
					{ id: 25, nome: 'SE' },
					{ id: 26, nome: 'SP' },
					{ id: 27, nome: 'TO' },

				],
			tipo_contrato:
				[
					{ id: 1, nome: 'Arrendamento Mercantil' },
					{ id: 2, nome: 'Reserva de Domínio' },
					{ id: 3, nome: 'Alienação Fidunciária' },
					{ id: 9, nome: 'Penhor' },
					{ id: 91, nome: 'Consórcio' },
					{ id: 92, nome: 'Carta de Crédito' },
				],
			dataInicial: "",
			dataFinal: "",
			arquivo: [],
			pdf_id: "",
			states: ["Selecione um estado...", "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"],
			pdf_estado: "",
			attach: "",
			municipio_dev: '',
			chassi: "",
			resize: false,
			nome_agente: 'Nome do Credor: ' + intituicaoData.nome,
			nome_devedor: "",
			uf_devedor: "",
			calendarLanguage: ptBR,
			total: -1,
			concluido: -1,
			processando: -1,
			pendente: -1,
			municipios: [],
			loading: false,
			getPlaceStates: '',
			getPlaceMuni: '',
			datalheStatus: false,
			detalheStatusLoading: false,
			file: '',
			tipos: [],
			agente: [],
			checkedAgente: [],
			checkedTipos: [],
			selectedPeriodo: "",
			marcas: [],
			checkedMarcas: [],
			modelos: [],
			checkedModelos: [],
			numero_contrato: "",
			placa_veiculo: "",
			renavam_veiculo: "",
			ano_veiculo_fab: "",
			ano_veiculo_modelo: "",
			numero_gravame: "",
			checkedTipoContrato: [],
			numero_contrato_eletronico: "",
			cnpj_cpf_dev: "",
			nome_agente_cadastro: "",
			dataInicialContrato: "",
			dataFinalContrato: "",
			dataInicialBaixa: "",
			dataFinalBaixa: "",
			dataInicialCancelamento: "",
			dataFinalCancelamento: "",
			dataInicialEnvioImg: "",
			dataFinalEnvioImg: "",
			dataInicialRegDetran: "",
			dataFinalRegDetran: "",
			dataInicialPeriodo: "",
			dataFinalPeriodo: "",
			importContrato: {
				fileType: '',
				file: ''
			},
			isLoading: false,
			columns: [
				{
					label: '#',
					field: '#',
					type: "number",
					sortable: false
				},
				{
					label: 'Id',
					field: 'id',
					type: "number",
					sortable: true
				},
				{
					label: "Instituição",
					field: "type",
					type: "string",
					sortable: true
				},
				{
					label: "Nº Contrato",
					field: "num_contrato",
					type: "number",
					sortable: true
				},
				{
					label: "Chassi",
					field: "chassi",
					sortable: true
				},
				{
					label: "Estado",
					field: "detrans.estado",
					type: 'string',
					sortable: true
				},
				{
					label: "Nº Gravame",
					field: "num_gravame",
					type: "number",
					sortable: true
				},
				{
					label: "Status",
					field: "contrato_status",
					type: 'string',
					sortable: true
				},
				{
					label: 'Dt Cadastro',
					field: 'created_at',
					type: 'date',
					dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
					dateOutputFormat: 'dd/MM/yyyy HH:mm:ss',
					filterable: true,
					sortable: true
				},
				{
					label: " ",
					field: "arquivo",
					tdClass: 'text-center',
					sortable: true
				},
				{
					label: "Ações",
					field: "visualizar",
					tdClass: '',
					sortable: false,
					html: true,
				},
			],
			rows: []
		};
	},
	watch: {
		selectedPeriodo: {
			immediate: true,
			handler(newValue, oldValue) {
				this.updateDateVariables();
			}
		},
		dataInicialPeriodo(newValue, oldValue) {
			// Verifique se o novo valor é diferente do antigo antes de chamar a função
			if (newValue !== oldValue) {
				this.updateDateVariables();
			}
		},
		dataFinalPeriodo(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.updateDateVariables();
			}
		},
		bancoSelecionado(value) {
			if (this.userGpEcon.is_group_economic == 'S') {
				this.detransOption = []
				this.getDetranBanco('S', this.bancoSelecionado.id)
			}
		},
		selectedTypePeriodo() {
			this.calcularDatasPeriodo();
		},
	},
	mounted() {
		this.colorArquivo = "#606266";
		this.titleArquivo = "Registro de contrato sem arquivo enviado";
		this.userCan = JSON.parse(this.permissions);
		this.loadContratos();
		this.loadFilters();
		this.loadDashboardData();
		this.loadMunicipios();
		this.loadTipos();
		this.getMarcas();
		this.getModelos();
		this.loadUsersAgente();
		this.loadStatusContratos();
		if (this.userGpEcon.is_group_economic == 'S') {
			this.getAllBancos()
		}
	},
	methods: {
		logUrl(uf, id) {
			const downloadUrl = `/portal/contratos/${uf}/${id}/download`;
		},
		calcularDatasPeriodo() {
			const hoje = new Date(); // Obtém a data atual
			switch (this.selectedTypePeriodo) {
				case 1: // Últimos 7 dias
					this.dataFinalPeriodo = hoje.toISOString();
					hoje.setDate(hoje.getDate() - 6);
					this.dataInicialPeriodo = hoje.toISOString();
					break;
				case 2: // Últimos 15 dias
					this.dataFinalPeriodo = hoje.toISOString();
					hoje.setDate(hoje.getDate() - 14);
					this.dataInicialPeriodo = hoje.toISOString();
					break;
				case 3: // Últimos 30 dias
					this.dataFinalPeriodo = hoje.toISOString();
					hoje.setDate(hoje.getDate() - 29);
					this.dataInicialPeriodo = hoje.toISOString();
					break;

			}
		},
		updateDateVariables() {

			this.dataInicial = "";
			this.dataFinal = "";
			this.dataInicialContrato = "";
			this.dataFinalContrato = "";
			this.dataInicialRegDetran = "";
			this.dataFinalRegDetran = "";
			this.dataInicialEnvioImg = "";
			this.dataFinalEnvioImg = "";
			this.dataInicialBaixa = "";
			this.dataFinalBaixa = "";
			this.dataInicialCancelamento = "";
			this.dataFinalCancelamento = "";

			if (this.selectedPeriodo === 1) {
				this.dataInicial = this.dataInicialPeriodo;
				this.dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 2) {
				this.dataInicialContrato = this.dataInicialPeriodo;
				this.dataFinalContrato = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 3) {
				this.dataInicialRegDetran = this.dataInicialPeriodo;
				this.dataFinalRegDetran = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 4) {
				this.dataInicialEnvioImg = this.dataInicialPeriodo;
				this.dataFinalEnvioImg = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 5) {
				this.dataInicialBaixa = this.dataInicialPeriodo;
				this.dataFinalBaixa = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 6) {
				this.dataInicialCancelamento = this.dataInicialPeriodo;
				this.dataFinalCancelamento = this.dataFinalPeriodo;
			} else {
				this.dataInicialPeriodo = "";
				this.dataFinalPeriodo = "";
			}
		},
		modifyTotalizers() {

			let detranId = this.checkedDetrans.length > 0 ? this.checkedDetrans : null
			let dataInicial = ''
			let dataFinal = ''
			if (this.selectedPeriodo === 1) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 2) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 3) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 4) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 5) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			} else if (this.selectedPeriodo === 6) {
				dataInicial = this.dataInicialPeriodo;
				dataFinal = this.dataFinalPeriodo;
			}

			this.loadTotalContratos(this.formatFilterDate(dataInicial), this.formatFilterDate(dataFinal), detranId, this.selectedPeriodo);
			this.loadConcluidoContratos(this.formatFilterDate(dataInicial), this.formatFilterDate(dataFinal), detranId, this.selectedPeriodo);
			this.loadProcessandoContratos(this.formatFilterDate(dataInicial), this.formatFilterDate(dataFinal), detranId, this.selectedPeriodo);
			this.loadPendenteContratos(this.formatFilterDate(dataInicial), this.formatFilterDate(dataFinal), detranId, this.selectedPeriodo);
		},
		async getDetranBanco(group, idBank) {
			await axios.post(`/api/portal/getDetransUser/${group}/${idBank}`)
				.then(res => {
					let detransBancoInst = res.data;
					const detrans = [];
					Object.keys(detransBancoInst).forEach(key => {
						const item = detransBancoInst[key];
						Object.values(item).forEach(values => {
							detrans.push({
								label: values.estado,
								id: values.id
							});
						});
					});
					// Ordena os Detrans em ordem alfabética
					this.detransOption = detrans.sort((a, b) => a.label.localeCompare(b.label));
				})
				.catch((err) => {
					console.error(err);
					this.loading = false;
				});
		},
		setSelectInstituicao(item) {
			this.bancoSelecionado = item
		},
		setSelectDetran(item) {
			this.detranSelecionado = item
		},
		getAllBancos() {
			axios.get("/api/portal/getAllBancos").then(res => {
				this.bancos = res.data.bancos;
				this.bancoOption = this.bancos.map(element => ({
					label: element.nome,
					id: element.id,
				}));
			}).catch((err) => {
				console.error(err);
				this.loading = false;
			});
		},
		getStatusArquivoSC(id) {
			axios.get(`/portal/statusArquivoSC/${id}`).then((res) => {
				if (res.data.length > 0 && res.data[0].status_file !== 1) {
					this.contratoIdsRed.push(id);
					this.titleDescricao[id] = res.data[0].descricao;
				} else if (res.data.length > 0 && res.data[0].status_file === 1) {
					this.contratoIdsGreen.push(id);
				} else {
					this.contratoIdsOrange.push(id);
				}
			}).catch((err) => {
				console.error(err);
			});
		},
		clearDate(dateType) {
			if (dateType === 'dataFinal') {
				this.dataFinal = '';
			} else if (dateType === 'dataInicial') {
				this.dataInicial = '';
			} else if (dateType === 'dataInicialContrato') {
				this.dataInicialContrato = '';
			} else if (dateType === 'dataFinalContrato') {
				this.dataFinalContrato = '';
			} else if (dateType === 'dataInicialRegistro') {
				this.dataInicialRegistro = '';
			} else if (dateType === 'dataFinalRegistro') {
				this.dataFinalRegistro = '';
			} else if (dateType === 'dataInicialBaixa') {
				this.dataInicialBaixa = '';
			} else if (dateType === 'dataFinalBaixa') {
				this.dataFinalBaixa = '';
			} else if (dateType === 'dataInicialCancelamento') {
				this.dataInicialCancelamento = '';
			} else if (dateType === 'dataFinalCancelamento') {
				this.dataFinalCancelamento = '';
			} else if (dateType === 'dataInicialEnvioImg') {
				this.dataInicialEnvioImg = '';
			} else if (dateType === 'dataFinalEnvioImg') {
				this.dataFinalEnvioImg = '';
			} else if (dateType === 'dataInicialRegDetran') {
				this.dataInicialRegDetran = '';
			} else if (dateType === 'dataFinalRegDetran') {
				this.dataFinalRegDetran = '';
			} else if (dateType === 'dataInicialPeriodo') {
				this.dataInicialPeriodo = '';
			} else if (dateType === 'dataFinalPeriodo') {
				this.dataFinalPeriodo = '';
			}
		},

		perPageChange(event) {
			event.preventDefault();
			this.contratosPagination.per_page = event.target.value;
			this.loadContratos();
		},

		changeDisabledLoading() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.disabledLoading = true;
			this.loadContratos();
			this.showSelectedFilters = true;
			this.legendFilter = 'Localizado(s)';
		},

		selectPage() {
			this.disabledLoading = true;
			this.loadContratos();
		},

		filtraRegistrados() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.checkedStatus = [1];
			this.disabledLoading = true;
			this.loadContratos();
		},
		filtraEmProcessamento() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.checkedStatus = this.processamentoIds;
			this.disabledLoading = true;
			this.loadContratos();
		},
		filtraPendentes() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.checkedStatus = this.pendentesIds;
			this.disabledLoading = true;
			this.loadContratos();
		},
		filtraTodosContratos() {
			this.pageDefault = 1;
			this.navigate_validation = 1;
			this.checkedStatus = [];
			this.disabledLoading = true;
			this.loadContratos();
		},

		loadTipos() {
			let _this = this;
			_this.loading = true;
			_this.tipos = [];
			axios.get("/portal/tipoveiculo").then((res) => {
				_this.tipos = _this.tipos.concat(res.data)
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		loadUsersAgente() {
			let _this = this;
			_this.loading = true;
			_this.agente = [];
			axios.get("/portal/users/agente").then((res) => {
				_this.agente = _this.agente.concat(res.data)
				_this.loading = false;
			}).catch((err) => {
				_this.loading = false;
			});
		},
		getMarcas(e) {
            if (e == null || e === '' || e === []) {
                this.marcas = [];
                this.modelos = [];
                this.cor_id = '';
                this.marca_id = '';
                this.modelo_id = '';
            }
            let tipo_id = e

            this.marcas = '';
            this.marca_id = '';
            this.modelo_id = '';

            if ("" !== tipo_id) {

                axios.get(`/portal/newmarcas/${tipo_id}`)
                    .then(response => {
                        this.marcas = response.data;
                    })
                    .catch(error => {

                    });
            } else {
                this.marcas = [];
                this.modelos = [];
                this.cores = []
                this.cor_id = ''
            }
        },
		getModelos(e) {
			if (e == null) {
				this.cores = [];
				this.modelos = [];
				this.marcas = [];
				this.cor_id = '';
				this.marca_id = '';
				this.modelo_id = '';
				this.tipo_id = '';
			}
			let marca_id = e
			if ("" !== marca_id) {
				axios.get(`/portal/modelos/${marca_id}`)
					.then(response => {
						// Ordenar os modelos em ordem alfabética
						this.modelos = response.data.sort((a, b) => a.nome.localeCompare(b.nome));

						if (this.modelos.length > 0) {
							this.loadCores();
							this.cor_id = this.cores.id;
						} else {
							this.cores = [];
							this.cor_id = '';
						}
					})
					.catch(error => {
					});
			} else {
				this.modelos = [];
				this.cores = [];
				this.cor_id = '';
			}
		},
		getModelNameById(modelId) {
			const model = this.modelos.find(item => item.id === modelId);
			return model ? model.nome : "";
		},
		getMarcaNameById(marcasId) {
			const marcas = this.marcas.find(item => item.id === marcasId);
			return marcas ? marcas.nome : "";
		},
		getAgenteNameById(agenteId) {
			const agente = this.agente.find(item => item.id === agenteId);
			return agente ? agente.name : "";
		},
		loadContratos() {

			let _this = this;
			_this.isLoading = true;
			if (_this.disabledLoading === false) _this.loading = true;

			let page;
			if (_this.navigate_validation === 1) {
				page = _this.pageDefault;
				_this.navigate_validation = null;
			} else {
				page = _this.contratosPagination.current_page;
			}

			axios.post(_this.endpoint + `?page=${page}&per_page=${this.contratosPagination.per_page}&sort=${this.sortField}&searchTerm=${this.contratosPagination.searchTerm}`, {
				gpEconId: [this.userGpEcon.gp_econ_id],
				search: [
					{
						field: "banco_id",
						value: _this.selectedInstituicao,
					},
					{
						field: "detrans",
						value: _this.checkedDetrans,
					},
					{
						field: "contrato_status",
						value: _this.checkedStatus,
					},
					{
						field: "arquivo",
						value: _this.arquivo,
					},
					{
						field: "tipo_operacao",
						value: _this.checkedOperacao,
					},
					{
						field: "data_inicial",
						value:
							_this.dataInicial != ""
								? _this.formatFilterDate(_this.dataInicial)
								: null,
					},
					{
						field: "data_final",
						value:
							_this.dataFinal != ""
								? _this.formatFilterDate(_this.dataFinal)
								: null,
					},
					{
						field: "nome_agente",
						value:
							_this.selectedInstituicao != ""
								? _this.selectedInstituicao
								: null,
					},
					{
						field: "nome_devedor",
						value:
							_this.nome_devedor != ""
								? _this.nome_devedor
								: null,
					},
					{
						field: "chassi",
						value:
							_this.chassi !== "" ? _this.chassi : null,
					},

					{
						field: "num_contrato",
						value: _this.numero_contrato !== "" ? _this.numero_contrato : null,
					},
					{
						field: "uf_devedor",
						value:
							_this.uf_devedor != "Selecione um estado..."
								? _this.uf_devedor
								: _this.$swal({
									title: "Erro",
									text: "selecione um estado valido!",
									icon: "error"
								}),
					},
					{
						field: "nome_municipio_devedor",
						value:
							_this.nome_municipio_devedor != ""
								? _this.nome_municipio_devedor
								: null,
					},
					{
						field: "ano_fabricacao",
						value:
							_this.ano_veiculo_fab !== "" ? _this.ano_veiculo_fab : null,
					},
					{
						field: "ano_modelo",
						value:
							_this.ano_veiculo_modelo !== "" ? _this.ano_veiculo_modelo : null,
					},
					{
						field: "tipo_gravame",
						value: _this.checkedTipoContrato,
					},
					{
						field: "tipo_id",
						value: _this.checkedTipos,
					},
					{
						field: "marca_id",
						value: _this.checkedMarcas,
					},
					{
						field: "modelo_id",
						value: _this.checkedModelos,
					},
					{
						field: "user_inc_id",
						value: _this.checkedAgente,
					},

					{
						field: "placa",
						value:
							_this.placa_veiculo !== "" ? _this.placa_veiculo : null,
					},
					{
						field: "renavam",
						value:
							_this.renavam_veiculo !== "" ? _this.renavam_veiculo : null,
					},
					{
						field: "num_gravame",
						value:
							_this.numero_gravame !== "" ? _this.numero_gravame : null,
					},
					{
						field: "numero_contrato_eletronico",
						value:
							_this.numero_contrato_eletronico !== "" ? _this.numero_contrato_eletronico : null,
					},
					{
						field: "nome_agente_cadastro",
						value:
							_this.nome_agente_cadastro !== "" ? _this.nome_agente_cadastro : null,
					},
					{
						field: "cpf_cnpj_devedor",
						value:
							_this.cnpj_cpf_dev != ""
								? _this.cnpj_cpf_dev
								: null,
					},
					{
						field: "data_inicial_contrato",
						value:
							_this.dataInicialContrato != ""
								? _this.formatFilterDate(_this.dataInicialContrato)
								: null,
					},
					{
						field: "data_final_contrato",
						value:
							_this.dataFinalContrato != ""
								? _this.formatFilterDate(_this.dataFinalContrato)
								: null,
					},
					{
						field: "data_inicial_baixa",
						value:
							_this.dataInicialBaixa != ""
								? _this.formatFilterDate(_this.dataInicialBaixa)
								: null,
					},
					{
						field: "data_final_baixa",
						value:
							_this.dataFinalBaixa != ""
								? _this.formatFilterDate(_this.dataFinalBaixa)
								: null,
					},
					{
						field: "data_inicial_cancelamento",
						value:
							_this.dataInicialCancelamento != ""
								? _this.formatFilterDate(_this.dataInicialCancelamento)
								: null,
					},
					{
						field: "data_final_cancelamento",
						value:
							_this.dataFinalCancelamento != ""
								? _this.formatFilterDate(_this.dataFinalCancelamento)
								: null,
					},
					{
						field: "data_inicial_envio_img",
						value:
							_this.dataInicialEnvioImg != ""
								? _this.formatFilterDate(_this.dataInicialEnvioImg)
								: null,
					},
					{
						field: "data_final_envio_img",
						value:
							_this.dataFinalEnvioImg != ""
								? _this.formatFilterDate(_this.dataFinalEnvioImg)
								: null,
					},

					{
						field: "data_inicial_reg_detran",
						value:
							_this.dataInicialRegDetran != ""
								? _this.formatFilterDate(_this.dataInicialRegDetran)
								: null,
					},
					{
						field: "data_final_reg_detran",
						value:
							_this.dataFinalRegDetran != ""
								? _this.formatFilterDate(_this.dataFinalRegDetran)
								: null,
					}
				],
			}).then((res) => {

				if (this.contratosPagination.searchTerm && res.data.contratos.data.length <= 0) {
					this.contratosPagination.searchTerm = ''
					this.loadContratos()
				} else {
					_this.contratos = res.data.contratos.data.map(contrato => {
						contrato.children = contrato.children.map(child => {
							return { ...child, nome_fantasia: contrato.nome_fantasia, nome: contrato.nome };
						});
						return contrato;
					});
					_this.contratosPagination = res.data.contratos
					_this.loading = false;
					_this.legendFilter = 'Localizado(s)';
					_this.title1 = res.data.title1
					_this.title2 = res.data.title2

					if ((_this.checkedDetrans.length > 0) || (_this.selectedPeriodo)) {
						this.modifyTotalizers()
					} else {
						this.loadDashboardData()
					}

					_this.contratos.forEach(item => {
						if (item.detran_id === 1) {
							_this.getStatusArquivoSC(item.id);
						}
					});
				}

			}).catch((err) => {
				_this.loading = false;
			});
		},
		updateParams(newProps) {
			this.contratosPagination = Object.assign({}, this.contratosPagination, newProps);
		},
		onSearch: _.debounce(function (params) {
			this.updateParams(params);
			this.changeDisabledLoading();
			return false;
		}, 500),

		// disparado quando clica nas colunas para ordenar
		onSortChange(params) {
			this.sortField = `${params[0].type === 'desc' ? '-' : ""}${params[0].field}`
			this.changeDisabledLoading()
		},

		getData(key) {
			return JSON.parse(this.instituicao)[key];
		},

		handleFileUploadContrato(event) {
			this.nameFile = event.target.files[0].name;
			this.importContrato.file = event.target.files[0];
		},

		changeStatus(item, estado, id) {

			//valida if para o texto
			var texto;
			if (id == 13) {
				texto = "Tem certeza que deseja realizar o Cancelamento do Registro do Contrato?";
			} else {
				texto = "Tem certeza que deseja realizar a Baixa do Registro do Contrato";
			}

			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Atenção",
				text: texto,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Continuar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove
					axios.get("/portal/contratos/status/" + estado + "/" + item.id + "/" + id).then((res) => {

						if (res.data.status == true) {

							_this.contratos.map((rowContrato) => {
								if (rowContrato.id == item.id) {
									rowContrato.contrato_status_id = id;
								}
							});

							_this.$swal({
								title: 'Sucesso',
								text: 'Contrato Atualizado com Sucesso',
								icon: 'success'
							});

						} else {

							_this.$swal({
								title: 'Erro',
								text: 'Falha ao atualizar o Contrato, tente novamente!',
								icon: 'error'
							});
						}

					}).catch(err => {

						_this.loading = false;

						_this.$swal({
							title: 'Erro',
							text: 'Falha ao atualizar o Contrato, tente novamente!',
							icon: 'error'
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation

		},
		importTXTCSV() {

			let _this = this;
			let formData = new FormData();
			formData.append('arquivo', _this.importContrato.file);
			if (this.userGpEcon.is_group_economic == 'S') {
				formData.append('intituicaoId', this.bancoSelecionado.id);
			}

			_this.loading = true;

			axios
				.post("/portal/contratos/importar", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then((res) => {

					_this.loading = false;

					if (!res.data.erro) {
						$("#modalImportarContratos").modal('hide');
						_this.$swal({
							title: "Sucesso",
							text: "Importação concluida com sucesso!",
							icon: "success"
						}).then(() => {
							this.loadContratos()
						});
					} else {
						let erros = res.data.erro
						const html = erros.map((erro) => `<div style="font-size:14px;text-align: left;" >- ${erro};</div>`).join('');

						$("#modalImportarContratos").modal('hide');
						_this.$swal({
							title: "Erro",
							html: html,
							icon: "error"
						});
					}
				})
				.catch((err) => {

					_this.loading = false;
					$("#modalImportarContratos").modal('hide');
					_this.$swal({
						title: "Erro",
						text: "Erro ao processar o arquivo, tente novamente",
						icon: "error"
					});
				});
		},
		resendContract(item) {
			let _this = this;

			//show alert confirmation
			_this.$swal({
				title: "Reenviar Contrato",
				text: "Tem certeza que deseja enviar novamente o Contrato para o Detran?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Enviar",
				cancelButtonText: "Cancelar"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {

					//remove user
					axios.get("/portal/contratos/" + item.detran_id + "/" + item.id + "/resend").then((res) => {

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Contrato reenviado com sucesso!",
							icon: "success"
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha ao reenviar o contrato. Tente novamente!",
							icon: "error"
						});

					});

				}//end validate isConfirmed

			});
			//end alert confirmation
		},
		loadFilters() {
			let _this = this;
			_this.loadDetrans();
			_this.loadStatusContratos();
		},
		editContract(id, estado, bancoId) {

			let url = `/portal/contratos/edit/${estado}/${id}`;
			if (this.userGpEcon.is_group_economic == 'S') {
				url = `/portal/contratos/edit/${estado}/${id}/${bancoId}`
			}
			location.href = url;
		},

		uploadImage(id, estado, ativarAba) {

			// Redirecione para a página de edição
			location.href = "/portal/contratos/" + estado + "/" + id + "/uploadImage";
		},
		//Método upload único
		uploadNovoPdf($id, $estado) {
			this.pdf_id = $id;
			this.pdf_estado = $estado;
			$("#modalUploadNovoPdf").modal('show');
		},
		updateCodigoAgente() {
			let _this = this;
			_this.loading = true;
			let dataDetranBanco = {
				id: _this.id_detran_banco,
				codigo_agente: _this.codigo_agente
			}
			axios.post('/portal/perfil/updateCodigoAgente', dataDetranBanco).then((res) => {

				_this.loading = false;

				$("#modalCodigoAgente").modal('hide');

				//show confirm alert
				_this.$swal({
					title: 'Sucesso',
					text: 'Código do Agente atualizado com sucesso!',
					icon: 'success'
				});
			}).catch((err) => {

				_this.loading = false;

				//show error alert
				_this.$swal({
					title: 'Erro',
					text: 'Falha ao atualizar o Código do Agente. Tente novamente!',
					icon: 'error'
				});
			});
		},
		multiContract() {

			//valida quantidade de detrans para instituição
			var detransInstitution = this.getData('detrans');

			if (detransInstitution.length > 1) {

				// trabalha com mais de um detran
				this.$swal({
					title: 'Selecione o estado',
					input: 'select',
					inputOptions: {
						'AC': 'AC',
						'AP': 'AP',
						'BA': 'BA',
						'ES': 'ES',
						'MG': 'MG',
						'PB': 'PB',
						'PE': 'PE',
						'SC': 'SC',
						'SP': 'SP',
						'RJ': 'RJ',
						'RR': 'RR',
						'MS': 'MS'
					},

					inputPlaceholder: 'Estado',
					showCancelButton: true,
					inputValidator: function (value) {
						return new Promise(function (resolve, reject) {
							if (value !== '') {
								resolve();
							} else {
								reject('A seleção do estado é obrigatória');
							}
						});
					}
				}).then(function (result) {
					if (result.value !== undefined) {
						location.href = "/portal/contratos/multi/" + result.value;
					}
				});

			} else {

				// trabalha somente com um detran
				location.href = "/portal/contratos/multi/" + detransInstitution[0].detran.estado;

			}

		},
		newContractGpEcon() {

			try {
				if (this.detranSelecionado.id !== undefined && this.bancoSelecionado.id !== undefined) {
					location.href = `/portal/contratos/create/${this.detranSelecionado.label}/${this.bancoSelecionado.id}`;
				} else {
					throw new Error('Selecione o Estado');
				}
			} catch (error) {
				this.$swal({
					title: "Erro",
					text: "Selecione o Estado",
					icon: "error"
				});
			}
		},
		newContract() {

			if (this.userGpEcon.is_group_economic == 'S') {

				this.newContractGpEcon()

			} else {
				//valida quantidade de detrans para instituição
				var detransInstitution = this.getData('detrans');
				var temp = {};

				detransInstitution.sort(function (a, b) { return (a.detran.estado > b.detran.estado ? 1 : (a.detran.estado === b.detran.estado ? 0 : -1)) });

				detransInstitution.map((row) => {
					if (row.detran.estado == 'AC') {
						temp = Object.assign(temp, { 'AC': 'AC' })
					} else if (row.detran.estado == 'AP') {
						temp = Object.assign(temp, { 'AP': 'AP' })
					} else if (row.detran.estado == 'BA') {
						temp = Object.assign(temp, { 'BA': 'BA' })
					} else if (row.detran.estado == 'MG') {
						temp = Object.assign(temp, { 'MG': 'MG' })
					} else if (row.detran.estado == 'PB') {
						temp = Object.assign(temp, { 'PB': 'PB' })
					} else if (row.detran.estado == 'PE') {
						temp = Object.assign(temp, { 'PE': 'PE' })
					} else if (row.detran.estado == 'SC') {
						temp = Object.assign(temp, { 'SC': 'SC' })
					} else if (row.detran.estado == 'SP') {
						temp = Object.assign(temp, { 'SP': 'SP' })
					} else if (row.detran.estado == 'RJ') {
						temp = Object.assign(temp, { 'RJ': 'RJ' })
					} else if (row.detran.estado == 'RR') {
						temp = Object.assign(temp, { 'RR': 'RR' })
					} else if (row.detran.estado == 'AL') {
						temp = Object.assign(temp, { 'AL': 'AL' })
					} else if (row.detran.estado == 'ES') {
						temp = Object.assign(temp, { 'ES': 'ES' })
					} else if (row.detran.estado == 'SE') {
						temp = Object.assign(temp, { 'SE': 'SE' })
					} else if (row.detran.estado == 'RO') {
						temp = Object.assign(temp, { 'RO': 'RO' })
					} else if (row.detran.estado == 'RS') {
						temp = Object.assign(temp, { 'RS': 'RS' })
					} else if (row.detran.estado == 'MT') {
						temp = Object.assign(temp, { 'MT': 'MT' })
					} else if (row.detran.estado == 'GO') {
						temp = Object.assign(temp, { 'GO': 'GO' })
					} else if (row.detran.estado == 'CE') {
						temp = Object.assign(temp, { 'CE': 'CE' })
					} else if (row.detran.estado == 'MS') {
						temp = Object.assign(temp, { 'MS': 'MS' })
					}

				})
				const detransBanco = Object.fromEntries(
					Object.entries(temp).sort(([, a], [, b]) => a - b)
				);

				if (detransInstitution.length > 1) {
					// Trabalha com mais de um detran
					this.$swal({
						title: `Instituição: ${this.nameBank}<br> Selecione o Estado abaixo:`,
						html: `
                    <div id="vue-select-container" style="position: relative; z-index: 1000;">
                    </div>
                `,
						showCancelButton: true,
						allowOutsideClick: false, // Impede que feche clicando fora
						didOpen: () => {
							const container = document.getElementById('vue-select-container');

							new this.$root.constructor({
								data: {
									options: Object.keys(detransBanco).map(key => key),
									selected: this.selected
								},
								template: `
                            <div>
                                <v-select
                                    v-model="selected"
                                    :options="options"
                                    placeholder="Selecione um estado."
                                    style="z-index: 1000; position: relative;">
                                </v-select>
                                <input id="selected_uf" type="hidden" :value="selected"> </input>
                            </div>`,
								components: {
									"v-select": vSelect,
								},
								watch: {
									selected(newValue) {
										document.getElementById("selected_uf").value = newValue;
									}
								}
							}).$mount(container);
						}
					}).then((result) => {
						if (result.isConfirmed) {
							let uf = document.getElementById("selected_uf").value;
							if (uf) {
								location.href = "/portal/contratos/create/" + uf;
							}
						}
					});

				} else {
					//trabalha somente com um detran
					location.href = "/portal/contratos/create/" + detransInstitution[0].detran.estado;

				}
			}

		},
		cancelContract(id, state) {
			let _this = this;

			if (state == 'BA') {

				axios
					.get("/api/portal/contratos/cancel/BA/" + id)
					.then((res) => {

						if (res.data.status == true) {

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "Baixa realizada com sucesso!",
								icon: "success"
							});

						}

					})
					.catch((err) => {
					});

			} else if (state == 'MG') {

				axios
					.get("/api/portal/contratos/cancel/MG/" + id)
					.then((res) => {

						if (res.data.status == true) {

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "Baixa realizada com sucesso!",
								icon: "success"
							});

						}

					})
					.catch((err) => {
					});

			}
		},
		validaStatus(id) {
			var nome = "";
			let _this = this;
			_this.contratoStatus.map((row) => {
				if (row.id == id) {
					nome = row.nome;
				}
			});
			return nome;
		},

		viewContract(id, estado) {
			location.href = "/portal/contratos/" + estado + "/" + id + "/view";
		},
		loadDetrans() {
			let _this = this;
			axios
				.get("/api/portal/detrans")
				.then((res) => {
					_this.detrans = res.data;
				})
				.catch((err) => {

				});
		},
		loadStatusContratos() {
			let _this = this;
			axios
				.get("/api/portal/contratostatus")
				.then((res) => {
					_this.contratoStatus = res.data;

					// Filtrar status com processamento = 'S' e obter os IDs
					_this.processamentoIds = res.data
						.filter((status) => status.processamento === 'S' || status.processamento === 's')
						.map((status) => status.id);

					// Filtrar status com pendente = 'S' e obter os IDs
					_this.pendentesIds = res.data
						.filter((status) => status.pendente === 'S' || status.processamento === 's')
						.map((status) => status.id);

				})
				.catch((err) => {

				});
		},
		loadDashboardData() {
			this.loadTotalContratos();
			this.loadConcluidoContratos();
			this.loadProcessandoContratos();
			this.loadPendenteContratos();
		},
		loadTotalContratos(dataInicial = null, dataFinal = null, detranId = null, tipoPeriodo = null) {

			let url = `/api/total-contratos/cliente/0/all/${new Date().getFullYear()}-01-01`
			if (this.userGpEcon.is_group_economic == 'S') {
				url = `/api/total-contratos/cliente/${this.userGpEcon.gp_econ_id}/all/${new Date().getFullYear()}-01-01`
				if (detranId, tipoPeriodo) {
					url = `/api/total-contratos/cliente/${this.userGpEcon.gp_econ_id}/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				} else if (detranId) {
					url = `/api/total-contratos/cliente/${this.userGpEcon.gp_econ_id}/${detranId}`
				} else if (tipoPeriodo) {
					url = `/api/total-contratos/cliente/${this.userGpEcon.gp_econ_id}/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				}
			} else if (detranId, tipoPeriodo) {
				url = `/api/total-contratos/cliente/0/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			} else if (detranId) {
				url = `/api/total-contratos/cliente/0/${detranId}`
			} else if (tipoPeriodo) {
				url = `/api/total-contratos/cliente/0/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			}

			axios.get(url)
				.then(res => {
					this.total = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadConcluidoContratos(dataInicial = null, dataFinal = null, detranId = null, tipoPeriodo = null) {
			let url = `/api/total-contratos-concluido/cliente/0/all/${new Date().getFullYear()}-01-01`
			if (this.userGpEcon.is_group_economic == 'S') {
				url = `/api/total-contratos-concluido/cliente/${this.userGpEcon.gp_econ_id}/all/${new Date().getFullYear()}-01-01`

				if (detranId, tipoPeriodo) {
					url = `/api/total-contratos-concluido/cliente/${this.userGpEcon.gp_econ_id}/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				} else if (detranId) {
					url = `/api/total-contratos-concluido/cliente/${this.userGpEcon.gp_econ_id}/${detranId}`
				} else if (tipoPeriodo) {
					url = `/api/total-contratos-concluido/cliente/${this.userGpEcon.gp_econ_id}/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				}
			} else if (detranId, tipoPeriodo) {
				url = `/api/total-contratos-concluido/cliente/0/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			} else if (detranId) {
				url = `/api/total-contratos-concluido/cliente/0/${detranId}`
			} else if (tipoPeriodo) {
				url = `/api/total-contratos-concluido/cliente/0/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			}

			axios.get(url)
				.then(res => {
					this.concluido = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadProcessandoContratos(dataInicial = null, dataFinal = null, detranId = null, tipoPeriodo = null) {

			let url = `/api/total-contratos-processando/cliente/0/all/${new Date().getFullYear()}-01-01`
			if (this.userGpEcon.is_group_economic == 'S') {
				url = `/api/total-contratos-processando/cliente/${this.userGpEcon.gp_econ_id}/all/${new Date().getFullYear()}-01-01`

				if (detranId, tipoPeriodo) {
					url = `/api/total-contratos-processando/cliente/${this.userGpEcon.gp_econ_id}/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				} else if (detranId) {
					url = `/api/total-contratos-processando/cliente/${this.userGpEcon.gp_econ_id}/${detranId}`
				} else if (tipoPeriodo) {
					url = `/api/total-contratos-processando/cliente/${this.userGpEcon.gp_econ_id}/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				}
			} else if (detranId, tipoPeriodo) {
				url = `/api/total-contratos-processando/cliente/0/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			} else if (detranId) {
				url = `/api/total-contratos-processando/0/${detranId}`
			} else if (tipoPeriodo) {
				url = `/api/total-contratos-processando/0/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			}

			axios.get(url)
				.then(res => {
					this.processando = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		loadPendenteContratos(dataInicial = null, dataFinal = null, detranId = null, tipoPeriodo = null) {

			let url = `/api/total-contratos-pendente/cliente/0/all/${new Date().getFullYear()}-01-01`
			if (this.userGpEcon.is_group_economic == 'S') {
				url = `/api/total-contratos-pendente/cliente/${this.userGpEcon.gp_econ_id}/all/${new Date().getFullYear()}-01-01`
				if (detranId, tipoPeriodo) {
					url = `/api/total-contratos-pendente/cliente/${this.userGpEcon.gp_econ_id}/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				} else if (detranId) {
					url = `/api/total-contratos-pendente/cliente/${this.userGpEcon.gp_econ_id}/${detranId}`
				} else if (tipoPeriodo) {
					url = `/api/total-contratos-pendente/cliente/${this.userGpEcon.gp_econ_id}/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
				}
			} else if (detranId, tipoPeriodo) {
				url = `/api/total-contratos-pendente/cliente/0/${detranId}/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			} else if (detranId) {
				url = `/api/total-contratos-pendente/cliente/0/${detranId}`
			} else if (tipoPeriodo) {
				url = `/api/total-contratos-pendente/cliente/0/all/${dataInicial}/${dataFinal}/${tipoPeriodo}`
			}

			axios.get(url)
				.then(res => {
					this.pendente = res.data;
				})
				.catch(error => {
					console.error(error);
				});
		},
		limparFiltros() {
			let _this = this;
			_this.selectedTypePeriodo = "";
			_this.showSelectedFilters = false;
			_this.pageDefault = 1;
			_this.navigate_validation = 1;
			_this.checkedDetrans = [];
			_this.checkedStatus = [];
			_this.arquivo = [];
			_this.checkedOperacao = [];
			_this.dataInicial = "";
			_this.dataFinal = "";
			_this.nome_agente = "";
			_this.nome_devedor = "";
			_this.chassi = "";
			_this.numero_contrato = "";
			_this.uf_devedor = "";
			_this.nome_municipio_devedor = "";
			_this.checkedModelos = "";
			_this.checkedTipos = [];
			_this.checkedMarcas = [];
			_this.checkedModelos = [];
			_this.checkedAgente = [];
			_this.numero_contrato = "";
			_this.placa_veiculo = "";
			_this.renavam_veiculo = "";
			_this.ano_veiculo_fab = "";
			_this.ano_veiculo_modelo = "";
			_this.numero_gravame = "";
			_this.checkedTipoContrato = [];
			_this.numero_contrato_eletronico = "";
			_this.cnpj_cpf_dev = "";
			_this.nome_agente_cadastro = "";
			_this.dataInicialContrato = "";
			_this.dataFinalContrato = "";
			_this.dataInicialRegistro = "",
				_this.dataFinalRegistro = "";
			_this.dataInicialBaixa = "";
			_this.dataFinalBaixa = "";
			_this.dataInicialCancelamento = "";
			_this.dataFinalCancelamento = "";
			_this.dataInicialEnvioImg = "";
			_this.dataFinalEnvioImg = "";
			_this.dataInicialRegDetran = "";
			_this.dataFinalRegDetran = "";
			_this.dataInicialPeriodo = "";
			_this.dataFinalPeriodo = "";
			_this.selectedPeriodo = ""
			_this.municipio_dev = '';
			_this.loadContratos();

			//set o valor de searchOn para false para buscar os dados quando clicar nas pagina do ContratosController@index
			_this.searchOn = false;
		},
		formatCurrency: function (value) {
			return Intl.NumberFormat("pt-BR", {
				style: "currency",
				currency: "BRL",
			}).format(value);
		},
		formatDate: function (value) {
			return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
		},
		formatFilterDate(date) {
			let d = new Date(date);
			let dateString = moment(String(d)).format("YYYY-MM-DD");
			return dateString;
		},
		openModalImport() {
			$("#modalImportarContratos").modal('show');
		},
		openModalConvencional() {
			$("#modalCadastoContratos").modal('show');
		},
		closeModalConvencinal() {
			$("#modalCadastoContratos").modal('hide');
		},
		verDetalheStatus(item) {
			let _this = this;
			_this.detalheStatusLoading = true;
			axios.get(`/api/portal/log/api/detalhe/${item.id}/${item.detrans.estado}`)
				.then((res) => {
					if (Object.keys(res.data).length === 0) {
						_this.$swal({
							icon: 'info',
							title: 'Sem Atualizações',
							text: 'Não há informações disponíveis para exibir',
							confirmButtonText: 'OK'
						});
					} else {
						_this.datalheStatus = res.data;
						_this.$refs.modal.open("modal");
					}
				}).catch((error) => {
					console.error('Erro ao buscar os dados:', error);
				});
		},
		fecharModal() {
			let _this = this;
			_this.datalheStatus = false;
			_this.$refs.modal.close("modal");
		},
		fecharModalSng() {
			let _this = this;
			_this.datalheStatus = false;
			_this.$refs.modalsng.close();
		},
		fecharModalRegistrado() {
			let _this = this;
			_this.datalheStatus = false;
			_this.$refs.modalcontratoregistrado.close();
		},
		fecharModalContratoEfetivado() {
			let _this = this;
			_this.datalheStatus = false;
			_this.$refs.modalcontratoefetivado.close();
		},
		closeModalImportacao() {
			let _this = this;
			_this.$refs.modalImportacao.close();
		},

		exportDataGrid(type, tableName) {
			let params = type == 'pdf' ?
				{
					type: 'pdf',
					ignoreColumn: [6],
					fileName: tableName,
					htmlContent: false,
					onCellHtmlData: (cell, row, col, htmlContent) => htmlContent,
					jspdf: {
						orientation: 'l',
						margins: { right: 10, left: 10, top: 40, bottom: 40 },
						autotable: {
							styles: {
								fillColor: 'inherit',
								textColor: 'inherit',
								halign: 'center',
								fillStyle: 'DF',
								lineColor: 200,
								lineWidth: 0.1
							},
							headerStyles: { halign: 'left' },
							tableWidth: 'auto'
						}
					}
				} :
				{
					type: 'excel',
					ignoreColumn: [6],
					htmlContent: false,
					fileName: tableName,
					mso: { fileFormat: 'xlsx' }
				};

			let options = { tableName: tableName };

			jQuery.extend(true, options, params);

			$('#vgt-table').tableExport(options);
		},
		viewUploadLote() {
			location.href = "/portal/contratos/upload"
		},
		handleFileUpload() {

			if (this.$refs.attach.files[0].size > 12 * 1024 * 1024) {
				this.$swal({
					title: "Erro",
					html: "Arquivo precisa ter no máximo 12mb de tamanho!",
					icon: "error"
				});

				return;
			} else {
				this.attach = this.$refs.attach.files[0];
			}
		},
		clearUploadPdf() {
			let _this = this;
			_this.attach = null;
			_this.$refs.attach.value = '';
			_this.pdf_id = "";
			_this.pdf_estado = "";
		},
		pdfUpload() {
			let _this = this;
			let formData = new FormData();

			formData.append('arquivo', _this.attach);
			formData.append('id', _this.pdf_id);
			formData.append('estado', _this.pdf_estado);

			_this.loading = true;

			axios.post('/portal/contratos/upload/modal/update', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
				_this.clearUploadPdf();
				_this.loading = false;
				if (res.data.success) {
					$("#modalUploadNovoPdf").modal('hide');
					_this.$swal({
						title: 'Sucesso',
						text: 'PDF atualizado com sucesso',
						icon: 'success',

					}).then(() => {
						this.loadContratos();
					});
				} else {
					$("#modalUploadNovoPdf").modal('hide');
					_this.$swal({
						title: 'Erro',
						text: res.data.error,
						icon: 'error'
					});
				}
			}).catch((err) => {
				_this.loading = false;
				$("#modalUploadNovoPdf").modal('hide');
				_this.clearUploadPdf();

				_this.$swal({
					title: 'Erro',
					text: 'Falha ao atualizar PDF. Tente novamente!',
					icon: 'error'
				});

			});
		},
		gerarCertidao(chassi, id, estado) {

			this.loading = true;
			axios.get("/portal/export/" + chassi + '/' + estado + '/' + id + "/gerarCertidao").then((res) => {

				this.loading = false;
				location.href = res.config.url;
			}).catch((err) => {

			});
		},
		gerarCertidaoResumida(chassi, id, estado) {

			this.loading = true;
			axios.get("/portal/export/" + chassi + '/' + estado + '/' + id + "/gerarResumo").then((res) => {
				this.loading = false;
				location.href = res.config.url;
			});
		},
		getSNG(id) {

			let _this = this;

			axios.get("/portal/contratos/contract-es-sng/" + id)
				.then((res) => {
					_this.returnSng = res.data.message;
				});
			_this.$refs.modalsng.open();

		},
		getContratoRegistrado(id) {

			let _this = this;

			axios.get("/portal/contratos/contract-es-registrado/" + id)
				.then((res) => {
					_this.returnContratoregistrado = res.data.message;
				});
			_this.$refs.modalcontratoregistrado.open();
		},
		getContratoEfetivado(id) {
			let _this = this;

			axios.get("/portal/contratos/contract-es-registrado-efetivado/" + id)
				.then((res) => {
					_this.returnContratoefetivado = res.data.message;
				});
			_this.$refs.modalcontratoefetivado.open();
		},
		getConsultaContratoSp(chassi) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/sp/1/${chassi}`
		},
		getConsultaContratoMg(id, chassi, gravame) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/mg/${id}/${chassi}/${gravame}`
		},
		getConsultaContratoBa(id, chassi) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/ba/${id}/${chassi}`
		},
		getConsultaContratoSc(id, chassi, gravame) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/sc/${id}/${chassi}/${gravame}`
		},
		getConsultaContratoRj001(id, chassi) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/rj/${id}/${chassi}/001`
		},
		getConsultaContratoRj003(id, chassi) {
			this.loading = true;
			window.location.href = process.env.MIX_URL + `consulta/rj/${id}/${chassi}/003`
		},
		canceContratoDetran(contrato) {

			let _this = this;

			_this.$swal({
				title: `Cancelamento de Contrato DETRAN ${contrato.detrans.estado}`,
				text: "Realmente deseja cancelar o contrato junto ao Detran?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "SIM",
				cancelButtonText: "NÂO"
			}).then((alertConfirm) => {

				//validate isConfirmed
				if (alertConfirm.isConfirmed) {
					let uf = contrato.detrans.estado.toLowerCase()
					//remove user
					axios.delete(`/portal/contratodetran${uf}/${contrato.id}`).then((res) => {

						//show confirm alert
						_this.$swal({
							title: "Sucesso",
							text: "Em instantes sua solicitação de cancelamento será disparada ao DETRAN",
							icon: "success"
						}).then(() => {
							_this.loadContratos();
						});

					}).catch(error => {

						//show error alert
						_this.$swal({
							title: "Erro",
							text: "Falha em solicitar o cancelamento. Tente novamente!",
							icon: "error"
						});

					});

				}

			});
		},
		formatShortDate: function (value) {
			return moment(String(value)).format('DD/MM/YYYY');
		},
		handleUfChange(selectedUf) {
			if (selectedUf != undefined && selectedUf != '') {
				this.loadMunicipios(selectedUf);


			}
		},
		loadMunicipios(uf_devedor) {
			let _this = this;
			if (uf_devedor != undefined && uf_devedor != '') {
				axios.get("/api/portal/detrancidades/search/" + uf_devedor).then((res) => {
					_this.municipios = res.data;
				});
			}
		},
		setSelectedDevedor(item) {
			let _this = this;
			let codigo = item;
			_this.nome_municipio_devedor = codigo.codigo_origem;
			_this.municipio_dev = codigo.municipio

		},
		setSelectedState(item) {
			let _this = this;
			let estado = item;
			_this.uf_devedor = estado;
			if (_this.uf_devedor == 'Selecione um estado...') {
				_this.$swal({
					title: "Erro",
					text: " selecione um estado valido para a pesquisa!",
					icon: "error"
				});
			}
		},
		setPLaceState() {
			let _this = this
			_this.getPlaceStates = 'Estado Devedor';
			return _this.getPlaceStates
		},

		setPLaceMuni() {
			let _this = this
			_this.getPlaceMuni = 'Selecione o municipio';
			return _this.getPlaceMuni
		},

		resized() {
			var tb = document.getElementById('vgt-table')
			tb.style.height = '380px';
		},

		resizedM() {
			var tb = document.getElementById('vgt-table')
			if (tb.style.height == '380px') {
				tb.style.height = 'auto';
			}
		}
	},
	components: {
		VueGoodTable,
		Datepicker,
		SweetModal,
		SweetModalTab,
		vSelect,
		VuePagination,
	},
};
</script>
<style>
#filtro.vs--searchable .vs__dropdown-toggle {
	height: 30px !important;
	background-color: #fdfdff !important;
	text-align: center;
	padding-left: 25px;
	font-size: 12px;
	border-color: #e4e6fc !important;
	border-radius: 30px;
}

#filtro.vs__selected {
	margin: 10px 2px 0 !important;
}

.contracts-filter input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 12px;
	text-align: center;
}

.swal2-actions {
	z-index: auto !important;
	/* Remove ou redefine o z-index */
}
</style>
